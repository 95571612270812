import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import Button from '@mui/material/Button';
import * as Yup from 'yup';

import {
  defaultFontBold,
  defaultFont,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  denimColor,
  dimGrayColor,
  whiteSmokeColor,
  romanColor,
} from '~/styles/themes/common-styles/color';
import * as NetworkActions from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';
import { ICluster, INetwork } from '~/types/network-types';
import { Account } from '~/types/account-types';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInput from './custom-input';
import SubmitButton from './submit-button';
// React i18next
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { MAX_CLUSTER_NAME_LENGTH } from '~/constants/validation';

interface IStateProps {
  accountSeleted?: Account;
}

interface IDispProps {
  updateCluster: (
    args: NetworkActions.MutationUpdateClusterArgs,
  ) => Promise<NetworkActions.UPDATE_CLUSTER_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
  network: INetwork;
  cluster: ICluster;
}

interface IState {}

class EditClusterDialog extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { classes, open, onClose, cluster, t } = this.props;
    const initialValues: FormValues = {
      clusterName: cluster.clusterName,
    };

    const validateSchema = Yup.object().shape<FormValues>({
      clusterName: Yup.string()
        .required(t('required_field'))
        .max(
          MAX_CLUSTER_NAME_LENGTH,
          t('too_many_character_of_clustername_error_message', { value: MAX_CLUSTER_NAME_LENGTH }),
        ),
    });

    return (
      <CustomDialog open={open} onClose={onClose}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-cluster-edit-title">{t('edit_cluster')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div>
                  <div className={classes.formLabel}>{t('cluster_id')}</div>
                  <div>
                    <CustomInput
                      id="member-cluster-edit-ident"
                      value={cluster.clusterUuid}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className={classes.formSection}>
                  <Field name="clusterName" render={this.clusterNameField} />
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  id="member-cluster-edit-cancel"
                  disabled={isSubmitting}
                  className={classes.leftBtn}
                  onClick={onClose}
                  variant="contained"
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  id="member-cluster-edit-submit"
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { updateCluster, onClose, cluster, network, accountSeleted } = this.props;

    if (!accountSeleted) {
      return;
    }

    try {
      await updateCluster({
        input: {
          accountUuid: accountSeleted.accountUuid,
          networkUuid: network.networkUuid,
          clusterUuid: cluster.clusterUuid,
          clusterName: values.clusterName.trim(),
        },
      });
      onClose();
    } catch (error) {
      setSubmitting(false);
    }
  };

  private clusterNameField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('cluster_name')}</div>
          {!!form.errors.clusterName && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.clusterName}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-cluster-edit-name"
            placeholder={this.props.t('input_cluster_name')}
          />
        </div>
      </>
    );
  };
}

const styles: StyleRules = {
  root: {},
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  // submit button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
};

const mapStateToProps = (store: IStore): IStateProps => ({
  accountSeleted: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  updateCluster: (args: NetworkActions.MutationUpdateClusterArgs) =>
    dispatch(NetworkActions.updateCluster(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditClusterDialog)),
);

type FormValues = {
  clusterName: string;
};

const MAX_LENGTH_CLUSTER_NAME = 30;

const validateSchema = Yup.object().shape({
  clusterName: Yup.string()
    .trim()
    .required('Required')
    .max(MAX_LENGTH_CLUSTER_NAME, 'Cluster name too long!'),
});
