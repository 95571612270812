import React from 'react';
// Redux
import classNames from 'classnames';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
// Component
import TableRowBodyCustom from '~/components/common/table-row-body';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import ImgIcon from '~/components/common/img-icon';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import EditButton from '~/components/common/edit-button';
import DeleteCustomDomainDialog from '../delete-custom-domain-dialog';
import ViewCustomDomainDialog from '../view-custom-domain-dialog';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  denimColor,
  matterhornColor,
  nightRiderColor,
  pattensBlueColor,
  whiteColor,
  whiteSmokeColor,
} from '~/styles/themes/common-styles/color';
// Type
import { Account, AccountRoleType, NetworkRoleType } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import LGButton from '~/components/common/lg-button';
import { CustomDomainDetailInfo, CustomDomainInfo } from '~/gapi/gtypes';
import { ICluster, INetwork } from '~/types/network-types';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';

interface IStateProps {}

interface IDispProps {
  checkCustomDomainStatus: (
    args: NetworkActions.MutationCheckCustomDomainStatusArgs,
  ) => Promise<NetworkActions.CHECK_CUSTOM_DOMAIN_STATUS_RESULT_TYPE>;
  listCustomDomains: (
    args: NetworkActions.QueryListCustomDomainsArgs,
  ) => Promise<NetworkActions.LIST_CUSTOM_DOMAINS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  customDomainInfo: CustomDomainDetailInfo;
  account: Account;
  network: INetwork;
  cluster: ICluster;
  isOpenView: boolean;
  disabled: boolean;
}

interface IState {
  openViewDomainDialog: boolean;
  openDeleteCustomDomainDialog: boolean;
  menuAnchorEl: HTMLElement | null;
}

class CustomDomainTableRow extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openViewDomainDialog: props.isOpenView,
      openDeleteCustomDomainDialog: false,
      menuAnchorEl: null,
    };
  }

  public render() {
    const { classes, customDomainInfo, account, network, cluster } = this.props;
    const { openViewDomainDialog, openDeleteCustomDomainDialog } = this.state;
    const statusTypeClasses = classNames({
      [classes.status]: true,
      [classes.backgroundRomanColor]: customDomainInfo.status === 'failure',
      [classes.backgroundSummerSkyColor]: customDomainInfo.status === 'verified',
      [classes.backgroundDarkColor]: customDomainInfo.status === 'pending',
    });
    return (
      <>
        {this.renderOptionPopover}
        <DeleteCustomDomainDialog
          open={openDeleteCustomDomainDialog}
          onClose={this.onCloseDeleteCustomDomainDialog}
          customDomainInfo={customDomainInfo}
          account={account}
          network={network}
          cluster={cluster}
        />
        <ViewCustomDomainDialog
          open={openViewDomainDialog}
          onClose={this.onCloseViewDomainDialog}
          customDomainInfo={customDomainInfo}
        />
        <TableRowBodyCustom>
          <TableCellBodyCustom>
            <IconButton
              className={classes.refreshIcon}
              onClick={this.onClickRefreshBtn(customDomainInfo.certArn)}
              disabled={this.props.disabled}
            >
              <img src="/images/icons/refresh_icon.svg" alt="" />
            </IconButton>
          </TableCellBodyCustom>
          <TableCellBodyCustom>
            <div className={classes.statusArea}>
              <div className={statusTypeClasses}>{customDomainInfo.status}</div>
            </div>
          </TableCellBodyCustom>
          <TableCellBodyCustom>{customDomainInfo.domainName}</TableCellBodyCustom>
          <TableCellBodyCustom>
            <div className={classes.viewDomainBtnArea}>
              <Button
                classes={{ root: classes.viewDomainBtn }}
                onClick={this.onOpenViewDomainDialog}
              >
                {this.props.t('view')}
              </Button>
            </div>
          </TableCellBodyCustom>
          <TableCellBodyCustom>
            <IconButton onClick={this.onOpenOptionPopover}>
              <ImgIcon className={classes.menuIcon} imgUrl="/images/icons/options-ico.png" />
            </IconButton>
          </TableCellBodyCustom>
        </TableRowBodyCustom>
      </>
    );
  }

  private onOpenViewDomainDialog = () => {
    this.setState({
      openViewDomainDialog: true,
    });
  };

  private onCloseViewDomainDialog = () => {
    this.setState({
      openViewDomainDialog: false,
    });
  };

  private onOpenDeleteCustomDomainDialog = () => {
    this.setState({
      openDeleteCustomDomainDialog: true,
    });
  };

  private onCloseDeleteCustomDomainDialog = () => {
    this.setState({
      openDeleteCustomDomainDialog: false,
    });
  };

  onClickRefreshBtn = (certArn: string) => () => {
    const { account, network, cluster, checkCustomDomainStatus, listCustomDomains } = this.props;
    checkCustomDomainStatus({
      input: {
        accountUuid: account.accountUuid,
        networkUuid: network.networkUuid,
        clusterUuid: cluster.clusterUuid,
        certArn: certArn,
      },
    });
    listCustomDomains({
      input: {
        accountUuid: account.accountUuid,
        networkUuid: network.networkUuid,
        clusterUuid: cluster.clusterUuid,
      },
    }).catch(console.log);
  };

  onOpenOptionPopover = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ menuAnchorEl: e.currentTarget });
  };
  onCloseOptionPopover = () => {
    this.setState({ menuAnchorEl: null });
  };

  get renderOptionPopover() {
    const { classes, t } = this.props;
    const { menuAnchorEl } = this.state;

    return (
      <Popover
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={this.onCloseOptionPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <List>
          <ListItem
            button
            className={classes.listOptionItem}
            onClick={this.onOpenDeleteCustomDomainDialog}
            disabled={this.props.disabled}
          >
            <ImgIcon className={classes.deleteIcon} imgUrl="/images/icons/delete_ico.png" />
            {t('delete')}
          </ListItem>
        </List>
      </Popover>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    viewDomainBtnArea: {
      textAlign: 'center',
      backgroundColor: whiteColor,
    },
    viewDomainText: {
      ...defaultFontRegular,
      fontSize: 15,
      color: whiteColor,
      textTransform: 'uppercase',
    },
    viewDomainBtn: {
      ...defaultFontBold,
      fontSize: 16,
      color: whiteColor,
      backgroundColor: denimColor,
      '&:hover': {
        backgroundColor: denimColor,
      },
      width: 142,
    },
    menuIcon: {},
    listOptionItem: {
      height: 46,
      width: 210,
      ...defaultFont,
      fontSize: 15,
      color: nightRiderColor,
    },
    deleteIcon: {
      marginRight: 8,
    },
    refreshIcon: {
      width: 40,
      minWidth: 40,
      height: 34,
      padding: 0,
    },
    statusArea: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 34,
      marginTop: 10,
      marginBottom: 10,
      borderRadius: 4,
      paddingRight: 10,
    },
    status: {
      ...defaultFontMedium,
      marginLeft: 10,
      display: 'flex',
      alignItems: 'center',
      height: 24,
      borderRadius: 14,
      paddingLeft: 15,
      paddingRight: 15,
      fontSize: 12,
      color: matterhornColor,
      textAlign: 'center',
    },
    backgroundRomanColor: {
      // backgroundColor: romanColor,
      backgroundColor: 'rgb(227, 90, 90, 0.2)',
    },
    backgroundSummerSkyColor: {
      // backgroundColor: summerSkyColor,
      backgroundColor: 'rgb(64, 194, 230, 0.2)',
    },
    backgroundDarkColor: {
      backgroundColor: 'rgb(136, 136, 136, 0.2)',
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({
  checkCustomDomainStatus: (args: NetworkActions.MutationRemoveCustomDomainArgs) =>
    dispatch(NetworkActions.checkCustomDomainStatus(args)),
  listCustomDomains: (args: NetworkActions.QueryListCustomDomainsArgs) =>
    dispatch(NetworkActions.listCustomDomains(args)),
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomDomainTableRow)),
);
