import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFontMedium, defaultFontBold } from '~/styles/themes/common-styles/font';
import { nightRiderColor } from '~/styles/themes/common-styles/color';
import * as PaymentAction from '~/stores/actions/payment-action';
import moment from 'moment-timezone';
import { Theme } from '@mui/material/styles';

// Component
import TableHeadCustom from '~/components/common/table-head';
import TableBodyCustom from '~/components/common/table-body';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import TableRowHeadCustom from '~/components/common/table-row-head';
import TableRowBodyCustom from '~/components/common/table-row-body';
import Table from '@mui/material/Table';
import LoadingIcon from '~/components/common/loading-icon';
import SortIcon from '~/components/common/sort-icon';
import DatePicker from '~/components/common/date-picker';
// Style
import classNames from 'classnames';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Type
import { Account, Profile } from '~/types/account-types';
import { ICoupon } from '~/types/payment-types';
// Utils
import { displayCurrency } from '~/utilities/payment-utils';
import { Order, getComparator, stableSort, HeadCell } from '~/utilities/sort-utils';
import { isDevMode } from '~/utilities/utils';

interface IStateProps {
  accountSelected?: Account;
  coupons: ICoupon;
  listCouponsLoading: boolean;
}

interface IDispProps {
  listCoupons: (
    args: PaymentAction.QueryListCouponsArgs,
  ) => Promise<PaymentAction.LIST_COUPONS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {}

interface IState {
  openRegisterCardDialog: boolean;
  openRegisterAddressDialog: boolean;
  order: Order;
  orderBy: SortableHeadCellId;
}

class CouponSection extends React.Component<
  IProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);

    this.state = {
      openRegisterCardDialog: false,
      openRegisterAddressDialog: false,
      order: 'ASC',
      orderBy: 'createAt',
    };
  }

  async componentDidMount() {
    const { coupons } = this.props;

    if (!coupons.details) {
      this.listCoupons();
    }
  }

  public render() {
    const { classes, t, coupons, listCouponsLoading } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.couponTitleArea}>
          <span id="member-billing-coupon-caption" className={classes.couponTitle}>
            {t('coupons')}
          </span>
          <div className={classes.datePickerArea}>
            <div className={classes.startMonthPicker}>
              <div className={classes.datePickerLabel}>{t('start_month')}</div>
              <DatePicker
                classes={{ textField: classes.datePicker }}
                datePickerInlineProps={{
                  value: new Date(coupons.startPeriod),
                  onChange: this.onStartDatePickerClick,
                  views: ['month', 'year'],
                  disabled: listCouponsLoading,
                  maxDate: new Date(coupons.endPeriod),
                  inputFormat: 'YYYY/MM',
                  className: classes.dtPicker,
                }}
              />
            </div>
            <div className={classes.endMonthPicker}>
              <div className={classes.datePickerLabel}>{t('end_month')}</div>
              <DatePicker
                classes={{ textField: classes.datePicker }}
                datePickerInlineProps={{
                  value: new Date(coupons.endPeriod),
                  onChange: this.onEndDatePickerClick,
                  views: ['month', 'year'],
                  disabled: listCouponsLoading,
                  minDate: new Date(coupons.startPeriod),
                  inputFormat: 'YYYY/MM',
                  className: classes.dtPicker,
                }}
              />
            </div>
          </div>
        </div>
        {this.couponTable}
      </div>
    );
  }

  get couponTable() {
    const { t, classes, listCouponsLoading } = this.props;
    const { order, orderBy } = this.state;

    if (listCouponsLoading) {
      return (
        <div className={classes.root}>
          <div className={classes.loadingArea}>
            <LoadingIcon />
          </div>
        </div>
      );
    }

    const items = this.getItems();
    const totalBalance = items.map((item) => item.balance).reduce((a, b) => a + b, 0);

    return (
      <>
        <Table id="member-billing-coupon-list" className={classes.couponTable}>
          <TableHeadCustom>
            <TableRowHeadCustom>
              {headCells.map((headCell) => (
                <TableCellHeadCustom
                  key={headCell.id}
                  onClick={headCell.sortable ? this.onTableCellHeadClick(headCell.id) : undefined}
                  style={{ cursor: headCell.sortable ? 'pointer' : 'unset' }}
                  classes={{ content: classes.tableCellHeadContent }}
                >
                  <span>{t(headCell.label)}</span>
                  {headCell.sortable && (
                    <SortIcon order={orderBy === headCell.id ? order : undefined} />
                  )}
                </TableCellHeadCustom>
              ))}
            </TableRowHeadCustom>
          </TableHeadCustom>
          <TableBodyCustom>
            {items.map((item) => (
              <TableRowBodyCustom key={item.id} className={classes.tableRowBodyCustom}>
                <TableCellBodyCustom className={classes.createAt}>
                  {t('billing_payment_tab_granted_date', { date: new Date(item.createAt) })}
                </TableCellBodyCustom>
                <TableCellBodyCustom className={classes.name}>{item.name}</TableCellBodyCustom>
                <TableCellBodyCustom className={classes.expiredAt}>
                  {t('billing_payment_tab_expried_date', { date: new Date(item.expiredAt) })}
                </TableCellBodyCustom>
                <TableCellBodyCustom className={classes.balance}>
                  ${displayCurrency(item.balance)}
                </TableCellBodyCustom>
              </TableRowBodyCustom>
            ))}
          </TableBodyCustom>
        </Table>
        <div id="member-billing-coupon-total-balance" className={classes.totalBalance}>
          <span>
            {t('coupon_balance')}: ${displayCurrency(totalBalance)}
          </span>
        </div>
      </>
    );
  }

  onStartDatePickerClick = (date: Date | null) => {
    const { coupons, listCoupons, accountSelected } = this.props;
    accountSelected &&
      !moment(date).isSame(moment(coupons.startPeriod), 'month') &&
      listCoupons({
        accountUuid: accountSelected.accountUuid,
        startDate: moment(date).startOf('month').toISOString(),
      });
  };

  onEndDatePickerClick = (date: Date | null) => {
    const { coupons, listCoupons, accountSelected } = this.props;
    accountSelected &&
      !moment(date).isSame(moment(coupons.endPeriod), 'month') &&
      listCoupons({
        accountUuid: accountSelected.accountUuid,
        endDate: moment(date).endOf('month').toISOString(),
      });
  };

  private getItems = (): Array<Item> => {
    const { coupons } = this.props;
    const { order, orderBy } = this.state;
    const nowUtc = moment().utc().toDate();

    const items: Array<Item> =
      (coupons.details || []).map((coupon) => {
        const expiredUtc = moment(coupon.expiredAt).utc().toDate();
        return {
          id: coupon.id,
          createAt: moment(coupon.createAt).utc().toDate(),
          name: coupon.name,
          expiredAt: expiredUtc,
          balance: expiredUtc > nowUtc ? coupon.balance : 0,
        };
      }) || [];

    return stableSort<Item>(items, getComparator<SortableHeadCellId>(order, orderBy));
  };

  private onTableCellHeadClick = (id: SortableHeadCellId) => () => {
    const { order, orderBy } = this.state;
    this.setState({
      orderBy: id,
      order: orderBy === id && order === 'ASC' ? 'DESC' : 'ASC',
    });
  };

  private listCoupons = () => {
    const { accountSelected, listCoupons } = this.props;

    if (accountSelected) {
      listCoupons({ accountUuid: accountSelected.accountUuid });
    }
  };
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 30,
    },
    loadingArea: {
      marginTop: 20,
      textAlign: 'center',
    },
    couponTitleArea: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    datePickerArea: {
      display: 'flex',
      alignItems: 'center',
    },
    startMonthPicker: {
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    endMonthPicker: {
      display: 'flex',
      alignItems: 'center',
    },
    datePickerLabel: {
      marginRight: 5,
      fontSize: 14,
    },
    couponTable: {
      marginTop: 20,
      width: '100%',
    },
    couponTitle: {
      ...defaultFontBold,
      fontSize: 18,
      color: nightRiderColor,
    },
    tableCellHeadContent: {
      paddingRight: 20,
    },
    totalBalance: {
      ...defaultFontMedium,
      fontSize: 12,
      marginTop: 20,
      textAlign: 'right',
    },
    dtPicker: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      datePickerLabel: {
        fontSize: 13,
        marginRight: 5,
      },
      dtPicker: {
        width: 100,
        boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
      },
      totalBalance: {
        fontSize: 24,
        lineHeight: 0.5,
      },
    },
    createAt: {
      width: '15%',
    },
    name: {
      width: '50%',
    },
    expiredAt: {
      width: '15%',
    },
    balance: {
      width: '20%',
    },
    datePicker: {
      '& .MuiInputBase-root': {
        width: 130,
      },
    },
    tableRowBodyCustom: {
      height: '48px',
      '& .MuiTableCell-root': {
        letterSpacing: 'normal',
        padding: '4px 30px 4px 10px',
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  accountSelected: store.appState.accountSeleted,
  coupons: store.appState.coupons,
  listCouponsLoading: PaymentAction.listCoupons.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  listCoupons: (args: PaymentAction.QueryListCouponsArgs) =>
    dispatch(PaymentAction.listCoupons(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CouponSection)),
);

export type Item = {
  id: string;
  createAt: Date;
  name: string;
  expiredAt: Date;
  balance: number;
};
type SortableHeadCellId = keyof Pick<Item, 'createAt' | 'expiredAt'>;
type UnsortableHeadCellId = keyof Pick<Item, 'name' | 'balance'>;
const headCells: Array<HeadCell<SortableHeadCellId, UnsortableHeadCellId>> = [
  { id: 'createAt', sortable: true, label: 'granted_date' },
  { id: 'name', sortable: false, label: 'coupon_name' },
  { id: 'expiredAt', sortable: true, label: 'expire_date' },
  { id: 'balance', sortable: false, label: 'coupon_balance' },
];
