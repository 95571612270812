import React from 'react';
import { connect } from 'react-redux';
// Component
import Button from '@mui/material/Button';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';

// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import {
  romanColor,
  dodgerBlueColor,
  whiteColor,
  pelorousColor2,
  denimColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';

interface IStateProps {}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
}

interface IState {}

class RegistrationDialog extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { classes, open, onClose, t } = this.props;

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <CustomDialogTitle className={classes.dialogTitle}>
          {t('registration_title')}
        </CustomDialogTitle>
        {/* Render Form Field */}
        <CustomDialogContent classes={{ root: classes.dialogText }}>
          {t('registration_content')}
        </CustomDialogContent>
        {/* Ok Button */}
        <CustomDialogActions classes={{ root: classes.dialogAction }}>
          <Button id="okay-button" className={classes.leftBtn} variant="outlined" onClick={onClose}>
            OK
          </Button>
        </CustomDialogActions>
      </CustomDialog>
    );
  }
}

const styles = createStyles({
  root: {},
  dialogPaper: {
    maxWidth: 600,
  },
  dialogTitle: {
    fontSize: '24px !important',
    lineHeight: '0.5em',
    fontWeight: 500,
  },
  dialogText: {
    textAlign: 'left',
    fontStyle: 'normal',
    fontFamily: 'Roboto !important',
    fontSize: '12px !important',
    fontStretch: 'normal',
    lineHeight: '1.5em',
    color: '#333',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
    marginTop: 12,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  dialogAction: {
    paddingLeft: 80,
    paddingRight: 220,
    paddingBottom: 50,
  },
  leftBtn: {
    ...defaultFont,
    fontSize: 16,
    height: 36,
    color: `${whiteColor} !important`,
    width: 160,
    fontWeight: 700,
    backgroundColor: dodgerBlueColor,
    backgroundImage: `linear-gradient(to bottom, ${pelorousColor2} , ${denimColor})`,
    '&:hover, &:focus': {
      backgroundColor: dodgerBlueColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginBottom: 30,
  },
});

export default withStyles(styles)(connect()(withTranslation('admin')(RegistrationDialog)));
