import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as AppActions from '~/stores/actions/app-action';
// Localization
import { withTranslation, WithTranslation } from 'react-i18next';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  lightSlateGreyColor,
  matterhornColor,
  whiteSmokeColor,
  dimGrayColor,
} from '~/styles/themes/common-styles/color';
// Components
import Button from '@mui/material/Button';
import SubmitButton from '../../submit-button';
import ConfirmDeleteAccountDialog from '../../confirm-delete-account-dialog';
// Form
import { Formik, Form, FormikActions } from 'formik';
// Types
import { Account, AccountRoleType } from '~/types/account-types';
// Mixs
import { sleep } from '~/utilities/application';

interface IStateProps {
  account?: Account;
  selectedAccountRole?: AccountRoleType;
}

interface IDispProps {
  removeAccount: (
    args: AppActions.MutationRemoveAccountArgs,
  ) => Promise<AppActions.REMOVE_ACCOUNT_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  onClose: () => void;
}

interface IState {
  openConfirmDeleteAccountDialog: boolean;
}

class WithdrawalTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      openConfirmDeleteAccountDialog: false,
    };
  }

  render() {
    const { classes, account, t, removeAccount, onClose, selectedAccountRole } = this.props;
    const { openConfirmDeleteAccountDialog } = this.state;
    const removable = selectedAccountRole === 'owner';

    if (!account) {
      return null;
    }

    return (
      <div className={classes.root}>
        <ConfirmDeleteAccountDialog
          open={openConfirmDeleteAccountDialog}
          onClose={this.onCloseConfirmDeleteAccountDialog}
          removeAccount={removeAccount}
          account={account}
        />
        <div className={classes.updateAccountForm}>
          <Formik
            onSubmit={this.onWithdrawAccountSubmit}
            initialValues={{ confirmName: '' }}
            enableReinitialize
            render={({ isValid, isSubmitting }) => (
              <Form>
                <div>
                  <div className={classes.block} id="member-account-remove-des">
                    <div className={classes.please}>
                      {t('all_services_will_not_be_available_after_withdrawal')}
                    </div>
                    <div className={classes.note}>
                      {t('organization_setting_withdrawal_notice')}
                    </div>
                  </div>
                  <div className={classes.updateAccountBtnArea}>
                    <Button
                      id="member-confirm-delete-account-cancel"
                      disabled={isSubmitting}
                      className={classes.leftBtn}
                      onClick={onClose}
                      variant="contained"
                    >
                      {t('cancel')}
                    </Button>
                    {/* Render Submit Button */}
                    <SubmitButton
                      classes={{
                        root: classes.submitDelAccountBtn,
                      }}
                      id="member-account-remove-submit"
                      isValid={removable}
                      isSubmitting={isSubmitting}
                      label={t('delete_organization')}
                      submittingLabel={t('processing')}
                    />
                  </div>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    );
  }

  private onWithdrawAccountSubmit = async (
    values: WithdrawFormValues,
    formikActions: FormikActions<WithdrawFormValues>,
  ) => {
    const { setSubmitting } = formikActions;
    await sleep(1000);
    this.onOpenConfirmDeleteAccountDialog();
    setSubmitting(false);
  };

  private onOpenConfirmDeleteAccountDialog = () => {
    this.setState({
      openConfirmDeleteAccountDialog: true,
    });
  };

  private onCloseConfirmDeleteAccountDialog = () => {
    this.setState({
      openConfirmDeleteAccountDialog: false,
    });
  };
}

const styles = createStyles({
  root: {
    overflow: 'auto',
  },
  updateAccountForm: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  updateAccountBtnArea: {
    display: 'flex',
    padding: '20px 0',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  please: {
    ...defaultFont,
    fontSize: 15,
    color: matterhornColor,
  },
  note: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
    wordBreak: 'break-word',
  },
  block: {
    textAlign: 'center',
    marginBottom: 20,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  submitDelAccountBtn: {
    width: 200,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  account: store.appState.accountSeleted,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  removeAccount: (args: AppActions.MutationRemoveAccountArgs) =>
    dispatch(AppActions.removeAccount(args)),
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WithdrawalTab)),
);

type WithdrawFormValues = {
  confirmName: string;
};
