import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import { defaultFont, defaultFontBold } from '~/styles/themes/common-styles/font';
import {
  lightSlateGreyColor,
  whiteSmokeColor,
  nightRiderColor,
  romanColor,
  denimColor,
  persianGreenColor,
} from '~/styles/themes/common-styles/color';
import moment from 'moment-timezone';
// Component
import TableRowBodyCustom from '~/components/common/table-row-body';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import RemoveTwoToneIcon from '@mui/icons-material/RemoveTwoTone';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Type
import { IBillingDetail } from '~/types/payment-types';
import { calculateSubtotal, displayCurrency } from '~/utilities/payment-utils';
import {
  DEFAULT_COUNTRY_CODE,
  REGISTRATION_NO,
  LICENSE_TYPE_ID_STORAGE,
  PAYMENT_TYPE_RESULT_FAIL,
  TAX_PERCENTAGE,
} from '~/constants/consts';
// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';
import { Account } from '~/gapi/gtypes';
import { Button } from '@mui/material';
import { IBillParams } from '~/components/Receipt';
import { IInvoiceParams } from '~/components/Invoice';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  bill: IBillingDetail;
  account?: Account;
}

interface IState {
  isExpand: boolean;
}

class StatementMonthRow extends React.Component<
  IProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);
    this.state = {
      isExpand: false,
    };
  }

  public render() {
    const { classes, t, bill, account } = this.props;
    const { isExpand } = this.state;

    document.title = TAB_TITLE_CONCAT + this.props.t('bill_state');

    const receipt = (() => {
      try {
        return JSON.parse(bill.receipt);
      } catch (error) {
        return {};
      }
    })();
    const encodeReceiptURI = encodeURIComponent(
      JSON.stringify({
        id: bill.id,
        amount: bill.amount,
        tax: bill.tax,
        coupon: bill.coupon,
        paidDate: bill.paidDate,
        receipt: {
          source: {
            brand: receipt.source?.brand,
            last4: receipt.source?.last4,
          },
          description: receipt.description,
        },
      } as IBillParams),
    );
    const encodeInvoiceURI = encodeURIComponent(
      JSON.stringify({
        id: bill.id,
        amount: bill.amount,
        tax: bill.tax,
        coupon: bill.coupon,
        paidDate: bill.paidDate,
        invoicedDate: bill.invoicedDate,
        licenses: bill.licenses,
      } as IInvoiceParams),
    );

    return (
      <>
        <TableRowBodyCustom className={classes.tableBodyCustom}>
          <TableCellBodyCustom>
            {t('billing_date_value', { date: new Date(bill.invoicedDate) })}
          </TableCellBodyCustom>
          <TableCellBodyCustom>{bill.id}</TableCellBodyCustom>
          <TableCellBodyCustom>
            {bill.result === PAYMENT_TYPE_RESULT_FAIL ? (
              t('payment_failed')
            ) : Boolean(bill.paidDate) ? (
              t('paid')
            ) : (
              <span className={classes.paymentStatusUnpaidValue}>{t('unpaid')}</span>
            )}
          </TableCellBodyCustom>
          <TableCellBodyCustom>
            ${displayCurrency(bill.amount + bill.tax - bill.coupon)}
          </TableCellBodyCustom>
          <TableCellBodyCustom>
            <IconButton onClick={this.onExpandBtnClick} className={classes.btnIcon}>
              {isExpand ? <RemoveTwoToneIcon /> : <AddTwoToneIcon />}
            </IconButton>
          </TableCellBodyCustom>
        </TableRowBodyCustom>
        <TableRowBodyCustom className={classes.collapseTableRow}>
          <TableCellBodyCustom
            colSpan={5}
            className={classNames(
              classes.collapseTableRowCell,
              !isExpand && classes.collapseTableRowCollapse,
            )}
          >
            <Collapse in={isExpand}>
              <table
                id="member-billing-statement-detail-sub-item"
                className={classNames(classes.tableDetail, classes.tblbilldetails)}
              >
                <thead>
                  <tr className={classes.textHeading}>
                    <th colSpan={2}>{t('license_name')}</th>
                    <th>{t('size')}</th>
                    <th>{t('unit_price')}</th>
                    <th>{t('quantity')}</th>
                    <th className={classes.textAlignRight}>{t('subtotal')}</th>
                  </tr>
                </thead>
                <tbody>
                  {bill.licenses.map((license, n) => (
                    <tr className={classes.textDetails} key={n}>
                      <td colSpan={2}>{license.licenseName}</td>
                      <td>
                        {license.licenseItemId !== LICENSE_TYPE_ID_STORAGE ? '' : '1'}
                        {t(license.size)}
                      </td>
                      <td>${displayCurrency(license.unitPrice)}</td>
                      <td>{license.qty}</td>
                      <td className={classes.textAlignRight}>
                        ${displayCurrency(license.amount || 0)}
                      </td>
                    </tr>
                  ))}
                  <tr className={classNames(classes.textDetails, classes.subtotalRow)}>
                    <td colSpan={5}>{t('subtotal')}</td>
                    <td className={classes.textAlignRight}>${displayCurrency(bill.amount)}</td>
                  </tr>
                  <tr className={classes.textDetails}>
                    <td colSpan={5}>{t('amount_of_coupon_used')}</td>
                    <td className={classNames(classes.couponValue, classes.textAlignRight)}>
                      -${displayCurrency(bill.coupon)}
                    </td>
                  </tr>
                  <tr className={classNames(classes.textDetails)}>
                    <td colSpan={5}>{`${t('tax')} (${TAX_PERCENTAGE})`}</td>
                    <td className={classes.textAlignRight}>${displayCurrency(bill.tax)}</td>
                  </tr>
                  <tr className={classNames(classes.textDetails, classes.billingAmountRow)}>
                    <td colSpan={5}>{t('billing_amount')}</td>
                    <td className={classes.textAlignRight}>
                      ${displayCurrency(bill.amount + bill.tax - bill.coupon)}
                    </td>
                  </tr>
                  <tr className={classNames(classes.textDetails, classes.billingAmountRow)}>
                    <td colSpan={5}></td>
                    <td className={classes.textAlignRight}>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.invoiceBtn}
                        onClick={() =>
                          window.open(`/invoice?details=${encodeInvoiceURI}`, '_blank')
                        }
                      >
                        {t('view_invoice')}
                      </Button>
                      {Boolean(bill.paidDate) && bill.result == 1 && (
                        <Button
                          variant="contained"
                          className={classes.receiptBtn}
                          size="small"
                          onClick={() =>
                            window.open(`/receipt?details=${encodeReceiptURI}`, '_blank')
                          }
                        >
                          {t('view_receipt')}
                        </Button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Collapse>
          </TableCellBodyCustom>
        </TableRowBodyCustom>
      </>
    );
  }

  private onExpandBtnClick = () => {
    this.setState({
      isExpand: !this.state.isExpand,
    });
  };
}

const styles = (theme: Theme) =>
  createStyles({
    paymentStatusUnpaidValue: {
      color: romanColor,
    },
    tableDetail: {
      width: '100%',
      padding: '20px 10px 30px 22%',
      borderSpacing: 0,
    },
    textHeading: {
      '& th': {
        ...defaultFontBold,
        fontSize: 13,
        color: lightSlateGreyColor,
      },
    },
    textDetails: {
      '& td': {
        ...defaultFont,
        fontSize: 15,
        color: nightRiderColor,
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    subtotalRow: {
      '& td': {
        ...defaultFontBold,
        borderTop: `1px solid ${whiteSmokeColor}`,
        borderBottom: `1px solid ${whiteSmokeColor}`,
        paddingTop: 10,
      },
    },
    couponValue: {
      color: `${romanColor} !important`,
    },
    billingAmountRow: {
      '& td': {
        ...defaultFontBold,
        color: denimColor,
        borderTop: `1px solid ${whiteSmokeColor}`,
        paddingTop: 10,
      },
    },
    textAlignRight: {
      textAlign: 'right',
    },
    collapseTableRow: {
      height: 'unset',
    },
    collapseTableRowCell: {
      padding: 0,
    },
    collapseTableRowCollapse: {
      borderBottom: 'none',
    },
    tblbilldetails: {},
    dtPicker: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      tblbilldetails: {
        padding: '20px 0 23px',
        '& th': {
          paddingBottom: 12,
        },
        '& th:first-child': {
          width: 'auto !important',
        },
        '& th:nth-child(2)': {
          width: 'auto !important',
        },
        '& td': {
          padding: '7px 0 !important',
        },
        '& tr:nth-child(5) td, & tr:nth-child(6) td, & tr:nth-child(7) td, & tr:nth-child(8) td': {
          fontSize: '15px !important',
        },
      },
    },
    tableBodyCustom: {
      height: '57px',
      '& .MuiTableCell-root': {
        letterSpacing: 'normal',
        padding: '4px 30px 4px 10px',
      },
    },
    btnIcon: {
      padding: '12px',
    },
    invoiceBtn: {
      borderColor: persianGreenColor,
      color: persianGreenColor,
      '&:hover': {
        borderColor: persianGreenColor,
      },
    },
    receiptBtn: {
      marginLeft: 5,
      color: 'white',
      backgroundColor: persianGreenColor,
      '&:hover': {
        backgroundColor: persianGreenColor,
      },
    },
  });

export default withStyles(styles)(withTranslation()(StatementMonthRow));
