import React from 'react';
// Redux
import { connect } from 'react-redux';
import * as NetworkActions from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';
// Component
import CustomSelect from '~/components/common/custom-select-with-label';
import SubmitButton from '~/components/common/submit-button';
import JSONPretty from 'react-json-pretty';
import { SelectChangeEvent } from '@mui/material/Select';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
// Type
import { INetwork } from '~/types/network-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';

interface IStateProps {
  isGetSignersLoading: boolean;
  isGetProposalStatusLoading: boolean;
  isGetTxpoolStatusLoading: boolean;
}

interface IDispProps {
  getSigners: (
    args: NetworkActions.QueryGetSignersArgs,
  ) => Promise<NetworkActions.GET_SIGNERS_RESULT_TYPE>;
  getProposalStatus: (
    args: NetworkActions.QueryGetProposalStatusArgs,
  ) => Promise<NetworkActions.GET_PROPOSAL_STATUS_RESULT_TYPE>;
  getTxpoolStatus: (
    args: NetworkActions.QueryGetTxpoolStatusArgs,
  ) => Promise<NetworkActions.GET_TXPOOL_STATUS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  network: INetwork;
}

interface IState {
  nodeOptions: Array<Option>;
  selectedNodeUuid: string;
  selectedMethod: SelectMethod;
  result?: any;
}

class DebugTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const arr = this.getNodeSelectItems();
    this.state = {
      nodeOptions: arr,
      selectedNodeUuid: arr[0]?.value,
      selectedMethod: 'get_validator',
    };
  }

  public render() {
    const {
      classes,
      t,
      isGetSignersLoading,
      isGetProposalStatusLoading,
      isGetTxpoolStatusLoading,
    } = this.props;
    const { nodeOptions, selectedNodeUuid, selectedMethod, result } = this.state;

    document.title = TAB_TITLE_CONCAT + this.props.t('debug');

    return (
      <div className={classes.root}>
        <div className={classes.topLine}>
          <CustomSelect
            classes={{ root: classes.customSelect, menuListItem: classes.menuListItem }}
            label={t('nodes')}
            onChange={this.onChangeNodeUuid}
            items={nodeOptions}
            valueSelected={selectedNodeUuid}
            input
          />
          <CustomSelect
            classes={{ root: classes.customSelect, menuListItem: classes.menuListItem }}
            label={t('command')}
            onChange={this.onChangeMethod}
            items={commandSelection}
            valueSelected={selectedMethod}
            input
          />
          <SubmitButton
            kind="flat"
            isSubmitting={
              isGetSignersLoading || isGetProposalStatusLoading || isGetTxpoolStatusLoading
            }
            isValid
            classes={{
              root: classes.submitButton,
            }}
            label={t('run')}
            submittingLabel={t('running')}
            onClick={this.onSubmit}
          />
        </div>
        <div>
          {result && (
            <div className={classes.debugResult}>
              <JSONPretty
                style={{ color: '#ffffff', overflowX: 'auto', padding: '10px' }}
                data={JSON.parse(result)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  getNodeSelectItems = (): Array<Option> => {
    const { network } = this.props;
    const nodeSelectItems: Array<Option> = [];
    for (const cluster of network.clusters) {
      nodeSelectItems.push(
        ...cluster.nodes.map((node) => ({
          label: node.nodeName,
          value: node.nodeUuid,
        })),
      );
    }
    return nodeSelectItems;
  };

  onChangeNodeUuid = (event: SelectChangeEvent) => {
    this.setState({
      selectedNodeUuid: event.target.value as string,
      result: undefined,
    });
  };

  onChangeMethod = (event: SelectChangeEvent) => {
    this.setState({
      selectedMethod: event.target.value as SelectMethod,
      result: undefined,
    });
  };

  onSubmit = async () => {
    const { network, getTxpoolStatus, getProposalStatus, getSigners } = this.props;
    const { selectedNodeUuid, selectedMethod } = this.state;
    const cluster = network.clusters.find((c) =>
      c.nodes.find((n) => n.nodeUuid === selectedNodeUuid),
    );

    let result: any = void 0;

    switch (selectedMethod) {
      case 'get_validator':
        result = (
          await getSigners({
            networkUuid: network.networkUuid,
            nodeUuid: selectedNodeUuid,
          })
        ).getSigners;
        break;

      case 'get_proposal':
        result = (
          await getProposalStatus({
            networkUuid: network.networkUuid,
            nodeUuid: selectedNodeUuid,
          })
        ).getProposalStatus;
        break;

      case 'get_txpool':
        result = (
          await getTxpoolStatus({
            networkUuid: network.networkUuid,
            clusterUuid: cluster ? cluster.clusterUuid : '',
            nodeUuid: selectedNodeUuid,
          })
        ).getTxpoolStatus;
        break;

      default:
        break;
    }

    this.setState({
      result,
    });
  };
}

const styles = createStyles({
  root: {
    marginTop: 20,
    paddingBottom: 50,
  },
  topLine: {
    display: 'flex',
    alignItems: 'center',
  },
  customSelect: {
    marginRight: 20,
    '& .MuiInputBase-root': {
      fontWeight: 300,
      letterSpacing: 'normal',
    },
  },
  menuListItem: {
    padding: '11px 16px',
  },
  submitButton: {
    width: 150,
    textTransform: 'none',
  },
  debugResult: {
    marginTop: 10,
    backgroundColor: '#272823',
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  isGetSignersLoading: NetworkActions.getSigners.isPending(store),
  isGetProposalStatusLoading: NetworkActions.getProposalStatus.isPending(store),
  isGetTxpoolStatusLoading: NetworkActions.getTxpoolStatus.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  getSigners: (args: NetworkActions.QueryGetSignersArgs) =>
    dispatch(NetworkActions.getSigners(args)),
  getProposalStatus: (args: NetworkActions.QueryGetProposalStatusArgs) =>
    dispatch(NetworkActions.getProposalStatus(args)),
  getTxpoolStatus: (args: NetworkActions.QueryGetTxpoolStatusArgs) =>
    dispatch(NetworkActions.getTxpoolStatus(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DebugTab)),
);

type Option = {
  label: string;
  value: string;
};

type SelectMethod = 'get_validator' | 'get_proposal' | 'get_txpool';

export const commandSelection = [
  { label: 'Get Validator', value: 'get_validator' },
  { label: 'Get Proposal', value: 'get_proposal' },
  { label: 'Get Txpool status', value: 'get_txpool' },
];
