import React from 'react';
// Redux
import { connect } from 'react-redux';
import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';
import { IStore } from '~/stores/configure-store';
// Component
import CustomSelect from '~/components/common/custom-select';
import SubmitButton from '~/components/common/submit-button';
import JSONPretty from 'react-json-pretty';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { nightRiderColor, whiteColor } from '~/styles/themes/common-styles/color';
import { defaultFont } from '~/styles/themes/common-styles/font';
// Type
import { INetwork, INode } from '~/types/network-types';
import { Account } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';

import {
  logsRangeSelectionMemberNode,
  linesRangeSelection,
  linesRangeSelectionAll,
} from '~/types/network-selection';

import moment from 'moment-timezone';
import download from 'downloadjs';

// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';

interface IStateProps {}

interface IDispProps {
  getLog: (
    args: NetworkActions.QueryGetNodeLogArgs,
  ) => Promise<NetworkActions.GET_NODE_LOG_RESULT_TYPE>;
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => void;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  node: INode;
  network: INetwork;
  account: Account;
}

interface IState {
  isGettingLog: boolean;
  isFetchingLog: boolean;
  getLogEntries?: NetworkActions.NodeLogEntry[];
  log: NetworkActions.NodeLogType;
  line: NetworkActions.NodeLogLine;
}

class LogsTab extends React.Component<IProps, IState> {
  private mounted = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isGettingLog: false,
      isFetchingLog: false,
      log: logsRangeSelectionMemberNode[0].value,
      line: linesRangeSelection[0].value,
    };
  }

  async componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  public render() {
    const { classes, t } = this.props;
    const { isGettingLog, isFetchingLog, getLogEntries, line, log } = this.state;

    document.title = TAB_TITLE_CONCAT + this.props.t('node_logs_title');

    return (
      <div className={classes.root}>
        <div className={classes.topArea}>
          <div className={classes.selectArea}>
            <CustomSelect
              className={classes.bgNone}
              classes={{
                selectRoot: classes.customSelect,
                selectMenu: classes.customSelectMenu,
                arrowDownIcon: classes.customSelectIcon,
              }}
              id="node-log-type"
              onChange={this.onChangeLog}
              items={logsRangeSelectionMemberNode}
              valueSelected={log}
            />
            <CustomSelect
              className={classes.bgNone}
              classes={{
                selectRoot: classes.customSelect,
                selectMenu: classes.customSelectMenu,
                arrowDownIcon: classes.customSelectIcon,
              }}
              id="node-log-lines"
              onChange={this.onChangeLine}
              items={linesRangeSelection}
              valueSelected={line}
            />
            <div className={classes.textTop}>{t('lines')}</div>
            <SubmitButton
              isValid
              classes={{
                root: classes.getLogBtn,
              }}
              id="node-get-log"
              isSubmitting={isGettingLog}
              label={t('get_log')}
              submittingLabel={t('getting')}
              onClick={this.onGetLogBtnClick}
            />
          </div>

          <SubmitButton
            isValid
            classes={{
              root: classes.downloadBtn,
            }}
            id="node-download-fulllog"
            isSubmitting={isFetchingLog}
            label={t('download_full_log')}
            submittingLabel={t('getting')}
            onClick={this.onDownloadBtnClick}
          />
        </div>

        <div>
          {getLogEntries && (
            <div id="node-log-content" className={classes.signersResult}>
              {
                <JSONPretty
                  style={{ color: '#ffffff', overflowX: 'auto', padding: '10px' }}
                  data={getLogEntries.map((l) => l.message).join('\n')}
                />
              }
            </div>
          )}
        </div>
      </div>
    );
  }

  private onChangeLog = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (this.mounted) {
      this.setState({
        log: event.target.value as NetworkActions.NodeLogType,
      });
    }
  };

  private onChangeLine = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (this.mounted) {
      this.setState({
        line: event.target.value as NetworkActions.NodeLogLine,
      });
    }
  };

  private onGetLogBtnClick = async () => {
    const { t, openSnackBar, network, node, getLog, account } = this.props;
    const { log, line } = this.state;

    if (this.mounted) {
      this.setState({
        isGettingLog: true,
      });

      try {
        if (node.serverInfo.status !== 'alive' && !node.serverInfo.publicDns) {
          throw new Error('The specified log stream does not exist.');
        }

        const result = await getLog({
          input: {
            accountUuid: account.accountUuid,
            networkUuid: network.networkUuid,
            nodeUuid: node.nodeUuid,
            logType: log,
            logLine: line,
          },
        });
        this.setState({
          getLogEntries: (result && result.getNodeLog.entries) || [],
        });
      } catch (err) {
        if (err.message !== 'The specified log stream does not exist.') {
          throw err;
        }
        openSnackBar({
          type: 'error',
          message: t('can_not_get_log'),
        });
      } finally {
        this.setState({
          isGettingLog: false,
        });
      }
    }
  };

  private onDownloadBtnClick = async () => {
    const { t, openSnackBar, network, node, getLog, account } = this.props;
    const { log } = this.state;

    if (this.mounted) {
      this.setState({
        isFetchingLog: true,
      });

      try {
        if (node.serverInfo.status !== 'alive' && !node.serverInfo.publicDns) {
          throw new Error('The specified log stream does not exist.');
        }

        const result = await getLog({
          input: {
            accountUuid: account.accountUuid,
            networkUuid: network.networkUuid,
            nodeUuid: node.nodeUuid,
            logType: log,
            logLine: linesRangeSelectionAll,
          },
        });
        const logTime = moment().format('YYYYMMDD-HHmmss');
        const entries = ((result && result.getNodeLog.entries) || []).map((l) => l.message);
        entries.push(''); // adjustment

        // download from JS
        download(entries.join('\n'), `${log}_log_${logTime}.txt`, 'text/plain');
      } catch (err) {
        if (err.message !== 'The specified log stream does not exist.') {
          throw err;
        }
        openSnackBar({
          type: 'error',
          message: t('can_not_get_log'),
        });
      } finally {
        this.setState({
          isFetchingLog: false,
        });
      }
    }
  };
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 20,
      paddingBottom: 50,
    },
    topArea: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    selectArea: {
      display: 'flex',
      alignItems: 'center',
    },
    getLogBtn: {
      width: 135,
    },
    bgNone: {
      background: whiteColor,
      width: 'unset',
      marginLeft: 10,
      marginRight: 10,
      height: 33,
      '& .MuiInputBase-root': {
        width: 130,
        fontSize: 14,
      },
      '& .MuiInputBase-root > .MuiInputBase-input': {
        padding: '6px 15px',
      },
    },
    customSelect: {
      minWidth: 130,
      fontSize: 14,
    },
    customSelectMenu: {
      padding: '6px 15px',
    },
    customSelectIcon: {
      right: 5,
    },
    textTop: {
      ...defaultFont,
      color: nightRiderColor,
      fontSize: 13,
      marginRight: 20,
    },
    downloadBtn: {
      ...defaultFont,
      fontSize: 15,
      color: nightRiderColor,
      minWidth: 220,
      textTransform: 'none',
      border: 'solid 1px #7b90a3',
      backgroundImage: 'linear-gradient(to bottom, #ffffff, #ededed)',
      '&:hover': {
        backgroundImage: 'linear-gradient(to bottom, #ffffff, #ededed)',
      },
    },
    signersResult: {
      marginTop: 10,
      backgroundColor: '#272823',
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      bgNone: {
        marginRight: 5,
        marginLeft: 0,
      },
      customSelect: {
        minWidth: 100,
        fontSize: 14,
      },
      customSelectMenu: {
        padding: ' 6px 10px',
      },
      getLogBtn: {
        width: 90,
        fontSize: 14,
      },
      downloadBtn: {
        minWidth: 150,
        width: 150,
        fontSize: 14,
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({
  getLog: (args: NetworkActions.QueryGetNodeLogArgs) => dispatch(NetworkActions.getNodeLog(args)),
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => dispatch(AppActions.openSnackBar(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('member')(LogsTab)),
);
