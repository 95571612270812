import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import Button from '@mui/material/Button';
import * as Yup from 'yup';

import {
  defaultFontBold,
  defaultFont,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  denimColor,
  dimGrayColor,
  whiteSmokeColor,
  romanColor,
} from '~/styles/themes/common-styles/color';
import * as NetworkActions from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';
import { ICluster, INetwork } from '~/types/network-types';
import { Account } from '~/types/account-types';

// Component
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import CustomInput from '~/components/common/custom-input';
import SubmitButton from '~/components/common/submit-button';
// React i18next
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { MAX_CLUSTER_NAME_LENGTH, VALIDATE_DOMAIN_NAME_PATTERN } from '~/constants/validation';

interface IStateProps {}

interface IDispProps {
  addCustomDomain: (
    args: NetworkActions.MutationAddCustomDomainArgs,
  ) => Promise<NetworkActions.ADD_CUSTOM_DOMAIN_RESULT_TYPE>;
  listCustomDomains: (
    args: NetworkActions.QueryListCustomDomainsArgs,
  ) => Promise<NetworkActions.LIST_CUSTOM_DOMAINS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
  networkUuid: string;
  clusterUuid: string;
  accountUuid: string;
}

interface IState {}

class AddCustomDomainDialog extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { classes, open, onClose, t } = this.props;
    const initialValues: FormValues = {
      domainName: '',
    };

    const validateSchema = Yup.object().shape<FormValues>({
      domainName: Yup.string()
        .required(t('required_field'))
        .matches(VALIDATE_DOMAIN_NAME_PATTERN, t('invalid_domain_name_format')),
    });

    return (
      <CustomDialog open={open} onClose={onClose}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-add-custom-domain    -title">{t('add_custom_domain')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div className={classes.formSection}>
                  <Field name="domainName" render={this.domainNameField} />
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  id="member-add-custom-domain-cancel"
                  disabled={isSubmitting}
                  className={classes.leftBtn}
                  onClick={onClose}
                  variant="contained"
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  id="member-add-custom-domain-submit"
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  label={t('add_domain')}
                  submittingLabel={t('adding')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { addCustomDomain, listCustomDomains, onClose, networkUuid, clusterUuid, accountUuid } =
      this.props;

    try {
      await addCustomDomain({
        input: {
          accountUuid: accountUuid,
          networkUuid: networkUuid,
          clusterUuid: clusterUuid,
          domainName: values.domainName.trim(),
        },
      });
      onClose();
      await listCustomDomains({
        input: {
          accountUuid: accountUuid,
          networkUuid: networkUuid,
          clusterUuid: clusterUuid,
        },
      }).catch(console.log);
    } catch (error) {
      setSubmitting(false);
    }
  };

  private domainNameField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('domain_name')}</div>
          {!!form.errors.domainName && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.domainName}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-custom-domain-name"
            placeholder={this.props.t('input_domain_name')}
          />
        </div>
      </>
    );
  };
}

const styles: StyleRules = {
  root: {},
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  // submit button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFont,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
};

const mapStateToProps = (store: IStore): IStateProps => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({
  addCustomDomain: (args: NetworkActions.MutationAddCustomDomainArgs) =>
    dispatch(NetworkActions.addCustomDomain(args)),
  listCustomDomains: (args: NetworkActions.QueryListCustomDomainsArgs) =>
    dispatch(NetworkActions.listCustomDomains(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCustomDomainDialog)),
);

type FormValues = {
  domainName: string;
};

const MAX_LENGTH_CLUSTER_NAME = 30;

const validateSchema = Yup.object().shape({
  clusterName: Yup.string()
    .trim()
    .required('Required')
    .max(MAX_LENGTH_CLUSTER_NAME, 'Cluster name too long!'),
});
