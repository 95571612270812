import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import Button from '@mui/material/Button';
import { defaultFontBold, defaultFont } from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  lightSlateGreyColor,
  denimColor,
  pattensBlueColor,
} from '~/styles/themes/common-styles/color';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Component
import ContentContainerView from '~/components/common/ContentContainer';
import CreateNetworkDialog from '~/components/common/create-network-dialog';
// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';
import { compose } from 'redux';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withInitAppData } from '~/hooks/with-init-app-data';

interface IProps extends WithStyles<typeof styles>, WithTranslation {}

interface IState {
  openCreateNetworkDialog: boolean;
}

class NetworkCreate extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      openCreateNetworkDialog: false,
    };
  }

  public render() {
    const { classes } = this.props;

    const { openCreateNetworkDialog } = this.state;

    document.title = TAB_TITLE_CONCAT + this.props.t('create_new_blockchain_network');

    return (
      <>
        <ContentContainerView
          title={this.props.t('create_new_blockchain_network')}
          tabs={[]}
          titleId="network-create-name"
        >
          <div className={classes.createBtnArea}>
            <div className={classes.createDescription} id="network-create-caption">
              {this.props.t('create_new_blockchain_network')}
            </div>
            <Button
              id="network-create-button"
              variant="contained"
              className={classes.createBtn}
              onClick={this.onOpenCreateNetworkDialog}
            >
              {this.props.t('create')}
            </Button>
          </div>
        </ContentContainerView>

        <CreateNetworkDialog
          open={openCreateNetworkDialog}
          onClose={this.onCloseCreateNetworkDialog}
        />
      </>
    );
  }

  private onCloseCreateNetworkDialog = () => {
    this.setState({
      openCreateNetworkDialog: false,
    });
  };

  private onOpenCreateNetworkDialog = () => {
    this.setState({
      openCreateNetworkDialog: true,
    });
  };
}

const styles: StyleRules = {
  createBtnArea: {
    marginTop: 17,
    paddingTop: 95,
    textAlign: 'center',
    height: 281,
    borderRadius: 4,
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
    border: `solid 1px ${pattensBlueColor}`,
    backgroundColor: whiteColor,
  },
  createDescription: {
    ...defaultFont,
    fontSize: 24,
    lineHeight: '1.17em',
    color: lightSlateGreyColor,
  },
  createBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    width: 160,
    height: 36,
    borderRadius: 4,
    textTransform: 'none',
    marginTop: 40,
  },
};

export default compose<React.ClassicComponentClass>(
  withStyles(styles),
  withTranslation(),
)(NetworkCreate);
