import { createTypeAsyncAction } from '@gu-corp/redux-async-lib';
import moment from 'moment-timezone';

import MemberGApiClient from './gapi/member-gapi-client';
import * as payment from './gapi/query/payment';
export * from './gapi/query/payment';
import { IStore } from '~/stores/configure-store';
import { QueryListInsufficientBlockExpInstanceLicensesArgs } from '~/gapi/gtypes';

// Actions
export const listActiveLicenses = createTypeAsyncAction(
  'LIST_ACTIVE_LICENSES',
  async (args: payment.QueryListActiveLicensesArgs) => {
    const client = new MemberGApiClient({});
    return client.listActiveLicenses(args);
  },
);

export const listActiveLicensesSummary = createTypeAsyncAction(
  'LIST_ACTIVE_LICENSES_SUMMARY',
  async (args: payment.QueryListActiveLicensesSummaryArgs) => {
    const client = new MemberGApiClient({});
    return client.listActiveLicensesSummary(args);
  },
);

export const listAvailableLicenses = createTypeAsyncAction(
  'LIST_AVAILABLE_LICENSES',
  async (args: payment.QueryListAvailableLicensesArgs) => {
    const client = new MemberGApiClient({});
    return client.listAvailableLicenses(args);
  },
);

export const listInsufficientLicenses = createTypeAsyncAction(
  'LIST_INSUFFICIENT_LICENSES',
  async (args: payment.QueryListInsufficientLicensesArgs) => {
    const client = new MemberGApiClient({});
    return client.listInsufficientLicenses(args);
  },
);

export const listInsufficientClusterLicenses = createTypeAsyncAction(
  'LIST_INSUFFICIENT_CLUSTER_LICENSES',
  async (args: payment.QueryListInsufficientClusterLicensesArgs) => {
    const client = new MemberGApiClient({});
    return client.listInsufficientClusterLicenses(args);
  },
);

export const listInsufficientNodeLicenses = createTypeAsyncAction(
  'LIST_INSUFFICIENT_NODE_LICENSES',
  async (args: payment.QueryListInsufficientNodeLicensesArgs) => {
    const client = new MemberGApiClient({});
    return client.listInsufficientNodeLicenses(args);
  },
);

export const listInsufficientNodeServerLicenses = createTypeAsyncAction(
  'LIST_INSUFFICIENT_NODE_SERVER_LICENSES',
  async (args: payment.QueryListInsufficientNodeServerLicensesArgs) => {
    const client = new MemberGApiClient({});
    return client.listInsufficientNodeServerLicenses(args);
  },
);

export const listInsufficientVolumeLicenses = createTypeAsyncAction(
  'LIST_INSUFFICIENT_VOLUME_LICENSES',
  async (args: payment.QueryListInsufficientVolumeLicensesArgs) => {
    const client = new MemberGApiClient({});
    return client.listInsufficientVolumeLicenses(args);
  },
);

export const estimateLicenseFee = createTypeAsyncAction(
  'ESTIMATE_LICENSE_FEE',
  async (args: payment.QueryEstimateLicenseFeeArgs) => {
    const client = new MemberGApiClient({});
    return client.estimateLicenseFee(args);
  },
);

export const listBillings = createTypeAsyncAction(
  'LIST_BILLINGS',
  async (args: payment.QueryListBillingsArgs, state: IStore) => {
    const { billings } = state.appState;
    const client = new MemberGApiClient({});
    return client.listBillings({
      accountUuid: args.accountUuid,
      startDate: args.startDate || billings.startPeriod,
      endDate: args.endDate || billings.endPeriod,
    });
  },
);

export type ListBillingsInMonthArgs = {
  accountUuid: string;
  month: string;
};
export const listBillingsInMonth = createTypeAsyncAction(
  'LIST_BILLINGS_IN_MONTH',
  async (args: ListBillingsInMonthArgs) => {
    const client = new MemberGApiClient({});
    return client.listBillings({
      accountUuid: args.accountUuid,
      startDate: moment(args.month).utc().startOf('month').toISOString(),
      endDate: moment(args.month).utc().endOf('month').toISOString(),
    });
  },
);

export const listCoupons = createTypeAsyncAction(
  'LIST_COUPONS',
  async (args: payment.QueryListCouponsArgs, state: IStore) => {
    const { coupons } = state.appState;
    const client = new MemberGApiClient({});
    return client.listCoupons({
      accountUuid: args.accountUuid,
      startDate: args.startDate || coupons.startPeriod,
      endDate: args.endDate || coupons.endPeriod,
    });
  },
);

export const listCards = createTypeAsyncAction(
  'LIST_CARDS',
  async (args: payment.QueryListCardsArgs) => {
    const client = new MemberGApiClient({});
    return client.listCards(args);
  },
);

export const registerCard = createTypeAsyncAction(
  'REGISTER_CARD',
  async (args: payment.MutationRegisterCardArgs) => {
    const client = new MemberGApiClient({});
    return client.registerCard(args);
  },
);

export const purchaseLicenses = createTypeAsyncAction(
  'PURCHASE_LICENSES',
  async (args: payment.MutationPurchaseLicensesArgs) => {
    const client = new MemberGApiClient({});
    return client.purchaseLicenses(args);
  },
);

export const cancelPurchaseLicense = createTypeAsyncAction(
  'CANCEL_PURCHASE_LICENSE',
  async (args: payment.MutationCancelPurchaseLicenseArgs) => {
    const client = new MemberGApiClient({});
    return client.cancelPurchaseLicense(args);
  },
);

export const listInsufficientBlockExplorerLicenses = createTypeAsyncAction(
  'LIST_INSUFFICIENT_BLOCK_EXPLORER_LICENSES_RESULT_TYPE',
  async (args: payment.QueryListInsufficientBlockExplorerLicensesArgs) => {
    const client = new MemberGApiClient({});
    return client.listInsufficientBlockExplorerLicenses(args);
  },
);

export const listInsufficientBlockExpInstanceLicenses = createTypeAsyncAction(
  'LIST_INSUFFICIENT_BLOCK_EXP_SERVER_LICENSES',
  async (args: QueryListInsufficientBlockExpInstanceLicensesArgs) => {
    const client = new MemberGApiClient({});
    return client.listInsufficientBlockExpInstanceLicenses(args);
  },
);
