import React from 'react';
import BN from 'bn.js';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';
// Form
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import * as Yup from 'yup';
// Component
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import SourceIcon from '@mui/icons-material/Source';
import CustomSelect from '~/components/common/custom-select';
import EditButton from '~/components/common/edit-button';
import OptionButton from '~/components/common/option-button';
import DeleteNodeDialog from '~/components/common/delete-node-dialog';
import EditNodeDialog from '~/components/common/edit-node-dialog';
import EditNodeVolumeDialog from '~/components/common/edit-node-volume-dialog';
import SubmitButton from '~/components/common/submit-button';
import CopyClipboardIcon from '~/components/common/copy-clipboard-icon';
import CustomNumericString from '~/components/common/custom-numeric-string';
import UpdateNodeVersionDialog from '~/components/common/change-node-version-dialog';
import ConfirmRebuildNodeDialog from '~/components/common/custom-confirm-dialog';
import UpdateExternalSignerDialog from './action-dialog/update-external-signer-dialog';
import MigrateClefNodeDialog from './action-dialog/migrate-clef-node-dialog';
import UpdateTransferAddressDialog from './action-dialog/update-transfer-address';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { oneLineText } from '~/styles/themes/common-styles/misc';
import {
  defaultFont,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  whiteSmokeColor,
  whiteColor,
  snowColor,
  denimColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
// Type
import { Account, AccountRoleType, NetworkRoleType } from '~/types/account-types';
import {
  INetwork,
  INode,
  ICluster,
  INodeControlType,
  INetworkProvider,
} from '~/types/network-types';
import { getProposeNodeSelection } from '~/types/network-selection';
// Util
import { displayClusterProviderName, renderClusterRegionLabel } from '~/utilities/render-utils';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Defines
import {
  DEFAULT_CURRENCY_NAME,
  DEFAULT_GAS_LIMIT,
  MASK_ASTERISK,
  TAB_TITLE_CONCAT,
} from '~/constants/consts';
import UpdateGasLimitDialog from '~/components/common/update-gas-limit-dialog';
import UpdateNodeInstanceDialog from '~/components/common/update-node-instance-dialog';
import {
  ellipsifyText,
  getLatestHardForkByGenesis,
  isAccountOwnCluster,
  isConsortiumAdmin,
} from '~/utilities/utils';
import UpdateHardForkDialog from '~/components/common/update-hard-fork-dialog';
import GenesisJsonViewDialog from '~/components/common/genesis-json-dialog';
import CircularProgress from '@mui/material/CircularProgress';
import { QueryGetGenesisJsonArgs } from '~/gapi/gtypes';
import ConfirmSendTokenDialog from '~/components/common/custom-confirm-dialog';

interface IStateProps {
  providers: INetworkProvider[];
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
  isLoadingGetGenesis: boolean;
}

interface IDispProps {
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => void;
  controleNode: (
    args: NetworkActions.MutationControlNodeArgs,
  ) => Promise<NetworkActions.CONTROL_NODE_RESULT_TYPE>;
  transferAll: (
    args: NetworkActions.MutationSendAllEtherFromNodeArgs,
  ) => Promise<NetworkActions.SEND_ALL_ETHER_FROM_NODE_RESULT_TYPE>;
  estimateGas: (
    args: NetworkActions.QueryEstimateTransferGasArgs,
  ) => Promise<NetworkActions.ESTIMATE_TRANSFER_GAS_RESULT_TYPE>;
  getGenesisJson: (
    args: QueryGetGenesisJsonArgs,
  ) => Promise<NetworkActions.GET_GENESIS_JSON_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  node: INode;
  cluster: ICluster;
  network: INetwork;
  account: Account;
}

interface IState {
  nodeOptionAnchorEl?: any;
  openDeleteNodeDialog: boolean;
  openEditNodeDialog: boolean;
  openAuthorizeNodeDialog: boolean;
  openExpandNodeVolumeDialog: boolean;
  submittingTransaction: boolean;
  openUpdateNodeVersionDialog: boolean;
  openConfirmRebuildNodeDialog: boolean;
  openUpdateGasLimitDialog: boolean;
  openUpdateInstanceTypeDialog: boolean;
  openHardForkDialog: boolean;
  openMigrateNodeDialog: boolean;
  openUpdateClefRuleDialog: boolean;
  openGenesisJsonDialog: boolean;
  genesisJson?: string;
  openUpdateExternalSignerDialog: boolean;
  openConfirmSendTokenDialog: boolean;
}

class OverviewTab extends React.Component<IProps, IState> {
  private mounted: boolean = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      openDeleteNodeDialog: false,
      openEditNodeDialog: false,
      openAuthorizeNodeDialog: false,
      openExpandNodeVolumeDialog: false,
      submittingTransaction: false,
      openUpdateNodeVersionDialog: false,
      openConfirmRebuildNodeDialog: false,
      openUpdateGasLimitDialog: false,
      openUpdateInstanceTypeDialog: false,
      openHardForkDialog: false,
      openGenesisJsonDialog: false,
      openUpdateExternalSignerDialog: false,
      openMigrateNodeDialog: false,
      openUpdateClefRuleDialog: false,
      openConfirmSendTokenDialog: false,
    };
  }

  async componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  public render() {
    const {
      classes,
      node,
      cluster,
      network,
      account,
      t,
      providers,
      selectedAccountRole,
      selectedNetworkRole,
      isLoadingGetGenesis,
    } = this.props;
    const updatableNode =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const deletableNode =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const expandableNode =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const ableGetGenesisJson =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedAccountRole === 'member' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'member' ||
      selectedNetworkRole === 'operator';
    const ableGetClefRuleJson = selectedAccountRole === 'owner' || selectedNetworkRole === 'owner';
    const ableUpdateRuleOfClef = selectedAccountRole === 'owner';

    const consortium = network.consortiums.find(
      (consortium) => consortium.accountUuid === account.accountUuid,
    );
    const showNodeProposal =
      ((consortium && consortium.role === 'admin') ||
        cluster.accountUuid === account.accountUuid) &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        selectedNetworkRole === 'owner' ||
        selectedNetworkRole === 'admin');
    const {
      nodeOptionAnchorEl,
      openDeleteNodeDialog,
      openEditNodeDialog,
      openExpandNodeVolumeDialog,
      openUpdateNodeVersionDialog,
      openConfirmRebuildNodeDialog,
      openUpdateGasLimitDialog,
      openUpdateInstanceTypeDialog,
      openHardForkDialog,
      openGenesisJsonDialog,
      genesisJson,
      openUpdateExternalSignerDialog,
      openMigrateNodeDialog,
      openUpdateClefRuleDialog,
      openConfirmSendTokenDialog,
    } = this.state;

    const { az, ip, instanceType, instanceImage, publicDns, volumeSize } = node.serverInfo;
    const formatter = new Intl.NumberFormat();

    document.title = TAB_TITLE_CONCAT + this.props.t('node_overview_title');

    const showSensitiveData =
      isConsortiumAdmin(account, network) || isAccountOwnCluster(account, cluster);
    const blockGasLimit =
      node.nodeInfo.blockGasLimit || network.blockchainInfo?.blockGasLimit || DEFAULT_GAS_LIMIT;

    const latestUpdate =
      node.nodeInfo.genesisConfig && getLatestHardForkByGenesis(node.nodeInfo.genesisConfig);

    return (
      <>
        <ConfirmSendTokenDialog
          open={openConfirmSendTokenDialog}
          title={t('confirm_transfer_token', {
            value: network.blockchainInfo?.currencyName || DEFAULT_CURRENCY_NAME,
          })}
          content={
            node.signerInfo?.internalClef
              ? t('confirm_clef_send_ether', {
                  value: network.blockchainInfo?.currencyName || DEFAULT_CURRENCY_NAME,
                  address: ellipsifyText(node.signerInfo.internalClef.transferAddress || ''),
                })
              : t('confirm_send_ether', {
                  value: network.blockchainInfo?.currencyName || DEFAULT_CURRENCY_NAME,
                })
          }
          onClose={this.onCloseConfirmSendTokenDialog}
          onSubmit={this.sendAllEtherOfNode}
        />
        <DeleteNodeDialog
          open={openDeleteNodeDialog}
          onClose={this.onCloseDeleteNodeDialog}
          node={node}
          cluster={cluster}
          networkUuid={network.networkUuid}
          organization={account.name}
        />
        <EditNodeDialog
          open={openEditNodeDialog}
          onClose={this.onCloseEditNodeDialog}
          networkUuid={network.networkUuid}
          cluster={cluster}
          node={node}
        />
        <EditNodeVolumeDialog
          open={openExpandNodeVolumeDialog}
          onClose={this.onCloseExpandNodeVolumeDialog}
          networkUuid={network.networkUuid}
          cluster={cluster}
          node={node}
        />
        <UpdateNodeVersionDialog
          open={openUpdateNodeVersionDialog}
          onClose={this.onCloseUpdateNodeVersionDialog}
          networkUuid={network.networkUuid}
          cluster={cluster}
          node={node}
        />
        <ConfirmRebuildNodeDialog
          title={t('rebuild_node')}
          content={t('are_you_sure_you_want_to_rebuild_this_node')}
          open={openConfirmRebuildNodeDialog}
          onClose={this.onCloseConfirmRebuildNodeDialog}
          onSubmit={this.onSubmitRebuildNode}
        />
        <UpdateGasLimitDialog
          open={openUpdateGasLimitDialog}
          network={network}
          node={node}
          cluster={cluster}
          onClose={this.onCloseUpdateGasLimitDialog}
        />
        <UpdateHardForkDialog
          open={openHardForkDialog}
          network={network}
          node={node}
          cluster={cluster}
          onClose={this.onCloseHardForkDialog}
        />
        <MigrateClefNodeDialog
          open={openMigrateNodeDialog}
          network={network}
          node={node}
          cluster={cluster}
          onClose={this.onCloseMigrateClefNodeDialog}
        />
        <UpdateTransferAddressDialog
          open={openUpdateClefRuleDialog}
          network={network}
          node={node}
          cluster={cluster}
          onClose={this.onCloseChangeExternalAddressDialogDialog}
        />
        {openGenesisJsonDialog && (
          <GenesisJsonViewDialog
            open={openGenesisJsonDialog}
            genesisJson={genesisJson}
            onClose={this.onCloseGenesisJsonDialog}
          />
        )}

        <UpdateNodeInstanceDialog
          open={openUpdateInstanceTypeDialog}
          onClose={this.onCloseUpdateInstanceTypeDialog}
          networkUuid={network.networkUuid}
          cluster={cluster}
          node={node}
        />
        {node.signerInfo?.externalClef && (
          <UpdateExternalSignerDialog
            open={openUpdateExternalSignerDialog}
            account={account}
            network={network}
            node={node}
            cluster={cluster}
            onClose={this.onCloseChangeExternalSignerDialog}
          />
        )}
        <Popover
          open={Boolean(nodeOptionAnchorEl)}
          anchorEl={nodeOptionAnchorEl}
          onClose={this.onCloseNodeOptionPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={1}
          classes={{ paper: classes.nodeOptionPopoverPaper }}
        >
          <List className={classes.listOption}>
            <ListItem
              button
              className={classes.listOptionItem}
              onClick={this.onOpenDeleteNodeDialog}
              disabled={!deletableNode}
            >
              {t('delete')}
            </ListItem>
            {node.serverInfo.status === 'alive' && (
              <>
                <ListItem
                  button
                  className={classes.listOptionItem}
                  onClick={this.onOpenExpandNodeVolumeDialog}
                  disabled={!expandableNode}
                >
                  {t('expand')}
                </ListItem>
                <ListItem
                  button
                  className={classes.listOptionItem}
                  onClick={this.onOpenUpdateNodeVersionDialog}
                  disabled={!updatableNode}
                >
                  {t('change_node_version')}
                </ListItem>
                <ListItem
                  button
                  className={classes.listOptionItem}
                  onClick={this.onOpenUpdateInstanceTypeDialog}
                  disabled={!updatableNode}
                >
                  {t('change_instance_type')}
                </ListItem>
                <ListItem
                  button
                  className={classes.listOptionItem}
                  onClick={this.hardForkNetwork}
                  disabled={!updatableNode || !this.getControlable()}
                >
                  {this.props.t('apply_latest_genesis_config')}
                </ListItem>
                <ListItem
                  button
                  className={classes.listOptionItem}
                  onClick={this.onOpenUpdateGasLimitDialog}
                  disabled={!updatableNode}
                >
                  {this.props.t('change_block_gas_limit')}
                </ListItem>
                {node.signerInfo?.externalClef && (
                  <ListItem
                    button
                    className={classes.listOptionItem}
                    onClick={this.onOpenChangeExternalSignerDialog}
                    disabled={!updatableNode}
                  >
                    {this.props.t('update_external_clef')}
                  </ListItem>
                )}
                {!node.signerInfo && (
                  <ListItem
                    button
                    className={classes.listOptionItem}
                    onClick={this.migrateClefNode}
                    disabled={!updatableNode}
                  >
                    {this.props.t('migrate_to_internal_clef')}
                  </ListItem>
                )}
                {node.signerInfo?.internalClef && (
                  <>
                    <ListItem
                      button
                      className={classes.listOptionItem}
                      onClick={this.changeExternalAddressDialog}
                      disabled={!ableUpdateRuleOfClef}
                    >
                      {t('update_transfer_address')}
                    </ListItem>
                  </>
                )}
              </>
            )}
            <div className={classes.horizontalSeparate}></div>
            {this.nodeControlMenu()}
            {/*<ListItem button className={classes.listOptionItem} onClick={this.controlNodeRebootForce}>
              {t('forceReboot')}
            </ListItem> // temporary */}
          </List>
        </Popover>
        <div className={classes.root}>
          <div className={classNames(classes.information, classes.memberNodeOverview)}>
            <div
              className={classNames(
                classes.informationBlock,
                classes.nodeInformationBlock,
                classes.nInfo,
              )}
            >
              <div className={classes.topLine}>
                <div className={classes.topLineTitle}>{t('node_information')}</div>
                <div>
                  {(cluster.accountUuid === account.accountUuid ||
                    isConsortiumAdmin(account, network)) &&
                    node.serverInfo.status === 'alive' && (
                      <Button
                        className={classes.genesisBtn}
                        onClick={this.onOpenGenesisJsonDialog}
                        startIcon={
                          isLoadingGetGenesis ? (
                            <CircularProgress size={16} style={{ color: '#7B90A3' }} />
                          ) : (
                            <SourceIcon />
                          )
                        }
                        disabled={!ableGetGenesisJson || isLoadingGetGenesis}
                      >
                        <span>{t('genesis_json')}</span>
                      </Button>
                    )}
                  {cluster.accountUuid === account.accountUuid && (
                    <EditButton
                      id="node-button-edit"
                      onClick={this.onOpenEditNodeDialog}
                      disabled={!updatableNode}
                    />
                  )}
                </div>
              </div>
              <div className={classes.informationContent}>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('node_id')}</div>
                  <div className={classes.informationItemValue}>{node.nodeUuid}</div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('organization_name')}</div>
                  <div className={classes.informationItemValue}>{account.name}</div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('cluster_name')}</div>
                  <div className={classes.informationItemValue}>{cluster.clusterName}</div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('node_type')}</div>
                  <div className={classes.informationItemValue}>
                    {node.nodeInfo.signer ? t('validator_node') : t('relay_node')}
                  </div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('node_version')}</div>
                  <div className={classes.informationItemValue}>{node.nodeInfo.version}</div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('block_gas_limit')}</div>
                  <div className={classes.informationItemValue}>
                    <CustomNumericString value={blockGasLimit.toString()} />
                  </div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('gas_price')}</div>
                  <div className={classes.informationItemValue}>
                    <CustomNumericString value={node.nodeInfo.initGasPrice} />
                  </div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('garbage_collection_mode')}</div>
                  <div className={classes.informationItemValue}>{node.nodeInfo.gcMode}</div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('allow_unprotected_txs')}</div>
                  <div className={classes.informationItemValue}>
                    {node.nodeInfo.allowUnprotectedTxs ? 'TRUE' : 'FALSE'}
                  </div>
                </div>
                {node.signerInfo?.internalClef && (
                  <>
                    <div className={classes.informationItem}>
                      <div className={classes.informationItemLabel}>
                        {t('internal_clef_version')}
                      </div>
                      <div className={classes.informationItemValue}>
                        {node.signerInfo.internalClef.version}
                      </div>
                    </div>
                  </>
                )}

                {node.signerInfo?.externalClef && (
                  <>
                    <div className={classes.informationItem}>
                      <div className={classes.informationItemLabel}>
                        {t('external_signer_server')}
                      </div>
                      <div style={{ display: 'flex' }} className={classes.informationItemValue}>
                        {`${node.signerInfo.externalClef.username}@${node.signerInfo.externalClef.host}`}
                        <div className={classes.externalServerStatus}>
                          {this.displayConnectionStatusDetail(node)}
                        </div>
                      </div>
                    </div>
                    <div className={classes.informationItem}>
                      <div className={classes.informationItemLabel}>
                        {t('external_signer_api_port')}
                      </div>
                      <div className={classes.informationItemValue}>
                        {node.signerInfo.externalClef.port}
                      </div>
                    </div>
                  </>
                )}
                {node.signerInfo?.internalClef && (
                  <div className={classes.informationItem}>
                    <div className={classes.informationItemLabel}>{t('transfer_addr')}</div>
                    <div className={classes.endPoint}>
                      <div className={classes.informationItemValue}>
                        {node.signerInfo?.internalClef.transferAddress || 'N/A'}
                      </div>
                      <CopyClipboardIcon
                        classes={{ root: classes.copyButton }}
                        textToCopy={node.signerInfo?.internalClef.transferAddress || ''}
                      />
                    </div>
                  </div>
                )}

                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('signing_address')}</div>
                  <div className={classes.endPoint}>
                    <div className={classes.informationItemValue}>
                      {node.nodeInfo.coinbaseAddress || 'fetching ...'}
                    </div>
                    <CopyClipboardIcon
                      classes={{ root: classes.copyButton }}
                      textToCopy={node.nodeInfo.coinbaseAddress || ''}
                    />
                  </div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('enode')}</div>
                  <div className={classes.endPoint}>
                    <div
                      className={classNames(
                        classes.informationItemValue,
                        classes.informationItemDisplay,
                      )}
                    >
                      {node.nodeInfo.enode || 'fetching ...'}
                      <CopyClipboardIcon
                        classes={{ root: classes.copyButton }}
                        textToCopy={node.nodeInfo.enode || ''}
                      />
                    </div>
                  </div>
                </div>
                {latestUpdate && (
                  <div className={classes.informationItem}>
                    <div className={classes.informationItemLabel}>{t('latest_hard_fork')}</div>
                    <div className={classes.informationItemValue}>{latestUpdate.label}</div>
                  </div>
                )}

                <div className={classNames(classes.informationItem, classes.lastInformationItem)}>
                  <div className={classes.informationItemLabel}>{t('balance')}</div>
                  <div className={classes.endPoint}>
                    <div className={classes.informationItemValue}>
                      {node.nodeInfo.balanceEth
                        ? `${node.nodeInfo.balanceEth} ${
                            network.blockchainInfo?.currencyName || DEFAULT_CURRENCY_NAME
                          }`
                        : 'fetching ...'}
                    </div>
                    {!node.signerInfo?.externalClef && this.renderTransferButton}
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.informationBlock}>
              <div className={classes.topLine}>
                <div className={classes.topLineTitle}>{t('server_information')}</div>
                {cluster.accountUuid === account.accountUuid && (
                  <OptionButton id="node-button-menu" onClick={this.onOpenNodeOptionPopover} />
                )}
              </div>
              <div className={classes.informationContent}>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('provider')}</div>
                  <div className={classes.informationItemValue}>
                    {cluster.provider && showSensitiveData
                      ? displayClusterProviderName(cluster.provider)
                      : MASK_ASTERISK}
                  </div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('region')}</div>
                  <div className={classes.informationItemValue}>
                    {showSensitiveData
                      ? renderClusterRegionLabel(providers, cluster)
                      : MASK_ASTERISK}
                  </div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('availability_zone')}</div>
                  <div className={classes.informationItemValue}>
                    {showSensitiveData ? az || 'fetching ...' : MASK_ASTERISK}
                  </div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('instance_type')}</div>
                  <div className={classes.informationItemValue}>
                    {showSensitiveData ? t(instanceType || '') : MASK_ASTERISK}
                  </div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('data_volume_size')}</div>
                  <div className={classes.informationItemValue}>
                    {showSensitiveData
                      ? `${volumeSize && volumeSize >= 0 ? formatter.format(volumeSize) : '-'}  GB`
                      : MASK_ASTERISK}
                  </div>
                </div>
                <div className={classes.informationItem}>
                  <div className={classes.informationItemLabel}>{t('ip_address')}</div>
                  <div className={classes.informationItemValue}>
                    {showSensitiveData ? (
                      ip ? (
                        <a href={`http://${ip}/`} target="_blank">
                          {ip}
                        </a>
                      ) : (
                        'fetching ...'
                      )
                    ) : (
                      MASK_ASTERISK
                    )}
                  </div>
                </div>
                <div className={classNames(classes.informationItem, classes.lastInformationItem)}>
                  <div className={classes.informationItemLabel}>{t('public_DNS_name')}</div>
                  <div
                    className={classNames(
                      classes.informationItemValue,
                      classes.informationItemDisplay,
                    )}
                  >
                    {showSensitiveData ? (
                      publicDns ? (
                        <a href={`http://${publicDns}/`} target="_blank">
                          {publicDns}
                        </a>
                      ) : (
                        'fetching ...'
                      )
                    ) : (
                      MASK_ASTERISK
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  private displayConnectionStatusDetail = (node: INode) => {
    const { t } = this.props;
    const status = node.signerInfo?.externalClef?.status;
    let result = '';

    if (status === 'alive') {
      result = t('connected');
    } else if (status === 'pending') {
      result = t('connecting');
    } else if (status === 'failure') {
      result = t('not_connected');
    }
    return result;
  };

  get renderTransferButton() {
    const { t, classes, cluster, node, network, account } = this.props;
    const balance = node.nodeInfo.balanceWei || '0';

    if (cluster.accountUuid === account.accountUuid && balance !== '0') {
      if (!this.state.submittingTransaction) {
        return (
          <div
            id="node-transfer-button"
            className={classes.transfer}
            onClick={this.onOpenConfirmSendTokenDialog}
          >
            {t('transfer', {
              value: network.blockchainInfo?.currencyName || DEFAULT_CURRENCY_NAME,
            })}
          </div>
        );
      } else {
        return (
          <div id="node-transfer-button" className={classes.transferDisable}>
            {t('sending')}
          </div>
        );
      }
    }
    return null;
  }

  private getControlable = () => {
    const { node, network } = this.props;
    const numberOfSignerNodes = network.clusters
      .map((cluster) => cluster.nodes.map((node) => node))
      .reduce((pre, cur) => pre.concat(cur), [])
      .filter((item) => item.nodeInfo.signer).length;

    return !node.nodeInfo.signer || numberOfSignerNodes > 1;
  };

  private nodeControlMenu = () => {
    const { classes, node, selectedAccountRole, selectedNetworkRole, network, account } =
      this.props;
    const controllableNode =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    let render: any = null;

    switch (node.serverInfo.status) {
      case 'alive':
        /*
                render = (
                  <>
                    <ListItem button className={classes.listOptionItem} onClick={this.controlNodeStop}>
                      {this.props.t('stop')}
                    </ListItem>
                    <ListItem button className={classes.listOptionItem} onClick={this.controlNodeReboot}>
                      {this.props.t('reboot')}
                    </ListItem>
                  </>
                );
        */
        // temporary
        render = (
          <>
            <ListItem
              button
              className={classes.listOptionItem}
              onClick={this.onOpenConfirmRebuildNodeDialog}
              disabled={!controllableNode || !this.getControlable()}
            >
              {this.props.t('rebuild')}
            </ListItem>
            <ListItem
              button
              className={classes.listOptionItem}
              onClick={this.controlNodeReboot}
              disabled={!controllableNode}
            >
              {this.props.t('reboot')}
            </ListItem>
          </>
        );
        break;
      case 'dead':
        render = (
          <ListItem button className={classes.listOptionItem} onClick={this.controlNodeStart}>
            {this.props.t('start')}
          </ListItem>
        );
        break;
      case 'failure':
        render = (
          <ListItem
            button
            className={classes.listOptionItem}
            onClick={this.controlNodeReboot}
            disabled={!controllableNode}
          >
            {this.props.t('reboot')}
          </ListItem>
        );
        break;
    }
    return render;
  };

  private controlNodeStart = async () => {
    this.onSelectControlNodeAction('start');
  };

  private controlNodeStop = async () => {
    this.onSelectControlNodeAction('stop');
  };

  private controlNodeReboot = async () => {
    this.onSelectControlNodeAction('reboot');
  };

  private onOpenConfirmRebuildNodeDialog = () => {
    this.setState({
      openConfirmRebuildNodeDialog: true,
    });
  };

  private onCloseConfirmRebuildNodeDialog = () => {
    this.setState({
      openConfirmRebuildNodeDialog: false,
    });
  };

  private onSubmitRebuildNode = () => {
    this.onCloseConfirmRebuildNodeDialog();
    this.onSelectControlNodeAction('rebuild');
  };

  private controlNodeRebootForce = async () => {
    this.onSelectControlNodeAction('restartForce');
  };

  private onSelectControlNodeAction = async (action: INodeControlType) => {
    const { controleNode, node, cluster, network, account } = this.props;
    this.onCloseNodeOptionPopover();

    await controleNode({
      input: {
        accountUuid: account.accountUuid,
        action: action,
        nodeUuids: [node.nodeUuid],
        clusterUuid: cluster.clusterUuid,
        networkUuid: network.networkUuid,
      },
    });
  };

  private onOpenNodeOptionPopover = (e) => {
    this.setState({
      nodeOptionAnchorEl: e.currentTarget,
    });
  };

  private onCloseNodeOptionPopover = () => {
    this.setState({
      nodeOptionAnchorEl: null,
    });
  };

  private onOpenUpdateNodeVersionDialog = () => {
    this.onCloseNodeOptionPopover();
    this.setState({
      openUpdateNodeVersionDialog: true,
    });
  };

  private onCloseUpdateNodeVersionDialog = () => {
    this.setState({
      openUpdateNodeVersionDialog: false,
    });
  };

  private onOpenUpdateInstanceTypeDialog = () => {
    this.onCloseNodeOptionPopover();
    this.setState({
      openUpdateInstanceTypeDialog: true,
    });
  };

  private onCloseUpdateInstanceTypeDialog = () => {
    this.setState({
      openUpdateInstanceTypeDialog: false,
    });
  };

  private onOpenDeleteNodeDialog = () => {
    this.onCloseNodeOptionPopover();
    this.setState({
      openDeleteNodeDialog: true,
    });
  };

  private onCloseDeleteNodeDialog = () => {
    this.setState({
      openDeleteNodeDialog: false,
    });
  };

  private onOpenEditNodeDialog = () => {
    this.onCloseNodeOptionPopover();
    this.setState({
      openEditNodeDialog: true,
    });
  };

  private onCloseEditNodeDialog = () => {
    this.setState({
      openEditNodeDialog: false,
    });
  };

  private onOpenGenesisJsonDialog = async () => {
    const { account, network, cluster, node, getGenesisJson } = this.props;
    try {
      const rs = await getGenesisJson({
        accountUuid: account.accountUuid,
        networkUuid: network.networkUuid,
        clusterUuid: cluster.clusterUuid,
        nodeUuid: node.nodeUuid,
      });
      this.setState({ genesisJson: rs.getGenesisJson });
      this.setState({
        openGenesisJsonDialog: true,
      });
    } catch (error) {}
  };

  private onCloseGenesisJsonDialog = () => {
    this.setState({
      openGenesisJsonDialog: false,
    });
  };

  private onOpenExpandNodeVolumeDialog = () => {
    this.onCloseNodeOptionPopover();
    this.setState({
      openExpandNodeVolumeDialog: true,
    });
  };

  private onCloseExpandNodeVolumeDialog = () => {
    this.setState({
      openExpandNodeVolumeDialog: false,
    });
  };

  private onOpenUpdateGasLimitDialog = () => {
    this.onCloseNodeOptionPopover();
    this.setState({
      openUpdateGasLimitDialog: true,
    });
  };

  private onCloseUpdateGasLimitDialog = () => {
    this.setState({
      openUpdateGasLimitDialog: false,
    });
  };

  private onOpenChangeExternalSignerDialog = () => {
    this.onCloseNodeOptionPopover();
    this.setState({
      openUpdateExternalSignerDialog: true,
    });
  };

  private onCloseChangeExternalSignerDialog = () => {
    this.setState({
      openUpdateExternalSignerDialog: false,
    });
  };

  private hardForkNetwork = () => {
    this.onOpenHardForkDialog();
    this.onCloseNodeOptionPopover();
  };

  private onOpenHardForkDialog = () => {
    this.setState({ openHardForkDialog: true });
  };

  private onCloseHardForkDialog = () => {
    this.setState({ openHardForkDialog: false });
  };

  private migrateClefNode = () => {
    this.onOpenMigrateClefNodeDialog();
    this.onCloseNodeOptionPopover();
  };

  private onOpenMigrateClefNodeDialog = () => {
    this.setState({ openMigrateNodeDialog: true });
  };

  private onCloseMigrateClefNodeDialog = () => {
    this.setState({ openMigrateNodeDialog: false });
  };

  private changeExternalAddressDialog = () => {
    this.onOpenChangeExternalAddressDialogDialog();
    this.onCloseNodeOptionPopover();
  };

  private onOpenChangeExternalAddressDialogDialog = () => {
    this.setState({ openUpdateClefRuleDialog: true });
  };

  private onCloseChangeExternalAddressDialogDialog = () => {
    this.setState({ openUpdateClefRuleDialog: false });
  };

  private onOpenConfirmSendTokenDialog = () => {
    if (this.state.submittingTransaction) {
      return;
    }
    const { openSnackBar, t, node } = this.props;
    const { signerInfo } = node;
    if (signerInfo?.internalClef) {
      if (!signerInfo.internalClef.transferAddress) {
        openSnackBar({
          type: 'error',
          message: t('transfer_address_not_exist'),
        });
        return;
      }
    }
    this.setState({
      openConfirmSendTokenDialog: true,
    });
  };

  private onCloseConfirmSendTokenDialog = () => {
    this.setState({
      openConfirmSendTokenDialog: false,
    });
  };

  private sendAllEtherOfNode = async () => {
    if (this.state.submittingTransaction) {
      return;
    }

    const { openSnackBar, t, node, cluster, network, account, transferAll, estimateGas } =
      this.props;
    const { nodeInfo, signerInfo } = node;

    if (signerInfo?.internalClef) {
      const transferAddr = signerInfo.internalClef.transferAddress;
      if (!transferAddr) {
        openSnackBar({
          type: 'error',
          message: t('transfer_address_not_exist'),
        });
        return;
      }
    }

    this.setState({ submittingTransaction: true, openConfirmSendTokenDialog: false });

    try {
      // check account
      if (cluster.accountUuid !== account.accountUuid) {
        throw new Error('validate_account_not_matched');
      }
      //      if (!account.addr) {
      //        throw new Error('validate_account_has_no_addr');
      //      }

      // check balance
      const gasLimit = await estimateGas({
        input: {
          accountUuid: account.accountUuid,
          networkUuid: network.networkUuid,
          clusterUuid: cluster.clusterUuid,
          nodeUuid: node.nodeUuid,
        },
      });
      const bnBalance = new BN(nodeInfo.balanceWei || '0');
      const bnGas = new BN(nodeInfo.balanceGasPrice).mul(new BN(gasLimit.estimateTransferGas));

      if (bnBalance.toString() === '0' || bnBalance.lte(bnGas)) {
        throw new Error('lack_of_balance_to_transfer');
      }
    } catch (err: any) {
      this.setState({ submittingTransaction: false });
      return;
    }

    // send
    const result = await transferAll({
      input: {
        accountUuid: account.accountUuid,
        networkUuid: network.networkUuid,
        clusterUuid: cluster.clusterUuid,
        nodeUuid: node.nodeUuid,
      },
    }).catch((_) => void 0);
    if (result?.sendAllEtherFromNode) {
      openSnackBar({
        type: 'success',
        message: t('transfer_token_done', {
          value: network.blockchainInfo?.currencyName || DEFAULT_CURRENCY_NAME,
        }),
      });
    }
    this.setState({ submittingTransaction: false });
  };
}

const styles = (theme) =>
  createStyles({
    root: {
      marginTop: 20,
      paddingBottom: 50,
    },
    information: {
      display: 'flex',
    },
    informationBlock: {
      flex: 1,
      borderRadius: 4,
      border: `1px solid ${pattensBlueColor}`,
      boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
    },
    nodeInformationBlock: {
      marginRight: 30,
    },
    topLine: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 20,
      paddingRight: 5,
      height: 40,
      backgroundColor: snowColor,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    topLineTitle: {
      ...defaultFontMedium,
      fontSize: 15,
      color: lightSlateGreyColor,
    },
    informationContent: {
      padding: 20,
    },
    informationItem: {
      marginBottom: 10,
    },
    lastInformationItem: {
      marginBottom: 0,
    },
    informationItemLabel: {
      ...defaultFontMedium,
      fontSize: 12,
    },
    informationItemValue: {
      ...defaultFontRegular,
      ...oneLineText,
      fontSize: 15,
      color: lightSlateGreyColor,
      maxWidth: 400,
      '& a': {
        color: denimColor,
      },
    },
    informationItemDisplay: {
      whiteSpace: 'inherit',
      wordBreak: 'break-all',
    },
    proposalArea: {
      marginTop: 15,
      width: '100%',
      float: 'right',
      paddingTop: 30,
      paddingBottom: 30,
      backgroundColor: whiteSmokeColor,
      border: `1px solid ${pattensBlueColor}`,
      textAlign: 'center',
      borderRadius: 4,
      boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
      marginBottom: 30,
    },
    proposalText: {
      ...defaultFont,
      fontSize: 12,
      color: lightSlateGreyColor,
    },
    // Node option popover
    listOption: {},
    listOptionItem: {
      height: 46,
      width: 260,
      ...defaultFont,
      fontSize: 16,
    },
    horizontalSeparate: {
      height: 1,
      width: '100%',
      backgroundColor: pattensBlueColor,
    },
    nodeOptionPopoverPaper: {},
    formContent: {
      marginTop: 15,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'left',
    },
    submitBtn: {
      marginLeft: 15,
    },
    requestTypeField: {
      width: 180,
    },
    customSelectPaper: {
      border: 0,
    },
    proposeNodeSelectMenu: {
      paddingTop: 8,
      paddingBottom: 7,
      backgroundImage: 'linear-gradient(to bottom, #ffffff, #ededed)',
      border: 'solid 1px #7b90a3',
      borderRadius: 4,
      color: '#7b90a3',
      fontSize: 15,
      fontWeight: 500,
    },
    endPoint: {
      display: 'flex',
    },
    copyButton: {
      padding: 5,
      marginLeft: 5,
    },
    transfer: {
      ...defaultFont,
      background: '#707070',
      color: whiteColor,
      padding: '4px 6px',
      fontSize: 10,
      borderRadius: 4,
      cursor: 'pointer',
      marginLeft: 30,
    },
    transferDisable: {
      ...defaultFont,
      background: '#707070',
      color: whiteColor,
      padding: '4px 6px',
      fontSize: 10,
      borderRadius: 4,
      marginLeft: 30,
    },
    memberNodeOverview: {},
    nInfo: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      memberNodeOverview: {
        flexDirection: 'column',
      },
      nInfo: {
        marginRight: 0,
        marginBottom: 21,
      },
      proposalArea: {
        width: 'calc(100% - 0px)',
        marginTop: 30,
      },
      requestTypeField: {},
    },
    genesisBtn: {
      ...defaultFontMedium,
      fontSize: 12,
      color: lightSlateGreyColor,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
      '& .MuiButton-startIcon': {
        width: '20px',
        height: '20px',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    externalServerStatus: {
      marginLeft: '8px',
      fontSize: 13,
      color: nightRiderColor,
    },
  });

const mapStateToProps = (store: IStore, ownProps): IStateProps => ({
  providers: store.appState.providers || [],
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
  isLoadingGetGenesis: NetworkActions.getGenesisJson.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => dispatch(AppActions.openSnackBar(args)),
  controleNode: (args: NetworkActions.MutationControlNodeArgs) =>
    dispatch(NetworkActions.controlNode(args)),
  transferAll: (args: NetworkActions.MutationSendAllEtherFromNodeArgs) =>
    dispatch(NetworkActions.sendAllEtherFromNode(args)),
  estimateGas: (args: NetworkActions.QueryEstimateTransferGasArgs) =>
    dispatch(NetworkActions.estimateTransferGas(args)),
  getGenesisJson: (args: QueryGetGenesisJsonArgs) => dispatch(NetworkActions.getGenesisJson(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OverviewTab)),
);

type ProposeNodeFormValues = {
  requestType: 'signer' | 'transaction';
};

const proposeNodeValidateSchema = Yup.object().shape({
  requestType: Yup.string().required('Required'),
});
