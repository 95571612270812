import React from 'react';
// Redux
import { connect } from 'react-redux';
import * as NetworkActions from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';
// Component
import Button from '@mui/material/Button';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/submit-button';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  snowColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';
// Type
import { INetwork, IConsortium, IConsortiumRoleType } from '~/types/network-types';
import { Account } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';

interface IStateProps {}

interface IDispProps {
  withdrawConsortium: (
    args: NetworkActions.MutationWithdrawConsortiumArgs,
  ) => Promise<NetworkActions.WITHDRAW_CONSORTIUM_RESULT_TYPE>;
  listNetworks: (
    args: NetworkActions.QueryListNetworksArgs,
  ) => Promise<NetworkActions.LIST_NETWORKS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
  network: INetwork;
  account: Account;
  consortium: IConsortium;
}

interface IState {
  isSubmitting: boolean;
}

class WithdrawConsortiumDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isSubmitting: false,
    };
  }

  public render() {
    const { classes, t, network, open, onClose, consortium } = this.props;
    const { isSubmitting } = this.state;

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <CustomDialogTitle>{t('withdraw_from_network')}</CustomDialogTitle>
        <CustomDialogContent>
          <div className={classes.consortiumInfo}>
            <div className={classes.consortiumInfoItem}>
              <div className={classes.consortiumInfoTitle}>{t('network_name')}</div>
              <div className={classes.consortiumInfoValue}>{network.networkName}</div>
            </div>
            <div className={classes.consortiumInfoItem}>
              <div className={classes.consortiumInfoTitle}>{t('network_id')}</div>
              <div className={classes.consortiumInfoValue}>{network.networkUuid}</div>
            </div>
            <div className={classes.consortiumInfoItem}>
              <div className={classes.consortiumInfoTitle}>{t('organization_name')}</div>
              <div className={classes.consortiumInfoValue}>{consortium.accountName}</div>
            </div>
            <div className={classes.consortiumInfoItem}>
              <div className={classes.consortiumInfoTitle}>{t('organization_id')}</div>
              <div className={classes.consortiumInfoValue}>{consortium.accountUuid}</div>
            </div>
          </div>
          <div className={classes.warningText}>{t('withdraw_consortium_warning_text')}</div>
        </CustomDialogContent>
        <CustomDialogActions>
          <Button className={classes.leftBtn} variant="contained" onClick={onClose}>
            {t('cancel')}
          </Button>
          <SubmitButton
            isValid={true}
            isSubmitting={isSubmitting}
            label={t('update')}
            submittingLabel={t('updating')}
            onClick={this.onSubmit}
          />
        </CustomDialogActions>
      </CustomDialog>
    );
  }

  onSubmit = async () => {
    const { network, account, onClose, consortium, withdrawConsortium, listNetworks } = this.props;
    this.setState({ isSubmitting: true });
    try {
      if (consortium.accountUuid) {
        await withdrawConsortium({
          input: {
            networkUuid: network.networkUuid,
            accountUuid: account.accountUuid,
            targetAccountUuid: consortium.accountUuid,
          },
        });
        if (account.accountUuid === consortium.accountUuid) {
          listNetworks({ accountUuid: account.accountUuid });
        }
      }
      onClose();
    } catch (error) {
      console.log(error.message);
    }
    this.setState({ isSubmitting: false });
  };
}

const styles = createStyles({
  root: {},
  dialogPaper: {},
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  consortiumInfo: {
    padding: '15px 30px',
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
  },
  consortiumInfoItem: {
    display: 'inline-block',
    padding: '15px 0',
    width: '50%',
    maxWidth: '50%',
  },
  consortiumInfoTitle: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  consortiumInfoValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  warningText: {
    marginTop: 30,
    textAlign: 'center',
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({
  withdrawConsortium: (args: NetworkActions.MutationWithdrawConsortiumArgs) =>
    dispatch(NetworkActions.withdrawConsortium(args)),
  listNetworks: (args: NetworkActions.QueryListNetworksArgs) =>
    dispatch(NetworkActions.listNetworks(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WithdrawConsortiumDialog)),
);

interface FormValues {
  role: IConsortiumRoleType;
}

const roleOptions: Array<{ value: IConsortiumRoleType; label: string }> = [
  {
    value: 'admin',
    label: 'Consortium Admin',
  },
  {
    value: 'member',
    label: 'Consortium Member',
  },
];
