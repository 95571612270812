import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as semver from 'semver';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';

// Component
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/submit-button';

import { INode, ICluster, INetwork } from '~/types/network-types';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  pattensBlueColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import CustomInput from '~/components/common/custom-input';
import { ALLOC_ADDRESS_LENGTH } from '~/constants/consts';
import JSONPretty from 'react-json-pretty';

interface IProps extends WithStyles<typeof styles> {
  network: INetwork;
  cluster: ICluster;
  node: INode;
  open: boolean;
  onClose: () => void;
}

type FormValues = {
  transferAddress?: string;
};

const UpdateClefExternalAddressDialog = (props: IProps) => {
  const { classes, open, node, network, cluster, onClose } = props;
  const accountSeleted = useSelector((store: IStore) => store.appState.accountSeleted);
  const isLoading = useSelector((store: IStore) =>
    NetworkActions.updateRuleOfInternalClef.isPending(store),
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    transferAddress: node.signerInfo?.internalClef?.transferAddress,
  };

  const transferAddressField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('transfer_addr')}</div>
            {!!form.errors.transferAddress && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {form.errors.transferAddress}
              </div>
            )}
          </div>
          <div>
            <CustomInput
              id="member-account-general-addr"
              {...field}
              placeholder={t('input_transfer_addr')}
            />
          </div>
        </>
      );
    },
    [classes, t],
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;
      const { transferAddress } = values;

      try {
        if (accountSeleted?.accountUuid) {
          await dispatch(
            NetworkActions.updateRuleOfInternalClef({
              input: {
                accountUuid: accountSeleted.accountUuid,
                networkUuid: network.networkUuid,
                clusterUuid: cluster.clusterUuid,
                nodeUuid: node.nodeUuid,
                transferAddress: transferAddress || '',
              },
            }),
          );
          onClose();
        }
      } catch (error) {}
      setSubmitting(false);
    },
    [
      accountSeleted?.accountUuid,
      cluster.clusterUuid,
      dispatch,
      network.networkUuid,
      node.nodeUuid,
      onClose,
    ],
  );

  const validateAllocAddress = useCallback(
    (value: string) => {
      let error: string = '';
      if (!value) {
        return;
      }
      if (value.indexOf('0x') === 0) {
        value = value.substring(2);
      }
      const allocAddressPattern = /^[0-9a-fA-F]+$/;
      if (!allocAddressPattern.test(value) || value.length !== ALLOC_ADDRESS_LENGTH) {
        error = t('error_alloc_address');
      }
      return error;
    },
    [t],
  );

  const handleCloseDialog = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <CustomDialog
        open={open}
        onClose={handleCloseDialog}
        classes={{ paper: classes.customDialog }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-registered-address-title">{t('update_transfer_address')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div className={classes.formSection}>
                  <Field
                    name="transferAddress"
                    render={transferAddressField}
                    validate={validateAllocAddress}
                  />
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  id="member-registered-address-cancel"
                  disabled={isSubmitting || isLoading}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={handleCloseDialog}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  id="member-registered-address-submit"
                  isValid={isValid}
                  isSubmitting={isSubmitting || isLoading}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  customDialog: {},
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  textField: {
    '& .MuiInputBase-root': {
      ...defaultFont,
      minHeight: 40,
      fontSize: 13,
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: pattensBlueColor,
      backgroundColor: '#F5F5F5',
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& .MuiAutocomplete-input': {
        padding: '0 15px 0 15px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  },
});

export default withStyles(styles)(UpdateClefExternalAddressDialog);
