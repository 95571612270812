import React from 'react';
import { connect } from 'react-redux';
// Redux
import * as NetworkActions from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';
// Component
import Button from '@mui/material/Button';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/submit-button';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  lightSlateGreyColor,
  snowColor,
  pattensBlueColor,
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
} from '~/styles/themes/common-styles/color';
// Type
import { ICluster, INetwork, INetworkAccess } from '~/types/network-types';
import { Account } from '~/types/account-types';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
import { CustomDomainInfo } from '~/gapi/gtypes';

interface IStateProps {}

interface IDispProps {
  removeCustomDomain: (
    args: NetworkActions.MutationRemoveCustomDomainArgs,
  ) => Promise<NetworkActions.REMOVE_CUSTOM_DOMAIN_RESULT_TYPE>;
  listCustomDomains: (
    args: NetworkActions.QueryListCustomDomainsArgs,
  ) => Promise<NetworkActions.LIST_CUSTOM_DOMAINS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  customDomainInfo: CustomDomainInfo;
  account: Account;
  network: INetwork;
  cluster: ICluster;
  onClose: () => void;
}

interface IState {
  isSubmitting: boolean;
}

class DeleteCustomDomainDialog extends React.Component<IProps & IDispProps, IState> {
  constructor(props: IProps & IDispProps) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  public render() {
    const { classes, open, onClose, customDomainInfo, t } = this.props;
    const { isSubmitting } = this.state;

    return (
      <CustomDialog open={open} onClose={onClose}>
        <CustomDialogTitle>
          <div id="member-domain-delete-title">{t('delete_domain')}</div>
        </CustomDialogTitle>
        <CustomDialogContent>
          <div id="member-domain-delete-information" className={classes.contentArea}>
            <div className={classes.infoItem}>
              <div className={classes.clusterItemInfo}>
                <div className={classes.subTitle}>{t('domain_name')}</div>
                <div className={classes.detailValue}>{customDomainInfo.domainName}</div>
              </div>
            </div>
          </div>
          <div id="member-access-delete-attention" className={classes.warningText}>
            {t('you_want_to_delete_the_custom_domain')}
          </div>
        </CustomDialogContent>
        {/* Render Submit Button */}
        <CustomDialogActions>
          <Button
            id="member-access-delete-submit-cancel"
            disabled={isSubmitting}
            className={classes.leftBtn}
            variant="contained"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
          <SubmitButton
            id="member-access-delete-submit-del"
            isValid={true}
            isSubmitting={isSubmitting}
            label={t('delete')}
            submittingLabel={t('deleting')}
            onClick={this.onRemoveCustomDomain}
          />
        </CustomDialogActions>
      </CustomDialog>
    );
  }

  private onRemoveCustomDomain = async () => {
    const {
      onClose,
      customDomainInfo,
      account,
      network,
      cluster,
      removeCustomDomain,
      listCustomDomains,
    } = this.props;
    if (!account || !network) {
      return;
    }

    this.setSubmitting(true);
    try {
      await removeCustomDomain({
        input: {
          accountUuid: account.accountUuid,
          networkUuid: network.networkUuid,
          clusterUuid: cluster.clusterUuid,
          certArn: customDomainInfo.certArn,
        },
      });
      onClose();
      listCustomDomains({
        input: {
          accountUuid: account.accountUuid,
          networkUuid: network.networkUuid,
          clusterUuid: cluster.clusterUuid,
        },
      }).catch(console.log);
    } catch (error) {
      // Do nothing
    }
    this.setSubmitting(false);
  };

  private setSubmitting = (value: boolean) => {
    this.setState({
      isSubmitting: value,
    });
  };
}

const styles = createStyles({
  root: {},
  nodeTitle: {
    ...defaultFontMedium,
    fontSize: 15,
    color: lightSlateGreyColor,
  },
  subTitle: {
    ...defaultFontBold,
    fontSize: 14,
  },
  contentArea: {
    paddingTop: 30,
    paddingBottom: 30,
    display: 'flex',
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
  },
  infoItem: {
    paddingLeft: 31,
    paddingRight: 31,
    flex: 1,
  },
  clusterItemInfo: {
    marginTop: 10,
    marginBottom: 10,
  },
  detailValue: {
    ...defaultFont,
    fontSize: 16,
    color: lightSlateGreyColor,
  },
  warningText: {
    ...defaultFont,
    fontSize: 18,
    color: romanColor,
    textAlign: 'center',
    marginTop: 25,
  },
  // Submit Button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({
  removeCustomDomain: (args: NetworkActions.MutationRemoveCustomDomainArgs) =>
    dispatch(NetworkActions.removeCustomDomain(args)),
  listCustomDomains: (args: NetworkActions.QueryListCustomDomainsArgs) =>
    dispatch(NetworkActions.listCustomDomains(args)),
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DeleteCustomDomainDialog)),
);
