import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';

// Component

import { INode, ICluster, INetwork } from '~/types/network-types';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  pattensBlueColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/submit-button';
import CustomInput from '~/components/common/custom-input';
import CustomInputNum from '~/components/common/custom-input-num';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Account } from '~/gapi/gtypes';
import {
  HOSTNAME_PATTERN,
  IP_ADDRESS_PATTERN,
  MAX_CLEF_SERVER_HOSTNAME_LENGTH,
  MAX_CLEF_SERVER_PRIVATE_KEY_LENGTH,
  MAX_CLEF_SERVER_USERNAME_LENGTH,
  SSH_PRIVATE_KEY_PATTERN,
  VALIDATE_SSH_PRIVATE_KEY_SIZE,
} from '~/constants/validation';

interface IProps extends WithStyles<typeof styles> {
  network: INetwork;
  cluster: ICluster;
  account: Account;
  node: INode;
  open: boolean;
  onClose: () => void;
}

type FormValues = {
  username: string;
  host: string;
  port: number;
  sshPrivateKey?: string;
};

const UpdateExternalSignerDialog = (props: IProps) => {
  const { classes, open, node, network, cluster, account, onClose } = props;
  const isUpdating = useSelector((store: IStore) =>
    NetworkActions.updateExternalClef.isPending(store),
  );
  const [sshPrivateKey, setSshPrivateKey] = useState<string | undefined>('');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    username: node.signerInfo?.externalClef?.username || '',
    host: node.signerInfo?.externalClef?.host || '',
    port: node.signerInfo?.externalClef?.port || 0,
  };

  const validateSchema = Yup.object().shape<FormValues>({
    username: Yup.string()
      .required(t('required_field'))
      .min(1, t('validate_minimum', { val: 1 }))
      .max(
        MAX_CLEF_SERVER_USERNAME_LENGTH,
        t('validate_maximum', { val: MAX_CLEF_SERVER_USERNAME_LENGTH }),
      ),
    host: Yup.string()
      .required(t('required_field'))
      .max(
        MAX_CLEF_SERVER_HOSTNAME_LENGTH,
        t('validate_maximum', { val: MAX_CLEF_SERVER_HOSTNAME_LENGTH }),
      )
      .test(
        'check-external-server-host',
        t('invalid_external_server_host'),
        function (host: string) {
          return HOSTNAME_PATTERN.test(host) || IP_ADDRESS_PATTERN.test(host);
        },
      ),
    port: Yup.number()
      .required(t('required_field'))
      .min(1, t('validate_minimum', { val: 1 }))
      .max(65353, t('validate_maximum', { val: 65353 })),
  });

  const handleFileUpload = useCallback((event, form: FormikProps<FormValues>) => {
    const file = event.target.files[0];
    form.setFieldValue('sshPrivateKey', file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSshPrivateKey(e.target?.result?.toString());
      };
      reader.readAsText(file);
    }
  }, []);

  const externalServerUserField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('clef_server_username')}</div>
          {!!form.errors.username && form.touched.username && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.username)}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="external address"
            placeholder={t('input_clef_server_username')}
          />
        </div>
      </>
    );
  };

  const externalHostField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('clef_server_host')}</div>
          {!!form.errors.host && form.touched.host && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.host)}
            </div>
          )}
        </div>
        <div>
          <CustomInput {...field} id="external host" placeholder={t('input_clef_server_host')} />
        </div>
      </>
    );
  };

  const externalPortField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('clef_api_port')}</div>
          {!!form.errors.port && form.touched.port && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.port)}
            </div>
          )}
        </div>
        <div>
          <CustomInputNum
            {...field}
            id="member-external-port"
            min={0}
            max={65353}
            placeholder={t('input_clef_api_port')}
          />
        </div>
      </>
    );
  };

  const externalSshPrivateField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('ssh_private_key_file')}</div>
          {!!form.errors.sshPrivateKey && form.touched.sshPrivateKey && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.sshPrivateKey)}
            </div>
          )}
        </div>
        <div>
          <TextField
            {...field}
            value={undefined}
            type="file"
            className={classes.textField}
            onChange={(event) => handleFileUpload(event, form)}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </>
    );
  };

  const validateFileSize = useCallback(
    (file) => {
      const error: string = '';
      if (file?.size > VALIDATE_SSH_PRIVATE_KEY_SIZE) {
        return t('ssh_private_key_too_large');
      }
      return error;
    },
    [t],
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;
      if (
        sshPrivateKey &&
        (!SSH_PRIVATE_KEY_PATTERN.test(sshPrivateKey) ||
          sshPrivateKey.length > MAX_CLEF_SERVER_PRIVATE_KEY_LENGTH)
      ) {
        dispatch(
          AppActions.openSnackBar({
            type: 'error',
            message: t('invalid_ssh_private_key'),
          }),
        );
        setSubmitting(false);
        return;
      }
      try {
        await dispatch(
          NetworkActions.updateExternalClef({
            input: {
              accountUuid: account.accountUuid,
              networkUuid: network.networkUuid,
              clusterUuid: cluster.clusterUuid,
              nodeUuid: node.nodeUuid,
              host: values.host,
              username: values.username,
              port: values.port,
              sshPrivateKey: sshPrivateKey || undefined,
            },
          }),
        );
        onClose();
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    },
    [
      account.accountUuid,
      cluster.clusterUuid,
      network.networkUuid,
      node.nodeUuid,
      sshPrivateKey,
      dispatch,
      onClose,
      t,
    ],
  );

  return (
    <>
      <CustomDialog open={open} onClose={onClose}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={onSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-update-external-signer-title">{t('update_external_clef')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div className={classes.formSection}>
                  <Grid container>
                    <Grid item xs={6} className={classNames(classes.gridLeftItem)}>
                      <Field name="username" render={externalServerUserField} />
                    </Grid>
                    <Grid item xs={6} className={classNames(classes.gridRightItem)}>
                      <Field name="host" render={externalHostField} />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.formSection}>
                  <Grid container>
                    <Grid item xs={6} className={classNames(classes.gridLeftItem)}>
                      <Field name="port" render={externalPortField} />
                    </Grid>
                    <Grid item xs={6} className={classNames(classes.gridRightItem)}>
                      <Field
                        name="sshPrivateKey"
                        render={externalSshPrivateField}
                        validate={validateFileSize}
                      />
                    </Grid>
                  </Grid>
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  id="member-update-external-signer-cancel"
                  disabled={isSubmitting || isUpdating}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={onClose}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  id="member-update-external-signer-submit"
                  isValid={isValid}
                  isSubmitting={isSubmitting || isUpdating}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  textField: {
    '& .MuiInputBase-root': {
      ...defaultFont,
      height: 40,
      fontSize: 13,
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: pattensBlueColor,
      backgroundColor: '#F5F5F5',
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& .MuiAutocomplete-input': {
        padding: '0 15px 0 15px',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: 10,
    },
    '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  },
});

export default withStyles(styles)(UpdateExternalSignerDialog);
