import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import classNames from 'classnames';
import {
  defaultFont,
  defaultFontMedium,
  defaultFontBold,
} from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  whiteSmokeColor,
  denimColor,
  whiteColor,
  nightRiderColor,
  romanColor,
  veryLightGreyColor,
} from '~/styles/themes/common-styles/color';
// Component
import { Theme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import LoadingIcon from '~/components/common/loading-icon';
import TableHeadCustom from '~/components/common/table-head';
import TableBodyCustom from '~/components/common/table-body';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import TableRowHeadCustom from '~/components/common/table-row-head';
import TableRowBodyCustom from '~/components/common/table-row-body';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Type
import { ILicenseSummaryByBilling, ILicenseSummaryByBillingDetail } from '~/types/payment-types';
import { Account } from '~/types/account-types';
import { displayCurrency } from '~/utilities/payment-utils';
import * as PaymentAction from '~/stores/actions/payment-action';
import { LICENSE_TYPE_ID_STORAGE, TAB_TITLE_CONCAT } from '~/constants/consts';

interface IStateProps {
  accountSelected?: Account;
  activeLicensesSummary?: ILicenseSummaryByBilling;
  listActiveLicensesSummaryLoading: boolean;
}

interface IDispProps {
  listActiveLicensesSummary: (
    args: PaymentAction.QueryListActiveLicensesSummaryArgs,
  ) => Promise<PaymentAction.LIST_ACTIVE_LICENSES_SUMMARY_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {}

interface IState {}

class LicenseTab extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // Get active licenses summary (force)
    this.listActiveLicensesSummary();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.props.accountSelected &&
      prevProps.accountSelected &&
      this.props.accountSelected.accountUuid !== prevProps.accountSelected.accountUuid
    ) {
      this.listActiveLicensesSummary();
    }
  }

  listActiveLicensesSummary = () => {
    const { accountSelected, listActiveLicensesSummary } = this.props;

    if (accountSelected) {
      listActiveLicensesSummary({ accountUuid: accountSelected.accountUuid }).catch(console.log);
    }
  };

  public render() {
    const { classes, t, activeLicensesSummary, listActiveLicensesSummaryLoading } = this.props;
    const isLicenseActive = Boolean(
      activeLicensesSummary &&
        (activeLicensesSummary.thisMonth.length || activeLicensesSummary.nextMonth.length),
    );

    document.title = TAB_TITLE_CONCAT + this.props.t('license');

    return (
      <div className={classes.root}>
        <div className={classes.infoItem}>
          <div id="member-billing-license-plan" className={classes.subTitle}>
            {isLicenseActive && !listActiveLicensesSummaryLoading && (
              <>
                {t('standard_plan')}
                <span className={classes.totalAccount}>{t('active')}</span>
              </>
            )}
          </div>
          <Button
            className={classes.refreshIcon}
            variant="outlined"
            onClick={this.listActiveLicensesSummary}
          >
            <img src="/images/icons/refresh_icon.svg" alt="" />
          </Button>
        </div>
        {this.licenseTable}
      </div>
    );
  }

  get licenseTable() {
    const { classes, t, listActiveLicensesSummaryLoading, activeLicensesSummary } = this.props;
    const isLicenseActive = Boolean(
      activeLicensesSummary &&
        (activeLicensesSummary.thisMonth.length || activeLicensesSummary.nextMonth.length),
    );

    if (listActiveLicensesSummaryLoading) {
      return (
        <div className={classes.loadingArea}>
          <LoadingIcon />
        </div>
      );
    }

    if (!activeLicensesSummary) {
      return null;
    }

    if (!isLicenseActive) {
      return (
        <div className={classes.contentDescriptionArea}>
          <div className={classes.contentDescription} id="license-detail-caption">
            {this.props.t('no_active_licenses')}
          </div>
        </div>
      );
    }

    const licenses = this.groupLicenses(activeLicensesSummary);

    return (
      <div className={classes.tableArea}>
        <Table
          id="member-billing-licenses-list"
          className={classNames(classes.licenseTable, classes.tblList)}
        >
          <TableHeadCustom>
            <TableRowHeadCustom className={classes.tableHeadCustom}>
              <TableCellHeadCustom className={classes.tableCellHeadCustom}>
                <span>{t('type')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.tableCellHeadCustom}>
                <span>{t('size')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.tableCellHeadCustom}>
                <span>{t('quantity')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.tableCellHeadCustom}>
                <span>{t('month_current')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.tableCellHeadCustom}>
                <span>{t('month_next')}</span>
              </TableCellHeadCustom>
            </TableRowHeadCustom>
          </TableHeadCustom>
          <TableBodyCustom>
            {Object.entries(licenses.hash.cur).map(([k, v]) => {
              const nextSum =
                k in licenses.hash.next
                  ? displayCurrency(licenses.hash.next[k].subTotalPrice)
                  : 'unknown';
              return (
                <TableRowBodyCustom key={`license-active-${k}`} className={classes.tableBodyCustom}>
                  <TableCellBodyCustom className={classNames(classes.textDetails, classes.font13)}>
                    {v.licenseName}
                  </TableCellBodyCustom>
                  <TableCellBodyCustom className={classes.font13}>
                    {v.licenseItemId !== LICENSE_TYPE_ID_STORAGE ? '' : '1'}
                    {t(v.size)}
                  </TableCellBodyCustom>
                  <TableCellBodyCustom className={classes.font13}>
                    {v.qty} License
                  </TableCellBodyCustom>
                  <TableCellBodyCustom className={classes.font13}>
                    $ {displayCurrency(v.subTotalPrice)}
                  </TableCellBodyCustom>
                  <TableCellBodyCustom className={classes.font13}>$ {nextSum}</TableCellBodyCustom>
                </TableRowBodyCustom>
              );
            })}

            <TableRowBodyCustom className={classNames(classes.topBorder, classes.tableBodyCustom)}>
              <TableCellBodyCustom
                colSpan={4}
                className={classNames(classes.textDetails, classes.subtotalRow, classes.font13)}
              >
                {t('subtotal')}
              </TableCellBodyCustom>
              <TableCellBodyCustom
                className={classNames(classes.textDetails, classes.subtotalRow, classes.font13)}
              >
                $ {displayCurrency(licenses.total.next)}
              </TableCellBodyCustom>
            </TableRowBodyCustom>
            <TableRowBodyCustom>
              <TableCellBodyCustom
                colSpan={4}
                className={classNames(classes.textDetails, classes.font13, classes.fontw700)}
              >
                {t('amount_of_coupon_used')}
              </TableCellBodyCustom>
              <TableCellBodyCustom className={classNames(classes.couponValue, classes.font13)}>
                {`-$ ` + displayCurrency(licenses.total.nextcouponamount)}
              </TableCellBodyCustom>
            </TableRowBodyCustom>
            <TableRowBodyCustom>
              <TableCellBodyCustom
                colSpan={4}
                className={classNames(classes.textDetails, classes.font13, classes.fontw700)}
              >
                {t('tax')}
              </TableCellBodyCustom>
              <TableCellBodyCustom>$ {displayCurrency(licenses.tax.next)}</TableCellBodyCustom>
            </TableRowBodyCustom>
            <TableRowBodyCustom className={classNames(classes.topBorder, classes.bottomBorder)}>
              <TableCellBodyCustom
                colSpan={4}
                className={classNames(
                  classes.textDetails,
                  classes.billingAmountRow,
                  classes.font16,
                )}
              >
                {t('total')}
              </TableCellBodyCustom>
              <TableCellBodyCustom
                className={classNames(classes.textDetails, classes.billingAmountRow)}
              >
                ${' '}
                {displayCurrency(
                  licenses.total.next - licenses.total.nextcouponamount + licenses.tax.next,
                )}
              </TableCellBodyCustom>
            </TableRowBodyCustom>
          </TableBodyCustom>
        </Table>
      </div>
    );
  }

  private groupLicenses = (activeLicensesSummary: ILicenseSummaryByBilling) => {
    // create hashed map from array (group by and sum 'qty','subTotalPrice' value)

    const hashNext = activeLicensesSummary.nextMonth
      .map((s) => s.licenses)
      .reduce((pre, cur) => {
        pre.push(...cur);
        return pre;
      }, [])
      .reduce((pre, cur) => {
        const itemId = cur.licenseItemId.toString();
        if (pre[itemId]) {
          pre[itemId].qty += cur.qty;
          pre[itemId].subTotalPrice += cur.subTotalPrice;
        } else {
          pre[itemId] = { ...cur };
        }
        return pre;
      }, {} as { [key: string]: ILicenseSummaryByBillingDetail });

    const hashThis = activeLicensesSummary.thisMonth
      .map((s) => s.licenses)
      .reduce((pre, cur) => {
        pre.push(...cur);
        return pre;
      }, [])
      .reduce((pre, cur) => {
        const itemId = cur.licenseItemId.toString();
        if (pre[itemId]) {
          pre[itemId].qty += cur.qty;
          pre[itemId].subTotalPrice += cur.subTotalPrice;
        } else {
          pre[itemId] = { ...cur };
        }

        if (!hashNext[itemId]) {
          hashNext[itemId] = {
            ...cur,
            qty: 0,
            subTotalPrice: 0,
          };
        }
        return pre;
      }, {} as { [key: string]: ILicenseSummaryByBillingDetail });

    return {
      hash: {
        cur: hashThis,
        next: hashNext,
      },
      total: {
        cur: activeLicensesSummary.thisMonth
          .map((s) => s.amount)
          .reduce((pre, cur) => pre + cur, 0),
        curcouponamount: activeLicensesSummary.thisMonth
          .map((s) => s.coupon)
          .reduce((pre, cur) => pre + cur, 0),
        next: activeLicensesSummary.nextMonth
          .map((s) => s.amount)
          .reduce((pre, cur) => pre + cur, 0),
        nextcouponamount: activeLicensesSummary.nextMonth
          .map((s) => s.coupon)
          .reduce((pre, cur) => pre + cur, 0),
      },
      tax: {
        cur: activeLicensesSummary.thisMonth
          .map((s) => s.taxFee)
          .reduce((pre, cur) => pre + cur, 0),
        next: activeLicensesSummary.nextMonth
          .map((s) => s.taxFee)
          .reduce((pre, cur) => pre + cur, 0),
      },
    };
  };
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 20,
      paddingBottom: 20,
      display: 'block',
    },
    refreshIcon: {
      width: 40,
      minWidth: 40,
      height: 32,
      padding: 0,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        border: '1px solid rgba(0, 0, 0, 0.23)',
      },
    },
    contentDescriptionArea: {
      marginTop: 20,
      textAlign: 'center',
      height: 281,
      lineHeight: 281,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
      border: `solid 1px ${pattensBlueColor}`,
      backgroundColor: whiteColor,
    },
    contentDescription: {
      ...defaultFont,
      fontSize: 24,
      lineHeight: '1.17em',
      color: lightSlateGreyColor,
      width: '100%',
    },
    infoItem: {
      flex: 1,
      minWidth: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    subTitle: {
      ...defaultFontBold,
      fontSize: 16,
    },
    loadingArea: {
      marginTop: 100,
      textAlign: 'center',
    },
    totalAccount: {
      ...defaultFontMedium,
      fontSize: 16,
      color: denimColor,
      marginLeft: 30,
    },
    licenseTable: {
      marginTop: 20,
      marginBottom: 20,
    },
    tableHeadCustom: {
      '& .MuiTableCell-root': {
        letterSpacing: 'normal',
      },
    },
    tableBodyCustom: {
      height: '48px',
      '& .MuiTableCell-root': {
        letterSpacing: 'normal',
        padding: '4px 30px 4px 10px',
      },
    },
    tableCellHeadCustom: {
      '& span': {
        ...defaultFontMedium,
      },
    },
    textDetails: {
      ...defaultFontMedium,
      fontSize: 15,
      color: nightRiderColor,
    },
    topBorder: {
      borderTop: `2px solid ${veryLightGreyColor}`,
    },
    bottomBorder: {
      borderBottom: `2px solid ${veryLightGreyColor}`,
    },
    subtotalRow: {
      ...defaultFontBold,
      borderTop: `1px solid ${whiteSmokeColor}`,
      paddingTop: 10,
    },
    couponValue: {
      color: `${romanColor} !important`,
    },
    billingAmountRow: {
      ...defaultFontBold,
      color: denimColor,
      borderTop: `1px solid ${whiteSmokeColor}`,
      paddingTop: 10,
    },
    tableArea: {},
    tblList: {},
    font13: {},
    font16: {},
    fontw700: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      tableArea: {
        overflow: 'auto',
      },
      tblList: {
        width: 920,
      },
      font13: {
        fontSize: 13,
      },
      font16: {
        fontSize: 16,
      },
      fontw700: {
        fontWeight: 700,
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  accountSelected: store.appState.accountSeleted,
  activeLicensesSummary: store.appState.activeLicensesSummary,
  listActiveLicensesSummaryLoading: PaymentAction.listActiveLicensesSummary.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  listActiveLicensesSummary: (args: PaymentAction.QueryListActiveLicensesSummaryArgs) =>
    dispatch(PaymentAction.listActiveLicensesSummary(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LicenseTab)),
);
