import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';

import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  whiteSmokeColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';

interface IProps {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: any;
  disabled?: boolean;
  name?: string;
  onBlur?: any;
  step?: number;
  min?: number;
  max?: number;
  id?: string;
  format?: any;
  parse?: any;
}

const customInputNum: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  className,
  placeholder,
  value,
  onChange,
  disabled,
  name,
  onBlur,
  step,
  min,
  max,
  id,
}) => {
  const defStep = step !== undefined ? step : 1;
  const defMin = min !== undefined ? min : 0;
  const defMax = max !== undefined ? max : undefined;

  return (
    <input
      name={name}
      onBlur={onBlur}
      type="number"
      className={classNames(classes.input, className)}
      placeholder={placeholder}
      step={defStep}
      min={defMin}
      max={defMax}
      defaultValue={value}
      onChange={onChange}
      disabled={disabled}
      id={id}
    />
  );
};

customInputNum.defaultProps = {
  disabled: false,
  step: 1,
  min: 1,
};

const styles: StyleRules = {
  root: {},
  input: {
    ...defaultFont,
    fontSize: 13,
    '&::placeholder': {
      color: lightSlateGreyColor,
    },
    width: '100%',
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: whiteSmokeColor,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: pattensBlueColor,
  },
  textarea: {
    height: 60,
  },
};

export default withStyles(styles)(customInputNum);
