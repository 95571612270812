import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';

import { ICluster } from '~/types/network-types';
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as PaymentActions from '~/stores/actions/payment-action';
import { Account } from '~/types/account-types';
import { INetworkProvider, IProviderImage, IProviderRegion, INetwork } from '~/types/network-types';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  denimColor,
  dimGrayColor,
  whiteSmokeColor,
  pattensBlueColor,
  romanColor,
  manateeColor,
  nightRiderColor,
  gainsboroColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
import { CustomDomainDetailInfo, CustomDomainInfo } from '~/gapi/gtypes';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogAction from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/submit-button';
import CustomInput from '~/components/common/custom-input';
import CopyClipboardIcon from '~/components/common/copy-clipboard-icon';
import LoadingIcon from '~/components/common/loading-icon';
import ImgIcon from '~/components/common/img-icon';
interface IStateProps {}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
  customDomainInfo: CustomDomainDetailInfo;
}

interface IState {}

class ViewCustomDomainDialog extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { classes, open, onClose, t, customDomainInfo } = this.props;
    return (
      <CustomDialog open={open} onClose={this.onClose}>
        <CustomDialogTitle className={classes.dialogTitle}>
          {t('custom_domain_info')}
        </CustomDialogTitle>
        <div className={classes.subTitle}>{t('custom_domain_description')}</div>
        <CustomDialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.secondTitle}>{t('for_authentication')}</div>
          <div className={classes.domainInfoArea}>
            <div className={classes.domainInfoItem}>
              <div className={classes.inputLabel}>CNAME</div>
              <div className={classes.input}>
                <CustomInput value={customDomainInfo.cname} />
                <IconButton className={classes.root} onClick={this.onClick(customDomainInfo.cname)}>
                  <ImgIcon className={classes.copyButton} imgUrl="/images/icons/copy_icon.png" />
                </IconButton>
              </div>
            </div>
            <div className={classes.domainInfoItem}>
              <div className={classes.inputLabel}>VALUE</div>
              <div className={classes.input}>
                <CustomInput value={customDomainInfo.cnameValue} />
                <IconButton
                  className={classes.root}
                  onClick={this.onClick(customDomainInfo.cnameValue)}
                >
                  <ImgIcon className={classes.copyButton} imgUrl="/images/icons/copy_icon.png" />
                </IconButton>
              </div>
            </div>
          </div>
          <div className={classes.secondTitle}>{t('for_registration')}</div>
          <div className={classes.domainInfoArea}>
            <div className={classes.domainInfoItem}>
              <div className={classes.inputLabel}>CNAME</div>
              <div className={classes.input}>
                <CustomInput value={customDomainInfo.domainName} />
                <IconButton
                  className={classes.root}
                  onClick={this.onClick(customDomainInfo.domainName)}
                >
                  <ImgIcon className={classes.copyButton} imgUrl="/images/icons/copy_icon.png" />
                </IconButton>
              </div>
            </div>
            <div className={classes.domainInfoItem}>
              <div className={classes.inputLabel}>VALUE</div>
              <div className={classes.input}>
                <CustomInput value={customDomainInfo.endpointDnsName} />
                <IconButton
                  className={classes.root}
                  onClick={this.onClick(customDomainInfo.endpointDnsName)}
                >
                  <ImgIcon className={classes.copyButton} imgUrl="/images/icons/copy_icon.png" />
                </IconButton>
              </div>
            </div>
          </div>
        </CustomDialogContent>
        <CustomDialogAction classes={{ root: classes.dialogAction }}>
          <Button variant="outlined" onClick={this.onClose} className={classes.okBtn}>
            OK
          </Button>
        </CustomDialogAction>
      </CustomDialog>
    );
  }

  onClose = () => {
    this.props.onClose();
  };

  onClick = (value) => () => {
    navigator.clipboard.writeText(value);
  };
}

const styles = (theme) =>
  createStyles({
    root: {},
    dialogTitle: {},
    // content
    dialogContent: {},
    subTitle: {
      color: lightSlateGreyColor,
      fontSize: 13,
      textAlign: 'center',
    },
    secondTitle: {
      color: lightSlateGreyColor,
      fontSize: 13,
      marginTop: 16,
    },
    domainInfoArea: {
      justifyContent: 'center',
    },
    domainInfoItem: {
      flexGrow: 1,
    },
    infoLabel: {
      ...defaultFontBold,
      fontSize: 13,
      color: manateeColor,
    },
    infoValue: {
      marginTop: 10,
      fontSize: 15,
      color: nightRiderColor,
      lineHeight: '25px',
    },
    cName: {
      marginTop: 15,
    },
    cNameValue: {
      marginTop: 15,
    },
    okBtn: {
      ...defaultFont,
      fontSize: 16,
      color: whiteColor,
      backgroundColor: denimColor,
      '&:hover': {
        backgroundColor: denimColor,
      },
      width: 142,
    },
    dialogAction: {},
    input: {
      display: 'flex',
      marginTop: 10,
    },
    inputLabel: {
      ...defaultFont,
      fontSize: 12,
      color: nightRiderColor,
      marginTop: 10,
      marginBottom: 5,
      flex: 'none',
    },
    copyButton: {},
    loadingArea: {
      marginTop: 100,
      textAlign: 'center',
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ViewCustomDomainDialog)),
);
