import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import {
  defaultFont,
  defaultFontMedium,
  defaultFontBold,
} from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  snowColor,
  denimColor,
  whiteColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
import * as PaymentAction from '~/stores/actions/payment-action';

// Component
import TableHeadCustom from '~/components/common/table-head';
import TableBodyCustom from '~/components/common/table-body';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import TableRowHeadCustom from '~/components/common/table-row-head';
import TableRowBodyCustom from '~/components/common/table-row-body';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import RegisterCardDialog from '~/components/common/register-card-dialog';
import RegisterAddressDialog from '~/components/common/register-address-dialog';

// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Type
import { Account, AccountRoleType, NetworkRoleType } from '~/types/account-types';
import { ICardSummary } from '~/types/payment-types';

interface IStateProps {
  account?: Account;
  cards?: ICardSummary[];
  selectedAccountRole?: AccountRoleType;
}

interface IDispProps {
  listCards: (
    args: PaymentAction.QueryListCardsArgs,
  ) => Promise<PaymentAction.LIST_CARDS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {}

interface IState {
  openRegisterCardDialog: boolean;
  openRegisterAddressDialog: boolean;
}

class CardSection extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      openRegisterCardDialog: false,
      openRegisterAddressDialog: false,
    };
  }

  async componentDidMount() {
    const { cards } = this.props;

    !cards && this.listCards();
  }

  public render() {
    const { classes, cards, t, selectedAccountRole } = this.props;
    const { openRegisterCardDialog, openRegisterAddressDialog } = this.state;
    const editableCard = selectedAccountRole === 'owner';

    const CardType = (props) => {
      const { brand, last4 } = props;
      switch (brand) {
        case 'MasterCard':
          return (
            <>
              <img className={classes.cardIcon} src="/images/icons/master_card_ico.png" /> **** ****
              **** {last4}{' '}
            </>
          );
        case 'American Express':
          return (
            <>
              <img className={classes.cardIcon} src="/images/icons/american_express_card_ico.png" />{' '}
              **** **** **** {last4}{' '}
            </>
          );
        case 'Visa':
          return (
            <>
              <img className={classes.cardIcon} src="/images/icons/visa_card_ico.png" /> **** ****
              **** {last4}{' '}
            </>
          );
        case 'JCB':
          return (
            <>
              <img className={classes.cardIcon} src="/images/icons/jcb_card_ico.png" /> **** ****
              **** {last4}{' '}
            </>
          );
        default:
          return <> **** **** **** {last4} </>;
      }
    };

    return (
      <div className={classes.root}>
        <RegisterAddressDialog
          open={openRegisterAddressDialog}
          onClose={this.onCloseRegisterAddressDialog}
        />
        <RegisterCardDialog
          open={openRegisterCardDialog}
          onClose={this.onCloseRegisterCardDialog}
        />
        <div className={classes.borderBlock}>
          <div className={classes.titleArea}>
            <span className={classes.networkTitle}>{t('your_credit_cards')}</span>
          </div>
          <div className={classes.contentAreaTable}>
            {cards && cards.length > 0 ? (
              <>
                <div className={classes.tableArea}>
                  <Table className={classes.customTable} id="member-billing-card-list">
                    <colgroup>
                      <col width={300} />
                      <col width={200} />
                      <col width="auto" />
                    </colgroup>
                    <TableHeadCustom classes={{ root: classes.customTHead }}>
                      <TableRowHeadCustom>
                        <TableCellHeadCustom
                          checkbox
                          className={classes.paddingLeft}
                          classes={{ root: classes.customTHeadCol }}
                        >
                          <span>{t('card_number')}</span>
                        </TableCellHeadCustom>
                        <TableCellHeadCustom checkbox classes={{ root: classes.customTHeadCol }}>
                          <span>{t('expires')}</span>
                        </TableCellHeadCustom>
                        <TableCellHeadCustom checkbox classes={{ root: classes.customTHeadCol }} />
                      </TableRowHeadCustom>
                    </TableHeadCustom>
                    <TableBodyCustom>
                      {cards.map((card, i) => {
                        const month = card.month.length === 1 ? `0${card.month}` : card.month;
                        return (
                          <TableRowBodyCustom
                            key={`bill-history-${i}`}
                            className={classes.tableRowBodyCustom}
                          >
                            <TableCellBodyCustom
                              className={classes.paddingLeft}
                              classes={{ root: classes.customBodyCellf }}
                            >
                              <CardType brand={card.brand} last4={card.last4} />
                            </TableCellBodyCustom>
                            <TableCellBodyCustom classes={{ root: classes.customBodyCellm }}>
                              {month}/{card.year}
                            </TableCellBodyCustom>
                            <TableCellBodyCustom
                              classes={{ root: classes.customBodyCellr }}
                            ></TableCellBodyCustom>
                          </TableRowBodyCustom>
                        );
                      })}
                    </TableBodyCustom>
                  </Table>
                </div>
                <div className={classes.infoItem}>
                  <div className={classNames(classes.subTitle, classes.changeBtnArea)}>
                    <Button
                      className={classes.changeCreditCardBtn}
                      id="member-billing-manage-renew"
                      onClick={this.onOpenRelatedDialog}
                      disabled={!editableCard}
                    >
                      {t('change_credit_card')}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className={classes.infoItem}>
                <div className={classes.createBtnArea}>
                  <div className={classes.infoItem}>
                    <div id="member-billing-create-caption" className={classes.createDescription}>
                      {t('register_payment_method')}
                    </div>
                    <Button
                      id="member-billing-create-button"
                      variant="contained"
                      className={classes.createBtn}
                      onClick={this.onOpenRelatedDialog}
                      disabled={!editableCard}
                    >
                      {this.props.t('register')}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  private onCloseRegisterAddressDialog = (status: boolean = false) => {
    // if address registration successed, then start to input card info
    const dialogState = {
      openRegisterAddressDialog: false,
      openRegisterCardDialog: status ? true : false,
    };
    this.setState(dialogState);
  };

  private onCloseRegisterCardDialog = (status: boolean = false) => {
    this.setState({
      openRegisterCardDialog: false,
    });
    if (status) {
      // reload card info
      this.listCards();
    }
  };

  private onOpenRelatedDialog = () => {
    const { account } = this.props;
    const requireAddr =
      !account || !account.paymentAddr || !account.paymentAddr.country ? true : false;

    this.setState({
      openRegisterAddressDialog: requireAddr,
      openRegisterCardDialog: !requireAddr,
    });
  };

  private listCards = () => {
    const { listCards, account } = this.props;

    account && listCards({ accountUuid: account.accountUuid }).catch(console.log);
  };
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    customTable: {
      borderCollapse: 'separate',
      borderSpacing: '0 10px',
    },
    customTHead: {
      background: 'transparent',
      borderTop: 'none',
      '& tr': {
        height: 30,
      },
    },
    paddingLeft: {
      paddingLeft: 30,
    },
    customTHeadCol: {
      ...defaultFontBold,
      fontSize: 14,
      color: nightRiderColor,
      borderBottom: 'none',
    },
    editBtn: {
      ...defaultFontBold,
      color: lightSlateGreyColor,
      fontSize: 13,
      border: 'none',
      textTransform: 'none',
      backgroundColor: 'transparent',
      '&:hover': {
        background: 'transparent',
      },
    },
    changeCreditCardBtn: {
      ...defaultFontBold,
      color: denimColor,
      fontSize: 14,
      border: 'none',
      textTransform: 'none',
      backgroundColor: 'transparent',
      '&:hover': {
        background: 'transparent',
      },
    },
    customBodyCellf: {
      ...defaultFontMedium,
      color: nightRiderColor,
      border: '1px solid #e9ecef',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      background: '#fafafa',
      borderRight: 'none',
    },
    customBodyCellm: {
      ...defaultFontMedium,
      color: nightRiderColor,
      border: '1px solid #e9ecef',
      borderLeft: 'none',
      borderRight: 'none',
      background: '#fafafa',
    },
    customBodyCellr: {
      textAlign: 'right',
      paddingRight: '0 !important',
      border: '1px solid #e9ecef',
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      borderLeft: 'none',
      background: '#fafafa',
    },
    cardIcon: {
      width: 30,
      marginRight: 5,
    },
    borderBlock: {
      '&:nth-child(2)': {
        marginTop: 17,
      },
      marginTop: 20,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 4,
      borderColor: pattensBlueColor,
      boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
    },
    titleArea: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 36,
      backgroundColor: snowColor,
      paddingLeft: 30,
      paddingRight: 10,
    },
    contentAreaTable: {
      paddingTop: 20,
      paddingBottom: 20,
      display: 'block',
    },
    networkTitle: {
      ...defaultFontMedium,
      fontSize: 15,
      color: nightRiderColor,
    },
    infoItem: {
      paddingLeft: 20,
      paddingRight: 20,
      flex: 1,
      minWidth: 0,
    },
    subTitle: {
      ...defaultFontMedium,
      fontSize: 12,
    },
    tableArea: {
      marginLeft: 20,
      marginRight: 20,
    },
    createDescription: {
      ...defaultFont,
      fontSize: 24,
      lineHeight: '1.17em',
      color: lightSlateGreyColor,
    },
    createBtn: {
      ...defaultFontBold,
      fontSize: 16,
      color: whiteColor,
      backgroundColor: denimColor,
      '&:hover': {
        backgroundColor: denimColor,
      },
      width: 160,
      height: 36,
      borderRadius: 4,
      textTransform: 'none',
      marginTop: 40,
    },
    createBtnArea: {
      textAlign: 'center',
      marginBottom: 20,
    },
    changeBtnArea: {
      marginTop: 20,
      textAlign: 'right',
      color: denimColor,
      fontSize: 14,
    },

    [theme.breakpoints.between('sm', 'sm')]: {
      networkTitle: {
        fontSize: 18,
      },
      customTHeadCol: {
        fontSize: 12,
      },
      customBodyCellf: {
        fontSize: 15,
      },
    },
    tableRowBodyCustom: {
      height: '48px',
      '& .MuiTableCell-root': {
        letterSpacing: 'normal',
        padding: '4px 30px 4px 10px',
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  account: store.appState.accountSeleted,
  cards: store.appState.cardSummary,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  listCards: (args: PaymentAction.QueryListCardsArgs) => dispatch(PaymentAction.listCards(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CardSection)),
);
