import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  dimGrayColor,
  whiteSmokeColor,
  romanColor,
  lightSlateGreyColor,
  nightRiderColor,
  matisseColor,
} from '~/styles/themes/common-styles/color';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInput from './custom-input';
import SubmitButton from './submit-button';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
//Redux
import * as AppActions from '~/stores/actions/app-action';

// types
import { Account } from '~/types/account-types';
import firebase from 'firebase';
import { AppRoutePath } from '~/AppRouter';

interface WithdrawFormValues {
  confirmName: string;
}

interface IProps extends IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  account: Account;
}

interface IDispProps {
  onClose: () => void;
  removeAccount: (
    args: AppActions.MutationRemoveAccountArgs,
  ) => Promise<AppActions.REMOVE_ACCOUNT_RESULT_TYPE>;
}

class ConfirmDeleteAccount extends React.Component<IProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { classes, open, t, account } = this.props;
    const initialValues: WithdrawFormValues = {
      confirmName: '',
    };
    const withdrawValidateSchema = Yup.object().shape<WithdrawFormValues>({
      confirmName: Yup.string()
        .required(t('required'))
        .test('confirm-name', t('account_name_not_matched'), function (name) {
          return name === account.name ? true : false;
        }),
    });

    return (
      <CustomDialog open={open} onClose={this.onCloseDialog}>
        <Formik
          initialValues={initialValues}
          validationSchema={withdrawValidateSchema}
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form>
              <CustomDialogTitle>
                <div>{t('delete_organization')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div className={classes.detailArea}>
                  <div className={classes.deleteOrgDetail}>
                    {t('are_you_sure_you_want_to_delete_organization')}
                  </div>
                  <div className={classes.deleteOrgNote}>
                    <span>{t('confirm_delete_organization_guide')}</span>
                  </div>
                </div>
                <div data-name="confirmName">
                  <Field name="confirmName" render={this.accountNameField} />
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  id="member-confirm-delete-account-cancel"
                  disabled={isSubmitting}
                  className={classes.leftBtn}
                  onClick={this.onCloseDialog}
                  variant="contained"
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  id="member-confirm-delete-account-submit"
                  kind="flat"
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  label={t('delete_organization')}
                  submittingLabel={t('deleting')}
                  classes={{
                    root: classes.deleteBtn,
                    disabled: classes.disabledBtn,
                  }}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private accountNameField = ({ field, form }: FieldProps<WithdrawFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('organization_name')}</div>
          {!!form.errors.confirmName && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.confirmName}
            </div>
          )}
        </div>
        <div>
          <CustomInput {...field} id="member-confirm-delete-account-input" />
        </div>
      </>
    );
  };

  private onCloseDialog = () => {
    this.props.onClose();
  };

  private onSubmit = async (
    values: WithdrawFormValues,
    formikActions: FormikActions<WithdrawFormValues>,
  ) => {
    const { removeAccount, account } = this.props;
    const { setSubmitting } = formikActions;
    const result = await removeAccount({
      accountUuid: account.accountUuid,
    }).catch(console.log);

    setSubmitting(false);

    if (result && result.removeAccount.status === 'success') {
      // force logout
      window.location.href = AppRoutePath.Logout;
    }
  };
}

const styles = createStyles({
  root: {},
  formSection: {
    marginTop: 15,
  },
  detailArea: {
    textAlign: 'center',
  },
  deleteOrgDetail: {
    ...defaultFont,
    color: romanColor,
    fontSize: 18,
    marginBottom: 30,
  },
  deleteOrgNote: {
    ...defaultFont,
    color: lightSlateGreyColor,
    fontSize: 12,
    marginBottom: 25,
  },
  warningArea: {
    margin: '0 auto 25px',
    border: '1px solid #e35a5a',
    borderRadius: 4,
    backgroundColor: '#f9dddd',
    padding: '20px 30px',
  },
  warningTitle: {
    ...defaultFont,
    color: romanColor,
    fontSize: 15,
  },
  warningText: {
    ...defaultFont,
    color: nightRiderColor,
    fontSize: 13,
    wordBreak: 'break-word',
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  // submit button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  deleteBtn: {
    ...defaultFontMedium,
    color: whiteColor,
    width: 200,
    backgroundColor: romanColor,
    '&:hover': {
      backgroundColor: romanColor,
    },
  },
  disabledBtn: {
    backgroundColor: 'rgb(224,224,224)',
  },
});

export default withStyles(styles)(withTranslation(['common', 'member'])(ConfirmDeleteAccount));
