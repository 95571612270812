import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import {
  defaultFontBold,
  defaultFont,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as PaymentActions from '~/stores/actions/payment-action';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInputNum from './custom-input-num';
import SubmitButton from './submit-button';

import { INode, ICluster, INetworkProvider } from '~/types/network-types';
import { Account } from '~/types/account-types';
import { connect } from 'react-redux';

import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import * as Yup from 'yup';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  whiteColor,
  denimColor,
  lightSlateGreyColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';

import ConfirmLicenseDialog from './confirm-license-dialog';
import RegisterAddressDialog from './register-address-dialog';

// defines
import { MAXIMUM_LICENSE_GB_SIZE } from '~/constants/consts';

interface IOwnProps {
  networkUuid: string;
  cluster: ICluster;
  node: INode;
  open: boolean;
  onClose: () => void;
}

interface IProps {
  providers: INetworkProvider[];
  accountSeleted?: Account;
}

interface IDispProps {
  expandVolume: (
    args: NetworkActions.MutationExpandNodeVolumeArgs,
  ) => Promise<NetworkActions.EXPAND_NODE_VOLUME_RESULT_TYPE>;
  insufficientLicenses: (
    args: PaymentActions.QueryListInsufficientVolumeLicensesArgs,
  ) => Promise<PaymentActions.LIST_INSUFFICIENT_VOLUME_LICENSES_RESULT_TYPE>;
  estimateLicenseFee: (
    args: PaymentActions.QueryEstimateLicenseFeeArgs,
  ) => Promise<PaymentActions.ESTIMATE_LICENSE_FEE_RESULT_TYPE>;
  listActiveLicensesSummary: (
    args: PaymentActions.QueryListActiveLicensesSummaryArgs,
  ) => Promise<PaymentActions.LIST_ACTIVE_LICENSES_SUMMARY_RESULT_TYPE>;
  listBillings: (
    args: PaymentActions.QueryListBillingsArgs,
  ) => Promise<PaymentActions.LIST_BILLINGS_RESULT_TYPE>;
  listCoupons: (
    args: PaymentActions.QueryListCouponsArgs,
  ) => Promise<PaymentActions.LIST_COUPONS_RESULT_TYPE>;
}

interface IState {
  openConfirmLicenseDialog: boolean;
  openRegisterAddressDialog: boolean;
  estimateDate: string;
  estimateFee: PaymentActions.EstimateSummary;
  requireCard: boolean;
  formik?: FormikActions<FormValues>;
}

class EditNodeVolumeDialog extends React.Component<
  IOwnProps & IProps & IDispProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);

    this.state = {
      openConfirmLicenseDialog: false,
      openRegisterAddressDialog: false,
      requireCard: false,
      estimateDate: '',
      estimateFee: {
        estimate: [
          {
            totalPrice: 0,
            taxFee: 0,
            taxRate: 0,
            licenses: [],
            nextMonth: {
              totalPrice: 0,
              taxFee: 0,
              taxRate: 0,
              licenses: [],
            },
          },
        ],
        coupon: {
          usable: 0,
        },
      },
    };
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  public render() {
    const { classes, open, onClose, node, accountSeleted, t } = this.props;
    const requireAddr =
      !accountSeleted || !accountSeleted.paymentAddr || !accountSeleted.paymentAddr.country
        ? true
        : false;

    const {
      openConfirmLicenseDialog,
      openRegisterAddressDialog,
      estimateDate,
      estimateFee,
      requireCard,
    } = this.state;

    const currentSize = Math.max(1, node.serverInfo.volumeSize || 0);
    const initialValues: FormValues = {
      sizeOfVolume: currentSize,
    };
    const validateSchema = Yup.object().shape<FormValues>({
      sizeOfVolume: Yup.number()
        .required(t('required_field'))
        .integer(t('integer_field'))
        .min(currentSize, t('must_be_greater_than_current_size'))
        .max(MAXIMUM_LICENSE_GB_SIZE, t('must_be_less_than_or_equal_to_16tb')),
    });

    return (
      <>
        <CustomDialog open={open} onClose={onClose}>
          <Formik
            initialValues={initialValues}
            validationSchema={validateSchema}
            onSubmit={this.onSubmit}
            render={({ isValid, isSubmitting }) => (
              <Form>
                <CustomDialogTitle>
                  <div id="member-node-volume-title">{t('expand')}</div>
                </CustomDialogTitle>
                <CustomDialogContent>
                  <div>
                    <Field name="sizeOfVolume" render={this.nodeSizeField} />
                  </div>
                </CustomDialogContent>
                <CustomDialogActions>
                  <Button
                    id="member-node-volume-cancel"
                    disabled={isSubmitting}
                    className={classes.leftBtn}
                    variant="contained"
                    onClick={onClose}
                  >
                    {t('cancel')}
                  </Button>
                  <SubmitButton
                    id="member-node-volume-submit"
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    label={t('update')}
                    submittingLabel={t('updating')}
                  />
                </CustomDialogActions>
              </Form>
            )}
          />
        </CustomDialog>

        <RegisterAddressDialog
          open={openRegisterAddressDialog}
          onClose={this.onCloseRegisterAddressDialog}
        ></RegisterAddressDialog>
        <ConfirmLicenseDialog
          open={openConfirmLicenseDialog}
          onClose={this.onCloseConfirmLicenseDialog}
          estimateDate={estimateDate}
          estimateFee={estimateFee}
          requireAddr={requireAddr}
          requireCard={requireCard}
        ></ConfirmLicenseDialog>
      </>
    );
  }

  private nodeSizeField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, node, t } = this.props;
    const currentSize = Math.max(1, node.serverInfo.volumeSize || 0);

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('node_size')}</div>
          {!!form.errors.sizeOfVolume && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.sizeOfVolume}
            </div>
          )}
        </div>
        <div className={classes.inputArea}>
          <CustomInputNum
            {...field}
            id="member-node-volume-size"
            min={currentSize}
            max={MAXIMUM_LICENSE_GB_SIZE}
            placeholder={t('input_node_size')}
          />
          <span>GB</span>
        </div>
        <p className={classes.detailText}>1GB = 1{t('license')}</p>
      </>
    );
  };

  private onCloseRegisterAddressDialog = (status: boolean = false) => {
    const { formik } = this.state;
    this.setState({
      openRegisterAddressDialog: false,
      formik: void 0,
    });

    if (formik) {
      if (status) {
        // if registration successed, then start to buy licenses (auto submit)
        formik.submitForm();
      } else {
        formik.setSubmitting(false);
      }
    }
  };

  private onOpenRegisterAddressDialog = () => {
    this.setState({
      openRegisterAddressDialog: true,
    });
  };

  private onCloseConfirmLicenseDialog = (status: number = 0) => {
    const { formik, requireCard } = this.state;
    this.setState({
      openConfirmLicenseDialog: false,
      formik: void 0,
    });
    if (requireCard && status > 0) {
      this.setState({ requireCard: false });
    }

    if (formik) {
      if (status === 2) {
        // if purchase successed, then start creation (auto submit)
        const { accountSeleted, listActiveLicensesSummary, listBillings, listCoupons } = this.props;
        formik.submitForm();

        if (accountSeleted) {
          listBillings({ accountUuid: accountSeleted.accountUuid }).catch((_) => void 0);
          listCoupons({ accountUuid: accountSeleted.accountUuid }).catch((_) => void 0);
          listActiveLicensesSummary({ accountUuid: accountSeleted.accountUuid }).catch(
            (_) => void 0,
          );
        }
      } else {
        formik.setSubmitting(false);
      }
    }
  };

  private onOpenConfirmLicenseDialog = () => {
    this.setState({
      openConfirmLicenseDialog: true,
    });
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { onClose, networkUuid, cluster, node, expandVolume, accountSeleted } = this.props;
    const currentSize = Math.max(1, node.serverInfo.volumeSize || 0);
    const { sizeOfVolume } = values;

    // Check selected account existence
    if (!accountSeleted) {
      return;
    }

    // check registration of address
    if (!accountSeleted.isRegisteredPaymentAddr) {
      this.setState({ formik: formikActions });
      this.onOpenRegisterAddressDialog();
      return;
    }

    try {
      if (sizeOfVolume !== currentSize) {
        const result = await expandVolume({
          input: {
            accountUuid: accountSeleted.accountUuid,
            networkUuid,
            clusterUuid: cluster.clusterUuid,
            nodeUuid: node.nodeUuid,
            sizeByUnitGB: sizeOfVolume,
          },
        });
        const { status, execAt } = result.expandNodeVolume;

        // handling charge|register card|creation
        if (status.includes('success')) {
          setSubmitting(false);
          onClose();
        } else if (status.includes('needCharge')) {
          // update state
          this.setState({
            requireCard: status.includes('needRegister') ? true : false,
            formik: formikActions,
          });

          // check licenses
          const { insufficientLicenses, estimateLicenseFee } = this.props;
          const needs = await insufficientLicenses({
            accountUuid: accountSeleted.accountUuid,
            volumeSize: sizeOfVolume - currentSize,
          });

          const params = {
            purchaseDate: execAt,
            licenses: needs.listInsufficientVolumeLicenses.map((l) => ({
              licenseItemId: l.licenseItemId,
              qty: l.qty,
            })),
          };
          const fee = await estimateLicenseFee({
            accountUuid: accountSeleted.accountUuid,
            input: [params],
          });
          this.setState({ estimateDate: execAt });

          if (fee.estimateLicenseFee.estimate.length > 0) {
            this.setState({ estimateFee: fee.estimateLicenseFee });
          }
          this.onOpenConfirmLicenseDialog();
        } else {
          setSubmitting(false);
          onClose(); // unknown status
        }
      } else {
        onClose();
      }
    } catch (error) {
      setSubmitting(false);
    }
  };
}

const styles = createStyles({
  root: {},
  formSection: {
    marginTop: 15,
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  inputArea: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '& span': {
      ...defaultFont,
      fontSize: 15,
      marginLeft: 10,
      color: nightRiderColor,
    },
  },
  detailText: {
    ...defaultFont,
    color: lightSlateGreyColor,
    fontSize: 12,
    marginTop: 10,
  },
});

const mapStateToProps = (store: IStore): IProps => ({
  providers: store.appState.providers || [],
  accountSeleted: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  expandVolume: (args: NetworkActions.MutationExpandNodeVolumeArgs) =>
    dispatch(NetworkActions.expandNodeVolume(args)),
  insufficientLicenses: (args: PaymentActions.QueryListInsufficientVolumeLicensesArgs) =>
    dispatch(PaymentActions.listInsufficientVolumeLicenses(args)),
  estimateLicenseFee: (args: PaymentActions.QueryEstimateLicenseFeeArgs) =>
    dispatch(PaymentActions.estimateLicenseFee(args)),
  listActiveLicensesSummary: (args: PaymentActions.QueryListActiveLicensesSummaryArgs) =>
    dispatch(PaymentActions.listActiveLicensesSummary(args)),
  listBillings: (args: PaymentActions.QueryListBillingsArgs) =>
    dispatch(PaymentActions.listBillings(args)),
  listCoupons: (args: PaymentActions.QueryListCouponsArgs) =>
    dispatch(PaymentActions.listCoupons(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditNodeVolumeDialog)),
);

type FormValues = {
  sizeOfVolume: number;
};
