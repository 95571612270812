import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import {
  defaultFont,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import { useDispatch, useSelector } from 'react-redux';
import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';

// Component
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/submit-button';

import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  snowColor,
  pattensBlueColor,
  matterhornColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import { INetwork, INode, IProposalAddressInfo } from '~/types/network-types';
import { Account } from '~/types/account-types';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import CheckboxAddress from './check-box-list';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import { ClickAwayListener, IconButton, Typography } from '@mui/material';
import classNames from 'classnames';
import { sortNodesByName } from '~/utilities/render-utils';
import { ellipsifyText } from '~/utilities/utils';

interface IProps extends WithStyles<typeof styles> {
  open: boolean;
  network: INetwork;
  account: Account;
  address?: string;
  scroll?: 'body' | 'paper';
  onClose: () => void;
}

type FormValues = {
  address: string;
};

const CreateProposalDialog = (props: IProps) => {
  const { classes, open, address = '', account, scroll, network, onClose } = props;
  const [checked, setChecked] = React.useState<Array<string>>([]);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const isPending = useSelector((store: IStore) => NetworkActions.sendProposal.isPending(store));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    address,
  };

  const validateSchema = Yup.object().shape<FormValues>({
    address: Yup.string()
      .required(t('required_field'))
      .test({
        message: t('error_alloc_address') as string,
        name: 'address',
        test: (val) => /^0x[a-fA-F0-9]{40}$/g.test(val),
      }),
  });

  const allNodes = useMemo(() => {
    return network.clusters
      .map((cluster) =>
        cluster.nodes.map((node) => ({ node, label: node.nodeInfo.coinbaseAddress })),
      )
      .reduce((pre, cur) => pre.concat(cur), [])
      .sort((a, b) => {
        return sortNodesByName(a.node, b.node);
      });
  }, [network.clusters]);

  const signers = useMemo(
    () => network.networkSnapshot?.signers ?? [],
    [network.networkSnapshot?.signers],
  );

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => `${option.node.nodeName}${option.label}`,
  });

  const handleArrowClick = useCallback(() => {
    setOpenAutocomplete(!openAutocomplete);
  }, [openAutocomplete]);

  const handleClickOutside = useCallback(() => {
    setOpenAutocomplete(false);
  }, []);

  const addressField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      const transText = signers.some((val) => val.address === field.value) ? (
        <div>
          <b style={{ color: 'red' }}>{t('validator')}</b> Node {'>>'} <b>{t('relay')}</b> Node
        </div>
      ) : (
        <div>
          <b>{t('relay')}</b> Node {'>>'} <b style={{ color: 'red' }}>{t('validator')}</b> Node
        </div>
      );

      return (
        <div>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('for_target_node_address')}</div>
            {!!form.errors.address && form.touched.address && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {t(form.errors.address)}
              </div>
            )}
          </div>
          <div>
            <ClickAwayListener onClickAway={handleClickOutside}>
              <Autocomplete
                {...field}
                open={openAutocomplete}
                disabled={!!address}
                disableClearable={true}
                freeSolo
                fullWidth
                inputValue={field.value}
                onInputChange={(event, newInputValue) => {
                  form.setFieldValue('address', newInputValue);
                }}
                options={allNodes}
                filterOptions={filterOptions}
                renderOption={(props, option) => {
                  const isSigner = option.node.nodeInfo?.signer || false;
                  return (
                    <Box style={{ justifyContent: 'space-between' }} component="li" {...props}>
                      <Typography noWrap fontSize={13} maxWidth={350}>
                        {option.node.nodeName}
                      </Typography>
                      <div
                        className={classNames({
                          [classes.nodeType]: true,
                          [classes.backgroundRomanColor]: isSigner,
                          [classes.backgroundSummerSkyColor]: !isSigner,
                        })}
                      >
                        <span>{isSigner ? t('validator_node') : t('relay_node')}</span>
                      </div>
                    </Box>
                  );
                }}
                renderInput={(params) => {
                  let nodeName;
                  if (/^0x[a-fA-F0-9]{40}$/g.test(field.value)) {
                    const selected = allNodes.find((val) => val.label === field.value);
                    nodeName = selected?.node.nodeName ? (
                      <div className={classes.inputNodeName}>{selected?.node.nodeName}</div>
                    ) : (
                      <div style={{ color: 'gray' }}>External Node</div>
                    );
                  }
                  return (
                    <TextField
                      {...params}
                      {...field}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: nodeName,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            {field.value ? (
                              <IconButton
                                onClick={() => {
                                  form.setFieldValue('address', '');
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            ) : (
                              <IconButton onClick={handleArrowClick}>
                                {params.inputProps?.['aria-expanded'] ? (
                                  <ArrowDropUpIcon />
                                ) : (
                                  <ArrowDropDownIcon />
                                )}
                              </IconButton>
                            )}
                          </>
                        ),
                      }}
                      className={classes.textField}
                      variant="outlined"
                      placeholder="Input target address"
                      onClick={handleArrowClick}
                    />
                  );
                }}
              />
            </ClickAwayListener>
          </div>
          {!form.errors.address && field.value && (
            <div className={classes.targetDetail}>{transText}</div>
          )}
        </div>
      );
    },
    [
      address,
      allNodes,
      classes,
      signers,
      openAutocomplete,
      handleArrowClick,
      filterOptions,
      handleClickOutside,
      t,
    ],
  );

  const handleCheckboxChange = useCallback(
    (address: string[], addrChecked: string[]) => {
      const newChecked = checked.filter((val) => !address.includes(val)).concat(addrChecked);
      setChecked(newChecked);
    },
    [checked],
  );

  const clustersSelection = useMemo(() => {
    return network.clusters
      .map((val) => ({
        ...val,
        nodes: val.nodes.filter(
          (node) =>
            node.nodeInfo.signer &&
            node.nodeInfo.status === 'alive' &&
            node.serverInfo.status === 'alive',
        ),
      }))
      .filter((val) => val.accountUuid === account.accountUuid && val.nodes.length > 0);
  }, [account.accountUuid, network.clusters]);

  const renderSendFromTypeField = useMemo(() => {
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('send_proposal_from')}</div>
        </div>
        <div>
          <List>
            {clustersSelection.map((cluster) => {
              return (
                <CheckboxAddress
                  key={cluster.clusterUuid}
                  cluster={cluster}
                  onCheckbox={handleCheckboxChange}
                />
              );
            })}
          </List>
        </div>
      </>
    );
  }, [classes, clustersSelection, t, handleCheckboxChange]);

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      try {
        const authorize = !signers.some((val) => val.address === values.address);
        if (!authorize) {
          // check diff between all nodes of network and 'tx' proposals num
          const allSigners = network.clusters
            .map((c) => c.nodes.filter((n) => n.nodeInfo.signer))
            .reduce((pre, cur) => {
              pre.push(...cur);
              return pre;
            }, []);

          // network must have more than 1 node
          if (allSigners.length < 2) {
            formikActions.setSubmitting(false);
            dispatch(
              AppActions.openSnackBar({
                type: 'error',
                message: t('possible_zero_signer_error'),
              }),
            );
            return;
          }
        }
        const votedNodes = allNodes.filter((val) => checked.includes(val.node.nodeUuid));
        if (
          votedNodes.some((val) =>
            val.node.nodeInfo?.localProposals.find(
              (val) => val.address === values.address && val.authorize === authorize,
            ),
          ) ||
          network.networkSnapshot?.proposals.find((val) => val.target.address === values.address)
        ) {
          formikActions.setSubmitting(false);
          dispatch(
            AppActions.openSnackBar({
              type: 'error',
              message: t('proposal_already_exist'),
            }),
          );
          return;
        }
        await dispatch(
          NetworkActions.sendProposal({
            input: {
              accountUuid: account.accountUuid,
              networkUuid: network.networkUuid,
              address: values.address,
              authorize,
              includeNodeUuids: checked,
            },
          }),
        );
        onClose();
        formikActions.setSubmitting(false);
      } catch (error) {
        formikActions.setSubmitting(false);
      }
    },
    [signers, allNodes, network, account.accountUuid, checked, onClose, t, dispatch],
  );

  const handleCloseDialog = useCallback(() => {
    setChecked([]);
    onClose();
  }, [onClose]);

  useEffect(() => {
    const uuids = network.clusters
      .filter((val) => val.accountUuid === account.accountUuid)
      .map((c) =>
        c.nodes
          .filter(
            (n) =>
              n.nodeInfo.signer && n.nodeInfo.status === 'alive' && n.serverInfo.status === 'alive',
          )
          .map((val) => val.nodeUuid),
      )
      .reduce((pre, cur) => {
        pre.push(...cur);
        return pre;
      }, []);
    setChecked(uuids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomDialog open={open} onClose={handleCloseDialog} scroll={scroll}>
      <Formik
        isInitialValid={!!address}
        initialValues={initialValues}
        validationSchema={validateSchema}
        onSubmit={onSubmit}
        render={({ isSubmitting, isValid, values }) => {
          return (
            <Form>
              <CustomDialogTitle>
                <div id="member-create-proposal-title">{t('create_proposal')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div className={classes.formSection}>{renderSendFromTypeField}</div>
                <div className={classes.formSection}>
                  <Field name="address" render={addressField} />
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  id="member-create-proposal-cancel"
                  disabled={isSubmitting || isPending}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={handleCloseDialog}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  id="member-create-proposal-submit"
                  isValid={isValid && !!checked.length}
                  isSubmitting={isSubmitting || isPending}
                  label={t('create')}
                  submittingLabel={t('creating')}
                />
              </CustomDialogActions>
            </Form>
          );
        }}
      />
    </CustomDialog>
  );
};

const styles = (theme) =>
  createStyles({
    root: {},
    checkbox: {
      '&.Mui-checked': {
        color: 'rgba(0, 0, 0, 0.6)',
      },
    },
    listBox: {
      maxHeight: 700,
      overflowY: 'auto',
    },
    nodeType: {
      ...defaultFontMedium,
      display: 'flex',
      alignItems: 'center',
      height: 28,
      borderRadius: 12,
      paddingLeft: 12,
      paddingRight: 12,
      fontSize: 10,
      color: matterhornColor,
    },
    backgroundRomanColor: {
      backgroundColor: 'rgb(227, 90, 90, 0.2)',
    },
    backgroundSummerSkyColor: {
      backgroundColor: 'rgb(64, 194, 230, 0.2)',
    },
    nodeGroup: {
      display: 'flex',
    },
    targetAddress: {
      fontSize: 13,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: 150,
      textOverflow: 'ellipsis',
    },
    nodeName: {
      fontWeight: 400,
      color: nightRiderColor,
      fontSize: 13,
      maxWidth: 200,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    inputNodeName: {
      fontWeight: 450,
      color: nightRiderColor,
      fontSize: 13,
      maxWidth: 100,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    targetDetail: {
      ...defaultFontRegular,
      fontSize: 12,
      marginTop: 5,
      height: '18px',
      color: 'black',
    },
    textField: {
      '& .MuiInputBase-root': {
        height: 40,
        fontSize: '13px',
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: pattensBlueColor,
        color: '#4D4F5C',
        backgroundColor: '#f5f5f5',
      },
      '& .MuiOutlinedInput-root': {
        paddingTop: 0,
        paddingBottom: 0,
        '& .MuiIconButton-root': {
          padding: 4,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': { border: 0 },
    },
    content: {
      marginTop: 10,
      padding: 10,
      paddingBottom: 0,
      backgroundColor: snowColor,
      borderRadius: 4,
      border: `1px solid ${pattensBlueColor}`,
      boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
      wordBreak: 'break-word',
    },
    formLabel: {
      ...defaultFontMedium,
      fontSize: 13,
      minWidth: 150,
    },
    formControlLabel: {
      marginLeft: '-14px',
      '& .MuiCheckbox-root': {
        padding: '12px',
      },
    },
    formSection: {
      marginTop: 10,
    },
    formLabelLine: {
      display: 'flex',
      alignItems: 'center',
    },
    formError: {
      color: romanColor,
      textAlign: 'end',
      marginLeft: 'auto',
    },
    warningText: {
      ...defaultFont,
      fontSize: 16,
      color: romanColor,
      textAlign: 'center',
      marginTop: 10,
    },
    leftBtn: {
      ...defaultFont,
      color: dimGrayColor,
      fontSize: 14,
      height: 36,
      backgroundColor: whiteSmokeColor,
      '&:hover': {
        backgroundColor: whiteSmokeColor,
      },
      paddingLeft: 20,
      paddingRight: 20,
      textTransform: 'none',
      marginRight: 10,
    },
    gridLeftItem: {
      paddingRight: 6,
      maxWidth: '50%',
    },
    gridRightItem: {
      paddingLeft: 6,
      maxWidth: '50%',
    },
  });

export default withStyles(styles)(CreateProposalDialog);
