import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import classNames from 'classnames';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
// @mui/material
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { denimColor } from '~/styles/themes/common-styles/color';
import { defaultFont } from '~/styles/themes/common-styles/font-guest';
import { parseBCP47String } from '~/utilities/utils';
import Cookies from 'js-cookie';
import clsx from 'clsx';
import { SelectChangeEvent } from '@mui/material/Select';

const HeaderLinks: React.FunctionComponent<WithStyles<typeof styles> & WithTranslation> = ({
  classes,
  t,
  i18n,
}) => {
  const [openSelectLng, setopenSelectLng] = React.useState(false);
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Select
          open={openSelectLng}
          onClose={() => setopenSelectLng(false)}
          onOpen={() => setopenSelectLng(true)}
          classes={{
            select: clsx(classes.customSelect, classes.selectMenu),
          }}
          value={parseBCP47String(i18n.language).language || 'en'}
          onChange={(event: SelectChangeEvent) => {
            i18n.changeLanguage(event.target.value as string);
            Cookies.set('lang', event.target.value);
          }}
          disableUnderline
          renderValue={(value: any) => value.toLocaleUpperCase()}
          inputProps={{ 'aria-label': 'Without label' }}
          variant="standard"
        >
          <MenuItem classes={{ root: classes.menuItem, selected: classes.selectedItem }} value="en">
            English
          </MenuItem>
          <MenuItem classes={{ root: classes.menuItem, selected: classes.selectedItem }} value="ja">
            日本語
          </MenuItem>
        </Select>
      </ListItem>
    </List>
  );
};

const styles = createStyles({
  list: {
    ...defaultFont,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    paddingLeft: '0',
    listStyle: 'none',
    paddingTop: '0',
    paddingBottom: '0',
    '@media (max-width: 970px)': {
      flexDirection: 'column',
      paddingTop: 8,
      marginTop: 20,
      borderTop: '1px solid #f5f5f5',
    },
  },
  listItem: {
    float: 'left',
    color: 'inherit',
    position: 'relative',
    display: 'block',
    width: 'auto',
    margin: '0',
    paddingLeft: 12,
    paddingRight: 12,
    '@media (max-width: 970px)': {
      padding: '5px 10px',
    },
  },
  menuItem: {
    padding: '7px 16px !important',
    height: '100%',
    '&:hover': {
      color: denimColor,
    },
    display: 'inherit !important',
  },
  customSelect: {
    marginLeft: 0,
    display: 'flex',
  },
  selectMenu: {
    paddingLeft: 0,
    paddingRight: '32px !important',
    width: 21,
    '&:focus': {
      backgroundColor: 'transparent',
    },
    fontSize: 14,
    fontWeight: 700,
  },
  iconActive: {
    transform: 'rotate(180deg)',
  },
  selectedItem: {
    backgroundColor: '#FFFFFF !important',
    color: `${denimColor} !important`,
  },
});

export default withStyles(styles)(withTranslation()(HeaderLinks));
