import React from 'react';
// Redux
import classNames from 'classnames';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
// Component
import TableRowBodyCustom from '~/components/common/table-row-body';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import ImgIcon from '~/components/common/img-icon';
import Button from '@mui/material/Button';
import EditButton from '~/components/common/edit-button';
import EditUserDialog from '../edit-user-dialog';
import DeleteUserDialog from '../delete-user-dialog';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { pattensBlueColor, romanColor, whiteSmokeColor } from '~/styles/themes/common-styles/color';
// Type
import { AccountRoleType, NetworkRoleType } from '~/types/account-types';
import { Item } from '..';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';

interface IStateProps {
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  item: Item;
}

interface IState {
  openEditUserDialog: boolean;
  openDeleteUserDialog: boolean;
}

class AccessTableRow extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openEditUserDialog: false,
      openDeleteUserDialog: false,
    };
  }

  public render() {
    const { classes, item, selectedAccountRole, selectedNetworkRole } = this.props;
    const { openEditUserDialog, openDeleteUserDialog } = this.state;
    const itemNetworkRole = item.networkAccess.networkRole;
    const editable =
      itemNetworkRole &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        (selectedNetworkRole === 'owner' && itemNetworkRole !== 'owner') ||
        (selectedNetworkRole === 'admin' &&
          itemNetworkRole !== 'owner' &&
          itemNetworkRole !== 'admin'));
    const removable =
      itemNetworkRole &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        (selectedNetworkRole === 'owner' && itemNetworkRole !== 'owner') ||
        (selectedNetworkRole === 'admin' &&
          itemNetworkRole !== 'owner' &&
          itemNetworkRole !== 'admin'));

    return (
      <>
        <EditUserDialog
          open={openEditUserDialog}
          onClose={this.onCloseEditUserDialog}
          user={item.networkAccess}
        />
        <DeleteUserDialog
          open={openDeleteUserDialog}
          onClose={this.onCloseDeleteUserDialog}
          user={item.networkAccess}
        />
        <TableRowBodyCustom>
          <TableCellBodyCustom
            className={classNames(classes.tableCellBodyCommon, classes.tableBodyTd)}
          >
            {item.hasProfile ? (
              item.displayName
            ) : (
              <Tooltip title={this.props.t('inactive_user_warning')}>
                <div className={classes.memberInactive}>{this.props.t('unknown_member')}</div>
              </Tooltip>
            )}
          </TableCellBodyCustom>
          <TableCellBodyCustom
            className={classNames(classes.tableCellBodyCommon, classes.tableBodyTd)}
          >
            {item.email}
          </TableCellBodyCustom>
          <TableCellBodyCustom
            className={classNames(
              classes.roleCell,
              classes.tableCellBodyCommon,
              classes.tableBodyTd,
            )}
          >
            {item.role}
          </TableCellBodyCustom>
          <TableCellBodyCustom
            className={classNames(classes.tableCellBodyCommon, classes.tableBodyTd)}
          >
            {item.createAt && this.props.t('user_createAt', { date: new Date(item.createAt) })}
          </TableCellBodyCustom>
          <TableCellBodyCustom
            className={classNames(classes.tableCellBodyCommon, classes.tableBodyTd)}
          >
            {item.lastLoginAt &&
              this.props.t('user_lastLoginAt', { date: new Date(item.lastLoginAt) })}
          </TableCellBodyCustom>
          <TableCellBodyCustom
            className={classNames(
              classes.tableCellbtnArea,
              classes.tableCellBodyCommon,
              classes.tableBodyTd,
            )}
          >
            <>
              {editable && (
                <EditButton
                  id="member-access-detail-edit"
                  className={classes.editBtn}
                  onClick={this.onOpenEditUserDialog}
                />
              )}
              {removable && (
                <Button
                  id="member-access-detail-delete"
                  className={classes.deleteBtn}
                  onClick={this.onOpenDeleteUserDialog}
                >
                  <ImgIcon className={classes.deleteIcon} imgUrl="/images/icons/delete_ico.png" />
                </Button>
              )}
            </>
          </TableCellBodyCustom>
        </TableRowBodyCustom>
      </>
    );
  }

  private onOpenEditUserDialog = () => {
    this.setState({
      openEditUserDialog: true,
    });
  };

  private onCloseEditUserDialog = () => {
    this.setState({
      openEditUserDialog: false,
    });
  };

  private onOpenDeleteUserDialog = () => {
    this.setState({
      openDeleteUserDialog: true,
    });
  };

  private onCloseDeleteUserDialog = () => {
    this.setState({
      openDeleteUserDialog: false,
    });
  };
}

const styles = (theme) =>
  createStyles({
    tableCellBodyCommon: {
      wordBreak: 'break-all',
      padding: '4px 1% 4px 1%',
    },
    roleCell: {
      whiteSpace: 'pre-wrap',
    },
    tableCellbtnArea: {
      paddingRight: '1% !important',
    },
    editBtn: {
      border: `1px solid ${pattensBlueColor}`,
      backgroundColor: whiteSmokeColor,
      width: 80,
      height: 36,
      marginRight: 5,
    },
    deleteBtn: {
      border: `1px solid ${pattensBlueColor}`,
      backgroundColor: whiteSmokeColor,
      width: 36,
      height: 36,
      minWidth: 36,
    },
    deleteIcon: {},
    tableBodyTd: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      tableBodyTd: {
        padding: '0 3px !important',
        fontSize: 13,
        '& :last-child': {
          fontSize: 0,
          padding: 0,
        },
      },
      editBtn: {
        width: 36,
        minWidth: 'auto',
      },
    },
    memberInactive: {
      color: romanColor,
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AccessTableRow)),
);
