import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import {
  defaultFont,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';

import { useDispatch } from 'react-redux';

// Component
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TruncateWalletAddress from '~/components/common/truncate-wallet-address';
import { Link } from 'react-router-dom';
import ChainProposalDetailRow from './proposal-detail-row';
import SubmitButton from '~/components/common/submit-button';

import {
  denimColor,
  lightSlateGreyColor,
  matterhornColor,
  persianGreenColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import { INetwork } from '~/types/network-types';
import { Account } from '~/gapi/gtypes';

import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';
import { Item } from '..';
import { listNodesBelongToAccount } from '~/utilities/utils';
import NodeTypeStatus from '~/components/common/node-type-status';

interface IProps extends WithStyles<typeof styles> {
  network: INetwork;
  account: Account;
  item: Item;
}

const ChainProposalsRow = (props: IProps) => {
  const { classes, network, account, item } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  const unVotedSignerNodesBelongToAccount = useMemo(
    () =>
      listNodesBelongToAccount(account, network, true)
        .filter((node) =>
          item.remainingNodeAddresses
            .map((val) => val.address)
            .includes(node.nodeInfo.coinbaseAddress),
        )
        .map((val) => ({
          ...val,
          proposal: val.nodeInfo.localProposals.find((val) => val.address === item.target.address),
        })),
    [account, item.remainingNodeAddresses, item.target.address, network],
  );

  const handleChange = useCallback(
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    },
    [],
  );

  const handleApproveProposal = useCallback(
    async (nodeUuid?: string) => {
      try {
        if (!item.authorize) {
          // check diff between all nodes of network and 'tx' proposals num
          const allSigners = network.clusters
            .map((c) => c.nodes.filter((n) => n.nodeInfo.signer))
            .reduce((pre, cur) => {
              pre.push(...cur);
              return pre;
            }, []);

          // network must have more than 1 node
          if (allSigners.length < 2) {
            dispatch(
              AppActions.openSnackBar({
                type: 'error',
                message: t('possible_zero_signer_error'),
              }),
            );
            return;
          }
        }
        await dispatch(
          NetworkActions.sendProposal({
            input: {
              accountUuid: account.accountUuid,
              networkUuid: network.networkUuid,
              address: item.target.address,
              authorize: item.authorize,
              includeNodeUuids: nodeUuid
                ? [nodeUuid]
                : unVotedSignerNodesBelongToAccount
                    .filter(
                      (node) =>
                        node.nodeInfo.status === 'alive' && node.serverInfo.status === 'alive',
                    )
                    .map((val) => val.nodeUuid),
            },
          }),
        );
      } catch (error) {}
    },
    [
      item.authorize,
      item.target.address,
      dispatch,
      account.accountUuid,
      network.networkUuid,
      network.clusters,
      unVotedSignerNodesBelongToAccount,
      t,
    ],
  );

  const onApproveAll = useCallback(async () => {
    try {
      setSubmitting(true);
      await handleApproveProposal();
    } finally {
      setSubmitting(false);
    }
  }, [handleApproveProposal]);

  const disabledApproveAllBtn = useMemo(() => {
    const isValidRole =
      account.role === 'owner' ||
      account.role === 'admin' ||
      network.role === 'owner' ||
      network.role === 'admin';
    return (
      !isValidRole ||
      unVotedSignerNodesBelongToAccount
        .filter((node) => node.nodeInfo.status === 'alive' && node.serverInfo.status === 'alive')
        .every(({ proposal }) => proposal?.authorize === item.authorize)
    );
  }, [unVotedSignerNodesBelongToAccount, item.authorize, account.role, network.role]);

  const renderApprovedNodes = useMemo(() => {
    return item.approved.map((voted) => (
      <ChainProposalDetailRow
        key={voted.address}
        network={network}
        account={account}
        nodeAddress={voted}
        isApproved={true}
      />
    ));
  }, [item.approved, network, account]);

  const renderWaittingNodes = useMemo(() => {
    return item.remainingNodeAddresses.map((remainingNode) => {
      const node = unVotedSignerNodesBelongToAccount.find(
        (val) => val.nodeUuid === remainingNode.node?.nodeUuid,
      );
      const disabledApproveProposal =
        isSubmitting ||
        node?.nodeInfo.status !== 'alive' ||
        node?.serverInfo.status !== 'alive' ||
        node?.proposal?.authorize === item.authorize;
      return (
        <ChainProposalDetailRow
          key={remainingNode.address}
          account={account}
          network={network}
          nodeAddress={remainingNode}
          disabledApproveProposal={disabledApproveProposal}
          onApproveProposal={handleApproveProposal}
        />
      );
    });
  }, [
    item.remainingNodeAddresses,
    item.authorize,
    unVotedSignerNodesBelongToAccount,
    isSubmitting,
    account,
    network,
    handleApproveProposal,
  ]);

  return (
    <Accordion
      disableGutters
      elevation={0}
      expanded={expanded === item.target.address}
      onChange={handleChange(item.target.address)}
      className={classes.accordion}
    >
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        className={classes.accordionSummary}
      >
        <div className={classes.chainProposalItem} onClick={(e) => e.stopPropagation()}>
          <div className={classes.targetAddress}>
            <TruncateWalletAddress
              classes={{ endPoint: classes.endPoint }}
              address={item.target.address}
              enableCopyBtn
            />
            {item.target.node ? (
              <Link
                className={classNames(classes.nodeLinkText, classes.tableBodyTdState)}
                to={`/network/${network.networkUuid}/cluster/${item.target.node.clusterUuid}/node/${item.target.node.nodeUuid}/overview`}
              >
                {`${item.target.node.nodeName} (${item.target.node.clusterName})`}
              </Link>
            ) : (
              <div className={classes.externalNode}>Node External</div>
            )}
          </div>
          <div
            className={classNames({
              [classes.nodeType]: true,
              [classes.backgroundRomanColor]: item.authorize,
              [classes.backgroundSummerSkyColor]: !item.authorize,
            })}
          >
            <NodeTypeStatus authorize={item.authorize} />
          </div>
          <div className={classes.actionBtn}>
            <SubmitButton
              id="approve-all-btn"
              kind="flat"
              classes={{ root: classes.approveBtn }}
              isValid={!disabledApproveAllBtn}
              isSubmitting={isSubmitting}
              label={t('approve_all')}
              onClick={onApproveAll}
            />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <div>
          <span className={classes.voterTitle}>{t('proposed')}</span>
          <div className={classes.nodeBox}>{renderApprovedNodes}</div>
        </div>
        <div>
          <span className={classes.voterTitle}>{t('waiting')}</span>
          <div className={classes.nodeBox}>{renderWaittingNodes}</div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    endPoint: {
      display: 'flex',
      minWidth: 200,
    },
    nodeLinkText: {
      color: denimColor,
    },
    tableBodyTdState: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    externalNode: {
      color: 'gray',
    },
    accordion: {
      marginBottom: 10,
      border: `1px solid ${theme.palette.divider}`,
      '&:before': {
        display: 'none',
      },
      borderRadius: '4px',
    },
    accordionSummary: {
      height: 28,
      flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
      '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
      },
    },
    accordionDetails: {
      borderTop: '1px solid rgba(0, 0, 0, .125)',
      paddingLeft: 45,
    },
    chainProposalItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      fontSize: 14,
      height: 34,
    },
    nodeType: {
      ...defaultFontMedium,
      display: 'flex',
      alignItems: 'center',
      height: 28,
      borderRadius: 14,
      paddingLeft: 15,
      paddingRight: 15,
      fontSize: 12,
      color: matterhornColor,
    },
    actionBtn: {
      width: 100,
    },
    approveBtn: {
      width: 'auto',
      paddingLeft: 15,
      paddingRight: 15,
      height: 32,
      ...defaultFont,
      fontSize: 13,
      color: 'white',
      borderRadius: 4,
      backgroundColor: persianGreenColor,
      '&:hover': {
        backgroundColor: persianGreenColor,
      },
      '&:disabled': {
        backgroundColor: 'rgb(224,224,224)',
      },
    },
    backgroundRomanColor: {
      backgroundColor: 'rgb(227, 90, 90, 0.2)',
    },
    backgroundSummerSkyColor: {
      backgroundColor: 'rgb(64, 194, 230, 0.2)',
    },
    proposeBtn: {
      ...defaultFontMedium,
      fontSize: 12,
      color: lightSlateGreyColor,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    targetAddress: {
      display: 'flex',
      fontSize: 14,
      width: '45%',
      whiteSpace: 'nowrap',
    },
    voterTitle: {
      ...defaultFontRegular,
      fontSize: 15,
      maxWidth: 400,
      marginBottom: 10,
    },
    nodeBox: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  });

export default withStyles(styles)(ChainProposalsRow);
