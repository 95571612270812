import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  DatePicker as InlineDatePicker,
  DatePickerProps as DatePickerInlineProps,
} from '@mui/x-date-pickers/DatePicker';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { pattensBlueColor } from '~/styles/themes/common-styles/color';

interface IProps {
  datePickerInlineProps: Omit<DatePickerInlineProps<Date, Date>, 'renderInput'>;
}

const DatePicker: React.FunctionComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  datePickerInlineProps,
}) => {
  const onKeyDown = React.useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <div className={classes.root}>
      <InlineDatePicker
        {...datePickerInlineProps}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.textField}
            fullWidth
            variant="outlined"
            helperText={null}
            onKeyDown={onKeyDown}
          />
        )}
      />
    </div>
  );
};

const styles = createStyles({
  root: {
    '& .Mui-disabled': {
      color: 'inherit',
      '-webkit-text-fill-color': 'unset',
    },
  },
  textField: {
    '& .MuiInputBase-root': {
      height: 32,
      width: 145,
      fontSize: '14px',
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: pattensBlueColor,
      caretColor: 'transparent',
      color: '#4D4F5C',
    },
    '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  },
});

export default withStyles(styles)(DatePicker);
