/* tslint:disable:max-file-line-count */

import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
// Component
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import LGButton from '~/components/common/lg-button';
import ImgIcon from '~/components/common/img-icon';
import OptionButton from '~/components/common/option-button';
import CreateNodeDialog from '~/components/common/create-node-dialog';
import CreateClusterDialog from '~/components/common/create-cluster-dialog';
import CreateBlockExplorerDialog from '~/components/common/create-block-explorer-dialog';
import EditClusterDialog from '~/components/common/edit-cluster-dialog';
import DeleteClusterDialog from '~/components/common/delete-cluster-dialog';
import NodeStatusIcon from '~/components/common/node-status-icon';
import CopyClipboardIcon from '~/components/common/copy-clipboard-icon';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import NetworkInfoSection from './network-info-section';
// Route
import { Link } from 'react-router-dom';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import classNames from 'classnames';
import {
  defaultFont,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  snowColor,
  whiteSmokeColor,
  denimColor,
  matterhornColor,
  whiteColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
import { oneLineText } from '~/styles/themes/common-styles/misc';
// Type
import { INetwork, ICluster, INode } from '~/types/network-types';
import { Account, Profile, AccountRoleType, NetworkRoleType } from '~/types/account-types';
import {
  displayNodeStatus,
  displayClusterStatus,
  displayBlockExplorerStatus,
  sortNodesByName,
} from '~/utilities/render-utils';
// Translation
import { TFunction } from 'i18next';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as AppActions from '~/stores/actions/app-action';
import { sleep } from '~/utilities/application';
import { isAccountOwnCluster, isConsortiumAdmin } from '~/utilities/utils';
import { MASK_ASTERISK } from '~/constants/consts';

interface IStateProps {
  profile?: Profile;
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
}

interface IDispProps {
  getAccount: (args: AppActions.QueryGetAccountArgs) => Promise<AppActions.GET_ACCOUNT_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  network: INetwork;
  account: Account;
  sendInvitation: (
    args: NetworkActions.MutationSendInvitationArgs,
  ) => Promise<NetworkActions.SEND_INVITATION_RESULT_TYPE>;
}

interface IState {
  openCreateNodeDialog: boolean;
  openCreateClusterDialog: boolean;
  openDeleteClusterDialog: boolean;
  openEditClusterDialog: boolean;
  openCreateBlockExplorerDialog: boolean;
  clusterOptionAnchorEl?: any;
  clusterSelected?: ICluster;
  arrowRef?: any;
  openTooltip: Array<any>;
}

class OverviewTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      openCreateNodeDialog: false,
      openCreateClusterDialog: false,
      openDeleteClusterDialog: false,
      openEditClusterDialog: false,
      openCreateBlockExplorerDialog: false,
      arrowRef: null,
      openTooltip: [],
    };
  }

  public render() {
    const { t, classes, network, account, selectedNetworkRole, selectedAccountRole } = this.props;
    const creatableCluster =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const updatableCluster =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const deletableCluster =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const creatableNode =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const showableSecurityTab =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedAccountRole === 'member' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const {
      openCreateNodeDialog,
      openCreateClusterDialog,
      openDeleteClusterDialog,
      clusterOptionAnchorEl,
      openEditClusterDialog,
      clusterSelected,
      openCreateBlockExplorerDialog,
      openTooltip,
    } = this.state;

    return (
      <div className={classes.root}>
        {clusterSelected && openCreateNodeDialog && (
          <CreateNodeDialog
            networkUuid={network.networkUuid}
            cluster={clusterSelected}
            open={openCreateNodeDialog}
            onClose={this.onCloseCreateNodeDialog}
            network={network}
          />
        )}
        {clusterSelected && (
          <CreateBlockExplorerDialog
            open={openCreateBlockExplorerDialog}
            onClose={this.onCloseCreateBlockExplorerDialog}
            cluster={clusterSelected}
            network={network}
          />
        )}
        <CreateClusterDialog
          open={openCreateClusterDialog}
          onClose={this.onCloseCreateNetworkDialog}
          networkUuid={network.networkUuid}
          accountUuid={account.accountUuid}
          network={network}
        />
        {/* <EditNetworkDialog
          network={network}
          open={openEditNetworkDialog}
          onClose={this.onCloseEditNetworkDialog}
        /> */}
        {clusterSelected && (
          <DeleteClusterDialog
            open={openDeleteClusterDialog}
            onClose={this.onCloseDeleteClusterDialog}
            network={network}
            cluster={clusterSelected}
          />
        )}
        {clusterSelected && (
          <EditClusterDialog
            cluster={clusterSelected}
            network={network}
            open={openEditClusterDialog}
            onClose={this.onCloseEditClusterDialog}
          />
        )}
        <Popover
          open={Boolean(clusterOptionAnchorEl)}
          anchorEl={clusterOptionAnchorEl}
          onClose={this.onCloseClusterOptionPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={1}
          classes={{ paper: classes.clusterOptionPopoverPaper }}
        >
          <List className={classes.listOption}>
            <ListItem
              id="network-cluster-button-node"
              button
              className={classes.listOptionItem}
              onClick={this.onOpenCreateNodeDialog}
              disabled={!creatableNode}
            >
              {this.props.t('create_node')}
            </ListItem>
            {!this.state.clusterSelected?.explorer && (
              <ListItem
                button
                className={classes.listOptionItem}
                onClick={this.onOpenCreateBlockExplorerDialog}
                disabled={!creatableNode}
              >
                {this.props.t('create_block_explorer')}
              </ListItem>
            )}
            <div className={classes.horizontalSeparate}></div>
            <ListItem
              id="network-cluster-button-edit"
              button
              className={classes.listOptionItem}
              onClick={this.onOpenEditClusterDialog}
              disabled={!updatableCluster}
            >
              <ImgIcon className={classes.editIcon} imgUrl="/images/icons/edit-ico.png" />
              <span>{t('edit')}</span>
            </ListItem>
            <div className={classes.horizontalSeparate}></div>
            <ListItem
              id="network-cluster-button-del"
              button
              className={classes.listOptionItem}
              onClick={this.onOpenDeleteClusterDialog}
              disabled={!deletableCluster}
            >
              <ImgIcon className={classes.deleteIcon} imgUrl="/images/icons/delete_ico.png" />
              {this.props.t('delete_cluster')}
            </ListItem>
          </List>
        </Popover>

        <NetworkInfoSection network={network} account={account} />

        {network.clusters.map((cluster, index) => {
          const explorerStatus = displayBlockExplorerStatus(cluster);

          let lockedEndpoint = true;
          let lockedExplorer = true;

          const showSensitiveData =
            isConsortiumAdmin(account, network) || isAccountOwnCluster(account, cluster);

          if (cluster.listeners) {
            const allowRpcRule = cluster.listeners.find(
              (r) => r.type === 'HTTP_RPC' && r.inbound.length > 0,
            );

            const allowExplorerRule = cluster.listeners.find(
              (r) => r.type === 'BLOCK_EXPLORER' && r.inbound.length > 0,
            );

            if (allowRpcRule) {
              lockedEndpoint = false;
            }
            if (allowExplorerRule) {
              lockedExplorer = false;
            }
          }

          return (
            <div key={cluster.clusterUuid} className={classes.borderBlock}>
              <div className={classNames(classes.titleArea, classes.clusterHeading)}>
                <div className={classes.clusterTitle}>
                  <NodeStatusIcon
                    circleClassName={classes.nodeStatusIcon}
                    status={displayClusterStatus(cluster)}
                  />
                  <Link
                    id="network-cluster-name"
                    to={`/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/overview`}
                    className={classes.clusterNameLink}
                  >
                    <span className={classes.clusterTitleText}>{cluster.clusterName}</span>
                  </Link>
                  <Button className={classes.otherAccountBtn} variant="contained">
                    {cluster.accountUuid === account.accountUuid
                      ? this.props.t('my_cluster')
                      : cluster.accountName}
                  </Button>
                </div>
                <div className={classes.handleBtnArea}>
                  {cluster.explorer && showSensitiveData && (
                    <>
                      <Button
                        id="network-cluster-button-metrics"
                        className={classes.handelBtn}
                        onClick={() => this.onOpenBlockExplorerUrl(cluster)}
                      >
                        {this.props.t('block_explorer')}
                      </Button>
                      <div className={classes.verticalSeparate}></div>
                    </>
                  )}

                  {cluster.accountUuid === account.accountUuid && (
                    <OptionButton
                      id="network-cluster-button-option"
                      onClick={(e) => this.onOpenClusterOptionPopover(e, cluster)}
                    />
                  )}
                </div>
              </div>
              <div className={classes.contentArea}>
                <div
                  id="network-cluster-nodes-list"
                  className={classNames(
                    classes.infoItem,
                    classes.separateBorder,
                    classes.clusterNodes,
                    classes.nodeSeperator,
                  )}
                >
                  <div className={classes.nodesText}>
                    {cluster.status === 'removing'
                      ? `${this.props.t('deleting')}...`
                      : this.props.t('nodes')}
                  </div>
                  {cluster.nodes.sort(sortNodesByName).map((node) => {
                    const nodeTypeClasses = classNames({
                      [classes.nodeType]: true,
                      [classes.backgroundRomanColor]: node.nodeInfo.signer,
                      [classes.backgroundSummerSkyColor]: !node.nodeInfo.signer,
                    });
                    const nodeType = node.nodeInfo.signer ? t('validator_node') : t('relay_node');
                    const isTransition = nodeType.indexOf(`>>`) > -1;
                    let nodeTypes;
                    const transitionText = this.props.t('proposing');
                    if (isTransition) {
                      nodeTypes = nodeType.split('>>');
                    }
                    const status = displayNodeStatus(node);
                    const detail = this.displayNodeStatusDetail(node, t);

                    return (
                      <Link
                        key={node.nodeUuid}
                        to={`/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/node/${node.nodeUuid}/overview`}
                      >
                        <div id="network-cluster-information" className={classes.nodeItem}>
                          <NodeStatusIcon
                            circleClassName={classes.nodeStatusIcon}
                            status={status}
                          />
                          <div className={classes.nodeName}>
                            <div className={classes.nodeNameText}>{node.nodeName}</div>
                            {detail && <span className={classes.nodeStatusText}>{detail}</span>}
                          </div>
                          {!isTransition && (
                            <div className={nodeTypeClasses}>
                              <span>{nodeType}</span>
                            </div>
                          )}
                          {isTransition && (
                            <div className={nodeTypeClasses}>
                              <span>{nodeTypes[0].trim()}</span>
                              <ImgIcon
                                className={classes.doubleArrowIcon}
                                imgUrl="/images/icons/double-arrow.png"
                              />
                              <span>{nodeTypes[1].trim()}</span>
                            </div>
                          )}
                          {isTransition && (
                            <div className={classes.displayNodeTypeStyle}>{transitionText}</div>
                          )}
                        </div>
                      </Link>
                    );
                  })}
                  {cluster.explorer && (
                    <Link
                      id="network-cluster-explorer-name"
                      to={`/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/explorer/overview`}
                    >
                      <div id="network-cluster-information" className={classes.nodeItem}>
                        <NodeStatusIcon
                          circleClassName={classes.nodeStatusIcon}
                          status={explorerStatus}
                        />
                        <div className={classes.nodeName}>
                          Block Explorer
                          {explorerStatus !== 'running' ? (
                            <span className={classes.nodeStatusText}>{explorerStatus}</span>
                          ) : null}
                        </div>
                      </div>
                    </Link>
                  )}
                </div>

                <div
                  id="network-cluster-information"
                  className={classNames(classes.infoItem, classes.clusterNodes)}
                >
                  <div className={classNames(classes.clusterItemInfo, classes.clusterId)}>
                    <div className={classes.subTitle}>{this.props.t('cluster_id')}</div>
                    <div className={classes.detailValue}>{cluster.clusterUuid}</div>
                  </div>
                  <div className={classes.clusterItemInfo}>
                    <div className={classes.clusterType}>
                      <div className={classes.subTitle}>{this.props.t('cluster_type')}</div>
                      <div className={classes.detailValue}>
                        {showSensitiveData ? cluster.clusterType : MASK_ASTERISK}
                      </div>
                    </div>
                    <div className={classes.nodeCount}>
                      <div className={classes.subTitle}>{this.props.t('node_count')}</div>
                      <div className={classes.detailValue}>{cluster.nodes.length}</div>
                    </div>
                  </div>
                  <div className={classes.clusterItemInfo}>
                    <div className={classes.subTitle}>{this.props.t('geth_RPC_endpoint')}</div>
                    {showSensitiveData ? (
                      <div className={classes.endPoint}>
                        <div className={classes.detailValue}>
                          {displayClusterStatus(cluster) !== 'running' ? (
                            <NodeStatusIcon
                              classes={{
                                root: classNames(classes.nodeIcon),
                                loadingIconBlock: classes.nodeIcon,
                              }}
                              status={'pending'}
                            />
                          ) : (
                            <>
                              <ClickAwayListener onClickAway={() => this.handleTooltipClose(index)}>
                                <Tooltip
                                  arrow
                                  title={
                                    <div className={classes.tooltipTitle}>
                                      {showableSecurityTab
                                        ? this.props.t('geth_rpc_endpoint_notification')
                                        : this.props.t('geth_rpc_endpoint_notification_for_member')}
                                      <br />
                                      {showableSecurityTab && (
                                        <div className={classes.tpContent}>
                                          <a
                                            href={`/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/endpoint`}
                                          >
                                            {this.props.t('add_inbound_rules')}
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  }
                                  classes={{
                                    popper: classNames(classes.arrowPopper, classes.toolTipBox),
                                    tooltip: classes.lightTooltip,
                                    arrow: classes.customTooltipArrow,
                                  }}
                                  PopperProps={{
                                    popperOptions: {
                                      modifiers: [
                                        {
                                          arrow: {
                                            enabled: Boolean(this.state.arrowRef),
                                            element: this.state.arrowRef,
                                          },
                                        },
                                      ],
                                    },
                                  }}
                                  onClick={() => this.handleTooltipOpen(index)}
                                  onClose={() => this.handleTooltipClose(index)}
                                  open={openTooltip[index]}
                                  placement="top"
                                  leaveDelay={200}
                                >
                                  {lockedEndpoint ? (
                                    <img src="/images/icons/lock.png" className={classes.tpIcon} />
                                  ) : (
                                    <img
                                      src="/images/icons/un-lock.png"
                                      className={classes.tpIcon}
                                    />
                                  )}
                                </Tooltip>
                              </ClickAwayListener>
                            </>
                          )}
                          {this.showRpcUrl(cluster) || 'fetching ...'}
                        </div>
                        <CopyClipboardIcon
                          classes={{ root: classes.copyButton }}
                          textToCopy={this.showRpcUrl(cluster) || ''}
                        />
                      </div>
                    ) : (
                      MASK_ASTERISK
                    )}
                  </div>
                  {cluster.explorer && (
                    <div className={classes.clusterItemInfo}>
                      <div className={classes.subTitle}>{this.props.t('block_explorer_URL')}</div>
                      {showSensitiveData ? (
                        <div className={classes.endPoint}>
                          <div className={classes.detailValue}>
                            {displayBlockExplorerStatus(cluster) !== 'running' ? (
                              <NodeStatusIcon
                                classes={{
                                  root: classNames(classes.nodeIcon),
                                  loadingIconBlock: classes.nodeIcon,
                                }}
                                status={'pending'}
                              />
                            ) : (
                              <>
                                <ClickAwayListener
                                  onClickAway={() => this.handleTooltipClose(index)}
                                >
                                  <Tooltip
                                    arrow
                                    title={
                                      <div className={classes.tooltipTitle}>
                                        {showableSecurityTab
                                          ? this.props.t('explorer_endpoint_notification')
                                          : this.props.t(
                                              'explorer_endpoint_notification_for_member',
                                            )}
                                        <br />
                                        {showableSecurityTab && (
                                          <div className={classes.tpContent}>
                                            <a
                                              href={`/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/endpoint`}
                                            >
                                              {this.props.t('add_inbound_rules')}
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                    }
                                    classes={{
                                      popper: classNames(classes.arrowPopper, classes.toolTipBox),
                                      tooltip: classes.lightTooltip,
                                      arrow: classes.customTooltipArrow,
                                    }}
                                    PopperProps={{
                                      popperOptions: {
                                        modifiers: [
                                          {
                                            arrow: {
                                              enabled: Boolean(this.state.arrowRef),
                                              element: this.state.arrowRef,
                                            },
                                          },
                                        ],
                                      },
                                    }}
                                    onClick={() => this.handleTooltipOpen(index)}
                                    onClose={() => this.handleTooltipClose(index)}
                                    open={openTooltip[index]}
                                    placement="top"
                                    leaveDelay={200}
                                  >
                                    {lockedExplorer ? (
                                      <img
                                        src="/images/icons/lock.png"
                                        className={classes.tpIcon}
                                      />
                                    ) : (
                                      <img
                                        src="/images/icons/un-lock.png"
                                        className={classes.tpIcon}
                                      />
                                    )}
                                  </Tooltip>
                                </ClickAwayListener>
                              </>
                            )}
                            {this.showBlockExplorerUrl(cluster) || 'fetching ...'}
                          </div>
                          <CopyClipboardIcon
                            classes={{ root: classes.copyButton }}
                            textToCopy={this.showBlockExplorerUrl(cluster) || ''}
                          />
                        </div>
                      ) : (
                        MASK_ASTERISK
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <div className={classes.bottomLine}>
          {/* <SearchInput placeholder="Find a cluster" className={classes.searchInput} /> */}
          <LGButton
            classes={{ root: classes.createClusterBtn }}
            onClick={this.onOpenCreateNetworkDialog}
            disabled={!creatableCluster}
          >
            <ImgIcon className={classes.addClusterIcon} imgUrl="/images/icons/add_ico.png" />
            <span className={classes.addClusterText}>{this.props.t('create_cluster')}</span>
          </LGButton>
        </div>
      </div>
    );
  }

  private displayNodeStatusDetail = (node: INode, trans: TFunction) => {
    const target = node.serverInfo.status === 'alive' ? 'node' : 'server';
    const status = displayNodeStatus(node);

    let result = '';

    if (status.substr(0, 2) === 'x_') {
      // custom status
      result = trans(status);
    } else if (status !== 'running') {
      result = `${target} ${status}`;
    } else if (status === 'running') {
      result = `(#${node.nodeInfo.latestBlock})`;
    }

    return result;
  };

  private showRpcUrl = (cluster: ICluster) => {
    const { account, selectedAccountRole } = this.props;
    let result: string = '';
    const rpc = cluster.listeners?.find((val) => val.type === 'HTTP_RPC');
    if (!rpc) {
      return 'N/A';
    }
    switch (cluster.status) {
      case 'alive':
        const customDomain =
          cluster.customDomains &&
          cluster.customDomains.find((e) => e.endpointDnsName !== undefined);
        if (
          customDomain &&
          customDomain.customDomainUrl &&
          account.accountUuid === cluster.accountUuid &&
          (selectedAccountRole === 'owner' || selectedAccountRole === 'admin')
        ) {
          result = `${customDomain.customDomainUrl}${rpc.port === 443 ? '' : `:${rpc.port}`}/`;
        } else {
          result = `${cluster.endpointUrl}${rpc.port === 443 ? '' : `:${rpc.port}`}/`;
        }
        break;
      case 'pending':
        // still fetching ...
        break;
      default:
        result = 'N/A';
        break;
    }
    return result;
  };

  private showBlockExplorerUrl = (cluster: ICluster) => {
    const { account, selectedAccountRole } = this.props;
    if (!cluster.explorer) {
      return '';
    }
    const explorer = cluster.listeners?.find((val) => val.type === 'BLOCK_EXPLORER');
    if (!explorer) {
      return 'N/A';
    }
    const { status } = cluster.explorer?.serverInfo;
    let result: string = '';

    if (cluster.endpointUrl && cluster.endpointUrl.substring(0, 4) === 'http') {
      switch (status) {
        case 'alive':
          const customDomain =
            cluster.customDomains &&
            cluster.customDomains.find((e) => e.endpointDnsName !== undefined);
          if (
            customDomain &&
            customDomain.customDomainUrl &&
            account.accountUuid === cluster.accountUuid &&
            (selectedAccountRole === 'owner' || selectedAccountRole === 'admin')
          ) {
            result = `${customDomain.customDomainUrl}${
              explorer.port === 443 ? '' : `:${explorer.port}`
            }/`;
          } else {
            result = `${cluster.endpointUrl}${explorer.port === 443 ? '' : `:${explorer.port}`}/`;
          }
          break;
        case 'pending':
          // still fetching ...
          break;
        default:
          result = 'N/A';
          break;
      }
    }
    return result;
  };

  private onOpenBlockExplorerUrl = (cluster: ICluster) => {
    if (!cluster.explorer) {
      return;
    }
    const { status } = cluster.explorer?.serverInfo;
    const explorer = cluster.listeners?.find((val) => val.type === 'BLOCK_EXPLORER');
    if (!explorer) {
      window.alert(this.props.t('block_explorer_not_specified'));
    } else {
      if (status === 'alive') {
        window.open(
          `${cluster.endpointUrl}${explorer.port === 443 ? '' : `:${explorer.port}`}/`,
          '_blank',
        );
      } else {
        const msg = status === 'pending' ? 'not launched yet' : 'not available';
        window.alert(`Block Explorer ${msg}.`);
      }
    }
  };

  private onOpenCreateNodeDialog = () => {
    this.setState({
      openCreateNodeDialog: true,
      clusterOptionAnchorEl: null,
    });
  };

  private onCloseCreateNodeDialog = () => {
    this.setState({
      openCreateNodeDialog: false,
    });
  };

  private onOpenCreateBlockExplorerDialog = () => {
    this.setState({
      openCreateBlockExplorerDialog: true,
      clusterOptionAnchorEl: null,
    });
  };

  private onCloseCreateBlockExplorerDialog = () => {
    this.setState({
      openCreateBlockExplorerDialog: false,
    });
  };

  private onOpenCreateNetworkDialog = () => {
    this.setState({
      openCreateClusterDialog: true,
    });
  };

  private onCloseCreateNetworkDialog = () => {
    this.setState({
      openCreateClusterDialog: false,
    });
  };

  private onCloseClusterOptionPopover = () => {
    this.setState({
      clusterOptionAnchorEl: null,
      clusterSelected: undefined,
    });
  };

  private onOpenClusterOptionPopover = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    cluster: ICluster,
  ) => {
    this.setState({
      clusterOptionAnchorEl: e.currentTarget,
      clusterSelected: cluster,
    });
  };

  private onOpenEditClusterDialog = () => {
    this.setState({
      openEditClusterDialog: true,
      clusterOptionAnchorEl: null,
    });
  };

  private onCloseEditClusterDialog = () => {
    this.setState({
      openEditClusterDialog: false,
    });
  };

  private onOpenDeleteClusterDialog = () => {
    this.setState({
      openDeleteClusterDialog: true,
      clusterOptionAnchorEl: null,
    });
  };

  private onCloseDeleteClusterDialog = () => {
    this.setState({
      openDeleteClusterDialog: false,
    });
  };

  private handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    });
  };

  handleTooltipClose = (index) => {
    const toolTips = [...this.state.openTooltip];
    toolTips[index] = false;
    this.setState({
      openTooltip: toolTips,
    });
  };

  handleTooltipOpen = (index) => {
    const toolTips = [...this.state.openTooltip];
    toolTips[index] = true;
    this.setState({
      openTooltip: toolTips,
    });
  };
}

function arrowGenerator(color: string) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
  };
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 20,
      paddingBottom: 50,
    },
    displayNodeTypeStyle: {
      marginTop: 3,
      marginBottom: 3,
      paddingLeft: 5,
      paddingRight: 5,
      fontSize: 12,
      textAlign: 'right',
      color: lightSlateGreyColor,
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '2em',
    },
    bottomLine: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 20,
    },
    searchInput: {
      flex: 1,
      maxWidth: 400,
    },
    createClusterBtn: {
      width: 'auto',
      height: 34,
      marginLeft: 'auto',
    },
    addClusterIcon: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    addClusterText: {
      ...defaultFontRegular,
      fontSize: 15,
      textAlign: 'center',
      color: nightRiderColor,
    },
    borderBlock: {
      '&:nth-child(2)': {
        marginTop: 17,
      },
      marginTop: 20,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 4,
      borderColor: pattensBlueColor,
      boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
    },
    titleArea: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 36,
      backgroundColor: snowColor,
      paddingLeft: 20,
      paddingRight: 10,
    },
    clusterHeading: {
      height: 'inherit',
      padding: '5px 20px',
    },
    contentArea: {
      paddingTop: 20,
      paddingBottom: 20,
      display: 'flex',
    },
    clusterNodes: {},
    nodeSeperator: {},
    separateBorder: {
      borderRight: `1px solid ${pattensBlueColor}`,
    },
    handleBtnArea: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
    },
    verticalSeparate: {
      width: 1,
      height: 20,
      backgroundColor: pattensBlueColor,
      marginRight: 5,
      marginLeft: 5,
    },
    infoItem: {
      paddingLeft: 20,
      paddingRight: 20,
      flex: 1,
      minWidth: 0,
    },
    networkDetail: {},
    nodesText: {
      ...defaultFontRegular,
      fontSize: 15,
      color: lightSlateGreyColor,
    },
    subTitle: {
      ...defaultFontMedium,
      fontSize: 12,
    },
    detailValue: {
      ...defaultFontRegular,
      ...oneLineText,
      fontSize: 15,
      color: lightSlateGreyColor,
    },
    nodeIcon: {
      width: 14,
      height: 20,
      margin: '0 5px 0 0',
      verticalAlign: 'middle',
    },
    networkDescription: {
      whiteSpace: 'normal',
      wordBreak: 'break-all',
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
    },
    linePartition: {
      display: 'none',
    },
    networkUUID: {
      ...defaultFontRegular,
      ...oneLineText,
      fontSize: 12,
      color: lightSlateGreyColor,
      alignItems: 'center',
    },
    clusterTitle: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: '80%',
    },
    clusterNameLink: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '50%',
    },
    clusterTitleText: {
      ...defaultFontMedium,
      fontSize: 18,
      color: denimColor,
      marginLeft: 10,
    },
    otherAccountBtn: {
      ...defaultFontMedium,
      wordBreak: 'break-all',
      fontSize: 12,
      color: lightSlateGreyColor,
      textTransform: 'none',
      height: 'inherit',
      padding: '4px 8px',
      backgroundColor: pattensBlueColor,
      '&:hover': {
        backgroundColor: pattensBlueColor,
        boxShadow: 'none',
      },
      boxShadow: 'none',
      marginLeft: 10,
      maxWidth: '50%',
    },
    clusterItemInfo: {
      marginBottom: 10,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    clusterType: {
      display: 'inline-block',
    },
    nodeCount: {
      marginLeft: 50,
      display: 'inline-block',
    },
    nodeItem: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 34,
      marginTop: 10,
      marginBottom: 10,
      backgroundColor: whiteSmokeColor,
      border: `1px solid ${pattensBlueColor}`,
      borderRadius: 4,
      paddingLeft: 10,
      paddingRight: 10,
    },
    nodeName: {
      ...defaultFont,
      fontSize: 14,
      color: denimColor,
      marginLeft: 10,
      flex: 1,
      display: 'flex',
      overflow: 'hidden',
      alignItems: 'center',
    },
    nodeNameText: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    nodeType: {
      ...defaultFontMedium,
      marginLeft: 10,
      display: 'flex',
      alignItems: 'center',
      height: 24,
      borderRadius: 14,
      paddingLeft: 15,
      paddingRight: 15,
      fontSize: 12,
      color: matterhornColor,
    },
    backgroundRomanColor: {
      // backgroundColor: romanColor,
      backgroundColor: 'rgb(227, 90, 90, 0.2)',
    },
    backgroundSummerSkyColor: {
      // backgroundColor: summerSkyColor,
      backgroundColor: 'rgb(64, 194, 230, 0.2)',
    },
    handelBtn: {
      ...defaultFontMedium,
      fontSize: 12,
      color: lightSlateGreyColor,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    // Network option popover
    listOption: {
      // paddingTop: 0,
      // paddingBottom: 0,
    },
    listOptionItem: {
      height: 46,
      width: 210,
      ...defaultFont,
      fontSize: 16,
    },
    editIcon: {
      width: 14,
      height: 14,
      marginRight: 7,
    },
    clusterOptionPopoverPaper: {},
    flexLineBreak: {
      width: '100%',
      height: 13,
    },
    loadingArea: {
      marginTop: 20,
      textAlign: 'center',
      flex: 'auto',
    },
    totalAccount: {
      ...defaultFont,
      fontSize: 16,
      color: denimColor,
      cursor: 'pointer',
    },
    nodeStatusIcon: {
      transform: 'translateY(2px)',
    },
    nodeStatusText: {
      color: 'black',
      paddingLeft: 8,
      whiteSpace: 'nowrap',
    },
    deleteIcon: {
      marginRight: 8,
    },
    doubleArrowIcon: {
      paddingLeft: 5,
      alignItem: 'center',
      paddingRight: 5,
      fontSize: 12,
      objectFit: 'contain',
    },
    horizontalSeparate: {
      height: 1,
      width: '100%',
      backgroundColor: pattensBlueColor,
    },
    endPoint: {
      display: 'flex',
    },
    copyButton: {
      padding: 5,
      marginLeft: 5,
    },
    arrowPopper: arrowGenerator(whiteColor),
    toolTipBox: {
      opacity: 'unset',
    },
    tooltipTitle: {
      fontWeight: 300,
    },
    customTooltipArrow: {
      color: '#fff',
    },
    arrow: {
      position: 'absolute',
      fontSize: 8,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },
    lightTooltip: {
      backgroundColor: whiteColor,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: '0 6px 10px 0 rgba(0, 0, 0, 0.16)',
      fontSize: 15,
      maxWidth: 234,
      padding: 15,
    },
    tpIcon: {
      margin: '0px 3px 4px 0px',
    },
    tpContent: {
      '& a': {
        color: denimColor,
        cursor: 'pointer',
      },
    },
    clusterId: {},

    [theme.breakpoints.between('sm', 'sm')]: {
      titleArea: {
        paddingLeft: 20,
      },
      clusterNodes: {
        margin: '20px 20px 0px 20px !important',
        paddingBottom: '20px !important',
      },
      nodeSeperator: {
        borderBottom: `1px solid ${pattensBlueColor}`,
      },
      separateBorder: {
        borderRight: 'none',
      },
      infoItem: {
        padding: '0px',
        minWidth: 'unset',
      },
      networkDetail: {
        display: 'grid',
      },
      networkDescription: {
        borderRight: 'none',
      },
      networkTitle: {
        whiteSpace: 'nowrap',
        marginRight: 15,
      },
      contentArea: {
        flexDirection: 'column',
        paddingTop: 0,
      },
      linePartition: {
        borderBottom: `1px solid ${pattensBlueColor}`,
        paddingBottom: 20,
        margin: '0px 20px',
        display: 'flex',
      },
      organizationDetail: {
        paddingBottom: 0,
      },
      flexLineInvisible: {
        display: 'none',
      },
      clusterItemInfo: {
        '&:first-child': {
          width: 350,
          float: 'left',
        },
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  profile: store.appState.profile,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  getAccount: (args: AppActions.QueryGetAccountArgs) => dispatch(AppActions.getAccount(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OverviewTab)),
);
