import React from 'react';
// Component
import ArrowIcon from '~/components/common/arrow-icon';
import Button from '@mui/material/Button';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  brightGreyColor,
  solitudeColor,
  whiteColor,
  denimColor,
} from '~/styles/themes/common-styles/color';

interface IStateProps {
  currentPage: number;
  totalPage: number;
}

interface IDispProps {
  selectPage: (page: number) => void;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles> {}

interface IState {}

class Pagination extends React.Component<IProps, IState> {
  public render() {
    const { classes, currentPage, totalPage } = this.props;
    const pageValues = getPageValues(currentPage, totalPage);

    return (
      <div className={classes.root}>
        <Button
          variant="outlined"
          className={classes.pageItem}
          onClick={this.onBack}
          disabled={currentPage === 1}
          classes={{
            disabled: classes.disabledPageItem,
          }}
        >
          <div className={classes.leftArrow}>
            <ArrowIcon direction="left" size={4} />
          </div>
        </Button>
        {pageValues.map((value, index) => (
          <Button
            key={index}
            classes={{
              disabled: classes.disabledPageItem,
            }}
            disabled={value === '...'}
            variant="outlined"
            className={classNames(classes.pageItem, currentPage === value && classes.currentPage)}
            onClick={() => this.selectPage(value)}
          >
            <span>{value}</span>
          </Button>
        ))}
        <Button
          variant="outlined"
          className={classes.pageItem}
          onClick={this.onNext}
          disabled={currentPage === totalPage}
          classes={{
            disabled: classes.disabledPageItem,
          }}
        >
          <div className={classes.rightArrow}>
            <ArrowIcon direction="right" size={4} />
          </div>
        </Button>
      </div>
    );
  }

  private selectPage = (value: number | '...') => {
    if (value !== '...' && value !== this.props.currentPage) {
      this.props.selectPage(value);
    }
  };

  private onBack = () => {
    this.props.selectPage(this.props.currentPage - 1);
  };

  private onNext = () => {
    this.props.selectPage(this.props.currentPage + 1);
  };
}

const styles = createStyles({
  root: {
    position: 'relative',
  },
  pageItem: {
    minWidth: 35,
    width: 35,
    height: 35,
    ...defaultFont,
    fontSize: 14,
    color: brightGreyColor,
    border: `1px solid ${solitudeColor}`,
    borderRadius: 2,
    marginLeft: 4,
    marginRight: 4,
    '&:hover': {
      backgroundColor: whiteColor,
    },
  },
  disabledPageItem: {
    border: `1px solid ${solitudeColor} !important`,
  },
  currentPage: {
    color: whiteColor,
    borderWidth: 0,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
  },
  leftArrow: {
    transform: 'translateX(2px)',
  },
  rightArrow: {
    transform: 'translateX(-2px)',
  },
});

export default withStyles(styles)(Pagination);

export const getPageValues = (currentPage: number, totalPage: number) => {
  const arr: (number | '...')[] = [];
  for (let e = 1; e <= totalPage; e++) {
    if (
      // Page number less or equal 2
      e <= 2 ||
      // Page number greater or equal totalPage -1
      e >= totalPage - 1 ||
      // Two units away from Current Page
      (e >= currentPage - 2 && e <= currentPage + 2) ||
      // Current page less or equal 2 => Show the first 5 stuff
      (currentPage <= 2 && e <= 5) ||
      // Current page less or equal totalPage - 1 => Show the last 5 stuff
      (currentPage >= totalPage - 2 && e >= totalPage - 4)
    ) {
      arr.push(e);
    } else if (arr[arr.length - 1] !== '...') {
      arr.push('...');
    }
  }
  return arr;
};
