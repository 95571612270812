import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import * as Yup from 'yup';

import { IStore } from '~/stores/configure-store';
import * as AppActions from '~/stores/actions/app-action';
import * as NetworkActions from '~/stores/actions/network-action';
import * as PaymentActions from '~/stores/actions/payment-action';
import { Profile, Account } from '~/types/account-types';
import { INetworkProvider, ICreateNetworkInput } from '~/types/network-types';
import {
  defaultFontBold,
  defaultFont,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  nightRiderColor,
  pattensBlueColor,
  whiteColor,
  denimColor,
  dimGrayColor,
  whiteSmokeColor,
  romanColor,
  persianGreenColor,
} from '~/styles/themes/common-styles/color';
// Formik
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import {
  instancesSizeSelection,
  instancesSizeEnterpriseSelection,
  nodeGethSelection,
  consensusTypesSelection,
  clusterTypeSelection,
  clusterTypeEnterpriseSelection,
  blockExplorerSizeOptions,
  blockscoutBackendSelection,
  nodeClefSelection,
} from '~/types/network-selection';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomSelect from './custom-select';
import CustomInput from './custom-input';
import CustomInputNum from './custom-input-num';
import CustomInputNumCommaFormat from './custom-input-num-comma-format';
import ImgIcon from '~/components/common/img-icon';
import SubmitButton from './submit-button';

import ConfirmLicenseDialog from './confirm-license-dialog';
import RegisterAddressDialog from './register-address-dialog';

// defines
import {
  DEFAULT_PEER_LIMITMAX,
  DEFAULT_PROVIDER_NAME,
  DEFAULT_CLUSTER_TYPE,
  DEFAULT_MIN_NODES_POA,
  DEFAULT_MIN_BLOCK_INTERVAL,
  UNDER5N_POA_BLOCK_INTERVAL,
  DEFAULT_BLOCK_INTERVAL,
  DEFAULT_GAS_LIMIT,
  DEFAULT_GAS_PRICE,
  DEFAULT_GAS_PRICE_MAX,
  DEFAULT_CHAIN_ID,
  DEFAULT_MIN_NETWORK_ID,
  DEFAULT_MAX_NETWORK_ID,
  DEFAULT_VERSION_GETH,
  DEFAULT_ALLOC_ADDRESS,
  DEFAULT_ALLOC_BALANCE,
  ALLOC_ADDRESS_LENGTH,
  ALLOC_BALANCE_LENGTH,
  DEFAULT_BLOCKEXPLR_SV_SIZE,
  DEFAULT_CURRENCY_NAME,
  DEFAULT_CURRENCY_SYMBOL,
  HARD_FORK_CONFIGURATION,
  MIN_GAS_LIMIT,
  MAX_GAS_LIMIT,
  DEFAULT_EPOCH_BLOCK,
  DEFAULT_MIN_EPOCH_BLOCK,
  DEFAULT_MAX_EPOCH_BLOCK,
  DEFAULT_VERSION_BLOCKSCOUT_BACKEND,
  DEFAULT_VERSION_BLOCKSCOUT_FRONTEND,
  DEFAULT_MINIMUM_BLOCKSCOUT_VERSION_SUPPORT_UI_V2,
  DEFAULT_VERSION_INTERNAL_CLEF,
} from '~/constants/consts';
import {
  VALIDATE_CLUSTER_TYPE_NODES,
  MAX_NETWORK_NAME_LENGTH,
  MAX_CLUSTER_NAME_LENGTH,
  MAX_NETWORK_DESCRIPTION_LENGTH,
  CREATE_NETWORK_MIN_NUMBER_OF_NODE,
  CREATE_NETWORK_MAX_NUMBER_OF_NODE,
  MAX_CURRENCY_NAME_LENGTH,
  MAX_CURRENCY_SYMBOL_LENGTH,
} from '~/constants/validation';
import { BlockExplorerSizeType, NodeSizeType } from '~/gapi/gtypes';
import * as semver from 'semver';

interface IProps
  extends WithStyles<typeof styles>,
    WithTranslation,
    RouteComponentProps<{ id: string }> {
  open: boolean;
  onClose: () => void;
  // added only to write test cases
  isShowAdvanced: boolean;
}

interface IStateProps {
  profile?: Profile;
  providers: INetworkProvider[];
  accountSeleted?: Account;
}

interface IDispProps {
  createNetwork: (
    args: NetworkActions.MutationCreateNetworkArgs,
  ) => Promise<NetworkActions.CREATE_NETWORK_RESULT_TYPE>;
  insufficientLicenses: (
    args: PaymentActions.QueryListInsufficientClusterLicensesArgs,
  ) => Promise<PaymentActions.LIST_INSUFFICIENT_CLUSTER_LICENSES_RESULT_TYPE>;
  estimateLicenseFee: (
    args: PaymentActions.QueryEstimateLicenseFeeArgs,
  ) => Promise<PaymentActions.ESTIMATE_LICENSE_FEE_RESULT_TYPE>;
  listActiveLicensesSummary: (
    args: PaymentActions.QueryListActiveLicensesSummaryArgs,
  ) => Promise<PaymentActions.LIST_ACTIVE_LICENSES_SUMMARY_RESULT_TYPE>;
  listBillings: (
    args: PaymentActions.QueryListBillingsArgs,
  ) => Promise<PaymentActions.LIST_BILLINGS_RESULT_TYPE>;
  listCoupons: (
    args: PaymentActions.QueryListCouponsArgs,
  ) => Promise<PaymentActions.LIST_COUPONS_RESULT_TYPE>;
  listMembers: (
    args: AppActions.QueryToListMembersArgs,
  ) => Promise<AppActions.LIST_MEMBERS_RESULT_TYPE>;
  getAccount: (args: AppActions.QueryGetAccountArgs) => Promise<AppActions.GET_ACCOUNT_RESULT_TYPE>;
}

interface IState {
  isShowAdvanced: boolean;
  openConfirmLicenseDialog: boolean;
  openRegisterAddressDialog: boolean;
  estimateDate: string;
  estimateFee: PaymentActions.EstimateSummary;
  requireCard: boolean;
  formik?: FormikActions<CreateNetworkFormValues>;
}

class CreateNetworkDialog extends React.Component<IProps & IStateProps & IDispProps, IState> {
  constructor(props) {
    super(props);
    const { isShowAdvanced } = this.props;

    this.state = {
      isShowAdvanced: isShowAdvanced,
      openConfirmLicenseDialog: false,
      openRegisterAddressDialog: false,
      requireCard: false,
      estimateDate: '',
      estimateFee: {
        estimate: [
          {
            totalPrice: 0,
            taxFee: 0,
            taxRate: 0,
            licenses: [],
            nextMonth: {
              totalPrice: 0,
              taxFee: 0,
              taxRate: 0,
              licenses: [],
            },
          },
        ],
        coupon: {
          usable: 0,
        },
      },
    };
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  public render() {
    const { classes, open, onClose, accountSeleted, t } = this.props;
    const select =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? clusterTypeEnterpriseSelection
        : clusterTypeSelection;
    const requireAddr =
      !accountSeleted || !accountSeleted.paymentAddr || !accountSeleted.paymentAddr.country
        ? true
        : false;

    const validateSchema = Yup.object().shape({
      networkName: Yup.string()
        .required('required_field')
        .max(
          MAX_NETWORK_NAME_LENGTH,
          t('too_many_character_error_message', { value: MAX_NETWORK_NAME_LENGTH }),
        )
        .test(
          'check-trailing-spaces',
          'trailing_spaces_not_allowed_to_name',
          function (name: string) {
            return (name || '').trim() !== '' ? true : false;
          },
        ),
      clusterName: Yup.string()
        .required('required_field')
        .max(
          MAX_CLUSTER_NAME_LENGTH,
          t('too_many_character_of_clustername_error_message', { value: MAX_CLUSTER_NAME_LENGTH }),
        )
        .test(
          'check-trailing-spaces',
          'trailing_spaces_not_allowed_to_name',
          function (name: string) {
            return (name || '').trim() !== '' ? true : false;
          },
        ),
      clusterType: Yup.string()
        .required('required_field')
        .oneOf(
          select.map((c) => c.value),
          'invalid_cluster_type',
        ),
      provider: Yup.string().required('required_field'),
      region: Yup.string().required('required_field'),
      instanceType: Yup.string()
        .required('required_field')
        .test(
          'check-with-cluster-type',
          'cluster_type_not_allowed_this_instance',
          function (instance: string) {
            const validator =
              VALIDATE_CLUSTER_TYPE_NODES[this.parent.clusterType || DEFAULT_CLUSTER_TYPE];
            return validator.allowed.includes(instance);
          },
        ),
      numberOfNode: Yup.number()
        .required('required_field')
        .min(CREATE_NETWORK_MIN_NUMBER_OF_NODE, 'validate_minimum')
        .max(CREATE_NETWORK_MAX_NUMBER_OF_NODE, 'validate_maximum')
        .test(
          'check-block-interval-poa',
          'number_of_nodes_must_greater_or_equal_8',
          function (num: number) {
            const bi = this.parent.blockInterval || 0;
            return bi < UNDER5N_POA_BLOCK_INTERVAL && num < DEFAULT_MIN_NODES_POA ? false : true;
          },
        ),
      allocAddress: Yup.string().required('required_field'),
      currencyName: Yup.string()
        .min(1)
        .max(
          MAX_CURRENCY_NAME_LENGTH,
          t('too_many_currency_character_error_message', { value: MAX_CURRENCY_NAME_LENGTH }),
        )
        .test(
          'check-trailing-spaces',
          'trailing_spaces_not_allowed_to_name',
          function (name: string) {
            return name && name.trim() === '' ? false : true;
          },
        ),
      currencySymbol: Yup.string()
        .min(1)
        .max(
          MAX_CURRENCY_SYMBOL_LENGTH,
          t('too_many_currency_character_error_message', { value: MAX_CURRENCY_SYMBOL_LENGTH }),
        )
        .test(
          'check-trailing-spaces',
          'trailing_spaces_not_allowed_to_name',
          function (name: string) {
            return name && name.trim() === '' ? false : true;
          },
        ),
      blockInterval: Yup.number()
        .notRequired()
        .min(
          DEFAULT_MIN_BLOCK_INTERVAL,
          t('validate_minimum', { val: DEFAULT_MIN_BLOCK_INTERVAL }),
        ),
      epochBlock: Yup.number()
        .notRequired()
        .min(DEFAULT_MIN_EPOCH_BLOCK, t('validate_minimum', { val: DEFAULT_MIN_EPOCH_BLOCK }))
        .max(DEFAULT_MAX_EPOCH_BLOCK, t('validate_maximum', { val: DEFAULT_MAX_EPOCH_BLOCK })),
      blockGasLimit: Yup.number()
        .notRequired()
        .min(MIN_GAS_LIMIT, t('validate_minimum', { val: MIN_GAS_LIMIT }))
        .max(MAX_GAS_LIMIT, t('validate_maximum', { val: MAX_GAS_LIMIT })),
      networkId: Yup.number()
        .notRequired()
        .max(DEFAULT_MAX_NETWORK_ID, t('validate_maximum', { val: DEFAULT_MAX_NETWORK_ID })),
      chainId: Yup.number()
        .notRequired()
        .min(1, t('validate_minimum', { val: 1 }))
        .max(DEFAULT_MAX_NETWORK_ID, t('validate_maximum', { val: DEFAULT_MAX_NETWORK_ID })),
      gasPrice: Yup.number()
        .notRequired()
        .min(0, t('validate_minimum', { val: 0 }))
        .max(DEFAULT_GAS_PRICE_MAX, t('validate_maximum', { val: DEFAULT_GAS_PRICE_MAX })),
      networkDescription: Yup.string()
        .notRequired()
        .max(
          MAX_NETWORK_DESCRIPTION_LENGTH,
          t('too_many_character_of_network_details_error_message', {
            value: MAX_NETWORK_DESCRIPTION_LENGTH,
          }),
        )
        .test(
          'check-trailing-spaces',
          'trailing_spaces_not_allowed_to_name',
          function (name: string) {
            name = name || '';
            return name === '' || name.trim() !== '' ? true : false;
          },
        ),
    });

    const {
      isShowAdvanced,
      openConfirmLicenseDialog,
      openRegisterAddressDialog,
      estimateDate,
      estimateFee,
      requireCard,
    } = this.state;

    return (
      <>
        <CustomDialog open={open} onClose={onClose} scroll="body">
          <Formik
            initialValues={createNetworkInitialValues}
            validationSchema={validateSchema}
            onSubmit={this.onCreateNetworkSubmit}
            render={({ isValid, isSubmitting, values }) => (
              <Form>
                <CustomDialogTitle>
                  <div id="member-network-add-title">{t('create_blockchain_network')}</div>
                </CustomDialogTitle>
                <CustomDialogContent>
                  <div>
                    <Field name="networkName" render={this.networkNameField} />
                  </div>
                  <div className={classes.formSection}>
                    <Field name="clusterName" render={this.clusterNameField} />
                  </div>
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid item md={6} className={classes.gridLeftItem}>
                        <Field name="clusterType" render={this.clusterTypeField} />
                      </Grid>
                      <Grid item md={6} className={classes.gridRightItem}>
                        <div className={classes.clusterTypeNote}>
                          {this.displayclusterTypeNote(values)}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid
                        item
                        md={6}
                        className={classNames(classes.gridLeftItem, classes.formSectionCommonGrid)}
                      >
                        <Field name="provider" render={this.providerField} />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        className={classNames(classes.gridRightItem, classes.formSectionCommonGrid)}
                      >
                        <Field name="region" render={this.regionField} />
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid
                        item
                        md={6}
                        className={classNames(classes.gridLeftItem, classes.formSectionCommonGrid)}
                      >
                        <Field name="instanceType" render={this.instanceTypeField} />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        className={classNames(classes.gridRightItem, classes.formSectionCommonGrid)}
                      >
                        <Field name="numberOfNode" render={this.numberOfNodeField} />
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid
                        item
                        md={6}
                        className={classNames(classes.gridLeftItem, classes.formSectionCommonGrid)}
                      >
                        <Field
                          name="includedBlockExplorer"
                          render={this.includedBlockExplorerCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  {values.includedBlockExplorer && (
                    <>
                      <div className={classes.formSection}>
                        <Grid container>
                          <Grid
                            item
                            md={6}
                            className={classNames(
                              classes.gridLeftItem,
                              classes.formSectionCommonGrid,
                            )}
                          >
                            <Field
                              name="blockExplorerInstanceType"
                              render={this.blockExplorerInstanceTypeField}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            className={classNames(
                              classes.gridRightItem,
                              classes.formSectionCommonGrid,
                            )}
                          >
                            <Field
                              name="blockscoutBackendVersion"
                              render={this.blockscoutBackendVersionField}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  )}
                  <div className={classes.formSection} data-name="allocAddress">
                    <Field
                      name="allocAddress"
                      render={this.allocAddressField}
                      validate={this.validateAllocAddress}
                    />
                  </div>
                  <div className={classes.advancedBtnArea}>
                    <div className={classes.separateLine}></div>
                    <div
                      id="member-network-add-separator"
                      className={classes.advancedBtn}
                      onClick={this.toggleAdvanced}
                    >
                      <ImgIcon
                        className={classes.advancedIcon}
                        imgUrl={
                          isShowAdvanced
                            ? '/images/icons/minus_ico.png'
                            : '/images/icons/add_ico.png'
                        }
                      />
                      {t('advanced_menu')}
                    </div>
                  </div>

                  {isShowAdvanced && (
                    <>
                      <div className={classes.formSection}>
                        <Grid container>
                          <Grid
                            item
                            md={4}
                            className={classNames(classes.gridLeftItem, classes.blockCommonGrid)}
                          >
                            <Field name="blockInterval" render={this.blockIntervalField} />
                          </Grid>
                          <Grid
                            item
                            md={4}
                            className={classNames(classes.gridLeftItem, classes.blockCommonGrid)}
                          >
                            <Field name="blockGasLimit" render={this.blockGasLimitField} />
                          </Grid>
                          <Grid
                            item
                            md={4}
                            className={classNames(classes.gridRightItem, classes.blockCommonGrid)}
                          >
                            <Field name="gasPrice" render={this.gasPriceField} />
                          </Grid>
                        </Grid>
                      </div>
                      <div className={classes.formSection}>
                        <Grid container>
                          <Grid item md={6} className={classes.gridLeftItem}>
                            <Field name="networkId" render={this.networkIdField} />
                          </Grid>
                          <Grid item md={6} className={classes.gridRightItem}>
                            <Field name="chainId" render={this.chainIdField} />
                          </Grid>
                        </Grid>
                      </div>
                      <div className={classes.formSection}>
                        <Grid container>
                          <Grid item md={6} className={classes.gridLeftItem}>
                            <Field name="nodeVersion" render={this.nodeVersionField} />
                          </Grid>
                          <Grid item md={6} className={classes.gridRightItem}>
                            <Field
                              name="internalClefVersion"
                              render={this.internalClefVersionField}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <div className={classes.formSection}>
                        <Grid container>
                          <Grid item md={6} className={classes.gridLeftItem}>
                            <Field
                              name="consensusAlgorithm"
                              render={this.consensusAlgorithmField}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <div className={classes.formSection} data-name="allocBalance">
                        <Field
                          name="allocBalance"
                          render={this.allocBalanceField}
                          validate={this.validateAllocBalance}
                        />
                      </div>
                      <div className={classes.formSection}>
                        <Grid container>
                          <Grid item md={6} className={classes.gridLeftItem}>
                            <Field name="currencyName" render={this.currencyNameField} />
                          </Grid>
                          <Grid item md={6} className={classes.gridRightItem}>
                            <Field name="currencySymbol" render={this.currencySymbolField} />
                          </Grid>
                        </Grid>
                      </div>
                      <div className={classes.formSection}>
                        <Grid container>
                          <Grid
                            item
                            md={6}
                            className={classNames(
                              classes.gridLeftItem,
                              classes.formSectionCommonGrid,
                            )}
                          >
                            <Field name="hardForkName" render={this.hardForkField} />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            className={classNames(
                              classes.gridRightItem,
                              classes.formSectionCommonGrid,
                            )}
                          >
                            <Field name="epochBlock" render={this.epochBlockField} />
                          </Grid>
                        </Grid>
                      </div>
                      <div className={classes.formSection} data-name="networkDescription">
                        <Field name="networkDescription" render={this.networkDescriptionField} />
                      </div>
                    </>
                  )}
                </CustomDialogContent>
                <CustomDialogActions>
                  <Button
                    id="member-network-add-cancel"
                    disabled={isSubmitting}
                    className={classes.leftBtn}
                    onClick={onClose}
                    variant="contained"
                  >
                    {t('cancel')}
                  </Button>
                  <SubmitButton
                    id="member-network-add-submit"
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    label={t('create')}
                    submittingLabel={t('creating')}
                  />
                </CustomDialogActions>
              </Form>
            )}
          />
        </CustomDialog>

        <RegisterAddressDialog
          open={openRegisterAddressDialog}
          onClose={this.onCloseRegisterAddressDialog}
        ></RegisterAddressDialog>
        <ConfirmLicenseDialog
          open={openConfirmLicenseDialog}
          onClose={this.onCloseConfirmLicenseDialog}
          estimateDate={estimateDate}
          estimateFee={estimateFee}
          requireAddr={requireAddr}
          requireCard={requireCard}
        ></ConfirmLicenseDialog>
      </>
    );
  }

  private validateAllocAddress = (value: string) => {
    let error: string = '';
    if (value.indexOf('0x') === 0) {
      value = value.substring(2);
    }
    const allocAddressPattern = /^[0-9a-fA-F]+$/;
    if (!allocAddressPattern.test(value) || value.length !== ALLOC_ADDRESS_LENGTH) {
      error = this.props.t('error_alloc_address');
    }
    return error;
  };

  private validateAllocBalance = (value: string) => {
    let error: string = '';
    if (value.indexOf('0x') === 0) {
      value = value.substring(2);
    }
    const allocBalancePattern = /^[0-9a-fA-F]+$/;
    if (!allocBalancePattern.test(value) || value.length === 0) {
      error = this.props.t('error_hexadecimal_number_required');
    } else if (!parseInt(value, 16)) {
      error = this.props.t('error_zero_balance_not_allowed');
    }
    return error;
  };

  private providerField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, providers } = this.props;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('provider')}</div>
          {!!form.errors.provider && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.provider}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-network-add-provider"
            valueSelected={field.value}
            placeholder={this.props.t('select_provider')}
            items={providers.map((item) => ({ value: item.value, label: item.label }))}
          />
        </div>
      </>
    );
  };

  private regionField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, providers, t } = this.props;
    const provider = providers.find((item) => item.value === form.values.provider);
    let regions = [{ value: '', label: t('select_your_provider_first') }];

    if (provider) {
      regions = provider.region.map((item) => ({ value: item.value, label: item.label }));
    }
    if (!regions.find((r) => r.value === field.value)) {
      field.value = '';
      form.values.region = field.value;
    }

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('region')}</div>
          {!!form.errors.region && form.touched.region && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.region)}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-network-add-region"
            valueSelected={field.value}
            placeholder={t(provider ? 'select_region' : 'select_your_provider_first')}
            items={regions}
          />
        </div>
      </>
    );
  };

  private networkNameField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('network_name')}</div>
          {!!form.errors.networkName && form.touched.networkName && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {this.props.t(form.errors.networkName)}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-network-add-name"
            placeholder={this.props.t('input_network_name')}
          />
        </div>
      </>
    );
  };

  private clusterNameField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('cluster_name')}</div>
          {!!form.errors.clusterName && form.touched.clusterName && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {this.props.t(form.errors.clusterName)}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-network-add-cluster"
            placeholder={this.props.t('input_cluster_name')}
          />
        </div>
      </>
    );
  };

  private clusterTypeField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t, accountSeleted } = this.props;
    const select =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? clusterTypeEnterpriseSelection
        : clusterTypeSelection;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('cluster_type')}</div>
          {!!form.errors.clusterType && form.touched.clusterType && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.clusterType)}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-network-add-cluster-type"
            valueSelected={field.value}
            placeholder={t('select_cluster_type')}
            items={select}
          />
        </div>
      </>
    );
  };

  private allocAddressField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('initial_address')}</div>
          {!!form.errors.allocAddress && form.touched.allocAddress && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.allocAddress === 'validate_minimum'
                ? t(form.errors.allocAddress, { val: DEFAULT_MIN_BLOCK_INTERVAL })
                : t(form.errors.allocAddress)}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-network-add-address"
            placeholder={t('input_initial_address')}
          />
        </div>
      </>
    );
  };

  private allocBalanceField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('initial_balance')}</div>
          {!!form.errors.allocBalance && form.touched.allocBalance && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.allocBalance)}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-network-add-balance"
            placeholder={t('input_initial_balance')}
          />
        </div>
      </>
    );
  };

  private instanceTypeField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t, accountSeleted } = this.props;
    const selectC =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? clusterTypeEnterpriseSelection
        : clusterTypeSelection;
    const selectI =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? instancesSizeEnterpriseSelection
        : instancesSizeSelection;

    const cluster = selectC.map((c) => c.value).find((c) => c === form.values.clusterType);
    const validate = VALIDATE_CLUSTER_TYPE_NODES[cluster || DEFAULT_CLUSTER_TYPE];
    const filtered = selectI.filter((item) => validate.allowed.includes(item.value));

    if (!filtered.find((i) => i.value === field.value)) {
      field.value = filtered.length > 0 ? filtered[0].value : '';
      form.values.instanceType = field.value;
    }

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('instance_type')}</div>
          {!!form.errors.instanceType && form.touched.instanceType && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.instanceType)}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-network-add-instance"
            valueSelected={field.value}
            placeholder={t('select_instance_type')}
            items={filtered}
          />
        </div>
      </>
    );
  };

  private blockExplorerInstanceTypeField = ({
    field,
    form,
  }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t } = this.props;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('block_explorer_instance_type')}</div>
          {!!form.errors.blockExplorerInstanceType && form.touched.blockExplorerInstanceType && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.blockExplorerInstanceType)}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-network-add-block-explorer-instance"
            valueSelected={field.value}
            placeholder={t('select_block_explorer_instance_type')}
            items={blockExplorerSizeOptions}
          />
        </div>
      </>
    );
  };

  private blockscoutBackendVersionField = ({
    field,
    form,
  }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('block_explorer_version')}</div>
          {!!form.errors.blockscoutBackendVersion && form.touched.blockscoutBackendVersion && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.blockscoutBackendVersion)}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            valueSelected={field.value}
            placeholder={t('select_block_exp_backend_version')}
            items={blockscoutBackendSelection}
          />
        </div>
      </>
    );
  };

  private numberOfNodeField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t, accountSeleted } = this.props;
    const select =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? clusterTypeEnterpriseSelection
        : clusterTypeSelection;

    const cluster = select.map((c) => c.value).find((c) => c === form.values.clusterType);
    const validate = VALIDATE_CLUSTER_TYPE_NODES[cluster || DEFAULT_CLUSTER_TYPE];

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('number_of_nodes')}</div>
          {!!form.errors.numberOfNode && form.touched.numberOfNode && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.numberOfNode === 'validate_minimum'
                ? t(form.errors.numberOfNode, { val: CREATE_NETWORK_MIN_NUMBER_OF_NODE })
                : form.errors.numberOfNode === 'validate_maximum'
                ? t(form.errors.numberOfNode, { val: CREATE_NETWORK_MAX_NUMBER_OF_NODE })
                : form.errors.numberOfNode === 'check-block-interval-poa'
                ? t(form.errors.numberOfNode, {
                    nodeNum: DEFAULT_MIN_NODES_POA,
                    blockInterval: UNDER5N_POA_BLOCK_INTERVAL,
                  })
                : t(form.errors.numberOfNode)}
            </div>
          )}
        </div>
        <div>
          <CustomInputNum
            {...field}
            id="member-network-add-num-nodes"
            min={CREATE_NETWORK_MIN_NUMBER_OF_NODE}
            max={DEFAULT_PEER_LIMITMAX}
            placeholder={t('input_number')}
          />
        </div>
      </>
    );
  };

  private blockIntervalField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('block_interval')}</div>
        </div>
        <div>
          <CustomInputNum
            {...field}
            id="member-network-add-interval"
            min={1}
            placeholder={t('input_block_interval')}
          />
          {!!form.errors.blockInterval && form.touched.blockInterval && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.blockInterval)}
            </div>
          )}
        </div>
      </>
    );
  };

  private epochBlockField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('epoch_block')}</div>
          {!!form.errors.epochBlock && form.touched.epochBlock && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.epochBlock)}
            </div>
          )}
        </div>
        <div>
          <CustomInputNumCommaFormat
            {...field}
            id="member-network-add-epoch"
            placeholder={t('input_epoch_block')}
            isAllowed={(value) => {
              const numberValue =
                value !== undefined ? Number(value.toString().replace(/,/g, '')) : undefined;
              const floatValue: number | undefined = numberValue;
              return floatValue === undefined || floatValue >= 0;
            }}
            onChange={(values) => {
              const { formattedValue, value, floatValue } = values;
              form.setFieldValue(field.name, floatValue);
            }}
          />
        </div>
      </>
    );
  };

  private blockGasLimitField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('block_gas_limit')}</div>
        </div>
        <div>
          <CustomInputNumCommaFormat
            {...field}
            id="member-network-add-gas"
            placeholder={t('input_gas_limit')}
            isAllowed={(value) => {
              const numberValue =
                value !== undefined ? Number(value.toString().replace(/,/g, '')) : undefined;
              const floatValue: number | undefined = numberValue;
              return floatValue === undefined || floatValue >= 0;
            }}
            onChange={(values) => {
              const { formattedValue, value, floatValue } = values;
              form.setFieldValue(field.name, floatValue);
            }}
          />
          {!!form.errors.blockGasLimit && form.touched.blockGasLimit && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.blockGasLimit)}
            </div>
          )}
        </div>
      </>
    );
  };

  private nodeVersionField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('node_version')}</div>
          {!!form.errors.nodeVersion && form.touched.nodeVersion && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.nodeVersion)}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-network-add-ver-node"
            valueSelected={field.value}
            placeholder={t('select_node_version')}
            items={nodeGethSelection}
          />
        </div>
      </>
    );
  };

  private internalClefVersionField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('internal_clef_version')}</div>
          {!!form.errors.internalClefVersion && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.internalClefVersion}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-node-add-ver-node"
            valueSelected={field.value}
            placeholder={this.props.t('select_node_version')}
            items={nodeClefSelection}
          />
        </div>
      </>
    );
  };

  private consensusAlgorithmField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('consensus_algorithm')}</div>
          {!!form.errors.consensusAlgorithm && form.touched.consensusAlgorithm && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.consensusAlgorithm)}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-network-add-algorithm"
            valueSelected={field.value}
            placeholder={t('select_consensus_algorithm')}
            items={consensusTypesSelection}
          />
        </div>
      </>
    );
  };

  private networkIdField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('network_id')}</div>
          {!!form.errors.networkId && form.touched.networkId && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.networkId)}
            </div>
          )}
        </div>
        <div>
          <CustomInputNum {...field} id="member-network-add-networkid" min={1} />
        </div>
      </>
    );
  };

  private chainIdField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('chain_id')}</div>
          {!!form.errors.chainId && form.touched.chainId && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.chainId)}
            </div>
          )}
        </div>
        <div>
          <CustomInputNum {...field} id="member-network-add-chainid" min={1} />
        </div>
      </>
    );
  };

  private gasPriceField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('gas_price')}</div>
        </div>
        <div>
          <CustomInputNumCommaFormat
            {...field}
            id="member-network-add-gas-price"
            isAllowed={(value) => {
              const numberValue =
                value !== undefined ? Number(value.toString().replace(/,/g, '')) : undefined;
              const floatValue: number | undefined = numberValue;
              return (
                floatValue === undefined || (floatValue >= 0 && floatValue <= DEFAULT_GAS_PRICE_MAX)
              );
            }}
            onChange={(values) => {
              const { formattedValue, value, floatValue } = values;
              form.setFieldValue(field.name, floatValue);
            }}
          />
          {!!form.errors.gasPrice && form.touched.gasPrice && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.gasPrice)}
            </div>
          )}
        </div>
      </>
    );
  };

  private networkDescriptionField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('description')}</div>
          {!!form.errors.networkDescription && form.touched.networkDescription && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {this.props.t(form.errors.networkDescription)}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-network-add-desc"
            placeholder={this.props.t('input_network_desc')}
          />
        </div>
      </>
    );
  };

  private includedBlockExplorerCheckbox = ({
    field,
    form,
  }: FieldProps<CreateNetworkFormValues>) => {
    const { classes } = this.props;
    return (
      <div>
        <FormControlLabel
          {...field}
          className={classes.formControlLabel}
          control={<Checkbox color="default" />}
          label={
            <span className={classes.formLabel}>{this.props.t('is_create_block_explorer')}</span>
          }
        />
      </div>
    );
  };

  private hardForkField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('hard_fork')}</div>
          {!!form.errors.hardForkName && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.hardForkName}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="hard-fork-name"
            valueSelected={field.value}
            placeholder={t('name')}
            items={HARD_FORK_CONFIGURATION}
          />
        </div>
      </>
    );
  };

  private onCreateNetworkSubmit = async (
    values: CreateNetworkFormValues,
    formikActions: FormikActions<CreateNetworkFormValues>,
  ) => {
    const { createNetwork, onClose, accountSeleted, t } = this.props;
    const { setSubmitting } = formikActions;

    // validation
    if (values.allocAddress.indexOf('0x') !== 0) {
      values.allocAddress = `0x${values.allocAddress}`;
    }

    if (values.allocBalance) {
      if (values.allocBalance.indexOf('0x') !== 0) {
        values.allocBalance = `0x${values.allocBalance}`;
      }
      if (values.allocBalance.length !== ALLOC_BALANCE_LENGTH + 2) {
        const hexValue = values.allocBalance.slice(2);
        values.allocBalance = `0x${`0`.repeat(ALLOC_BALANCE_LENGTH - hexValue.length)}${hexValue}`;
      }
    }

    // confirmation
    if ((values.numberOfNode || DEFAULT_MIN_NODES_POA) < DEFAULT_MIN_NODES_POA) {
      if (!window.confirm(t('warn_create_network_nodes_value'))) {
        setSubmitting(false);
        return;
      }
    }

    if (accountSeleted) {
      // check registration of address
      if (!accountSeleted.paymentAddr || !accountSeleted.paymentAddr.country) {
        this.setState({ formik: formikActions });
        this.onOpenRegisterAddressDialog();
        return;
      }

      // start creation
      const params: ICreateNetworkInput = {
        accountUuid: accountSeleted.accountUuid,
        networkName: values.networkName,
        clusterName: values.clusterName,
        clusterType: values.clusterType as NetworkActions.ClusterSizeType,
        provider: values.provider,
        region: values.region,
        instance: values.instanceType,
        blockExplorerSizeType: values.blockExplorerInstanceType,
        nodeNums: values.numberOfNode || void 0,
        allocAddress: values.allocAddress,
        networkDescription: values.networkDescription || void 0,
        includedBlockExplorer: values.includedBlockExplorer,
        optional: {
          networkId: values.networkId || void 0,
          chainId: values.chainId || void 0,
          blockInterval: values.blockInterval || void 0,
          blockGasLimit: values.blockGasLimit ? values.blockGasLimit.toString() : undefined,
          osVersion: values.imageVersion || void 0,
          nodeVersion: values.nodeVersion || void 0,
          consensus: values.consensusAlgorithm || void 0,
          allocBalance: values.allocBalance || void 0,
          gasPrice: values.gasPrice,
          currencySymbol: values.currencySymbol || void 0,
          currencyName: values.currencyName || void 0,
          hardForkName: values.hardForkName || undefined,
          epochBlock: values.epochBlock || undefined,
          blockscoutBackendVersion: values.blockscoutBackendVersion,
          blockscoutFrontendVersion:
            values.blockscoutBackendVersion &&
            semver.gte(
              values.blockscoutBackendVersion,
              DEFAULT_MINIMUM_BLOCKSCOUT_VERSION_SUPPORT_UI_V2,
            )
              ? DEFAULT_VERSION_BLOCKSCOUT_FRONTEND
              : undefined,
          internalClefVersion: values.internalClefVersion,
        },
      };

      const result = await createNetwork({ input: params }).catch((_) => void 0);

      if (!result) {
        // creation failed
        onClose();
        return;
      }
      const { status, data, execAt } = result.createNetwork;

      // handling charge|register card|creation
      if (status.includes('success')) {
        // pull latest account info
        const { getAccount, listMembers } = this.props;
        await getAccount({ accountUuid: accountSeleted.accountUuid });
        await listMembers({ accountUuid: accountSeleted.accountUuid });

        // redirect
        onClose();
        this.props.history.push(`/network/${data!.networkUuid}/overview`);
      } else if (status.includes('needCharge')) {
        // update state
        this.setState({
          requireCard: status.includes('needRegister') ? true : false,
          formik: formikActions,
        });

        // check licenses
        const { insufficientLicenses, estimateLicenseFee } = this.props;
        const needs = await insufficientLicenses({
          accountUuid: accountSeleted.accountUuid,
          nodeNum: values.numberOfNode || DEFAULT_MIN_NODES_POA,
          nodeType: values.instanceType as PaymentActions.NodeSizeType,
          clusterType: values.clusterType as NetworkActions.ClusterSizeType,
          includedBlockExplorer: values.includedBlockExplorer,
          blockExplorerType: values.blockExplorerInstanceType,
        });

        const params = {
          purchaseDate: execAt,
          licenses: needs.listInsufficientClusterLicenses.map((l) => ({
            licenseItemId: l.licenseItemId,
            qty: l.qty,
          })),
        };
        const fee = await estimateLicenseFee({
          accountUuid: accountSeleted.accountUuid,
          input: [params],
        });
        this.setState({ estimateDate: execAt });

        if (fee.estimateLicenseFee.estimate.length > 0) {
          this.setState({ estimateFee: fee.estimateLicenseFee });
        }
        this.onOpenConfirmLicenseDialog();
      } else {
        setSubmitting(false);
        onClose(); // unknown status
      }
    } else {
      setSubmitting(false);
      if (window) {
        window.alert(t('account_not_selected'));
      }
    }
  };

  private toggleAdvanced = () => {
    this.setState({
      isShowAdvanced: !this.state.isShowAdvanced,
    });
  };

  private onCloseRegisterAddressDialog = (status: boolean = false) => {
    const { formik } = this.state;

    this.setState({
      openRegisterAddressDialog: false,
      formik: void 0,
    });

    if (formik) {
      if (status) {
        // if registration successed, then start to buy licenses (auto submit)
        formik.submitForm();
      } else {
        formik.setSubmitting(false);
      }
    }
  };

  private onOpenRegisterAddressDialog = () => {
    this.setState({
      openRegisterAddressDialog: true,
    });
  };

  private onCloseConfirmLicenseDialog = (status: number = 0) => {
    const { formik, requireCard } = this.state;
    this.setState({
      openConfirmLicenseDialog: false,
      formik: void 0,
    });

    if (requireCard && status > 0) {
      this.setState({ requireCard: false });
    }

    if (formik) {
      if (status === 2) {
        // if purchase successed, then start creation (auto submit)
        const { accountSeleted, listActiveLicensesSummary, listBillings, listCoupons } = this.props;
        formik.submitForm();

        if (accountSeleted) {
          listBillings({ accountUuid: accountSeleted.accountUuid }).catch((_) => void 0);
          listCoupons({ accountUuid: accountSeleted.accountUuid }).catch((_) => void 0);
          listActiveLicensesSummary({ accountUuid: accountSeleted.accountUuid }).catch(
            (_) => void 0,
          );
        }
      } else {
        formik.setSubmitting(false);
      }
    }
  };

  private onOpenConfirmLicenseDialog = () => {
    this.setState({
      openConfirmLicenseDialog: true,
    });
  };

  private displayclusterTypeNote = (values: CreateNetworkFormValues) => {
    const { accountSeleted } = this.props;
    const select =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? clusterTypeEnterpriseSelection
        : clusterTypeSelection;
    const types = select.map((c) => c.value);

    if (!types.includes(values.clusterType)) {
      return '';
    }
    return this.props.t(`desc_cluster_${values.clusterType}`);
  };

  private currencyNameField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('currency_name')}</div>
          {!!form.errors.currencyName && form.touched.currencyName && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {this.props.t(form.errors.currencyName)}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-network-add-currency-name"
            placeholder={this.props.t('currency_name_input')}
          />
        </div>
      </>
    );
  };

  private currencySymbolField = ({ field, form }: FieldProps<CreateNetworkFormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('currency_symbol')}</div>
          {!!form.errors.currencySymbol && form.touched.currencySymbol && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {this.props.t(form.errors.currencySymbol)}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-network-add-currency-symbol"
            placeholder={this.props.t('currency_symbol_input')}
          />
        </div>
      </>
    );
  };
}

const styles = createStyles({
  root: {},
  paper: {
    width: '90%',
    maxWidth: 700,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  radioGroup: {
    display: 'flex',
    marginTop: 20,
  },
  radioGroupRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  radioButton: {
    paddingLeft: 0,
    paddingRight: 4,
    color: `${persianGreenColor} !important`,
  },
  radioLabel: {
    ...defaultFontMedium,
    fontSize: 15,
    color: nightRiderColor,
    letterSpacing: 'normal',
  },
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  formSectionCommonGrid: {
    flexGrow: 0,
    maxWidth: '50%',
    flexBasis: '50%',
  },
  blockCommonGrid: {
    flexGrow: 0,
    maxWidth: '33.333333%',
    flexBasis: '33.333333%',
  },
  // Advance Menu
  advancedBtnArea: {
    marginTop: 30,
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    height: 24,
  },
  separateLine: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
  advancedBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'inline-block',
    backgroundColor: whiteColor,
    paddingLeft: 10,
    paddingRight: 10,
    ...defaultFont,
    fontSize: 16,
    color: denimColor,
    cursor: 'pointer',
  },
  advancedIcon: {
    marginRight: 5,
    width: 14,
  },
  // submit button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
  clusterTypeNote: {
    display: 'flex',
    height: '100%',
    paddingTop: '1.8em',
    ...defaultFont,
    fontSize: 12,
  },
});

const mapStateToProps = (store: IStore, ownProps): IStateProps => ({
  profile: store.appState.profile,
  providers: store.appState.providers || [],
  accountSeleted: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  createNetwork: (args: NetworkActions.MutationCreateNetworkArgs) =>
    dispatch(NetworkActions.createNetwork(args)),
  insufficientLicenses: (args: PaymentActions.QueryListInsufficientClusterLicensesArgs) =>
    dispatch(PaymentActions.listInsufficientClusterLicenses(args)),
  estimateLicenseFee: (args: PaymentActions.QueryEstimateLicenseFeeArgs) =>
    dispatch(PaymentActions.estimateLicenseFee(args)),
  listActiveLicensesSummary: (args: PaymentActions.QueryListActiveLicensesSummaryArgs) =>
    dispatch(PaymentActions.listActiveLicensesSummary(args)),
  listBillings: (args: PaymentActions.QueryListBillingsArgs) =>
    dispatch(PaymentActions.listBillings(args)),
  listCoupons: (args: PaymentActions.QueryListCouponsArgs) =>
    dispatch(PaymentActions.listCoupons(args)),
  listMembers: (args: AppActions.QueryToListMembersArgs) => dispatch(AppActions.listMembers(args)),
  getAccount: (args: AppActions.QueryGetAccountArgs) => dispatch(AppActions.getAccount(args)),
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateNetworkDialog))),
);

type CreateNetworkFormValues = {
  networkName: string;
  clusterName: string;
  clusterType: string;
  provider: string;
  region: string;
  instanceType: NodeSizeType;
  blockExplorerInstanceType: BlockExplorerSizeType;
  numberOfNode?: number;
  allocAddress: string;
  blockInterval?: number;
  blockGasLimit?: number;
  imageVersion?: string;
  nodeVersion?: string;
  internalClefVersion?: string;
  consensusAlgorithm?: string;
  allocBalance?: string;
  networkId?: number;
  chainId?: number;
  gasPrice?: number;
  networkDescription?: string;
  includedBlockExplorer?: boolean;
  currencySymbol?: string;
  currencyName?: string;
  hardForkName?: string;
  epochBlock?: number;
  blockscoutBackendVersion?: string;
};

const createNetworkInitialValues: CreateNetworkFormValues = {
  networkName: '',
  clusterName: '',
  clusterType: DEFAULT_CLUSTER_TYPE,
  provider: DEFAULT_PROVIDER_NAME,
  region: '',
  instanceType: 'small_v3',
  blockExplorerInstanceType: 'large_v3',
  numberOfNode: DEFAULT_MIN_NODES_POA,
  imageVersion: '',
  nodeVersion: DEFAULT_VERSION_GETH,
  internalClefVersion: DEFAULT_VERSION_INTERNAL_CLEF,
  consensusAlgorithm:
    consensusTypesSelection && consensusTypesSelection.length > 0
      ? consensusTypesSelection[0].value
      : '',
  allocAddress: DEFAULT_ALLOC_ADDRESS,
  allocBalance: DEFAULT_ALLOC_BALANCE,
  blockInterval: DEFAULT_BLOCK_INTERVAL,
  blockGasLimit: DEFAULT_GAS_LIMIT,
  chainId: DEFAULT_CHAIN_ID,
  gasPrice: DEFAULT_GAS_PRICE,
  networkDescription: '',
  includedBlockExplorer: false,
  currencyName: DEFAULT_CURRENCY_NAME,
  currencySymbol: DEFAULT_CURRENCY_SYMBOL,
  hardForkName: 'london',
  epochBlock: DEFAULT_EPOCH_BLOCK,
  blockscoutBackendVersion: DEFAULT_VERSION_BLOCKSCOUT_BACKEND,
};
