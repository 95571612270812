import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { whiteColor, nightRiderColor, pattensBlueColor } from '~/styles/themes/common-styles/color';
import { AlertType } from '~/types/alert-types';

interface IProps extends WithTranslation, WithStyles<typeof styles> {
  values?: Array<AlertType>;
  onChange: (values?: Array<AlertType>) => void;
}

interface IState {
  anchorEl: HTMLElement | null;
}

class TypeSelector extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  render() {
    const { classes, values } = this.props;
    const label = values
      ? ITEMS.filter((item) => values.includes(item.value))
          .map((item) => item.label)
          .join(',')
      : 'All Type';

    return (
      <>
        {this.popover}
        <div className={classes.root} onClick={this.handleOpenMenu}>
          <span>{label}</span>
          <img src="/images/icons/down_ico_off.svg" alt="" />
        </div>
      </>
    );
  }

  private get popover() {
    const { classes, values } = this.props;
    const { anchorEl } = this.state;
    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={this.handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: classes.popoverPaper }}
      >
        <List className={classes.popoverList}>
          <ListItem
            button={true}
            className={classes.popoverListItem}
            onClick={this.onListItemClick()}
          >
            <img
              className={classNames(classes.checkIcon, !values && classes.selectedIcon)}
              src="/images/icons/check_icon.svg"
              alt=""
            />
            All Type
          </ListItem>
          {ITEMS.map((item) => (
            <ListItem
              key={item.value}
              button={true}
              className={classes.popoverListItem}
              onClick={this.onListItemClick([item.value])}
            >
              <img
                className={classNames(
                  classes.checkIcon,
                  values && values.includes(item.value) && classes.selectedIcon,
                )}
                src="/images/icons/check_icon.svg"
                alt=""
              />
              {item.label}
            </ListItem>
          ))}
        </List>
      </Popover>
    );
  }

  onListItemClick = (values?: Array<AlertType>) => () => {
    this.props.onChange(values);
    this.handleCloseMenu();
  };

  private handleOpenMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  private handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };
}

const styles = createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: whiteColor,
    border: `1px solid ${pattensBlueColor}`,
    borderRadius: 4,
    height: 32,
    width: 170,
    paddingLeft: 20,
    paddingRight: 10,
    fontSize: 14,
    cursor: 'pointer',
  },
  popoverPaper: {},
  popoverList: {
    '& .MuiListItem-root:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  popoverListItem: {
    width: 170,
    fontSize: 15,
    color: nightRiderColor,
    paddingTop: 8,
    paddingBottom: 8,
  },
  checkIcon: {
    visibility: 'hidden',
    marginRight: 15,
    width: 14,
    height: 14,
  },
  selectedIcon: {
    visibility: 'visible',
  },
});

export default withStyles(styles)(withTranslation()(TypeSelector));

const ITEMS: Array<{ label: string; value: AlertType }> = [
  { label: 'Information', value: 'information' },
  { label: 'Important', value: 'important' },
  { label: 'Warning', value: 'warning' },
  { label: 'Error', value: 'error' },
];
