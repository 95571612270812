import React from 'react';
import * as Yup from 'yup';
import * as semver from 'semver';
import { connect } from 'react-redux';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import { Formik, Form, FormikActions, FieldProps, Field } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  defaultFontBold,
  defaultFont,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  denimColor,
  dimGrayColor,
  whiteSmokeColor,
  romanColor,
  pattensBlueColor,
} from '~/styles/themes/common-styles/color';
import * as NetworkActions from '~/stores/actions/network-action';
import * as PaymentActions from '~/stores/actions/payment-action';
import { IStore } from '~/stores/configure-store';
import {
  ICluster,
  INetwork,
  INetworkProvider,
  IProviderImage,
  IProviderRegion,
} from '~/types/network-types';
import { Account } from '~/types/account-types';
// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInput from './custom-input';
import SubmitButton from './submit-button';
import ImgIcon from '~/components/common/img-icon';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
import ConfirmLicenseDialog from './confirm-license-dialog';
import CustomSelect from './custom-select';
import {
  blockExplorerSizeOptions,
  blockscoutBackendSelection,
  nodeGethSelection,
} from '~/types/network-selection';
import { BlockExplorerSizeType } from '~/gapi/gtypes';
import classNames from 'classnames';
import {
  DEFAULT_MINIMUM_BLOCKSCOUT_VERSION_SUPPORT_UI_V2,
  DEFAULT_VERSION_BLOCKSCOUT_BACKEND,
  DEFAULT_VERSION_BLOCKSCOUT_FRONTEND,
  DEFAULT_VERSION_GETH,
} from '~/constants/consts';
import CustomTextField from './custom-text-field';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

type FormValues = {
  clusterUuid: string;
  blockExplorerInstanceType: BlockExplorerSizeType;
  nodeVersion?: string;
  imageVersion?: string;
  reCaptchaClientKey?: string;
  reCaptchaSecretKey?: string;
  walletConnectProjectId?: string;
  rpcUrl?: string;
  backendVersion: string;
};

interface IStateProps {
  providers: INetworkProvider[];
  accountSeleted?: Account;
}

interface IDispProps {
  createBlockExplorer: (
    args: NetworkActions.MutationToCreateBlockExplorerArgs,
  ) => Promise<NetworkActions.ADD_BLOCK_EXPLORER_RESULT_TYPE>;
  listInsufficientBlockExplorerLicenses: (
    args: PaymentActions.QueryListInsufficientBlockExplorerLicensesArgs,
  ) => Promise<PaymentActions.LIST_INSUFFICIENT_BLOCK_EXPLORER_LICENSES_RESULT_TYPE>;
  estimateLicenseFee: (
    args: PaymentActions.QueryEstimateLicenseFeeArgs,
  ) => Promise<PaymentActions.ESTIMATE_LICENSE_FEE_RESULT_TYPE>;
  listActiveLicensesSummary: (
    args: PaymentActions.QueryListActiveLicensesSummaryArgs,
  ) => Promise<PaymentActions.LIST_ACTIVE_LICENSES_SUMMARY_RESULT_TYPE>;
  listBillings: (
    args: PaymentActions.QueryListBillingsArgs,
  ) => Promise<PaymentActions.LIST_BILLINGS_RESULT_TYPE>;
  listCoupons: (
    args: PaymentActions.QueryListCouponsArgs,
  ) => Promise<PaymentActions.LIST_COUPONS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
  network: INetwork;
  cluster: ICluster;
}

interface IState {
  isShowAdvanced: boolean;
  openConfirmLicenseDialog: boolean;
  estimateDate: string;
  estimateFee: PaymentActions.EstimateSummary;
  requireCard: boolean;
  showClientKey: boolean;
  showSecretKey: boolean;
  showWalletConnectProjectId: boolean;
  formik?: FormikActions<FormValues>;
}

class CreateBlockExplorerDialog extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isShowAdvanced: false,
      openConfirmLicenseDialog: false,
      requireCard: false,
      showClientKey: false,
      showSecretKey: false,
      showWalletConnectProjectId: false,
      estimateDate: '',
      estimateFee: {
        estimate: [
          {
            totalPrice: 0,
            taxFee: 0,
            taxRate: 0,
            licenses: [],
            nextMonth: {
              totalPrice: 0,
              taxFee: 0,
              taxRate: 0,
              licenses: [],
            },
          },
        ],
        coupon: {
          usable: 0,
        },
      },
    };
  }

  public render() {
    const { isShowAdvanced, openConfirmLicenseDialog, estimateDate, estimateFee, requireCard } =
      this.state;
    const { accountSeleted, classes, open, onClose, cluster, t, network, providers } = this.props;
    const requireAddr =
      !accountSeleted || !accountSeleted.paymentAddr || !accountSeleted.paymentAddr.country
        ? true
        : false;
    const provider = providers.find((item) => item.value === cluster.provider) || {
      region: [] as IProviderRegion[],
    };
    const region = provider.region.find((item) => item.value === cluster.region) || {
      images: [] as IProviderImage[],
    };

    const initialValues: FormValues = {
      clusterUuid: cluster.clusterUuid,
      blockExplorerInstanceType: 'large_v3',
      imageVersion: region.images[0] && region.images[0].value,
      nodeVersion: DEFAULT_VERSION_GETH,
      backendVersion: DEFAULT_VERSION_BLOCKSCOUT_BACKEND,
    };

    const validateSchema = Yup.object().shape<FormValues>(
      {
        clusterUuid: Yup.string().required(t('required_field')),
        backendVersion: Yup.string().required(t('required_field')),
        blockExplorerInstanceType: Yup.string().required(t('required_field')) as any,
        reCaptchaClientKey: Yup.string().when('reCaptchaSecretKey', {
          is: (exists) => !!exists,
          then: Yup.string().required(t('required_field')),
          otherwise: Yup.string().notRequired(),
        }),
        reCaptchaSecretKey: Yup.string().when('reCaptchaClientKey', {
          is: (exists) => !!exists,
          then: Yup.string().required(t('required_field')),
          otherwise: Yup.string(),
        }),
        rpcUrl: Yup.string().url().notRequired(),
        walletConnectProjectId: Yup.string().notRequired(),
      },
      [['reCaptchaClientKey', 'reCaptchaSecretKey']],
    );

    return (
      <>
        <CustomDialog open={open} onClose={onClose} scroll="body">
          <Formik
            initialValues={initialValues}
            validationSchema={validateSchema}
            onSubmit={this.onSubmit}
            render={({ isSubmitting, values }) => (
              <Form>
                <CustomDialogTitle>
                  <div id="member-create-block-explorer-title">{t('create_block_explorer')}</div>
                </CustomDialogTitle>
                <CustomDialogContent>
                  <div>
                    <div className={classes.formSection}>
                      <Grid container>
                        <Grid item md={6} className={classes.gridLeftItem}>
                          <Field name="provider" render={this.providerField} />
                        </Grid>
                        <Grid item md={6} className={classes.gridRightItem}>
                          <Field name="region" render={this.regionField} />
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.formSection}>
                      <Grid container>
                        <Grid
                          item
                          md={6}
                          className={classNames(
                            classes.gridLeftItem,
                            classes.formSectionCommonGrid,
                          )}
                        >
                          <Field
                            name="blockExplorerInstanceType"
                            render={this.blockExplorerInstanceTypeField}
                          />
                        </Grid>
                        <Grid item md={6} className={classes.gridRightItem}>
                          <Field name="backendVersion" render={this.backendVersionField} />
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.advancedBtnArea}>
                      <div className={classes.separateLine}></div>
                      <div
                        id="member-node-add-separator"
                        className={classes.advancedBtn}
                        onClick={this.toggleAdvanced}
                      >
                        <ImgIcon
                          className={classes.advancedIcon}
                          imgUrl={
                            isShowAdvanced
                              ? '/images/icons/minus_ico.png'
                              : '/images/icons/add_ico.png'
                          }
                        />
                        {t('advanced_menu')}
                      </div>
                    </div>

                    {isShowAdvanced && (
                      <>
                        <div className={classes.formSection}>
                          <Grid container>
                            <Grid item md={6} className={classes.gridLeftItem}>
                              <Field name="imageVersion" render={this.imageVersionField} />
                            </Grid>
                            <Grid item md={6} className={classes.gridRightItem}>
                              <Field name="nodeVersion" render={this.nodeVersionField} />
                            </Grid>
                          </Grid>
                        </div>
                        <div className={classes.formSection}>
                          <Grid container>
                            <Grid item md={12}>
                              <Field
                                name="reCaptchaClientKey"
                                render={this.reCaptchaClientKeyField}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        <div className={classes.formSection}>
                          <Grid container>
                            <Grid item md={12}>
                              <Field
                                name="reCaptchaSecretKey"
                                render={this.reCaptchaSecretKeyField}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {semver.gte(
                          values.backendVersion,
                          DEFAULT_MINIMUM_BLOCKSCOUT_VERSION_SUPPORT_UI_V2,
                        ) && (
                          <>
                            <div className={classes.formSection}>
                              <Grid container>
                                <Grid item md={12}>
                                  <Field
                                    name="walletConnectProjectId"
                                    render={this.walletConnectProjectIdField}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.formSection}>
                              <Grid container>
                                <Grid item md={12}>
                                  <Field name="rpcUrl" render={this.rpcEndpointField} />
                                </Grid>
                              </Grid>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </CustomDialogContent>
                <CustomDialogActions>
                  <Button
                    id="member-create-block-explorer-cancel"
                    disabled={isSubmitting}
                    className={classes.leftBtn}
                    onClick={onClose}
                    variant="contained"
                  >
                    {t('cancel')}
                  </Button>
                  <SubmitButton
                    id="member-create-block-explorer-submit"
                    isValid={true}
                    isSubmitting={isSubmitting}
                    label={t('create')}
                    submittingLabel={t('updating')}
                  />
                </CustomDialogActions>
              </Form>
            )}
          />
        </CustomDialog>
        <ConfirmLicenseDialog
          open={openConfirmLicenseDialog}
          onClose={this.onCloseConfirmLicenseDialog}
          estimateDate={estimateDate}
          estimateFee={estimateFee}
          requireAddr={requireAddr}
          requireCard={requireCard}
        ></ConfirmLicenseDialog>
      </>
    );
  }

  private toggleAdvanced = (e) => {
    this.setState({
      isShowAdvanced: !this.state.isShowAdvanced,
    });
  };

  private toggleShowClientKey = () => {
    this.setState({
      showClientKey: !this.state.showClientKey,
    });
  };

  private toggleShowSecretKey = () => {
    this.setState({
      showSecretKey: !this.state.showSecretKey,
    });
  };

  private toggleShowWalletConnectProjectId = () => {
    this.setState({
      showWalletConnectProjectId: !this.state.showWalletConnectProjectId,
    });
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { createBlockExplorer, onClose, cluster, network, accountSeleted } = this.props;
    if (!accountSeleted) {
      return;
    }

    try {
      const result = await createBlockExplorer({
        input: {
          accountUuid: accountSeleted.accountUuid,
          networkUuid: network.networkUuid,
          clusterUuid: cluster.clusterUuid,
          sizeType: values.blockExplorerInstanceType,
          nodeVersion: values.nodeVersion,
          reCaptchaClientKey: values.reCaptchaClientKey || undefined,
          reCaptchaSecretKey: values.reCaptchaSecretKey || undefined,
          backendVersion: values.backendVersion || DEFAULT_VERSION_BLOCKSCOUT_BACKEND,
          ...(semver.gte(
            values.backendVersion,
            DEFAULT_MINIMUM_BLOCKSCOUT_VERSION_SUPPORT_UI_V2,
          ) && {
            frontendVersion: DEFAULT_VERSION_BLOCKSCOUT_FRONTEND,
            rpcUrl: values.rpcUrl || undefined,
            walletConnectProjectId: values.walletConnectProjectId || undefined,
          }),
        },
      });

      if (!result) {
        // creation failed
        onClose();
        return;
      }
      const { status, data, execAt } = result.createBlockExplorer;

      // handling charge|register card|creation
      if (status.includes('needCharge')) {
        // update state
        this.setState({
          requireCard: status.includes('needRegister') ? true : false,
          formik: formikActions,
        });

        // check licenses
        const { listInsufficientBlockExplorerLicenses, estimateLicenseFee } = this.props;
        const needs = await listInsufficientBlockExplorerLicenses({
          accountUuid: accountSeleted.accountUuid,
          sizeType: values.blockExplorerInstanceType,
        });

        const params = {
          purchaseDate: execAt,
          licenses: needs.listInsufficientBlockExplorerLicenses.map((l) => ({
            licenseItemId: l.licenseItemId,
            qty: l.qty,
          })),
        };
        const fee = await estimateLicenseFee({
          accountUuid: accountSeleted.accountUuid,
          input: [params],
        });
        this.setState({ estimateDate: execAt });

        if (fee.estimateLicenseFee.estimate.length > 0) {
          this.setState({ estimateFee: fee.estimateLicenseFee });
        }
        this.onOpenConfirmLicenseDialog();
      } else {
        setSubmitting(false);
        onClose(); // unknown status
      }
      onClose();
    } catch (error) {
      setSubmitting(false);
    }
  };

  private onOpenConfirmLicenseDialog = () => {
    this.setState({
      openConfirmLicenseDialog: true,
    });
  };

  private onCloseConfirmLicenseDialog = (status: number = 0) => {
    const { formik, requireCard } = this.state;
    this.setState({
      openConfirmLicenseDialog: false,
      formik: void 0,
    });

    if (requireCard && status > 0) {
      this.setState({ requireCard: false });
    }

    if (formik) {
      if (status === 2) {
        // if purchase successed, then start creation (auto submit)
        const { accountSeleted, listActiveLicensesSummary, listBillings, listCoupons } = this.props;
        formik.submitForm();

        if (accountSeleted) {
          listBillings({ accountUuid: accountSeleted.accountUuid }).catch((_) => void 0);
          listCoupons({ accountUuid: accountSeleted.accountUuid }).catch((_) => void 0);
          listActiveLicensesSummary({ accountUuid: accountSeleted.accountUuid }).catch(
            (_) => void 0,
          );
        }
      } else {
        formik.setSubmitting(false);
      }
    }
  };

  private imageVersionField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, providers, cluster } = this.props;
    const provider = providers.find((item) => item.value === cluster.provider) || {
      region: [] as IProviderRegion[],
    };
    const region = provider.region.find((item) => item.value === cluster.region) || {
      images: [] as IProviderImage[],
    };

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('os_version')}</div>
          {!!form.errors.imageVersion && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.imageVersion}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-node-add-ver-os"
            valueSelected={field.value}
            placeholder={this.props.t('select_os_version')}
            items={region.images || []}
          />
        </div>
      </>
    );
  };

  private providerField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, cluster, providers } = this.props;
    const provider = providers.find((p) => p.value === (cluster && cluster.provider)) || {
      label: 'N/A',
    };
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('provider')}</div>
        </div>
        <div>
          <CustomInput
            {...field}
            placeholder={this.props.t('provider_name')}
            value={provider.label}
            disabled
          />
        </div>
      </>
    );
  };

  private regionField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, providers, cluster } = this.props;
    const provider = providers.find((p) => p.value === cluster.provider);
    const region = (provider
      ? provider.region.find((r) => r.value === cluster.region)
      : undefined) || { label: 'N/A' };
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('region')}</div>
        </div>
        <div>
          <CustomInput
            {...field}
            placeholder={this.props.t('region_name')}
            value={region.label}
            disabled
          />
        </div>
      </>
    );
  };

  private blockExplorerInstanceTypeField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('block_explorer_instance_type')}</div>
          {!!form.errors.blockExplorerInstanceType && form.touched.blockExplorerInstanceType && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.blockExplorerInstanceType)}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-network-add-block-explorer-instance"
            valueSelected={field.value}
            placeholder={t('select_block_explorer_instance_type')}
            items={blockExplorerSizeOptions}
          />
        </div>
      </>
    );
  };

  private nodeVersionField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('node_version')}</div>
          {!!form.errors.nodeVersion && form.touched.nodeVersion && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.nodeVersion)}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-network-add-ver-node"
            valueSelected={field.value}
            placeholder={t('select_node_version')}
            items={nodeGethSelection}
          />
        </div>
      </>
    );
  };

  private reCaptchaSecretKeyField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    const { showSecretKey } = this.state;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('re_captcha_secret_key')}</div>
          {!!form.errors.reCaptchaSecretKey && form.touched.reCaptchaSecretKey && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.reCaptchaSecretKey)}
            </div>
          )}
        </div>
        <div>
          <CustomTextField
            id="re-captcha-secret-key"
            placeholder={t('input_re_captcha_secret_key')}
            type={showSecretKey ? 'text' : 'password'}
            endAdornmentIconButton={showSecretKey ? <VisibilityIcon /> : <VisibilityOffIcon />}
            onEndAdornmentClick={this.toggleShowSecretKey}
            {...field}
          />
        </div>
      </>
    );
  };

  private reCaptchaClientKeyField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    const { showClientKey } = this.state;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('re_captcha_client_key')}</div>
          {!!form.errors.reCaptchaClientKey && form.touched.reCaptchaClientKey && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.reCaptchaClientKey)}
            </div>
          )}
        </div>
        <div>
          <CustomTextField
            id="re-captcha-site-key"
            placeholder={t('input_re_captcha_client_key')}
            type={showClientKey ? 'text' : 'password'}
            endAdornmentIconButton={showClientKey ? <VisibilityIcon /> : <VisibilityOffIcon />}
            onEndAdornmentClick={this.toggleShowClientKey}
            {...field}
          />
        </div>
      </>
    );
  };

  private rpcEndpointField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classNames(classes.formLabel, classes.rpcEndpoint)}>
            {t('network_RPC_endpoint')}{' '}
            <Tooltip
              title={t('input_network_RPC_endpoint_description')}
              classes={{ tooltip: classes.tooltip }}
            >
              <HelpIcon style={{ marginLeft: 2, fontSize: '12px' }} />
            </Tooltip>
          </div>
          {!!form.errors.rpcUrl && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.rpcUrl}
            </div>
          )}
        </div>
        <div>
          <CustomTextField
            {...field}
            id="rpc-endpoint-field"
            placeholder={t('input_network_RPC_endpoint')}
          />
        </div>
      </>
    );
  };

  private walletConnectProjectIdField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    const { showWalletConnectProjectId } = this.state;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('wallet_connect_project_id')}</div>
          {!!form.errors.walletConnectProjectId && form.touched.walletConnectProjectId && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.walletConnectProjectId)}
            </div>
          )}
        </div>
        <div>
          <CustomTextField
            id="wallet-connect-project-id"
            placeholder={t('input_wallet_connect_project_id')}
            type={showWalletConnectProjectId ? 'text' : 'password'}
            endAdornmentIconButton={
              showWalletConnectProjectId ? <VisibilityIcon /> : <VisibilityOffIcon />
            }
            onEndAdornmentClick={this.toggleShowWalletConnectProjectId}
            {...field}
          />
        </div>
      </>
    );
  };

  private backendVersionField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('block_explorer_version')}</div>
          {!!form.errors.backendVersion && form.touched.backendVersion && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.backendVersion)}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            valueSelected={field.value}
            placeholder={t('select_block_exp_backend_version')}
            items={blockscoutBackendSelection}
          />
        </div>
      </>
    );
  };
}

const styles: StyleRules = {
  root: {},
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  // Advance Menu
  advancedBtnArea: {
    marginTop: 30,
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    height: 24,
  },
  separateLine: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
  advancedBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'inline-block',
    backgroundColor: whiteColor,
    paddingLeft: 10,
    paddingRight: 10,
    ...defaultFont,
    fontSize: 16,
    color: denimColor,
    cursor: 'pointer',
  },
  advancedIcon: {
    marginRight: 5,
    width: 14,
  },
  // submit button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
  rpcEndpoint: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    backgroundColor: 'rgb(20, 26, 31)',
  },
};

const mapStateToProps = (store: IStore): IStateProps => ({
  providers: store.appState.providers || [],
  accountSeleted: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  createBlockExplorer: (args: NetworkActions.MutationToCreateBlockExplorerArgs) =>
    dispatch(NetworkActions.createBlockExplorerAction(args)),
  listInsufficientBlockExplorerLicenses: (
    args: PaymentActions.QueryListInsufficientBlockExplorerLicensesArgs,
  ) => dispatch(PaymentActions.listInsufficientBlockExplorerLicenses(args)),
  estimateLicenseFee: (args: PaymentActions.QueryEstimateLicenseFeeArgs) =>
    dispatch(PaymentActions.estimateLicenseFee(args)),
  listActiveLicensesSummary: (args: PaymentActions.QueryListActiveLicensesSummaryArgs) =>
    dispatch(PaymentActions.listActiveLicensesSummary(args)),
  listBillings: (args: PaymentActions.QueryListBillingsArgs) =>
    dispatch(PaymentActions.listBillings(args)),
  listCoupons: (args: PaymentActions.QueryListCouponsArgs) =>
    dispatch(PaymentActions.listCoupons(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateBlockExplorerDialog)),
);
