import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
// style const
import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  whiteSmokeColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';

export type CustomTextFieldProps = TextFieldProps &
  WithStyles<typeof styles> & {
    hideBorder?: boolean;
    endAdornmentIconButton?: string | JSX.Element;
    endAdornmentIcon?: string | JSX.Element;
    onEndAdornmentClick?: () => void;
    onChangeText?: (value: string, name?: string) => void;
    value?: any;
  };

const CustomTextField = (props: CustomTextFieldProps) => {
  const {
    value,
    variant = 'outlined',
    endAdornmentIconButton,
    endAdornmentIcon,
    hideBorder,
    onEndAdornmentClick,
    onChangeText,
    InputProps,
    classes,
    ...rest
  } = props;

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const inputElement = ref.current;
    if (inputElement) {
      inputElement.value = value || '';
    }
  }, [value]);

  const endAdornment = useMemo(() => {
    if (endAdornmentIconButton)
      return (
        <InputAdornment position="end">
          <IconButton data-testid="endAdornment-button" size="small" onClick={onEndAdornmentClick}>
            {typeof endAdornmentIconButton === 'string' ? (
              <img src={endAdornmentIconButton} alt="endAdornment-button" />
            ) : (
              endAdornmentIconButton
            )}
          </IconButton>
        </InputAdornment>
      );
    if (endAdornmentIcon) {
      return (
        <InputAdornment position="end">
          {typeof endAdornmentIcon === 'string' ? (
            <img src={endAdornmentIcon} alt="endAdornment-button" />
          ) : (
            endAdornmentIcon
          )}
        </InputAdornment>
      );
    }
    return undefined;
  }, [onEndAdornmentClick, endAdornmentIconButton, endAdornmentIcon]);

  const onChage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChangeText) {
        onChangeText(event.target.value, event.target.name);
      }
    },
    [onChangeText],
  );

  return (
    <TextField
      inputRef={ref}
      fullWidth
      variant={variant as any}
      className={classes.textField}
      InputProps={{
        endAdornment,
        ...InputProps,
      }}
      onChange={onChage}
      {...rest}
    />
  );
};

const styles = createStyles({
  textField: {
    '& .MuiInputBase-root': {
      ...defaultFont,
      fontSize: 13,
      '&::placeholder': {
        color: lightSlateGreyColor,
      },
      width: '100%',
      height: 40,
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: whiteSmokeColor,
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: pattensBlueColor,
      '& input': {
        padding: 0,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': { border: 0 },
    '& fieldset': {
      display: 'none',
    },
  },
});

export default withStyles(styles)(CustomTextField);
