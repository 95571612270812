/**
 *
 * Consts definition module
 *
 */

import { env } from '~/env';

/**
 * DEFAULT values
 */

// Node.js
export const NODEJS_ENV_SPECIAL_KEYS = ['NODE_ENV', 'PORT'];

// MongoDB (mongoose)
export const MONGOOSE_CONN_OK_STATUS = [1, 2],
  MONGOOSE_CONN_OPTIONS = {
    useNewUrlParser: true,
    useCreateIndex: true,
    useFindAndModify: false,
  };

// Provider
export const DEFAULT_PROVIDER_NAME = 'amazon',
  DEFAULT_REGION_AMAZON = 'ap-northeast-1',
  DEFAULT_METAURL_AMAZON = 'http://169.254.169.254/latest/meta-data',
  DEFAULT_VPCADDR_AMAZON = '10.0.0.0',
  DEFAULT_VPCMASK_AMAZON = 19,
  DEFAULT_SUBMASK_AMAZON = 22,
  DEFAULT_USERMAX_AMAZON = 100,
  DEFAULT_ACIDLEN_AMAZON = 12,
  DEFAULT_SSMPREFIX_AMAZON = 'app.';

export const CUSTOMTPL_DIVS_ACMAIL_AMAZON = '##########',
  CUSTOMTPL_NOMAL_ACMAIL_AMAZON = `bcc-aws-account+${CUSTOMTPL_DIVS_ACMAIL_AMAZON}@bccloud.net`,
  CUSTOMTPL_NOMAL_ACNAME_AMAZON = 'bccloud-account-';

// Payment
export const MAXIMUM_LICENSE_GB_SIZE = 16000, // 16TB - AWS limitation
  MINIMUM_LICENSE_GB_SIZE = 10,
  DEFAULT_LICENSE_GB_SMALL = 30,
  DEFAULT_BLOCKEXPLR_GB_SIZE = 20,
  DEFAULT_BLOCKEXPLR_SV_SIZE = 'small',
  DEFAULT_CARD_EXPIRE_MONTH = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ],
  LICENSE_TYPE_ID_CLUSTER_S = 10,
  LICENSE_TYPE_ID_CLUSTER_M = 11,
  LICENSE_TYPE_ID_CLUSTER_L = 12,
  LICENSE_TYPE_ID_CLUSTER_MG = 17,
  LICENSE_TYPE_ID_CLUSTER_EN = 13,
  LICENSE_TYPE_ID_NODE_S = 2,
  LICENSE_TYPE_ID_NODE_M = 3,
  LICENSE_TYPE_ID_NODE_L = 4,
  LICENSE_TYPE_ID_NODE_XL = 5,
  LICENSE_TYPE_ID_NODE_BIZ = 16,
  LICENSE_TYPE_ID_NODE_MEGA = 15,
  LICENSE_TYPE_ID_NODE_METAL = 14,
  LICENSE_TYPE_ID_BLOCKEXP_S = 6,
  LICENSE_TYPE_ID_BLOCKEXP_M = 18,
  LICENSE_TYPE_ID_STORAGE = 7,
  LICENSE_TYPE_ID_ENTERPRISE = 8,
  LICENSE_NAME_CLUSTER = 'Cluster License',
  PAYMENT_TYPE_RESULT_OK = 1,
  PAYMENT_TYPE_RESULT_FAIL = 0,
  PAYMENT_TYPE_RESULT_RETRY = -1,
  PAYMENT_TYPE_CHARGE_CARD = 1,
  PAYMENT_TYPE_CHARGE_COUPON = 2,
  PAYMENT_MINIMUM_CHARGE_VAL = 50;

export const CONVERT_PRICE_TO_CURRENCY = 100;

// Cluster
export const DEFAULT_CLUSTER_TYPE = 'small',
  DEFAULT_CLUSTER_NO_NWACL = 'reserved-network-acl',
  DEFAULT_CLUSTER_NO_P2TCACL = 'reserved-p2p-tcp-acl',
  DEFAULT_CLUSTER_NO_P2UDACL = 'reserved-p2p-udp-acl';

// Node
export const DEFAULT_NODE_CACHE_SIZE = 512;

// Ethereum node
export const DEFAULT_PEER_LIMITMAX = 1000,
  DEFAULT_TYPE_NODE_POA = 'geth',
  DEFAULT_MIN_NODES_POA = 8,
  DEFAULT_MIN_BLOCK_INTERVAL = 1,
  UNDER5N_POA_BLOCK_INTERVAL = 5, // Minimum interval for POA network of 'less than 5 nodes'
  DEFAULT_BLOCK_INTERVAL = 5,
  DEFAULT_GAS_LIMIT = 100000000,
  DEFAULT_GAS_PRICE = 1000000000,
  DEFAULT_GAS_PRICE_MAX = 500000000000,
  DEFAULT_VERSION_GETH = '1.11.6',
  DEFAULT_VERSION_INTERNAL_CLEF = '1.11.6',
  DEFAULT_VERSION_BLOCKSCOUT_BACKEND = '6.5.0-gubuild.0',
  DEFAULT_MINIMUM_BLOCKSCOUT_VERSION_SUPPORT_UI_V2 = '5.4.0-gubuild.0',
  DEFAULT_VERSION_BLOCKSCOUT_FRONTEND = '1.30.1-gubuild.0',
  DEFAULT_MIN_NETWORK_ID = 10000,
  DEFAULT_MAX_NETWORK_ID = 2147483647,
  DEFAULT_CHAIN_ID = 1337,
  DEFAULT_CURRENCY_NAME = 'Ether',
  DEFAULT_CURRENCY_SYMBOL = 'ETH',
  DEFAULT_ALLOC_ADDRESS = '',
  DEFAULT_ALLOC_BALANCE = '0x33B2E3C9FD0803CE8000000',
  TAGS_RES_NODE_INSTANCE = [{ key: 'resource-type', value: 'node' }],
  TAGS_RES_BLOCKEXPLORER = [{ key: 'resource-type', value: 'block explorer' }],
  TAGS_RES_LOADBALANCER = [{ key: 'resource-type', value: 'load balancer' }],
  TAGS_RES_SECURITYGROUP = [{ key: 'resource-type', value: 'security group' }],
  APPLIED_EIP155_GETH_VERSION = '1.10.0',
  MIN_GAS_LIMIT = 5000,
  MAX_GAS_LIMIT = Number.MAX_SAFE_INTEGER; // Use max safe int js https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER

export const ALLOC_ADDRESS_LENGTH = 40,
  ALLOC_BALANCE_LENGTH = 64;

// Batch
export const BATCH_DEFAULT_DAYS_OF_NOTICE_MAIL = 5,
  BATCH_DEFAULT_DAYS_OF_STOP_NODE = 7,
  BATCH_DEFAULT_DAYS_OF_REVOKE_NODE = 14,
  BATCH_DEFAULT_DAYS_OF_CLEAN_ALERT = 90,
  BATCH_DEFAULT_DAYS_OF_CLEAN_BACKUP = 30,
  BATCH_DEFAULT_MONTH_OF_CARD_EXPIRE_MAIL = 3,
  BATCH_LASTDAYS_OF_CARD_EXPIRE_MAIL = 3;

// user
export const DEFAULT_COUPON_BALANCE_MAX = 10000000;

// Others
export const EXPAND_ISSTACK_INTERVAL = 7200,
  DEFAULT_ISSTACK_INTERVAL = 7200,
  DEFAULT_REFRESH_INTERVAL = 3300000,
  DEFAULT_POLLING_INTERVAL = 15000,
  SHORTER_POLLING_INTERVAL = 7000,
  DEFAULT_POLLING_BUFFER = 1000,
  DEFAULT_POLLING_MAXRETRY = 40,
  DEFAULT_PROPOSE_INTERVAL = 7500,
  DEFAULT_PROPOSE_MAXRETRY = 20,
  DEFAULT_GENUUID_MAXRETRY = 1000,
  DEFAULT_MAXLEN_NAME = 100,
  DEFAULT_MAXLEN_DESC = 1000,
  DEFAULT_MAXRULE_RESTRICT = 50,
  DEFAULT_FETCH_TIMEOUT = 30000,
  DEFAULT_SELECT_YEARSTART = 2020,
  DEFAULT_VEXPAND_INTERVAL = 361,
  DEFAULT_PROMISE_MAX_CRUD = 10,
  DEFAULT_TRANSLATE_LANG = 'en',
  DEFAULT_SUPPORTED_LANG = [DEFAULT_TRANSLATE_LANG, 'ja'],
  DEFAULT_COUNTRY_CODE = 'JP',
  DEFAULT_EMAIL_SYS_SENDER = 'G.U.Blockchain Cloud <no-reply@bccloud.net>',
  DEFAULT_ENVIRONMENT_PROD = 'production',
  DEFAULT_ENVIRONMENT_DEV = 'development',
  WRITE_CONFLICT_INTERVAL = 1000,
  WRITE_CONFLICT_MAXRETRY = 3,
  CREATENW_PROPOSE_INTERVAL = 5000,
  CREATENW_PROPOSE_MAXRETRY = 60,
  DEFAULT_ONE_DAY = 1,
  DEFAULT_ZERO_DAYS = 0,
  DEFAULT_PROPOSALS_POLLING_INTERVAL = 10000,
  DEFAULT_EPOCH_BLOCK = 30000,
  DEFAULT_MIN_EPOCH_BLOCK = 100,
  DEFAULT_MAX_EPOCH_BLOCK = 1000000;

export const ROLE_OWNER_NETWORK = ['accountOwner'];

// Guest, Member server URL
export const DEFAULT_MEMBER_URL = 'https://www.bccloud.net',
  DEFAULT_GUEST_URL = 'https://app.bccloud.net';

// admin coupon dollar input max
export const DEFAULT_COUPON_BALANCE_ADMIN_MAX = 100000;

// max organization account limit
export const ORG_ACCOUNT_LIMIT = 10;

// summary product
export const PRODUCT_ID = '001',
  PRODUCT_NAME = 'B.C Cloud',
  MAX_CSV_OUTPUT_DATA = 10000,
  MAX_CSV_COUPON_DATA = 10000;

export const POLLING_NETWORK_INTERVAL =
  Number(env.REACT_APP_POLLING_NETWORK_INTERVAL) || DEFAULT_POLLING_INTERVAL;

// tab title concat
export const TAB_TITLE_CONCAT = 'G.U.Blockchain Cloud - ';

export const MASK_ASTERISK = '**********';

// Hard fork list
export const HARD_FORK_CONFIGURATION: Array<{
  label: string;
  value: string;
  nameInGenesis: string;
  order: number;
  incompatibleGeths: Array<string>;
}> = [
  {
    label: 'MuirGlacier',
    value: 'muirGlacier',
    nameInGenesis: 'muirGlacierBlock',
    order: 6,
    incompatibleGeths: [],
  },
  {
    label: 'Berlin',
    value: 'berlin',
    nameInGenesis: 'berlinBlock',
    order: 9,
    incompatibleGeths: ['1.9.25'],
  },
  {
    label: 'London',
    value: 'london',
    nameInGenesis: 'londonBlock',
    order: 10,
    incompatibleGeths: ['1.9.25'],
  },
];

// Invoice, receipt and busisness information
export const TAX_PERCENTAGE = '10%';
export const REGISTRATION_NO = 'T7011001136239';
export const BUSINESS_PHONE = '03-5456-5795';
export const BUSINESS_CONTACT = 'https://www.bccloud.net/contact';
