import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import { Formik, Field, Form, FieldProps, FormikActions, FormikErrors, FieldArray } from 'formik';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import * as Yup from 'yup';
import IPCIDR from 'ip-cidr';
import publicIp from 'public-ip';

import {
  defaultFontBold,
  defaultFont,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  denimColor,
  dimGrayColor,
  whiteSmokeColor,
  romanColor,
} from '~/styles/themes/common-styles/color';
import { oneLineText } from '~/styles/themes/common-styles/misc';
import * as NetworkActions from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';
import {
  ICluster,
  INetwork,
  IRestrictionListExplorer,
  IRestrictionViewRules,
  RestrictionExplorerProtocolType,
} from '~/types/network-types';
import { Account } from '~/types/account-types';
import { displayPortRange } from '~/utilities/render-utils';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInputControlled from './custom-input-controlled';
import CustomSelect from './custom-select';
import SubmitButton from './submit-button';
import LGButton from '~/components/common/lg-button';
import ImgIcon from '~/components/common/img-icon';
import TableHeadCustom from './table-head';
import TableBodyCustom from './table-body';
import TableCellHeadCustom from './table-cell-head';
import TableCellBodyCustom from './table-cell-body';
import TableRowHeadCustom from './table-row-head';
import TableRowBodyCustom from './table-row-body';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
import { explorerProtocolTypeSelection, switchCidrInputType } from '~/types/network-selection';
import {
  VALIDATE_SECURITY_DESC_PATTERN,
  VALIDATE_SECURITY_DESC_LENGTH,
} from '~/constants/validation';

interface IStateProps {
  accountSelected?: Account;
}

interface IDispProps {
  setRules: (
    args: NetworkActions.MutationSetExplorerRestrictionArgs,
  ) => Promise<NetworkActions.SET_EXPLORER_RESTRICTION_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
  network: INetwork;
  cluster: ICluster;
  rules: IRestrictionViewRules[];
}

interface IState {}

class EditExplorerRulesDialog extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { classes, open, onClose, rules, t } = this.props;
    const initialValues: FormValues = {
      rules:
        rules.length > 0
          ? rules.map((r) => ({
              ...r,
              protocol: r.protocol as RestrictionExplorerProtocolType,
              cidrType: 'custom',
            }))
          : [],
    };

    const validateSchema = Yup.object().shape({
      rules: Yup.array().of(
        Yup.object().shape({
          protocol: Yup.string()
            .required(t('required_field'))
            .oneOf(
              explorerProtocolTypeSelection.map((sel) => sel.value),
              t('invalid_protocol_type'),
            ),
          cidr: Yup.string()
            .required(t('required_field'))
            .test('cidr-v4-check', t('invalid_ipv4_cidr_format'), function (cidrV4) {
              const addr = new IPCIDR(cidrV4);
              return addr.isValid();
            }),
          desc: Yup.string()
            .max(
              VALIDATE_SECURITY_DESC_LENGTH,
              t('validate_maximum_length', { val: VALIDATE_SECURITY_DESC_LENGTH }),
            )
            .matches(
              VALIDATE_SECURITY_DESC_PATTERN,
              t('desc_of_secvurity_allows_alpha_numeric_symbol'),
            ),
        }),
      ),
    });

    return (
      <CustomDialog
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={this.onSubmit}
          render={({ values, errors, isValid, isSubmitting, handleReset, setValues }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-explorer-security-title">{this.props.t('edit_inbound_rules')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <Table>
                  <colgroup>
                    <col width="140px" />
                    <col width="110px" />
                    <col width="110px" />
                    <col width="300px" />
                    <col width="auto" />
                  </colgroup>
                  <TableHeadCustom>
                    <TableRowHeadCustom>
                      <TableCellHeadCustom>
                        <span>{this.props.t('type')}</span>
                      </TableCellHeadCustom>
                      <TableCellHeadCustom>
                        <span>{this.props.t('protocol')}</span>
                      </TableCellHeadCustom>
                      <TableCellHeadCustom>
                        <span>{this.props.t('port_range')}</span>
                      </TableCellHeadCustom>
                      <TableCellHeadCustom>
                        <span>{this.props.t('source')}</span>
                      </TableCellHeadCustom>
                      <TableCellHeadCustom>
                        <span>{this.props.t('description')}</span>
                      </TableCellHeadCustom>
                    </TableRowHeadCustom>
                  </TableHeadCustom>
                  <FieldArray
                    name="rules"
                    render={(arrayHelpers) => {
                      return (
                        <TableBodyCustom>
                          {values.rules.length > 0 &&
                            values.rules.map((rule, i) => {
                              const error = errors.rules && errors.rules[i];
                              const { protocol, range } = displayPortRange(rule.protocol);
                              return (
                                <TableRowBodyCustom key={i}>
                                  <TableCellBodyCustom className={classes.tableProtocolColumn}>
                                    {this.renderErrorArea(error, 'protocol')}
                                    <Field
                                      name={`rules.${i}.protocol`}
                                      render={this.ruleProtocolTypeField}
                                    />
                                  </TableCellBodyCustom>
                                  <TableCellBodyCustom>
                                    <div id="member-explorer-security-rule-protocol">
                                      {protocol}
                                    </div>
                                  </TableCellBodyCustom>
                                  <TableCellBodyCustom>
                                    <div id="member-explorer-security-rule-range">{range}</div>
                                  </TableCellBodyCustom>
                                  <TableCellBodyCustom className={classes.tableCidrColumn}>
                                    {this.renderErrorArea(error, 'cidr')}
                                    <div>
                                      <Grid container>
                                        <Grid item md={6} className={classes.gridLeftItem}>
                                          {this.renderSwitchCidrInput(arrayHelpers, values, i)}
                                        </Grid>
                                        <Grid item md={6} className={classes.gridRightItem}>
                                          <Field
                                            name={`rules.${i}.cidr`}
                                            render={this.ruleCidrField}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </TableCellBodyCustom>
                                  <TableCellBodyCustom>
                                    {this.renderErrorArea(error, 'desc')}
                                    <div>
                                      <Grid container>
                                        <Grid item md={10} className={classes.gridLeftItem}>
                                          <Field
                                            name={`rules.${i}.desc`}
                                            render={this.ruleDescriptionField}
                                          />
                                        </Grid>
                                        <Grid item md={2} className={classes.gridRightItem}>
                                          <div>
                                            <IconButton
                                              id="member-explorer-security-rule-remove"
                                              className={classes.closeBtn}
                                              onClick={() => arrayHelpers.remove(i)}
                                            >
                                              <ImgIcon
                                                className={classes.closeIcon}
                                                imgUrl="/images/icons/alert_error_ico.png"
                                              />
                                            </IconButton>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </TableCellBodyCustom>
                                </TableRowBodyCustom>
                              );
                            })}
                        </TableBodyCustom>
                      );
                    }}
                  />
                </Table>
              </CustomDialogContent>
              <CustomDialogActions>
                <LGButton
                  classes={{ root: classes.addRuleBtn }}
                  onClick={() => this.onAddItem(setValues, values)}
                >
                  <div>
                    <ImgIcon className={classes.addRuleIcon} imgUrl={`/images/icons/add_ico.png`} />
                    <span className={classes.addRuleItem}>{t('add_rule')}</span>
                  </div>
                </LGButton>
                <Button
                  className={classes.leftBtn}
                  onClick={() => this.onCancel(handleReset, onClose)}
                  variant="contained"
                >
                  {this.props.t('cancel')}
                </Button>
                <SubmitButton
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  label={this.props.t('update')}
                  submittingLabel={this.props.t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { setRules, onClose, cluster, network, accountSelected } = this.props;

    if (!accountSelected) {
      return;
    }

    try {
      console.log(values);
      const ruleMap: { [key: string]: IRestrictionListExplorer } = {};

      for (const rule of values.rules) {
        if (ruleMap[rule.protocol]) {
          ruleMap[rule.protocol].inbound.push({
            cidr: rule.cidr,
            desc: rule.desc || '',
          });
        } else {
          ruleMap[rule.protocol] = {
            protocol: rule.protocol,
            inbound: [
              {
                cidr: rule.cidr,
                desc: rule.desc || '',
              },
            ],
          };
        }
      }

      await setRules({
        input: {
          accountUuid: accountSelected.accountUuid,
          networkUuid: network.networkUuid,
          clusterUuid: cluster.clusterUuid,
          rules: Object.entries(ruleMap).map(([_, v]) => v),
        },
      });
      onClose();
    } catch (err) {
      setSubmitting(false);
    }
  };

  private onCancel = (handleReset: () => void, onClose: () => void) => {
    handleReset();
    onClose();
  };

  private onAddItem = (setValues: (values: FormValues) => void, values: FormValues) => {
    values.rules.push({
      protocol: 'HTTP',
      cidrType: 'custom',
      cidr: '',
      desc: '',
    });
    setValues(values);
  };

  private ruleProtocolTypeField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <div>
        <CustomSelect
          {...field}
          id="member-explorer-security-rule-type"
          placeholder="Select protocol"
          valueSelected={field.value}
          items={explorerProtocolTypeSelection}
        />
      </div>
    );
  };

  private ruleCidrField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <div>
        <CustomInputControlled
          {...field}
          id="member-explorer-security-rule-address"
          placeholder=""
        />
      </div>
    );
  };

  private ruleDescriptionField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <div>
        <CustomInputControlled
          {...field}
          id="member-explorer-security-rule-description"
          placeholder=""
        />
      </div>
    );
  };

  private renderErrorArea = (err: FormikErrors<FormValuesRule> | undefined, column: string) => {
    const { classes } = this.props;
    return (
      <div className={classes.formLabelLine}>
        {err && err[column] && (
          <div className={classNames(classes.formLabel, classes.formError)}>{err[column]}</div>
        )}
      </div>
    );
  };

  private renderSwitchCidrInput = (helper: any, values: FormValues, index: number) => {
    return (
      <div>
        <CustomSelect
          id="member-explorer-security-rule-addr-type"
          placeholder="Select CIDR input type"
          items={switchCidrInputType}
          valueSelected={values.rules[index].cidrType || switchCidrInputType[0].value}
          onChange={async (e: any) => {
            if (values.rules[index]) {
              const newValue = await this.onSwitchCidrValue(e);
              const result = {
                ...values.rules[index],
                cidr: newValue || values.rules[index].cidr,
                cidrType: e.target.value,
              };
              helper.replace(index, result);
            }
          }}
        />
      </div>
    );
  };

  private onSwitchCidrValue = async (e: any) => {
    const { target } = e;
    let result = '';

    switch (target.value) {
      case 'myip':
        result = `${await publicIp.v4()}/32`;
        break;
      case 'anywhere':
        result = '0.0.0.0/0';
        break;
      case 'custom':
      default:
        break;
    }
    return result;
  };
}

const styles: StyleRules = {
  root: {},
  dialogPaper: {
    maxWidth: 1240,
  },
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  // submit button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
  addRuleBtn: {
    width: 150,
    height: 34,
    position: 'absolute',
    left: 90,
  },
  addRuleIcon: {
    verticalAlign: 'middle',
  },
  addRuleItem: {
    ...defaultFontMedium,
    ...oneLineText,
    fontSize: 15,
    marginRight: 10,
    marginLeft: 8,
    verticalAlign: 'middle',
  },
  closeIcon: {
    width: 16,
    height: 16,
  },
  closeBtn: {},
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  tableProtocolColumn: {
    minWidth: 70,
  },
  tableCidrColumn: {
    minWidth: 160,
  },
};

const mapStateToProps = (store: IStore): IStateProps => ({
  accountSelected: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  setRules: (args: NetworkActions.MutationSetExplorerRestrictionArgs) =>
    dispatch(NetworkActions.setExplorerRestriction(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditExplorerRulesDialog)),
);

type FormValuesRule = {
  protocol: RestrictionExplorerProtocolType;
  cidrType: string;
  cidr: string;
  desc: string;
};

type FormValues = {
  rules: FormValuesRule[];
};
