import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as AppActions from '~/stores/actions/app-action';
// Localization
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
// Components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import ImgIcon from '~/components/common/img-icon';
import LGButton from '~/components/common/lg-button';
import LoadingIcon from '~/components/common/loading-icon';
import AddAccountMemberDialog from './add-account-member-dialog';
import MemberTableRow from './member-table-row';
// Types
import { IAccountMember, Account, AccountRoleType } from '~/types/account-types';
// Mixs

interface IStateProps {
  account?: Account;
  accountMembers?: Array<IAccountMember>;
  listMembersLoading: boolean;
  selectedAccountRole?: AccountRoleType;
}

interface IDispProps {
  listMembers: (
    args: AppActions.QueryToListMembersArgs,
  ) => Promise<AppActions.LIST_MEMBERS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {}

interface IState {
  openAddAccountMemberDialog: boolean;
}

class MemberTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      openAddAccountMemberDialog: false,
    };
  }

  componentDidMount() {
    const { accountMembers } = this.props;

    if (!accountMembers) {
      this.listMembers();
    }
  }

  listMembers = () => {
    const { listMembers, account } = this.props;
    account && listMembers({ accountUuid: account.accountUuid }).catch(console.log);
  };

  render() {
    const { classes, accountMembers, listMembersLoading, selectedAccountRole } = this.props;
    const { openAddAccountMemberDialog } = this.state;
    const addable = selectedAccountRole === 'owner' || selectedAccountRole === 'admin';

    if (listMembersLoading) {
      return (
        <div className={classes.loadingArea}>
          <LoadingIcon />
        </div>
      );
    }

    return (
      <>
        <AddAccountMemberDialog
          open={openAddAccountMemberDialog}
          onClose={this.onCloseAddAccountMemberDialog}
        />
        <div className={classes.membersTabsContent}>
          <Table id="member-account-general-detail">
            <TableBody>
              {(accountMembers || [])
                .sort((a, b) => Number(b.hasProfile) - Number(a.hasProfile))
                .map((member) => (
                  <MemberTableRow key={member.uid} member={member} />
                ))}
            </TableBody>
          </Table>
        </div>
        <div className={classes.membersTabBottom}>
          <LGButton
            id="member-account-general-action"
            classes={{ root: classes.createMemberBtn }}
            onClick={this.onOpenAddAccountMemberDialog}
            disabled={!addable}
          >
            <ImgIcon className={classes.addMemberIcon} imgUrl="/images/icons/add_ico.png" />
            <span>
              <Trans i18nKey={'add_member'} />
            </span>
          </LGButton>
        </div>
      </>
    );
  }

  private onOpenAddAccountMemberDialog = () => {
    this.setState({
      openAddAccountMemberDialog: true,
    });
  };

  private onCloseAddAccountMemberDialog = () => {
    this.setState({
      openAddAccountMemberDialog: false,
    });
  };
}

const styles = createStyles({
  loadingArea: {
    marginTop: 80,
    textAlign: 'center',
  },
  membersTabsContent: {
    flex: 1,
    overflowY: 'auto',
  },
  membersTabBottom: {
    marginTop: 25,
    textAlign: 'right',
  },
  createMemberBtn: {
    width: 'auto',
  },
  addMemberIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  accountMembers: store.appState.accountMembers,
  account: store.appState.accountSeleted,
  listMembersLoading: AppActions.listMembers.isPending(store),
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  listMembers: (args: AppActions.QueryToListMembersArgs) => dispatch(AppActions.listMembers(args)),
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MemberTab)),
);
