import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as AppActions from '~/stores/actions/app-action';
// Component
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import CustomDialog from '../../../custom-dialog';
import CustomDialogTitle from '../../../custom-dialog-title';
import CustomDialogContent from '../../../custom-dialog-content';
import CustomDialogActions from '../../../custom-dialog-actions';
import CustomSelect from '../../../custom-select';
import SubmitButton from '../../../submit-button';
// Form
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import classNames from 'classnames';
import { defaultFont, defaultFontBold } from '~/styles/themes/common-styles/font';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  pattensBlueColor,
  snowColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';
// Type
import { IAccountMember, AccountRoleType, Profile, Account } from '~/types/account-types';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';

interface IStateProps {
  profile?: Profile;
  account?: Account;
  selectedAccountRole?: AccountRoleType;
}

interface IDispProps {
  updateMember: (
    args: AppActions.MutationUpdateMemberArgs,
  ) => Promise<AppActions.EDIT_MEMBER_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  member: IAccountMember;
  onClose: () => void;
}

interface IState {}

class EditUserDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { classes, open, onClose, member, t } = this.props;

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <Formik
          initialValues={{
            accountRole: this.props.member.role,
          }}
          validationSchema={validateSchema}
          onSubmit={this.onSubmit}
          enableReinitialize
          render={({ isValid, isSubmitting }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-account-members-edit-title">{t('edit_member')}</div>
              </CustomDialogTitle>
              {/* Render Form Field */}
              <CustomDialogContent>
                <div className={classes.infoArea} id="member-account-members-edit-card">
                  <Avatar className={classes.avatar} src="/images/icons/avatar_ico.svg" />
                  <div className={classes.userInfo}>
                    <div className={classes.infoItem}>
                      <div className={classes.subTitle}>{this.props.t('member_name')}</div>
                      <div className={classes.detailValue}>{member.displayName}</div>
                    </div>
                    <div className={classes.infoItem}>
                      <div className={classes.subTitle}>{this.props.t('email')}</div>
                      <div className={classes.detailValue}>{member.email}</div>
                    </div>
                  </div>
                </div>
                <div className={classes.formSection} title="account-role-field">
                  <Field name="accountRole" render={this.accountRoleField} />
                </div>
              </CustomDialogContent>
              {/* Render Submit Button */}
              <CustomDialogActions>
                <Button
                  id="member-account-members-edit-cancel"
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={onClose}
                >
                  {this.props.t('cancel')}
                </Button>
                <SubmitButton
                  id="member-account-members-edit-submit"
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  label={this.props.t('update')}
                  submittingLabel={this.props.t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private accountRoleField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, selectedAccountRole } = this.props;
    const options: { value: AccountRoleType; label: string }[] =
      selectedAccountRole === 'owner'
        ? [
            { value: 'owner', label: 'Owner' },
            { value: 'admin', label: 'Admin' },
            { value: 'member', label: 'Member' },
            { value: 'guest', label: 'Guest' },
          ]
        : selectedAccountRole === 'admin'
        ? [
            { value: 'member', label: 'Member' },
            { value: 'guest', label: 'Guest' },
          ]
        : [];

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('organization_role')}</div>
          {!!form.errors.accountRole && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.accountRole}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-account-members-edit-select"
            valueSelected={field.value}
            placeholder={this.props.t('select_role')}
            items={options}
          />
        </div>
      </>
    );
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { onClose, updateMember, account, member, profile } = this.props;

    if (!account) {
      return;
    }

    try {
      await updateMember({
        input: {
          accountUuid: account.accountUuid,
          uid: member.uid,
          accountRole: values.accountRole as AccountRoleType,
        },
      });
      onClose();

      // if change yourself then must to reload all data
      if (profile && profile.uid === member.uid && window) {
        window.location.href = '/';
      }
    } catch (error) {
      setSubmitting(false);
    }
  };
}

const styles = createStyles({
  dialogPaper: {
    maxWidth: 620,
  },
  // Form Field
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontBold,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  // Submit Button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  infoArea: {
    borderRadius: 4,
    border: `solid 1px ${pattensBlueColor}`,
    backgroundColor: snowColor,
    display: 'flex',
  },
  avatar: {
    width: 100,
    height: 100,
    marginTop: 30,
    marginLeft: 30,
    marginBottom: 30,
  },
  userInfo: {
    paddingLeft: 40,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  subTitle: {
    ...defaultFontBold,
    fontSize: 12,
    paddingBottom: 2,
  },
  infoItem: {
    marginTop: 10,
    marginBottom: 6,
  },
  detailValue: {
    ...defaultFont,
    fontSize: 14,
    color: lightSlateGreyColor,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  profile: store.appState.profile,
  account: store.appState.accountSeleted,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
});

const mapDispatchToProps = (dispatch) => ({
  updateMember: (args: AppActions.MutationUpdateMemberArgs) =>
    dispatch(AppActions.updateMember(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditUserDialog)),
);

type FormValues = {
  accountRole: string;
};

const validateSchema = Yup.object().shape<FormValues>({
  accountRole: Yup.string().required('Required'),
});
