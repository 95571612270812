import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Redux
import { IStore } from '~/stores/configure-store';
// Route
import { RouteComponentProps, withRouter } from 'react-router-dom';
// Component
import ContentContainerView from '../../common/ContentContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import LGButton from '~/components/common/lg-button';
import ImgIcon from '~/components/common/img-icon';
import TableHeadCustom from '~/components/common/table-head';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableRowHeadCustom from '~/components/common/table-row-head';
import GrantAccessDialog from './grant-access-dialog';
import LoadingIcon from '~/components/common/loading-icon';
import AccessTableRow from './access-table-row';
import SortIcon from '~/components/common/sort-icon';

// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
// Type
import { INetwork, INetworkAccess } from '~/types/network-types';
import { Account, AccountRoleType, NetworkRoleType } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Utils
import { Order, getComparator, stableSort, HeadCell } from '~/utilities/sort-utils';
import * as NetworkActions from '~/stores/actions/network-action';
// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';

interface IStateProps {
  networkSelected?: INetwork;
  accountSelected?: Account;
  networkAccesses?: Array<INetworkAccess>;
  listNetworkAccessesLoading: boolean;
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
}

interface IDispProps {
  listNetworkAccesses: (
    args: NetworkActions.QueryListNetworkAccessesArgs,
  ) => Promise<NetworkActions.LIST_NETWORK_ACCESSES_RESULT_TYPE>;
}

interface IProps
  extends IStateProps,
    IDispProps,
    WithStyles<typeof styles>,
    RouteComponentProps<{}>,
    WithTranslation {}

interface IState {
  openCreateUserDialog: boolean;
  order: Order;
  orderBy: SortableHeadCellId;
}

class Access extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openCreateUserDialog: false,
      order: 'ASC',
      orderBy: 'createAt',
    };
  }

  componentDidMount() {
    const { networkAccesses, networkSelected, history } = this.props;
    if (!networkSelected) {
      history.push(`/`);
    }
    if (!networkAccesses) {
      this.listNetworkAccesses();
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      (prevProps.networkSelected && prevProps.networkSelected.networkUuid) !==
        (this.props.networkSelected && this.props.networkSelected.networkUuid) ||
      (prevProps.accountSelected && prevProps.accountSelected.accountUuid) !==
        (this.props.accountSelected && this.props.accountSelected.accountUuid)
    ) {
      this.listNetworkAccesses();
    }
  }

  listNetworkAccesses = () => {
    const { accountSelected, networkSelected, listNetworkAccesses } = this.props;
    accountSelected &&
      networkSelected &&
      listNetworkAccesses({
        networkUuid: networkSelected.networkUuid,
        accountUuid: accountSelected.accountUuid,
      }).catch(console.log);
  };

  public render() {
    const { networkSelected, classes, t, selectedAccountRole, selectedNetworkRole } = this.props;
    const { openCreateUserDialog } = this.state;
    const grantable =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin';

    if (networkSelected === undefined) {
      return null;
    }

    document.title = TAB_TITLE_CONCAT + this.props.t('member_access');

    return (
      <ContentContainerView
        title={this.props.t('access')}
        titleId="member-access-title"
        routes={[
          {
            name: `${this.props.t('network')}: ${networkSelected.networkName}`,
            url: `/network/${networkSelected.networkUuid}/overview`,
          },
          { name: this.props.t('access'), url: `/access` },
        ]}
      >
        <div className={classes.root}>
          {/* Render Dialog */}
          <GrantAccessDialog open={openCreateUserDialog} onClose={this.onCloseCreateUserDialog} />
          {/* Render Top Line */}
          <div className={classes.topLine}>
            <LGButton
              id="member-access-button"
              classes={{ root: classes.createUserBtn }}
              onClick={this.onOpenCreateUserDialog}
              disabled={!grantable}
            >
              <ImgIcon className={classes.addUserIcon} imgUrl="/images/icons/add_ico.png" />
              <span>{this.props.t('grant_access')}</span>
            </LGButton>
          </div>
          {/* Render Network User Info Table */}
          <div className={classes.tableArea}>{this.accessTable}</div>
        </div>
      </ContentContainerView>
    );
  }

  get accessTable() {
    const { classes, t, networkAccesses, listNetworkAccessesLoading } = this.props;
    const { order, orderBy } = this.state;

    if (networkAccesses === undefined || listNetworkAccessesLoading) {
      return (
        <div className={classes.loadingArea}>
          <LoadingIcon />
        </div>
      );
    }

    const items = this.getItems();

    return (
      <Table id="member-access-detail" className={classes.tblList}>
        <colgroup>
          <col width="auto" />
          <col width="auto" />
          <col width="140px" />
          <col width="16%" />
          <col width="16%" />
          <col width="150px" />
        </colgroup>
        <TableHeadCustom>
          <TableRowHeadCustom>
            {headCells.map((headCell) => (
              <TableCellHeadCustom
                key={headCell.id}
                onClick={headCell.sortable ? this.onTableCellHeadClick(headCell.id) : undefined}
                style={{ cursor: headCell.sortable ? 'pointer' : 'unset' }}
                classes={{ content: classes.tableCellHeadContent }}
              >
                <span>{t(headCell.label)}</span>
                {headCell.sortable && (
                  <SortIcon order={orderBy === headCell.id ? order : undefined} />
                )}
              </TableCellHeadCustom>
            ))}
          </TableRowHeadCustom>
        </TableHeadCustom>
        <TableBody>
          {items.map((item) => (
            <AccessTableRow key={item.uid} item={item} />
          ))}
        </TableBody>
      </Table>
    );
  }

  private onOpenCreateUserDialog = () => {
    this.setState({
      openCreateUserDialog: true,
    });
  };

  private onCloseCreateUserDialog = () => {
    this.setState({
      openCreateUserDialog: false,
    });
  };

  private getItems = (): Array<Item> => {
    const { networkAccesses } = this.props;
    const { order, orderBy } = this.state;

    const items: Array<Item> =
      (networkAccesses &&
        networkAccesses.map((user) => ({
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          hasProfile: user.hasProfile,
          role: `${user.accountRole ? `Account ${user.accountRole}\n` : ''}${
            user.networkRole ? `Network ${user.networkRole}` : ''
          }`,
          createAt: user.createAt ? new Date(user.createAt) : undefined,
          lastLoginAt: user.lastloginAt ? new Date(user.lastloginAt) : undefined,
          networkAccess: user,
        }))) ||
      [];

    return stableSort<Item>(items, getComparator<SortableHeadCellId>(order, orderBy));
  };

  private onTableCellHeadClick = (id: SortableHeadCellId) => () => {
    const { order, orderBy } = this.state;
    this.setState({
      orderBy: id,
      order: orderBy === id && order === 'ASC' ? 'DESC' : 'ASC',
    });
  };
}

const styles = (theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    loadingArea: {
      marginTop: 100,
      textAlign: 'center',
    },
    // Top Line
    topLine: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
      marginTop: 17,
    },
    createUserBtn: {
      width: 'auto',
    },
    addUserIcon: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    // Network User Info Table
    tableArea: {
      marginTop: 20,
    },
    tableCellHeadContent: {
      paddingRight: 20,
    },
    tblList: {},
    tableBodyTd: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      tableArea: {
        overflow: 'auto',
      },
      tblList: {
        width: 670,
      },
      tableBodyTd: {
        padding: 0,
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  networkSelected: store.appState.networkSelected,
  accountSelected: store.appState.accountSeleted,
  networkAccesses: store.appState.networkAccesses,
  listNetworkAccessesLoading: NetworkActions.listNetworkAccesses.isPending(store),
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  listNetworkAccesses: (args: NetworkActions.QueryListNetworkAccessesArgs) =>
    dispatch(NetworkActions.listNetworkAccesses(args)),
});

export default compose<React.ClassicComponentClass>(
  withRouter,
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Access);

export type Item = {
  uid: string;
  displayName: string;
  hasProfile: boolean;
  email: string;
  role: string;
  createAt?: Date;
  lastLoginAt?: Date;
  networkAccess: INetworkAccess;
};
type SortableHeadCellId = keyof Omit<Item, 'uid' | 'email' | 'networkAccess' | 'hasProfile'>;
type UnsortableHeadCellId = keyof Pick<Item, 'email'> | 'actions';
const headCells: Array<HeadCell<SortableHeadCellId, UnsortableHeadCellId>> = [
  { id: 'displayName', sortable: true, label: 'name' },
  { id: 'email', sortable: false, label: 'email' },
  { id: 'role', sortable: true, label: 'role' },
  { id: 'createAt', sortable: true, label: 'create_date' },
  { id: 'lastLoginAt', sortable: true, label: 'last_login' },
  { id: 'actions', sortable: false, label: 'actions' },
];
