import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import { defaultFont } from '~/styles/themes/common-styles/font';

// Component
import TruncateWalletAddress from '~/components/common/truncate-wallet-address';
import { Link } from 'react-router-dom';

import { denimColor, persianGreenColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import { INetwork } from '~/types/network-types';
import { Account, ProposalAddressInfo } from '~/gapi/gtypes';
import SubmitButton from '~/components/common/submit-button';

interface IProps extends WithStyles<typeof styles> {
  network: INetwork;
  account: Account;
  nodeAddress: ProposalAddressInfo;
  disabledApproveProposal?: boolean;
  isApproved?: boolean;
  onApproveProposal?: (nodeUuid?: string) => void;
}

const ChainProposalDetailRow = (props: IProps) => {
  const {
    classes,
    network,
    account,
    nodeAddress,
    isApproved,
    disabledApproveProposal,
    onApproveProposal,
  } = props;

  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  const enableApproveProposal = useMemo(() => {
    const isNodeOwner = account.accountUuid === nodeAddress?.node?.accountUuid;
    return (
      isNodeOwner &&
      (account.role === 'owner' ||
        account.role === 'admin' ||
        network?.role === 'owner' ||
        network?.role === 'admin')
    );
  }, [account.accountUuid, account.role, network?.role, nodeAddress?.node?.accountUuid]);

  const handleApproveProposal = useCallback(
    async (nodeUuid?: string) => {
      try {
        setSubmitting(true);
        if (onApproveProposal) {
          await onApproveProposal(nodeUuid);
        }
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    },
    [onApproveProposal],
  );

  return (
    <div className={classes.chainProposalItem} key={nodeAddress.address}>
      <div className={classes.targetAddress}>
        <TruncateWalletAddress
          classes={{ endPoint: classes.endPoint }}
          address={nodeAddress.address}
          enableCopyBtn
        />
        {nodeAddress?.node ? (
          <Link
            className={classNames(classes.nodeLinkText, classes.tableBodyTdState)}
            to={`/network/${network.networkUuid}/cluster/${nodeAddress?.node.clusterUuid}/node/${nodeAddress?.node.nodeUuid}/overview`}
          >
            {`${nodeAddress?.node.nodeName} (${nodeAddress?.node.clusterName})`}
          </Link>
        ) : (
          <div className={classes.externalNode}>Node External</div>
        )}
      </div>
      {nodeAddress?.node && (
        <div>
          {nodeAddress?.node?.accountUuid === account.accountUuid
            ? t('my_node')
            : `${network.networkName}'s Node`}
        </div>
      )}
      <div className={classes.actionBtn}>
        {isApproved
          ? t('approved')
          : enableApproveProposal && (
              <SubmitButton
                id="approve-all-btn"
                kind="flat"
                classes={{ root: classes.approveBtn }}
                isValid={!disabledApproveProposal}
                isSubmitting={isSubmitting}
                label={t('approve')}
                onClick={(e) => handleApproveProposal(nodeAddress?.node?.nodeUuid)}
              />
            )}
      </div>
    </div>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    endPoint: {
      display: 'flex',
      minWidth: 200,
    },
    nodeLinkText: {
      color: denimColor,
    },
    tableBodyTdState: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    externalNode: {
      color: 'gray',
    },
    chainProposalItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      fontSize: 14,
      height: 34,
      '&:not(:first-child)': {
        marginTop: 5,
      },
    },
    actionBtn: {
      width: 100,
      display: 'flex',
      justifyContent: 'center',
    },
    approveBtn: {
      width: 'auto',
      paddingLeft: 15,
      paddingRight: 15,
      height: 32,
      ...defaultFont,
      fontSize: 13,
      color: 'white',
      borderRadius: 4,
      backgroundColor: persianGreenColor,
      '&:hover': {
        backgroundColor: persianGreenColor,
      },
      '&:disabled': {
        backgroundColor: 'rgb(224,224,224)',
      },
    },
    targetAddress: {
      display: 'flex',
      fontSize: 14,
      width: '45%',
      whiteSpace: 'nowrap',
    },
  });

export default withStyles(styles)(ChainProposalDetailRow);
