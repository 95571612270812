import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { denimColor } from '~/styles/themes/common-styles/color';
import ChainProposalsRow from './chain-proposals';
import PendingProposalRow from './pending-proposals';
import CreateProposalDialog from '~/components/common/ProposalDialog';
import LGButton from '~/components/common/lg-button';
import ImgIcon from '~/components/common/img-icon';
import CustomNumericString from '~/components/common/custom-numeric-string';
// Translation
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
// Defines
import { DEFAULT_EPOCH_BLOCK, TAB_TITLE_CONCAT } from '~/constants/consts';
// Actions
import { useDispatch } from 'react-redux';
import { getLatestBlockInNetwork, listNodesBelongToAccount } from '~/utilities/utils';
import { INetwork } from '~/types/network-types';
import { Account } from '~/types/account-types';
import { LocalNodeProposals, ProposalAddressInfo } from '~/gapi/gtypes';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  network: INetwork;
  account: Account;
}

const ProposalList = (props: IProps) => {
  const { classes, account, network } = props;
  const { t } = useTranslation();
  document.title = TAB_TITLE_CONCAT + t('proposals');

  const [openCreateProposalDialog, setOpenCreateProposalDialog] = useState(false);

  const onOpenCreateProposalDialog = useCallback(() => {
    setOpenCreateProposalDialog(true);
  }, []);

  const onCloseCreateProposalDialog = useCallback(() => {
    setOpenCreateProposalDialog(false);
  }, []);

  const nextEpochBlock = useMemo(() => {
    if (!network) return;
    const latestBlock = getLatestBlockInNetwork(network);
    const epoch = network.blockchainInfo?.epochBlock || DEFAULT_EPOCH_BLOCK;
    return (
      <CustomNumericString value={(epoch * (Math.floor(latestBlock / epoch) + 1)).toString()} />
    );
  }, [network]);

  const enableCreateProposal = useMemo(() => {
    const aliveSignerNodeBelongAccount = listNodesBelongToAccount(account, network, true).filter(
      (node) => node.nodeInfo.status === 'alive' && node.serverInfo.status === 'alive',
    );
    return (
      aliveSignerNodeBelongAccount.length > 0 &&
      (account.role === 'owner' ||
        account.role === 'admin' ||
        network?.role === 'owner' ||
        network?.role === 'admin')
    );
  }, [account, network]);

  const pendingProposals = useMemo(() => {
    const pendingProposals: LocalNodeProposals[] = [];
    const localProposalsInSignerNodes = network.clusters
      .map((c) =>
        c.nodes.filter(
          (n) =>
            n.nodeInfo.signer && n.nodeInfo.status === 'alive' && n.serverInfo.status === 'alive',
        ),
      )
      .reduce((pre, cur) => {
        pre.push(...cur);
        return pre;
      }, [])
      .reduce((pre, cur) => {
        pre.push(...(cur.nodeInfo.localProposals || []));
        return pre;
      }, [] as LocalNodeProposals[]);
    localProposalsInSignerNodes.forEach(({ address, authorize }) => {
      if (
        network.networkSnapshot?.proposals.find(
          (val) => val.target.address === address && val.authorize === authorize,
        )
      ) {
        return;
      }
      if (pendingProposals.find((val) => val.address === address && val.authorize === authorize)) {
        return;
      }
      if (!!network.networkSnapshot?.signers.find((val) => val.address === address) == authorize) {
        return;
      }
      pendingProposals.push({ address, authorize });
    });

    return pendingProposals.sort((a, b) => a.address.localeCompare(b.address));
  }, [network]);

  const recentProposals = useMemo<Array<Item>>(
    () =>
      network.networkSnapshot?.proposals
        .map((proposal) => {
          const remainingNodeAddresses =
            network.networkSnapshot?.signers.filter(
              (signer) => !proposal.approved.some((val) => val.address === signer.address),
            ) ?? [];
          return {
            ...proposal,
            remainingNodeAddresses,
          };
        })
        .sort((a, b) => a.target.address.localeCompare(b.target.address)) ?? [],
    [network.networkSnapshot?.proposals, network.networkSnapshot?.signers],
  );

  return (
    <>
      {account && network && openCreateProposalDialog && (
        <CreateProposalDialog
          open={openCreateProposalDialog}
          account={account}
          network={network}
          scroll="body"
          onClose={onCloseCreateProposalDialog}
        />
      )}
      <div className={classes.topLine}>
        <div>
          {t('next_epoch_block')}: {nextEpochBlock}
        </div>
        <LGButton
          classes={{ root: classes.createClusterBtn }}
          disabled={!enableCreateProposal}
          onClick={onOpenCreateProposalDialog}
        >
          <ImgIcon className={classes.addClusterIcon} imgUrl="/images/icons/add_ico.png" />
          <span>{t('new_proposal')}</span>
        </LGButton>
      </div>
      <div className={classes.tableArea}>
        {pendingProposals.map((item) => (
          <PendingProposalRow key={item.address} proposal={item} />
        ))}
        {recentProposals.map((item) => (
          <ChainProposalsRow
            key={item.target.address}
            account={account}
            network={network}
            item={item}
          />
        ))}
      </div>
    </>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    topLine: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 10,
      marginBottom: 10,
    },
    createClusterBtn: {
      width: 'auto',
      color: '#333',
    },
    addClusterIcon: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    tableArea: {
      marginBottom: 20,
    },
    waitingIcon: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: 14,
      height: 24,
    },
    tableCellHeadContent: {
      paddingRight: 20,
    },
    tableBodyCellCommon: {
      wordBreak: 'break-all',
    },
    nodeStatusIcon: {
      marginRight: 10,
      transform: 'translateY(2px)',
    },
    nodeLinkText: {
      color: denimColor,
    },
    historyLink: {
      color: denimColor,
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    tableCellStatusText: {},
    tblList: {},
    tableBodyTd: {},
    tableBodyTdState: {},
    font13: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      tableArea: {
        overflow: 'auto',
      },
      tblList: {
        width: 920,
      },
      tableBodyTd: {
        padding: 0,
      },
      tableBodyTdState: {
        fontSize: 13,
        color: '#7b90a3',
      },
      font13: {
        fontSize: 13,
      },
    },
    typeCell: {
      width: '10%',
    },
    addressCell: {
      width: '30%',
    },
    statusCell: {
      width: '15%',
    },
    actionCell: {
      width: '10%',
    },
    pagination: {
      marginTop: 30,
      textAlign: 'center',
    },
    loading: {
      margin: 10,
    },
    divider: {
      marginTop: 70,
      marginBottom: 50,
    },
  });

export type Item = {
  authorize: boolean;
  target: ProposalAddressInfo;
  approved: ProposalAddressInfo[];
  remainingNodeAddresses: ProposalAddressInfo[];
};

export default withStyles(styles)(withTranslation()(ProposalList));
