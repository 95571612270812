import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
// Component
import { Link } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import CopyClipboardIcon from '~/components/common/copy-clipboard-icon';
import EditButton from '~/components/common/edit-button';
import OptionButton from '~/components/common/option-button';
import EditNetworkDialog from '~/components/common/edit-network-dialog';
import NetworkInviteDialog from '~/components/common/network-invite-dialog';
import DeleteNetworkDialog from '~/components/common/delete-network-dialog';
import WithdrawConsortimDialog from '../../consortium-tab/withdraw-consortium-dialog';
import NetworkOptionDialog from '~/components/common/network-option-dialog';
import CustomNumericString from '~/components/common/custom-numeric-string';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  defaultFont,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  snowColor,
  denimColor,
} from '~/styles/themes/common-styles/color';
import { oneLineText } from '~/styles/themes/common-styles/misc';
// Type
import { INetwork } from '~/types/network-types';
import { Account, AccountRoleType, NetworkRoleType } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import { getLatestUpdateInNetwork, isConsortiumAdmin } from '~/utilities/utils';

interface IStateProps {
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  network: INetwork;
  account: Account;
}

interface IState {
  openEditNetworkDialog: boolean;
  openNetworkInviteDialog: boolean;
  openDestroyNetworkDialog: boolean;
  openWithdrawConsortiumDialog: boolean;
  openNetworkOptionDialog: boolean;
  networkOptionAnchorEl: HTMLButtonElement | null;
}

class ConsortiumTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      openEditNetworkDialog: false,
      openNetworkInviteDialog: false,
      openDestroyNetworkDialog: false,
      openWithdrawConsortiumDialog: false,
      openNetworkOptionDialog: false,
      networkOptionAnchorEl: null,
    };
  }

  public render() {
    const { classes, t, network, selectedAccountRole, selectedNetworkRole, account } = this.props;
    const updatableNetwork =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin';
    const { openEditNetworkDialog } = this.state;

    const latestUpdate = getLatestUpdateInNetwork(network);

    return (
      <div className={classes.root}>
        {this.networkOptionPopoverRender}
        <EditNetworkDialog
          network={network}
          open={openEditNetworkDialog}
          onClose={this.onCloseEditNetworkDialog}
        />
        <div className={classes.borderBlock}>
          <div className={classes.titleArea}>
            <span className={classes.networkTitle}>{this.props.t('network_information')}</span>
            <div className={classes.handleBtnArea}>
              <div className={classNames(classes.subTitle, classes.networkUUID)}>
                {this.props.t('network_uuid') + ': ' + network.networkUuid}
              </div>
              <CopyClipboardIcon
                classes={{ root: classes.copyButton }}
                textToCopy={network.networkUuid}
              />
              <div className={classes.verticalSeparate}></div>
              {isConsortiumAdmin(account, network) && (
                <>
                  <EditButton
                    id="network-information-edit-button"
                    onClick={this.onOpenEditNetworkDialog}
                    disabled={!updatableNetwork}
                  />
                  <div className={classes.verticalSeparate}></div>
                </>
              )}
              <OptionButton
                id="network-information-pulldown-menu"
                onClick={this.onOpenNetworkOptionPopover}
              />
            </div>
          </div>
          <div className={classes.contentArea}>
            <Grid container spacing={0}>
              <Grid item sm={12} md={6}>
                <div className={classes.networkInfoLeft}>
                  <Grid container spacing={1}>
                    <Grid item sm={12}>
                      <div className={classes.subTitle}>{this.props.t('network_name')}</div>
                      <div className={classes.detailValue}>{network.networkName}</div>
                    </Grid>
                    {network.blockchainInfo?.currencyName && (
                      <Grid item sm={6}>
                        <div className={classes.subTitle}>{this.props.t('currency_name')}</div>
                        <div className={classNames(classes.detailValue)}>
                          {network.blockchainInfo?.currencyName}
                        </div>
                      </Grid>
                    )}
                    {network.blockchainInfo?.currencySymbol && (
                      <Grid item sm={6}>
                        <div className={classes.subTitle}>{this.props.t('currency_symbol')}</div>
                        <div className={classNames(classes.detailValue)}>
                          {network.blockchainInfo?.currencySymbol}
                        </div>
                      </Grid>
                    )}
                    {network.networkDescription && (
                      <Grid item sm={12}>
                        <div className={classes.subTitle}>{this.props.t('description')}</div>
                        <div
                          className={classNames(classes.detailValue, classes.networkDescription)}
                        >
                          {network.networkDescription}
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div className={classes.networkInfoRight}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} md={6} lg={4}>
                      <div className={classes.subTitle}>{this.props.t('network_id')}</div>
                      <div className={classes.detailValue}>{network.networkId}</div>
                    </Grid>
                    <Grid item sm={4} md={6} lg={4}>
                      <div className={classes.subTitle}>{this.props.t('chain_id')}</div>
                      <div className={classes.detailValue}>{network.chainId}</div>
                    </Grid>
                    <Grid item sm={4} md={6} lg={4}>
                      <div className={classes.subTitle}>
                        {this.props.t('number_of_nodes_clusters')}
                      </div>
                      <div className={classes.detailValue}>
                        {network.clusters
                          .map((c) => (c.nodes || []).length)
                          .reduce((pre, cur) => pre + cur, 0)}{' '}
                        / {network.clusters.length}
                      </div>
                    </Grid>
                    <Grid item sm={4} md={6} lg={4}>
                      <div className={classes.subTitle}>{this.props.t('organizations')}</div>
                      <Link to={`/network/${network.networkUuid}/consortium`}>
                        <div className={classNames(classes.detailValue, classes.organizationValue)}>
                          {t('organization_count', { count: network.consortiums.length })}
                        </div>
                      </Link>
                    </Grid>
                    {latestUpdate && (
                      <Grid item sm={4} md={6} lg={4}>
                        <div className={classes.subTitle}>{this.props.t('latest_hard_fork')}</div>
                        <div className={classNames(classes.detailValue)}>
                          <div>
                            {latestUpdate.label}
                            {' - '}
                            <CustomNumericString value={latestUpdate.blockNumber?.toString()} />
                          </div>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }

  private onOpenEditNetworkDialog = () => {
    this.setState({ openEditNetworkDialog: true });
  };
  private onCloseEditNetworkDialog = () => {
    this.setState({ openEditNetworkDialog: false });
  };

  get networkOptionPopoverRender() {
    const { classes, t, network, account, selectedAccountRole, selectedNetworkRole } = this.props;
    const invitableNetwork = selectedAccountRole === 'owner';
    const deletableNetwork = selectedAccountRole === 'owner' || selectedNetworkRole === 'owner';
    const {
      networkOptionAnchorEl,
      openNetworkInviteDialog,
      openDestroyNetworkDialog,
      openWithdrawConsortiumDialog,
      openNetworkOptionDialog,
    } = this.state;
    const consortium = network.consortiums.find(
      (consortium) => consortium.accountUuid === account.accountUuid,
    );

    return (
      <>
        <NetworkInviteDialog
          open={openNetworkInviteDialog}
          onClose={this.onCloseNetworkInviteDialog}
          network={network}
          account={account}
        />
        <DeleteNetworkDialog
          open={openDestroyNetworkDialog}
          onClose={this.onCloseDestroyNetworkDialog}
          network={network}
          account={account}
        />
        {consortium && (
          <WithdrawConsortimDialog
            open={openWithdrawConsortiumDialog}
            onClose={this.onCloseWithdrawConsortiumDialog}
            network={network}
            account={account}
            consortium={consortium}
          />
        )}
        {consortium && (
          <NetworkOptionDialog
            open={openNetworkOptionDialog}
            onClose={this.onCloseNetworkOptionDialog}
            network={network}
            account={account}
            consortium={consortium}
          />
        )}
        <Popover
          open={Boolean(networkOptionAnchorEl)}
          anchorEl={networkOptionAnchorEl}
          onClose={this.onCloseNetworkOptionPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={1}
        >
          <List>
            <ListItem
              button
              className={classes.listOptionItem}
              onClick={this.inviteToNetwork}
              disabled={!invitableNetwork}
            >
              {this.props.t('invite')}
            </ListItem>
            <div className={classes.horizontalSeparate} />
            <ListItem
              button
              className={classes.listOptionItem}
              onClick={this.onOpenWithdrawConsortiumDialog}
              disabled={!invitableNetwork}
            >
              {this.props.t('withdraw')}
            </ListItem>
            {isConsortiumAdmin(account, network) && (
              <>
                <div className={classes.horizontalSeparate} />
                <ListItem
                  button
                  className={classes.listOptionItem}
                  onClick={this.onOpenNetworkOptionDialog}
                  disabled={!invitableNetwork}
                >
                  {this.props.t('option')}
                </ListItem>
                <div className={classes.horizontalSeparate} />
                <ListItem
                  button
                  className={classes.listOptionItem}
                  onClick={this.onOpenDestroyNetworkDialog}
                  disabled={!deletableNetwork}
                >
                  <img className={classes.deleteIcon} src="/images/icons/delete_ico.png" alt="" />
                  {this.props.t('destroy_network')}
                </ListItem>
              </>
            )}
          </List>
        </Popover>
      </>
    );
  }

  private onCloseNetworkOptionPopover = () => {
    this.setState({ networkOptionAnchorEl: null });
  };
  private onOpenNetworkOptionPopover = (e) => {
    this.setState({ networkOptionAnchorEl: e.currentTarget });
  };

  private inviteToNetwork = () => {
    this.onOpenNetworkInviteDialog();
    this.onCloseNetworkOptionPopover();
  };

  private onOpenNetworkInviteDialog = () => {
    this.setState({ openNetworkInviteDialog: true });
  };
  private onCloseNetworkInviteDialog = () => {
    this.setState({ openNetworkInviteDialog: false });
  };

  private onOpenDestroyNetworkDialog = () => {
    this.onCloseNetworkOptionPopover();
    this.setState({ openDestroyNetworkDialog: true });
  };
  private onCloseDestroyNetworkDialog = () => {
    this.setState({ openDestroyNetworkDialog: false });
  };

  private onOpenWithdrawConsortiumDialog = () => {
    this.onCloseNetworkOptionPopover();
    this.setState({ openWithdrawConsortiumDialog: true });
  };
  private onCloseWithdrawConsortiumDialog = () => {
    this.setState({ openWithdrawConsortiumDialog: false });
  };

  private onOpenNetworkOptionDialog = () => {
    this.onCloseNetworkOptionPopover();
    this.setState({ openNetworkOptionDialog: true });
  };
  private onCloseNetworkOptionDialog = () => {
    this.setState({ openNetworkOptionDialog: false });
  };
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    borderBlock: {
      '&:nth-child(2)': {
        marginTop: 17,
      },
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 4,
      borderColor: pattensBlueColor,
      boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
    },
    titleArea: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 36,
      backgroundColor: snowColor,
      paddingLeft: 20,
      paddingRight: 10,
      '@media only screen and (min-width: 750px) and (max-width: 970px)': {
        paddingLeft: 20,
      },
    },
    networkTitle: {
      ...defaultFontMedium,
      fontSize: 15,
      color: lightSlateGreyColor,
    },
    handleBtnArea: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
    },
    subTitle: {
      ...defaultFontMedium,
      fontSize: 12,
    },
    verticalSeparate: {
      width: 1,
      height: 20,
      backgroundColor: pattensBlueColor,
      marginRight: 5,
      marginLeft: 5,
    },
    networkUUID: {
      ...defaultFontRegular,
      ...oneLineText,
      fontSize: 12,
      color: lightSlateGreyColor,
      alignItems: 'center',
    },
    copyButton: {
      padding: 5,
      marginLeft: 5,
    },
    contentArea: {
      padding: '0 20px',
      [theme.breakpoints.up('md')]: {
        padding: '20px 0',
      },
    },
    networkInfoLeft: {
      padding: '20px 0',
      borderRight: 'none',
      borderBottom: `1px solid ${pattensBlueColor}`,
      [theme.breakpoints.up('md')]: {
        padding: '0 20px',
        borderRight: `1px solid ${pattensBlueColor}`,
        borderBottom: 'none',
      },
    },
    networkInfoRight: {
      padding: '20px 0',
      borderRight: 'none',
      [theme.breakpoints.up('md')]: {
        padding: '0 20px',
        borderBottom: 'none',
      },
    },
    detailValue: {
      ...defaultFontRegular,
      ...oneLineText,
      fontSize: 15,
      color: lightSlateGreyColor,
    },
    organizationValue: {
      color: denimColor,
    },
    networkDescription: {
      whiteSpace: 'normal',
      wordBreak: 'break-all',
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      '@media only screen and (min-width: 750px) and (max-width: 970px)': {
        borderRight: 'none',
      },
    },
    listOptionItem: {
      height: 46,
      width: 210,
      ...defaultFont,
      fontSize: 16,
    },
    horizontalSeparate: {
      height: 1,
      width: '100%',
      backgroundColor: pattensBlueColor,
    },
    deleteIcon: {
      marginRight: 8,
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConsortiumTab)),
);
