import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import { defaultFontRegular, defaultFontBold } from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import { useDispatch, useSelector } from 'react-redux';
import * as NetworkActions from '~/stores/actions/network-action';

// Component
import LoadingComponent from '~/components/common/loading';
import ProposalNodeRow from './proposal-node-row';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import LGButton from '~/components/common/lg-button';
import ImgIcon from '~/components/common/img-icon';
import TableHeadCustom from '~/components/common/table-head';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableRowHeadCustom from '~/components/common/table-row-head';
import SendProposalDialog from './proposal-node-row/send-proposal-dialog';

// Style
import { lightSlateGreyColor, denimColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';
import { ICluster, INetwork, INode } from '~/types/network-types';
import { Account, ProposalAddressInfo } from '~/gapi/gtypes';
import { oneLineText } from '~/styles/themes/common-styles/misc';

type IProposeToOtherNode = Record<
  string,
  {
    target: ProposalAddressInfo;
    statusInNetwork?: boolean;
    statusInGethLocal?: boolean;
  }
>;

interface IProps extends WithStyles<typeof styles> {
  node: INode;
  cluster: ICluster;
  network: INetwork;
  account: Account;
}

const ProposalNodeTab = (props: IProps) => {
  const { classes, node, cluster, network, account } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isDiscardLoading = useSelector((store: IStore) =>
    NetworkActions.discardProposal.isPending(store),
  );

  const [openCreateProposalDialog, setOpenCreateProposalDialog] = useState(false);

  const onDiscard = useCallback(
    async (address) => {
      await dispatch(
        NetworkActions.discardProposal({
          input: {
            networkUuid: network.networkUuid,
            accountUuid: account.accountUuid,
            clusterUuid: cluster.clusterUuid,
            nodeUuid: node.nodeUuid,
            address,
          },
        }),
      );
    },
    [account.accountUuid, cluster.clusterUuid, dispatch, network.networkUuid, node.nodeUuid],
  );

  const onOpenCreateProposalDialog = useCallback(() => {
    setOpenCreateProposalDialog(true);
  }, []);

  const onCloseCreateProposalDialog = useCallback(() => {
    setOpenCreateProposalDialog(false);
  }, []);

  const enableCreateProposal = useMemo(() => {
    return (
      node.nodeInfo.status === 'alive' &&
      node.serverInfo.status === 'alive' &&
      node.nodeInfo.signer &&
      (account?.role === 'owner' ||
        account?.role === 'admin' ||
        network?.role === 'owner' ||
        network?.role === 'admin')
    );
  }, [account?.role, network?.role, node]);

  const allNodes = useMemo(
    () =>
      network.clusters
        .map((cluster) => cluster.nodes.map((node) => ({ cluster, node })))
        .reduce((pre, cur) => pre.concat(cur), []),
    [network],
  );

  const proposeToOtherNodes = useMemo(() => {
    const result: IProposeToOtherNode = {};
    const localNodeProposals = allNodes.find((val) => val.node.nodeUuid === node.nodeUuid)?.node
      .nodeInfo.localProposals;
    network.networkSnapshot?.votes
      .filter((vote) => vote.from.address === node.nodeInfo.coinbaseAddress)
      .forEach((vote) => {
        result[vote.to.address] = {
          target: vote.to,
          statusInNetwork: vote.authorize,
        };
      });
    localNodeProposals?.forEach(({ address, authorize }) => {
      const existNode = allNodes.find((val) => val.node.nodeInfo.coinbaseAddress === address);
      result[address] = {
        ...(result[address] || {}),
        target: {
          address,
          node: existNode
            ? {
                accountUuid: existNode.cluster.accountUuid,
                clusterName: existNode.cluster.clusterName,
                clusterUuid: existNode.cluster.clusterUuid,
                nodeName: existNode.node.nodeName,
                nodeUuid: existNode.node.nodeUuid,
              }
            : undefined,
        },
        statusInGethLocal: authorize,
      };
    });

    return Object.values(result)
      .map((val) => {
        return {
          ...val,
          signer: !!network.networkSnapshot?.signers.find(
            (signer) => signer.address === val.target.address,
          ),
        };
      })
      .sort((a, b) => a.target.address.localeCompare(b.target.address));
  }, [allNodes, network.networkSnapshot, node]);

  return (
    <div className={classes.root}>
      {enableCreateProposal && (
        <SendProposalDialog
          open={openCreateProposalDialog}
          network={network}
          account={account}
          node={node}
          onClose={onCloseCreateProposalDialog}
        />
      )}
      <div className={classes.topLine}>
        <LGButton
          classes={{ root: classes.sendProposalBtn }}
          onClick={onOpenCreateProposalDialog}
          disabled={!enableCreateProposal}
        >
          <ImgIcon className={classes.sendProposalIcon} imgUrl="/images/icons/add_ico.png" />
          <span>{t('new_proposal')}</span>
        </LGButton>
      </div>
      <div className={classes.showProposalText}>{t('proposing_other_nodes')}</div>
      <div className={classes.tableArea}>
        <Table id="member-notification-detail" className={classes.tblList}>
          <TableHeadCustom>
            <TableRowHeadCustom>
              <TableCellHeadCustom className={classes.nodeNameCell}>
                <span>{t('node_name')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.addressCell}>
                <span>{t('address')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.nodeStatusCell}>
                <span>{t('node_status')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.statusCell}>
                <span>{t('network_porposal_status')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.statusCell}>
                <span>{t('geth_local_porposal_status')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.actionCell}>
                <span>{t('action')}</span>
              </TableCellHeadCustom>
            </TableRowHeadCustom>
          </TableHeadCustom>
          <TableBody>
            {(proposeToOtherNodes || []).map((item) => (
              <ProposalNodeRow
                key={item.target.address}
                item={item}
                account={account}
                network={network}
                node={node}
                isDiscardLoading={isDiscardLoading}
                onDiscard={onDiscard}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      marginTop: 20,
      paddingBottom: 50,
    },
    showProposalText: {
      ...defaultFontBold,
      fontSize: 15,
    },
    tableArea: {
      marginTop: 20,
    },
    sendProposalBtn: {
      width: 'auto',
      color: '#333',
    },
    sendProposalIcon: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    tblList: {},
    nodeNameCell: {
      width: '20%',
    },
    addressCell: {
      width: '25%',
    },
    nodeStatusCell: {
      width: '15%',
    },
    statusCell: {
      width: '20%',
    },
    actionCell: {
      width: '10%',
      whiteSpace: 'nowrap',
    },
    topLine: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
    },
    informationItemValue: {
      ...defaultFontRegular,
      ...oneLineText,
      fontSize: 15,
      color: lightSlateGreyColor,
      maxWidth: 400,
      '& a': {
        color: denimColor,
      },
    },
  });

export default withStyles(styles)(ProposalNodeTab);
