import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { defaultFontBold, defaultFontMedium } from '~/styles/themes/common-styles/font';
import {
  lightSlateGreyColor,
  nightRiderColor,
  pattensBlueColor,
  whiteSmokeColor,
} from '~/styles/themes/common-styles/color';
import moment from 'moment-timezone';
// Component
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHeadCustom from '~/components/common/table-head';
import TableBodyCustom from '~/components/common/table-body';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import TableRowHeadCustom from '~/components/common/table-row-head';
import TableRowBodyCustom from '~/components/common/table-row-body';
import LoadingIcon from '~/components/common/loading-icon';
import DatePicker from './date-picker';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Type
import { IBilling, IBillingDetail } from '~/types/payment-types';
import { Account, Profile } from '~/types/account-types';
import * as PaymentAction from '~/stores/actions/payment-action';
import { displayCurrency } from '~/utilities/payment-utils';
import { PAYMENT_TYPE_RESULT_FAIL, TAB_TITLE_CONCAT } from '~/constants/consts';

interface IStateProps {
  accountSelected?: Account;
  billings: IBilling;
  listBillingsLoading: boolean;
}

interface IDispProps {
  listBillings: (
    args: PaymentAction.QueryListBillingsArgs,
  ) => Promise<PaymentAction.LIST_BILLINGS_RESULT_TYPE>;
}

interface IProps
  extends RouteComponentProps<{}>,
    IStateProps,
    IDispProps,
    WithStyles<typeof styles>,
    WithTranslation {}

interface IState {}

class StatementMonths extends React.Component<
  IProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { billings } = this.props;
    !billings.details && this.listBillings();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.props.accountSelected &&
      prevProps.accountSelected &&
      this.props.accountSelected.accountUuid !== prevProps.accountSelected.accountUuid
    ) {
      this.listBillings();
    }
  }

  listBillings() {
    const { accountSelected, listBillings, billings } = this.props;
    accountSelected &&
      listBillings({
        accountUuid: accountSelected.accountUuid,
        startDate: billings.startPeriod,
        endDate: billings.endPeriod,
      }).catch(console.log);
  }

  public render() {
    const { classes, t, listBillingsLoading, billings } = this.props;

    document.title = TAB_TITLE_CONCAT + this.props.t('bill_state');

    return (
      <div className={classes.root}>
        <div className={classes.titleArea}>
          <span id="member-billing-statement-caption" className={classes.networkTitle}>
            {t('usage_history')}
          </span>
          <div className={classes.datePickerArea}>
            <div className={classes.startMonthPicker}>
              <div className={classes.datePickerLabel}>{t('start_month')}</div>
              <DatePicker
                datePickerInlineProps={{
                  value: new Date(billings.startPeriod),
                  views: ['month', 'year'],
                  onChange: this.onStartDatePickerClick,
                  disabled: listBillingsLoading,
                  maxDate: new Date(billings.endPeriod),
                  inputFormat: 'YYYY/MM',
                }}
                classes={{ root: classes.dtPicker }}
              />
            </div>
            <div className={classes.endMonthPicker}>
              <div className={classes.datePickerLabel}>{t('end_month')}</div>
              <DatePicker
                datePickerInlineProps={{
                  value: new Date(billings.endPeriod),
                  onChange: this.onEndDatePickerClick,
                  views: ['month', 'year'],
                  disabled: listBillingsLoading,
                  minDate: new Date(billings.startPeriod),
                  inputFormat: 'YYYY/MM',
                }}
                classes={{ root: classes.dtPicker }}
              />
            </div>
          </div>
        </div>
        {this.billingTable}
      </div>
    );
  }

  get billingTable() {
    const { t, classes, billings, listBillingsLoading } = this.props;

    if (!billings.details || listBillingsLoading) {
      return (
        <div className={classes.loadingArea}>
          <LoadingIcon />
        </div>
      );
    }

    const billingsByMonth = this.groupBillingsMyMonth(billings);

    return (
      <Table id="member-billing-billing-list">
        <TableHeadCustom>
          <TableRowHeadCustom>
            <TableCellHeadCustom className={classes.tableCellHeadCustom}>
              <span>{t('billing_month')}</span>
            </TableCellHeadCustom>
            <TableCellHeadCustom className={classes.tableCellHeadCustom}>
              <span>{t('payment_status')}</span>
            </TableCellHeadCustom>
            <TableCellHeadCustom className={classes.tableCellHeadCustom}>
              <span>{t('billing_amount')}</span>
            </TableCellHeadCustom>
            <TableCellHeadCustom
              classes={{ root: classes.customCellHead }}
              className={classes.tableCellHeadCustom}
            >
              <span>{t('action')}</span>
            </TableCellHeadCustom>
          </TableRowHeadCustom>
        </TableHeadCustom>
        <TableBodyCustom>
          {billingsByMonth.map((bill, i) => {
            const month = moment(bill.groupMonth);

            return (
              <TableRowBodyCustom key={`bill-history-${i}`} className={classes.tableBodyCustom}>
                <TableCellBodyCustom>{month.format('YYYY/MM')}</TableCellBodyCustom>
                <TableCellBodyCustom>
                  {bill.isPaid ? t('paid') : t('unpaid_exist')}
                </TableCellBodyCustom>
                <TableCellBodyCustom>
                  $ {displayCurrency(bill.amount + bill.tax - bill.coupon)}
                </TableCellBodyCustom>
                <TableCellBodyCustom>
                  <Link to={`/billing/statement/${month.format('YYYY-MM')}`}>
                    <Button id="member-billing-billing-action-button" className={classes.actionBtn}>
                      <img className={classes.btnIcon} src="/images/icons/open_icon.svg" alt="" />
                      {t('open')}
                    </Button>
                  </Link>
                </TableCellBodyCustom>
              </TableRowBodyCustom>
            );
          })}
        </TableBodyCustom>
      </Table>
    );
  }

  private groupBillingsMyMonth(billings: IBilling): IBillingsMonth[] {
    const grouped = (
      billings.details?.filter((d) => d.result !== PAYMENT_TYPE_RESULT_FAIL) || []
    ).reduce((pre, cur) => {
      const group = moment(cur.invoicedDate).startOf('month').toISOString();

      if (pre[group]) {
        pre[group].push(cur);
      } else {
        pre[group] = [cur];
      }
      return pre;
    }, {} as { [key: string]: IBillingDetail[] });

    return Object.entries(grouped)
      .map((g) => ({
        groupMonth: g[0],
        isPaid: g[1].reduce((a, b) => a && Boolean(b.paidDate), true),
        amount: g[1].reduce((a, b) => a + b.amount, 0),
        coupon: g[1].reduce((a, b) => a + b.coupon, 0),
        tax: g[1].reduce((a, b) => a + b.tax, 0),
      }))
      .sort((a, b) => moment(b.groupMonth).valueOf() - moment(a.groupMonth).valueOf());
  }

  onStartDatePickerClick = (date) => {
    const { billings, listBillings, accountSelected } = this.props;
    accountSelected &&
      !moment(date).isSame(moment(billings.startPeriod), 'month') &&
      listBillings({
        accountUuid: accountSelected.accountUuid,
        startDate: moment(date).startOf('month').toISOString(),
      });
  };

  onEndDatePickerClick = (date) => {
    const { billings, listBillings, accountSelected } = this.props;
    accountSelected &&
      !moment(date).isSame(moment(billings.endPeriod), 'month') &&
      listBillings({
        accountUuid: accountSelected.accountUuid,
        endDate: moment(date).endOf('month').toISOString(),
      });
  };
}

const styles = (theme: Theme) =>
  createStyles({
    loadingArea: {
      margin: '100px 0',
      textAlign: 'center',
    },
    root: {
      paddingBottom: 41,
    },
    titleArea: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '28px 0',
    },
    datePickerArea: {
      display: 'flex',
      alignItems: 'center',
    },
    startMonthPicker: {
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    endMonthPicker: {
      display: 'flex',
      alignItems: 'center',
    },
    datePickerLabel: {
      marginRight: 5,
      fontSize: 14,
    },
    networkTitle: {
      ...defaultFontBold,
      fontSize: 18,
      color: nightRiderColor,
    },
    tableArea: {},
    detailArea: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    customCellHead: {
      paddingRight: 0,
    },
    tableCellHeadCustom: {
      '& span': {
        ...defaultFontMedium,
      },
    },
    actionBtn: {
      ...defaultFontMedium,
      border: `solid 1px ${pattensBlueColor}`,
      backgroundColor: whiteSmokeColor,
      color: lightSlateGreyColor,
      textTransform: 'none',
      fontSize: 13,
    },
    btnIcon: {
      width: 12,
      height: 12,
      marginRight: 5,
    },
    dtPicker: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      datePickerLabel: {
        fontSize: 13,
        marginRight: 5,
        color: '#7b90a3',
      },
      dtPicker: {
        width: 100,
        boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
      },
    },
    tableBodyCustom: {
      height: '48px',
      '& .MuiTableCell-root': {
        letterSpacing: 'normal',
        padding: '4px 30px 4px 10px',
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  accountSelected: store.appState.accountSeleted,
  billings: store.appState.billings,
  listBillingsLoading: PaymentAction.listBillings.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  listBillings: (args: PaymentAction.QueryListBillingsArgs) =>
    dispatch(PaymentAction.listBillings(args)),
});

export default withStyles(styles)(
  withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(StatementMonths))),
);

export interface IBillingsMonth {
  groupMonth: string;
  isPaid: boolean;
  amount: number;
  coupon: number;
  tax: number;
}
