import React from 'react';
// Component
import IconButton from '@mui/material/IconButton';
import ImgIcon from '~/components/common/img-icon';
// Stype
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
// Util
import { copyTextToClipboard } from '~/utilities/utils';

interface IProps extends WithStyles<typeof styles> {
  textToCopy: string;
  onClick?: any;
}

const CopyClipboardIcon: React.StatelessComponent<IProps> = ({ classes, textToCopy }) => {
  return (
    <IconButton className={classes.root} onClick={handleClickCopy} data-id={textToCopy}>
      <ImgIcon className={classes.icon} imgUrl="/images/icons/copy_icon.png" />
    </IconButton>
  );
};

const handleClickCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
  const text = String(event.currentTarget.dataset.id);
  copyTextToClipboard(text);
};

const styles: StyleRules = {
  root: {},
  icon: {
    width: 12,
    height: 12,
  },
};

export default withStyles(styles)(CopyClipboardIcon);
