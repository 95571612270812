import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import { Theme } from '@mui/material/styles';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import moment from 'moment-timezone';
// Component
import TableHeadCustom from '~/components/common/table-head';
import TableBodyCustom from '~/components/common/table-body';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableRowHeadCustom from '~/components/common/table-row-head';
import StatementMonthRow from './statement-month-row';
import IconButton from '@mui/material/IconButton';
import LoadingIcon from '~/components/common/loading-icon';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Type
import { IBilling } from '~/types/payment-types';
import { Account, Profile } from '~/types/account-types';
import { defaultFontBold } from '~/styles/themes/common-styles/font';
import { nightRiderColor } from '~/styles/themes/common-styles/color';
import * as PaymentAction from '~/stores/actions/payment-action';
import * as Gtypes from '~/gapi/gtypes';

interface IStateProps {
  accountSelected?: Account;
  billings: IBilling;
  listBillingsInMonthLoading: boolean;
}

interface IDispProps {
  listBillingsInMonth: (
    args: PaymentAction.ListBillingsInMonthArgs,
  ) => Promise<PaymentAction.LIST_BILLINGS_RESULT_TYPE>;
}

interface IProps
  extends IStateProps,
    IDispProps,
    RouteComponentProps<{ date: string }>,
    WithStyles<typeof styles>,
    WithTranslation {}

interface IState {
  billingDetails?: Array<Gtypes.BillingDetail>;
}

class StatementMonth extends React.Component<
  IProps & RouteComponentProps<{ month: string }> & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);

    this.state = {
      billingDetails: undefined,
    };
  }

  componentDidMount() {
    this.getStatementDates();
  }

  public render() {
    const { classes, t, listBillingsInMonthLoading, accountSelected } = this.props;
    const { billingDetails } = this.state;
    const { month } = this.props.match.params;

    if (listBillingsInMonthLoading) {
      return (
        <div className={classes.loadingArea}>
          <LoadingIcon />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.titleArea}>
          <div className={classes.detailArea}>
            <IconButton
              onClick={(_) => this.props.history.push('/billing/statement')}
              children={<img src="/images/icons/back_icon.svg" alt="" />}
              className={classes.btnBack}
            />
            <span id="member-billing-statement-month" className={classes.networkTitle}>
              {month && month.replace('-', '/')}
            </span>
          </div>
        </div>
        <div className={classes.contentAreaTable}>
          <div className={classes.tableArea}>
            <Table id="member-billing-statement-detail-list" className={classes.tblBillDetailList}>
              <colgroup className={classes.tblColGroup}>
                <col width="180px" />
                <col width="250px" />
                <col width="250px" />
                <col width="120px" />
                <col width="65px" />
              </colgroup>
              <TableHeadCustom>
                <TableRowHeadCustom className={classes.tableHeadCustom}>
                  <TableCellHeadCustom>
                    <span>{t('billing_date')}</span>
                  </TableCellHeadCustom>
                  <TableCellHeadCustom>
                    <span>{t('billing_id')}</span>
                  </TableCellHeadCustom>
                  <TableCellHeadCustom>
                    <span>{t('payment_status')}</span>
                  </TableCellHeadCustom>
                  <TableCellHeadCustom classes={{ root: classes.customCellHead }}>
                    <span>{t('billing_amount')}</span>
                  </TableCellHeadCustom>
                  <TableCellHeadCustom
                    classes={{ content: classes.tableCellHeadCollapse }}
                  ></TableCellHeadCustom>
                </TableRowHeadCustom>
              </TableHeadCustom>
              <TableBodyCustom>
                {(billingDetails || []).map((bill, i) => (
                  <StatementMonthRow key={i} bill={bill} account={accountSelected} />
                ))}
              </TableBodyCustom>
            </Table>
          </div>
        </div>
      </div>
    );
  }

  getStatementDates = async () => {
    const { accountSelected, billings, listBillingsInMonth, history } = this.props;
    const { month } = this.props.match.params;

    let statementDates: Array<Gtypes.BillingDetail> | undefined = undefined;

    if (!moment(month).isValid()) {
      history.push('/billing/statement');
      return;
    }

    const startSelectedMonthMoment = moment(month).startOf('month');
    const endSelectedMonthMoment = moment(month).endOf('month');

    if (
      billings.details &&
      moment(billings.startPeriod).isSameOrBefore(startSelectedMonthMoment) &&
      moment(billings.endPeriod).isSameOrAfter(endSelectedMonthMoment)
    ) {
      statementDates = billings.details.filter(
        (billingDetail) =>
          moment(billingDetail.invoicedDate).startOf('month').format('YYYY-MM') === month,
      );
    } else {
      try {
        statementDates =
          accountSelected &&
          (
            await listBillingsInMonth({
              accountUuid: accountSelected.accountUuid,
              month: moment(month).toISOString(),
            })
          ).listBillings.details;
      } catch (err) {
        // Do nothing
      }
    }

    this.setState({
      billingDetails: statementDates,
    });
  };
}

const styles = (theme: Theme) =>
  createStyles({
    loadingArea: {
      margin: '100px 0',
      textAlign: 'center',
    },
    root: {
      paddingBottom: 41,
    },
    titleArea: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '28px 0',
    },
    contentAreaTable: {
      display: 'block',
    },
    networkTitle: {
      ...defaultFontBold,
      fontSize: 18,
      color: nightRiderColor,
    },
    tableArea: {},
    detailArea: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    customCellHead: {
      paddingRight: '0 !important',
    },
    tableCellHeadCollapse: {
      borderLeft: 'none',
    },
    btnBack: {
      padding: '12px',
    },
    tblBillDetailList: {},
    tblColGroup: {},

    [theme.breakpoints.between('sm', 'sm')]: {
      titleArea: {
        padding: '10px 0',
      },
      btnBack: {
        paddingLeft: 0,
      },
      tblColGroup: {
        '& col': {
          display: 'none',
        },
      },
      tblBillDetailList: {
        '& th:first-child': {
          width: 90,
        },
        '& th:nth-child(2)': {
          width: 150,
        },
        '& th div': {
          paddingRight: 0,
          whiteSpace: 'nowrap',
        },
        '& td': {
          fontSize: 13,
          wordBreak: 'break-word',
          padding: '0 10px',
        },
        '& td:nth-child(5) button': {
          position: 'relative',
          right: 30,
        },
      },
    },
    tableHeadCustom: {
      '& .MuiTableCell-root': {
        letterSpacing: 'normal',
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  accountSelected: store.appState.accountSeleted,
  billings: store.appState.billings,
  listBillingsInMonthLoading: PaymentAction.listBillingsInMonth.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  listBillingsInMonth: (args: PaymentAction.ListBillingsInMonthArgs) =>
    dispatch(PaymentAction.listBillingsInMonth(args)),
});

export default withStyles(styles)(
  withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StatementMonth))),
);
