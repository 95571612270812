import React, { useEffect, useMemo } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import { defaultFontMedium } from '~/styles/themes/common-styles/font';

import { nightRiderColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Checkbox from '@mui/material/Checkbox';
import { Cluster } from '~/gapi/gtypes';
import { sortNodesByName } from '~/utilities/render-utils';

interface IProps extends WithStyles<typeof styles> {
  cluster: Cluster;
  onCheckbox: (alls: string[], checked: string[]) => void;
}

const CheckboxAddress = (props: IProps) => {
  const { classes, cluster, onCheckbox } = props;
  const [checked, setChecked] = React.useState<Array<string>>([]);
  const { t } = useTranslation();

  const singerNodes = useMemo(() => {
    return cluster.nodes.sort((a, b) => {
      return sortNodesByName(a, b);
    });
  }, [cluster]);

  const signerNodeUuids = useMemo(() => singerNodes.map((node) => node.nodeUuid), [singerNodes]);

  const handleCheckboxAllChange = () => {
    let newChecked = [] as string[];
    if (checked.length != signerNodeUuids.length) {
      newChecked = signerNodeUuids;
    }
    setChecked(newChecked);
    onCheckbox(signerNodeUuids, newChecked);
  };

  const handleCheckboxChange = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    onCheckbox(signerNodeUuids, newChecked);
  };

  useEffect(() => {
    setChecked(signerNodeUuids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.nodeGroup}>
      <div className={classes.formLabel}>
        <Checkbox
          name={`cluster-${cluster.clusterUuid}`}
          className={classes.checkbox}
          edge="start"
          checked={signerNodeUuids.every((nodeUuid) => checked.indexOf(nodeUuid) !== -1)}
          onClick={() => handleCheckboxAllChange()}
          disableRipple
          indeterminate={checked.length > 0 && signerNodeUuids.length != checked.length}
        />
        {cluster.clusterName}
      </div>
      {singerNodes.map((node) => {
        return (
          <ListItem key={node.nodeUuid} disablePadding>
            <ListItemButton
              className={classes.listItemBtn}
              onClick={() => handleCheckboxChange(node.nodeUuid)}
              dense
            >
              <div className={classes.nodeNameCheckbox}>
                <Checkbox
                  name={`${node.nodeUuid}`}
                  className={classes.checkbox}
                  edge="start"
                  checked={checked.indexOf(node.nodeUuid) !== -1}
                  disableRipple
                />
                <div className={classes.nodeName}>{node.nodeName}</div>
              </div>
              <div className={classes.targetAddress}>
                <div>{node.nodeInfo.coinbaseAddress}</div>
              </div>
            </ListItemButton>
          </ListItem>
        );
      })}
    </div>
  );
};

const styles = (theme) =>
  createStyles({
    root: {},
    checkbox: {
      '&.Mui-checked': {
        color: 'rgba(0, 0, 0, 0.6)',
      },
      '&.MuiCheckbox-indeterminate': {
        color: 'rgba(0, 0, 0, 0.6)',
      },
    },
    listItemBtn: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    nodeNameCheckbox: {
      display: 'flex',
      flex: 1,
      fontSize: 13,
      whiteSpace: 'nowrap',
      alignItems: 'center',
      overflow: 'hidden',
    },
    targetAddress: {
      fontSize: 13,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    nodeName: {
      fontWeight: 400,
      color: nightRiderColor,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    formLabel: {
      ...defaultFontMedium,
      fontSize: 13,
      marginBottom: 5,
    },
    nodeGroup: {
      paddingLeft: 20,
    },
  });

export default withStyles(styles)(CheckboxAddress);
