import React from 'react';
import * as semver from 'semver';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';

import { ICluster } from '~/types/network-types';
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as PaymentActions from '~/stores/actions/payment-action';
import * as AppActions from '~/stores/actions/app-action';
import { Account } from '~/types/account-types';
import { INetworkProvider, IProviderImage, IProviderRegion, INetwork } from '~/types/network-types';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  denimColor,
  dimGrayColor,
  whiteSmokeColor,
  pattensBlueColor,
  romanColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  instancesSizeSelection,
  instancesSizeEnterpriseSelection,
  nodeGethSelection,
  nodeGcModeOptions,
  nodeClefSelection,
} from '~/types/network-selection';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomSelect from './custom-select';
import CustomInput from './custom-input';
import CustomInputNum from './custom-input-num';
import CustomInputNumCommaFormat from './custom-input-num-comma-format';
import ImgIcon from '~/components/common/img-icon';
import SubmitButton from './submit-button';

import ConfirmLicenseDialog from './confirm-license-dialog';
import RegisterAddressDialog from './register-address-dialog';

// defines
import {
  DEFAULT_VERSION_GETH,
  DEFAULT_MIN_NODES_POA,
  DEFAULT_GAS_PRICE,
  DEFAULT_GAS_PRICE_MAX,
  APPLIED_EIP155_GETH_VERSION,
  ALLOC_ADDRESS_LENGTH,
  DEFAULT_VERSION_INTERNAL_CLEF,
} from '~/constants/consts';
import {
  VALIDATE_CLUSTER_TYPE_NODES,
  MAX_NODE_NAME_LENGTH,
  CREATE_NODE_MIN_NUMBER_OF_NODE,
  CREATE_NODE_MAX_NUMBER_OF_NODE,
  VALIDATE_SSH_PRIVATE_KEY_SIZE,
  MAX_CLEF_SERVER_PRIVATE_KEY_LENGTH,
  SSH_PRIVATE_KEY_PATTERN,
  HOSTNAME_PATTERN,
  MAX_CLEF_API_PORT,
  MAX_CLEF_SERVER_USERNAME_LENGTH,
  MAX_CLEF_SERVER_HOSTNAME_LENGTH,
  IP_ADDRESS_PATTERN,
} from '~/constants/validation';
import * as Gtypes from '~/gapi/gtypes';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';

interface IOwnProps {
  networkUuid: string;
  cluster?: ICluster;
  open: boolean;
  onClose: () => void;
  network: INetwork;
}

interface IProps {
  providers: INetworkProvider[];
  accountSeleted?: Account;
  networkSelected?: INetwork;
}

interface IDispProps {
  createNode: (
    args: NetworkActions.MutationCreateNodeArgs,
  ) => Promise<NetworkActions.CREATE_NODE_RESULT_TYPE>;
  insufficientLicenses: (
    args: PaymentActions.QueryListInsufficientNodeLicensesArgs,
  ) => Promise<PaymentActions.LIST_INSUFFICIENT_NODE_LICENSES_RESULT_TYPE>;
  estimateLicenseFee: (
    args: PaymentActions.QueryEstimateLicenseFeeArgs,
  ) => Promise<PaymentActions.ESTIMATE_LICENSE_FEE_RESULT_TYPE>;
  listActiveLicensesSummary: (
    args: PaymentActions.QueryListActiveLicensesSummaryArgs,
  ) => Promise<PaymentActions.LIST_ACTIVE_LICENSES_SUMMARY_RESULT_TYPE>;
  listBillings: (
    args: PaymentActions.QueryListBillingsArgs,
  ) => Promise<PaymentActions.LIST_BILLINGS_RESULT_TYPE>;
  listCoupons: (
    args: PaymentActions.QueryListCouponsArgs,
  ) => Promise<PaymentActions.LIST_COUPONS_RESULT_TYPE>;
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => void;
}

interface IState {
  isShowAdvanced: boolean;
  checked: boolean;
  openConfirmLicenseDialog: boolean;
  openRegisterAddressDialog: boolean;
  estimateDate: string;
  estimateFee: PaymentActions.EstimateSummary;
  requireCard: boolean;
  formik?: FormikActions<FormValues>;
  clusterSelected?: ICluster;
  sshPrivateKey?: string;
  isShowExternalClef: boolean;
}

class CreateNodeDialog extends React.Component<
  IOwnProps & IProps & IDispProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);
    const { cluster, networkSelected } = this.props;

    this.state = {
      isShowAdvanced: false,
      checked: false,
      isShowExternalClef: false,
      openConfirmLicenseDialog: false,
      openRegisterAddressDialog: false,
      requireCard: false,
      estimateDate: '',
      estimateFee: {
        estimate: [
          {
            totalPrice: 0,
            taxFee: 0,
            taxRate: 0,
            licenses: [],
            nextMonth: {
              totalPrice: 0,
              taxFee: 0,
              taxRate: 0,
              licenses: [],
            },
          },
        ],
        coupon: {
          usable: 0,
        },
      },
      clusterSelected: cluster || (networkSelected && networkSelected.clusters[0]),
    };
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  public componentWillReceiveProps(nextProps) {
    const { cluster } = nextProps;
    const { clusterSelected } = this.state;

    if (cluster) {
      if (!clusterSelected || cluster.clusterUuid !== clusterSelected.clusterUuid) {
        this.setState({ clusterSelected: cluster });
      }
    }
  }

  public render() {
    const { classes, open, onClose, providers, cluster, accountSeleted, network, t } = this.props;
    const {
      isShowAdvanced,
      openConfirmLicenseDialog,
      openRegisterAddressDialog,
      estimateDate,
      estimateFee,
      requireCard,
      clusterSelected,
    } = this.state;

    if (!clusterSelected) {
      return null;
    }

    const convGasPrice = network.blockchainInfo?.defaultGasPrice;

    const initialValues: FormValues = {
      nameOfNode: '',
      instanceType: 'small_v3',
      numberOfNode: 1,
      nodeVersion: DEFAULT_VERSION_GETH,
      clefVersion: DEFAULT_VERSION_INTERNAL_CLEF,
      imageVersion: '',
      gasPrice: !Number.isNaN(convGasPrice) ? convGasPrice : DEFAULT_GAS_PRICE,
      gcMode: 'full',
      allowUnprotectedTxs: false,
      externalPort: undefined,
      usedExternalSigner: false,
    };

    const requireAddr =
      !accountSeleted || !accountSeleted.paymentAddr || !accountSeleted.paymentAddr.country
        ? true
        : false;
    const validator = VALIDATE_CLUSTER_TYPE_NODES[clusterSelected.clusterType || 'small']
      ? VALIDATE_CLUSTER_TYPE_NODES[clusterSelected.clusterType || 'small'].allowed
      : [];

    const validateSchema = Yup.object().shape({
      nameOfNode: Yup.string()
        .trim()
        .required(t('required_field'))
        .max(
          MAX_NODE_NAME_LENGTH,
          t('too_many_character_of_nodename_error_message', { value: MAX_NODE_NAME_LENGTH }),
        )
        .test(
          'check-trailing-spaces',
          t('trailing_spaces_not_allowed_to_name'),
          function (name: string) {
            return (name || '').trim() !== '' ? true : false;
          },
        ),
      instanceType: Yup.string()
        .trim()
        .required(t('required_field'))
        .test(
          'check-with-cluster-type',
          t('cluster_type_not_allowed_this_instance'),
          function (instance: string) {
            return validator.includes(instance);
          },
        ),
      numberOfNode: Yup.number()
        .required(t('required_field'))
        .min(
          CREATE_NODE_MIN_NUMBER_OF_NODE,
          t('validate_minimum', { val: CREATE_NODE_MIN_NUMBER_OF_NODE }),
        )
        .max(
          CREATE_NODE_MAX_NUMBER_OF_NODE,
          t('validate_maximum', { val: CREATE_NODE_MAX_NUMBER_OF_NODE }),
        ),
      gasPrice: Yup.number()
        .notRequired()
        .min(0, t('validate_minimum', { val: 0 }))
        .max(DEFAULT_GAS_PRICE_MAX, t('validate_maximum', { val: DEFAULT_GAS_PRICE_MAX })),
      externalServerUsername: Yup.string().when('usedExternalSigner', {
        is: true,
        then: Yup.string()
          .required(t('required_field'))
          .max(
            MAX_CLEF_SERVER_USERNAME_LENGTH,
            t('validate_maximum', { val: MAX_CLEF_SERVER_USERNAME_LENGTH }),
          ),
        otherwise: Yup.string().notRequired(),
      }),
      externalServerHost: Yup.string().when('usedExternalSigner', {
        is: true,
        then: Yup.string()
          .required(t('required_field'))
          .max(
            MAX_CLEF_SERVER_HOSTNAME_LENGTH,
            t('validate_maximum', { val: MAX_CLEF_SERVER_HOSTNAME_LENGTH }),
          )
          .test(
            'check-external-server-host',
            t('invalid_external_server_host'),
            function (host: string) {
              return HOSTNAME_PATTERN.test(host) || IP_ADDRESS_PATTERN.test(host);
            },
          ),
        otherwise: Yup.string().notRequired(),
      }),
      externalPort: Yup.number().when('usedExternalSigner', {
        is: true,
        then: Yup.number()
          .required(t('required_field'))
          .min(1, t('validate_minimum', { val: 1 }))
          .max(MAX_CLEF_API_PORT, t('validate_maximum', { val: MAX_CLEF_API_PORT })),
        otherwise: Yup.number().notRequired(),
      }),
      externalSignerAddress: Yup.string().when('usedExternalSigner', {
        is: true,
        then: Yup.string()
          .required(t('required_field'))
          .test(
            'check-external-signer-address',
            t('error_alloc_address'),
            function (address: string) {
              return /^0x[a-fA-F0-9]{40}$/g.test(address);
            },
          ),
        otherwise: Yup.string().notRequired(),
      }),
      sshPrivateKey: Yup.string().when('usedExternalSigner', {
        is: true,
        then: Yup.string().required(t('required_field')),
        otherwise: Yup.string().notRequired(),
      }),
    });

    const provider = providers.find((item) => item.value === clusterSelected.provider) || {
      region: [] as IProviderRegion[],
    };
    const region = provider.region.find((item) => item.value === clusterSelected.region) || {
      images: [] as IProviderImage[],
    };

    if ((region.images || []).length > 0) {
      initialValues.imageVersion = region.images[0].value;
    }

    const selectBase =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? instancesSizeEnterpriseSelection
        : instancesSizeSelection;
    const selector = selectBase.filter((item) => validator.includes(item.value));
    initialValues.instanceType = (
      selector.length > 0 ? selector[0].value : 'small'
    ) as Gtypes.NodeSizeType;

    return (
      <>
        <CustomDialog open={open} onClose={onClose} scroll="body">
          <Formik
            initialValues={initialValues}
            validationSchema={validateSchema}
            onSubmit={this.onSubmit}
            render={({ isValid, isSubmitting, values }) => (
              <Form>
                <CustomDialogTitle>
                  <div id="member-node-add-title">{t('create_node')}</div>
                </CustomDialogTitle>
                <CustomDialogContent classes={{ root: classes.dialogContentRoot }}>
                  <div>
                    <Field name="nameOfNode" render={this.nodeNameField} />
                  </div>
                  <div>{!cluster && this.renderClusterSelection}</div>
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid item md={6} className={classes.gridLeftItem}>
                        <Field name="provider" render={this.providerField} />
                      </Grid>
                      <Grid item md={6} className={classes.gridRightItem}>
                        <Field name="region" render={this.regionField} />
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid item md={6} className={classes.gridLeftItem}>
                        <Field name="instanceType" render={this.instanceTypeField} />
                      </Grid>
                      <Grid item md={6} className={classes.gridRightItem}>
                        <Field name="numberOfNode" render={this.numberOfNodeField} />
                      </Grid>
                    </Grid>
                  </div>

                  {/* External signer */}
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid
                        item
                        className={classNames(classes.gridLeftItem, classes.formSectionCommonGrid)}
                      >
                        <Field name="usedExternalSigner" render={this.usedExternalSignerCheckbox} />
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ display: values.usedExternalSigner ? 'block' : 'none' }}>
                    <div className={classes.formSection}>
                      <Field
                        name="externalSignerAddress"
                        render={this.externalSignerAddressField}
                        validate={this.validateSignerAddress}
                      />
                    </div>
                    <div className={classes.formSection}>
                      <Grid container>
                        <Grid
                          item
                          xs={6}
                          className={classNames(
                            classes.gridLeftItem,
                            classes.formSectionCommonGrid,
                          )}
                        >
                          <Field
                            name="externalServerUsername"
                            render={this.externalServerUsernameField}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className={classNames(
                            classes.gridRightItem,
                            classes.formSectionCommonGrid,
                          )}
                        >
                          <Field name="externalServerHost" render={this.externalHostField} />
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.formSection}>
                      <Grid container>
                        <Grid
                          item
                          xs={6}
                          className={classNames(
                            classes.gridLeftItem,
                            classes.formSectionCommonGrid,
                          )}
                        >
                          <Field name="externalPort" render={this.externalPortField} />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className={classNames(
                            classes.gridRightItem,
                            classes.formSectionCommonGrid,
                          )}
                        >
                          <Field
                            name="sshPrivateKey"
                            render={this.externalSshPrivateField}
                            validate={this.validateFileSize}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div className={classes.advancedBtnArea}>
                    <div className={classes.separateLine}></div>
                    <div
                      id="member-node-add-separator"
                      className={classes.advancedBtn}
                      onClick={this.toggleAdvanced}
                    >
                      <ImgIcon
                        className={classes.advancedIcon}
                        imgUrl={
                          isShowAdvanced
                            ? '/images/icons/minus_ico.png'
                            : '/images/icons/add_ico.png'
                        }
                      />
                      {t('advanced_menu')}
                    </div>
                  </div>

                  {isShowAdvanced && (
                    <>
                      <div className={classes.formSection}>
                        <Grid container>
                          <Grid item md={6} className={classes.gridLeftItem}>
                            <Field name="imageVersion" render={this.imageVersionField} />
                          </Grid>
                          <Grid item md={6} className={classes.gridRightItem}>
                            <Field name="nodeVersion" render={this.nodeVersionField} />
                          </Grid>
                        </Grid>
                      </div>

                      <div className={classes.formSection}>
                        <Grid container>
                          <Grid item md={6} className={classes.gridLeftItem}>
                            <Field name="gasPrice" render={this.gasPriceField} />
                          </Grid>
                          <Grid item md={6} className={classes.gridRightItem}>
                            <Field name="gcMode" render={this.gcModeField} />
                          </Grid>
                        </Grid>
                      </div>
                      {!values.usedExternalSigner && (
                        <div className={classes.formSection}>
                          <Grid container>
                            <Grid item md={6} className={classes.gridLeftItem}>
                              <Field name="clefVersion" render={this.clefVersionField} />
                            </Grid>
                          </Grid>
                        </div>
                      )}
                      <div className={classes.formSection}>
                        <Grid
                          item
                          className={classNames(
                            classes.gridLeftItem,
                            classes.formSectionCommonGrid,
                          )}
                        >
                          <Field
                            name="allowUnprotectedTxs"
                            render={this.allowUnprotectedTxsCheckbox}
                          />
                        </Grid>
                      </div>
                    </>
                  )}
                </CustomDialogContent>
                <CustomDialogActions>
                  <Button
                    id="member-node-add-cancel"
                    disabled={isSubmitting}
                    className={classes.leftBtn}
                    onClick={onClose}
                    variant="contained"
                  >
                    {t('cancel')}
                  </Button>
                  <SubmitButton
                    id="member-node-add-submit"
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    label={t('create')}
                    submittingLabel={t('creating')}
                  />
                </CustomDialogActions>
              </Form>
            )}
          />
        </CustomDialog>

        <RegisterAddressDialog
          open={openRegisterAddressDialog}
          onClose={this.onCloseRegisterAddressDialog}
        ></RegisterAddressDialog>
        <ConfirmLicenseDialog
          open={openConfirmLicenseDialog}
          onClose={this.onCloseConfirmLicenseDialog}
          estimateDate={estimateDate}
          estimateFee={estimateFee}
          requireAddr={requireAddr}
          requireCard={requireCard}
        ></ConfirmLicenseDialog>
      </>
    );
  }

  get renderClusterSelection() {
    const { classes, networkSelected, t } = this.props;
    const { clusterSelected } = this.state;

    if (!networkSelected) {
      return null;
    }

    const clusterSelection = networkSelected.clusters.map((cluster) => ({
      value: cluster.clusterUuid,
      label: cluster.clusterName,
    }));

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('cluster')}</div>
        </div>
        <div>
          <CustomSelect
            id="member-node-add-cluster"
            onChange={this.changeClusterSelected}
            valueSelected={clusterSelected ? clusterSelected.clusterUuid : ''}
            placeholder={t('select_cluster')}
            items={clusterSelection}
          />
        </div>
      </>
    );
  }

  private changeClusterSelected = (e) => {
    const { networkSelected } = this.props;
    if (networkSelected) {
      this.setState({
        clusterSelected: networkSelected.clusters.find(
          (cluster) => cluster.clusterUuid === e.target.value,
        ),
      });
    }
  };

  private nodeNameField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('node_name')}</div>
          {!!form.errors.nameOfNode && form.touched.nameOfNode && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.nameOfNode}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-node-add-name"
            placeholder={this.props.t('input_node_name')}
          />
        </div>
      </>
    );
  };

  private providerField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, providers } = this.props;
    const { clusterSelected } = this.state;
    const provider = providers.find(
      (p) => p.value === (clusterSelected && clusterSelected.provider),
    ) || { label: 'N/A' };
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('provider')}</div>
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-node-add-provider"
            placeholder={this.props.t('provider_name')}
            value={provider.label}
            disabled
          />
        </div>
      </>
    );
  };

  private regionField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, providers } = this.props;
    const { clusterSelected } = this.state;
    const provider = providers.find(
      (p) => p.value === (clusterSelected && clusterSelected.provider),
    );
    const region = (provider
      ? provider.region.find((r) => r.value === (clusterSelected && clusterSelected.region))
      : undefined) || { label: 'N/A' };
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('region')}</div>
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-node-add-region"
            placeholder={this.props.t('region_name')}
            value={region.label}
            disabled
          />
        </div>
      </>
    );
  };

  private instanceTypeField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t, accountSeleted } = this.props;
    const { clusterSelected } = this.state;

    const filter =
      clusterSelected && VALIDATE_CLUSTER_TYPE_NODES[clusterSelected.clusterType || 'small']
        ? VALIDATE_CLUSTER_TYPE_NODES[clusterSelected.clusterType || 'small'].allowed
        : [];
    const select =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? instancesSizeEnterpriseSelection
        : instancesSizeSelection;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('instance_type')}</div>
          {!!form.errors.instanceType && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.instanceType}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-node-add-instance"
            valueSelected={field.value}
            placeholder={t('select_instance_type')}
            items={select.filter((item) => filter.includes(item.value))}
          />
        </div>
      </>
    );
  };

  private numberOfNodeField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('number_of_nodes')}</div>
          {!!form.errors.numberOfNode && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.numberOfNode}
            </div>
          )}
        </div>
        <div>
          <CustomInputNum
            {...field}
            id="member-node-add-num-nodes"
            min={1}
            max={CREATE_NODE_MAX_NUMBER_OF_NODE}
            placeholder={this.props.t('input_number')}
            onChange={(event) => this.onNumOfNodeChange(event, form)}
          />
        </div>
      </>
    );
  };

  private usedExternalSignerCheckbox = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <div>
        <FormControlLabel
          {...field}
          checked={this.state.isShowExternalClef}
          control={
            <Checkbox
              color="default"
              disabled={form.values.numberOfNode != 1}
              onChange={(e, value) => this.onExternalClefCheckboxChange(value, form)}
            />
          }
          label={
            <span className={classes.formLabel}>
              {t('connect_to_external_clef')}&nbsp;
              {form.values.numberOfNode != 1 && `(${t('available_when_create_one_node')})`}
            </span>
          }
        />
      </div>
    );
  };

  private externalSignerAddressField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('signer_address')}</div>
          {!!form.errors.externalSignerAddress && form.touched.externalSignerAddress && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.externalSignerAddress)}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-network-external-signer-address"
            placeholder={t('input_signer_address')}
          />
        </div>
      </>
    );
  };

  private externalServerUsernameField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('clef_server_username')}</div>
          {!!form.errors.externalServerUsername && form.touched.externalServerUsername && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.externalServerUsername)}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="external address"
            placeholder={t('input_clef_server_username')}
          />
        </div>
      </>
    );
  };

  private externalHostField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('clef_server_host')}</div>
          {!!form.errors.externalServerHost && form.touched.externalServerHost && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.externalServerHost)}
            </div>
          )}
        </div>
        <div>
          <CustomInput {...field} id="external host" placeholder={t('input_clef_server_host')} />
        </div>
      </>
    );
  };

  private externalPortField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('clef_api_port')}</div>
          {!!form.errors.externalPort && form.touched.externalPort && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.externalPort)}
            </div>
          )}
        </div>
        <div>
          <CustomInputNum
            {...field}
            id="member-external port"
            min={0}
            max={65353}
            placeholder={this.props.t('input_clef_api_port')}
          />
        </div>
      </>
    );
  };

  private externalSshPrivateField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('ssh_private_key_file')}</div>
          {!!form.errors.sshPrivateKey && form.touched.sshPrivateKey && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.sshPrivateKey)}
            </div>
          )}
        </div>
        <div>
          <TextField
            {...field}
            value={undefined}
            type="file"
            className={classes.textField}
            onChange={(event) => this.handleFileUpload(event, form)}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </>
    );
  };

  private imageVersionField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, providers } = this.props;
    const { clusterSelected } = this.state;
    const provider = providers.find(
      (item) => item.value === (clusterSelected && clusterSelected.provider),
    ) || { region: [] as IProviderRegion[] };
    const region = provider.region.find(
      (item) => item.value === (clusterSelected && clusterSelected.region),
    ) || { images: [] as IProviderImage[] };

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('os_version')}</div>
          {!!form.errors.imageVersion && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.imageVersion}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-node-add-ver-os"
            valueSelected={field.value}
            placeholder={this.props.t('select_os_version')}
            items={region.images || []}
          />
        </div>
      </>
    );
  };

  private onCheckboxChange = (value, form: FormikProps<FormValues>) => {
    form.setFieldValue('allowUnprotectedTxs', value);
    this.setState({ checked: value });
  };

  private onNodeVersionChange = (event, form: FormikProps<FormValues>) => {
    form.setFieldValue('nodeVersion', event.target.value);
    if (semver.lt(event.target.value, APPLIED_EIP155_GETH_VERSION)) {
      this.onCheckboxChange(true, form);
    } else {
      this.onCheckboxChange(false, form);
    }
  };

  private handleFileUpload = (event, form: FormikProps<FormValues>) => {
    const file = event.target.files[0];
    form.setFieldValue('sshPrivateKey', file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ sshPrivateKey: e.target?.result?.toString() });
      };
      reader.readAsText(file);
    }
  };

  private nodeVersionField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('node_version')}</div>
          {!!form.errors.nodeVersion && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.nodeVersion}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-node-add-ver-node"
            valueSelected={field.value}
            placeholder={this.props.t('select_node_version')}
            items={nodeGethSelection}
            onChange={(event) => this.onNodeVersionChange(event, form)}
          />
        </div>
      </>
    );
  };

  private clefVersionField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('internal_clef_version')}</div>
          {!!form.errors.clefVersion && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.clefVersion}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-node-add-ver-node"
            valueSelected={field.value}
            placeholder={this.props.t('select_node_version')}
            items={nodeClefSelection}
          />
        </div>
      </>
    );
  };

  private gasPriceField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('gas_price')}</div>
          {!!form.errors.gasPrice && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.gasPrice}
            </div>
          )}
        </div>
        <div>
          <CustomInputNumCommaFormat
            {...field}
            id="member-node-add-gas-price"
            isAllowed={(value) => {
              const numberValue =
                value !== undefined ? Number(value.toString().replace(/,/g, '')) : undefined;
              const floatValue: number | undefined = numberValue;
              return (
                floatValue === undefined || (floatValue >= 0 && floatValue <= DEFAULT_GAS_PRICE_MAX)
              );
            }}
            onChange={(values) => {
              const { formattedValue, value, floatValue } = values;
              form.setFieldValue(field.name, floatValue);
            }}
          />
        </div>
      </>
    );
  };

  private allowUnprotectedTxsCheckbox = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <div>
        <FormControlLabel
          {...field}
          disabled={semver.lt(
            form.values.nodeVersion || DEFAULT_VERSION_GETH,
            APPLIED_EIP155_GETH_VERSION,
          )}
          checked={this.state.checked}
          className={classes.formControlLabel}
          control={
            <Checkbox color="default" onChange={(e, value) => this.onCheckboxChange(value, form)} />
          }
          label={<span className={classes.formLabel}>{t('allow_unprotected_txs')}</span>}
        />
      </div>
    );
  };

  private gcModeField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('garbage_collection_mode')}</div>
          {!!form.errors.gcMode && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.gcMode}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            valueSelected={field.value}
            placeholder={this.props.t('select_garbage_collection_mode')}
            items={nodeGcModeOptions}
          />
        </div>
      </>
    );
  };

  private onExternalClefCheckboxChange = (value, form: FormikProps<FormValues>) => {
    form.setFieldValue('usedExternalSigner', value);
    this.setState({ isShowExternalClef: value });
  };

  private onNumOfNodeChange = (event, form: FormikProps<FormValues>) => {
    form.setFieldValue('numberOfNode', Number(event.target.value));
    this.onExternalClefCheckboxChange(false, form);
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { onClose, networkUuid, createNode, accountSeleted, t } = this.props;
    const { clusterSelected, sshPrivateKey } = this.state;
    const {
      nameOfNode,
      instanceType,
      numberOfNode,
      nodeVersion,
      imageVersion,
      gasPrice,
      gcMode,
      allowUnprotectedTxs,
      usedExternalSigner,
      externalServerUsername,
      externalPort,
      externalSignerAddress,
      externalServerHost,
      clefVersion,
    } = values;

    // Check selected account existence
    if (!accountSeleted) {
      return;
    }

    // check registration of address
    if (!accountSeleted.isRegisteredPaymentAddr) {
      this.setState({ formik: formikActions });
      this.onOpenRegisterAddressDialog();
      return;
    }

    if (!clusterSelected) {
      setSubmitting(false);
      if (window) {
        window.alert(t('cluster_not_selected'));
      }
      return;
    }
    let externalClef;
    if (
      usedExternalSigner &&
      externalServerUsername &&
      externalPort &&
      externalServerHost &&
      externalSignerAddress &&
      sshPrivateKey
    ) {
      if (
        sshPrivateKey &&
        (!SSH_PRIVATE_KEY_PATTERN.test(sshPrivateKey) ||
          sshPrivateKey.length > MAX_CLEF_SERVER_PRIVATE_KEY_LENGTH)
      ) {
        this.props.openSnackBar({
          type: 'error',
          message: this.props.t('invalid_ssh_private_key'),
        });
        setSubmitting(false);
        return;
      }
      externalClef = {
        username: externalServerUsername,
        port: externalPort,
        host: externalServerHost,
        signerAddress: externalSignerAddress.toLowerCase(),
        sshPrivateKey,
      };
    }

    try {
      const result = await createNode({
        input: {
          accountUuid: accountSeleted.accountUuid,
          networkUuid,
          clusterUuid: clusterSelected.clusterUuid,
          nodeName: nameOfNode.trim(),
          nodeNums: numberOfNode || 1,
          serverInfo: {
            instanceType,
            osVersion: imageVersion || void 0,
            nodeVersion: nodeVersion || void 0,
            gasPrice,
            gcMode,
            allowUnprotectedTxs,
            internalClefVersion: clefVersion,
          },
          externalClef,
        },
      });
      const { status, execAt } = result.createNode;

      // handling charge|register card|creation
      if (status.includes('success')) {
        setSubmitting(false);
        onClose();
      } else if (status.includes('needCharge')) {
        // update state
        this.setState({
          requireCard: status.includes('needRegister') ? true : false,
          formik: formikActions,
        });

        if (!accountSeleted) {
          const msg = t('account_not_selected');
          if (window) {
            window.alert(msg);
          }
          throw new Error(msg);
        }

        // check licenses
        const { insufficientLicenses, estimateLicenseFee } = this.props;
        const needs = await insufficientLicenses({
          accountUuid: accountSeleted.accountUuid,
          nodeNum: values.numberOfNode || DEFAULT_MIN_NODES_POA,
          nodeType: values.instanceType as PaymentActions.NodeSizeType,
        });

        const params = {
          purchaseDate: execAt,
          licenses: needs.listInsufficientNodeLicenses.map((l) => ({
            licenseItemId: l.licenseItemId,
            qty: l.qty,
          })),
        };
        const fee = await estimateLicenseFee({
          accountUuid: accountSeleted.accountUuid,
          input: [params],
        });
        this.setState({ estimateDate: execAt });

        if (fee.estimateLicenseFee.estimate.length > 0) {
          this.setState({ estimateFee: fee.estimateLicenseFee });
        }
        this.onOpenConfirmLicenseDialog();
      } else {
        setSubmitting(false);
        onClose(); // unknown status
      }
    } catch (err) {
      setSubmitting(false);
    }
  };

  private toggleAdvanced = (e) => {
    this.setState({
      isShowAdvanced: !this.state.isShowAdvanced,
    });
  };

  private onCloseRegisterAddressDialog = (status: boolean = false) => {
    const { formik } = this.state;
    this.setState({
      openRegisterAddressDialog: false,
      formik: void 0,
    });

    if (formik) {
      if (status) {
        // if registration successed, then start to buy licenses (auto submit)
        formik.submitForm();
      } else {
        formik.setSubmitting(false);
      }
    }
  };

  private onOpenRegisterAddressDialog = () => {
    this.setState({
      openRegisterAddressDialog: true,
    });
  };

  private onCloseConfirmLicenseDialog = (status: number = 0) => {
    const { formik, requireCard } = this.state;
    this.setState({
      openConfirmLicenseDialog: false,
      formik: void 0,
    });
    if (requireCard && status > 0) {
      this.setState({ requireCard: false });
    }

    if (formik) {
      if (status === 2) {
        // if purchase successed, then start creation (auto submit)
        const { accountSeleted, listActiveLicensesSummary, listBillings, listCoupons } = this.props;
        formik.submitForm();

        if (accountSeleted) {
          listBillings({ accountUuid: accountSeleted.accountUuid }).catch((_) => void 0);
          listCoupons({ accountUuid: accountSeleted.accountUuid }).catch((_) => void 0);
          listActiveLicensesSummary({ accountUuid: accountSeleted.accountUuid }).catch(
            (_) => void 0,
          );
        }
      } else {
        formik.setSubmitting(false);
      }
    }
  };

  private onOpenConfirmLicenseDialog = () => {
    this.setState({
      openConfirmLicenseDialog: true,
    });
  };

  private validateSignerAddress = (value: string) => {
    let error: string = '';
    if (value) {
      if (value.indexOf('0x') === 0) {
        value = value.substring(2);
      }
      const allocAddressPattern = /^[0-9a-fA-F]+$/;
      if (!allocAddressPattern.test(value) || value.length !== ALLOC_ADDRESS_LENGTH) {
        error = this.props.t('error_alloc_address');
      }
      return error;
    }
  };

  private validateFileSize = (file) => {
    if (file?.size > VALIDATE_SSH_PRIVATE_KEY_SIZE) {
      return this.props.t('ssh_private_key_too_large');
    }
  };
}

const styles: StyleRules = {
  root: {},
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  dialogContentRoot: {},
  // Advance Menu
  advancedBtnArea: {
    marginTop: 18,
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    height: 24,
  },
  separateLine: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
  advancedBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'inline-block',
    backgroundColor: whiteColor,
    paddingLeft: 10,
    paddingRight: 10,
    ...defaultFont,
    fontSize: 16,
    color: denimColor,
    cursor: 'pointer',
  },
  advancedIcon: {
    marginRight: 5,
    width: 14,
  },
  // submit button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  inputUpload: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  },
  textField: {
    '& .MuiInputBase-root': {
      ...defaultFont,
      height: 40,
      fontSize: 13,
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: pattensBlueColor,
      backgroundColor: '#F5F5F5',
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& .MuiAutocomplete-input': {
        padding: '0 15px 0 15px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  },
};

const mapStateToProps = (store: IStore): IProps => ({
  providers: store.appState.providers || [],
  accountSeleted: store.appState.accountSeleted,
  networkSelected: store.appState.networkSelected,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  createNode: (args: NetworkActions.MutationCreateNodeArgs) =>
    dispatch(NetworkActions.createNode(args)),
  insufficientLicenses: (args: PaymentActions.QueryListInsufficientNodeLicensesArgs) =>
    dispatch(PaymentActions.listInsufficientNodeLicenses(args)),
  estimateLicenseFee: (args: PaymentActions.QueryEstimateLicenseFeeArgs) =>
    dispatch(PaymentActions.estimateLicenseFee(args)),
  listActiveLicensesSummary: (args: PaymentActions.QueryListActiveLicensesSummaryArgs) =>
    dispatch(PaymentActions.listActiveLicensesSummary(args)),
  listBillings: (args: PaymentActions.QueryListBillingsArgs) =>
    dispatch(PaymentActions.listBillings(args)),
  listCoupons: (args: PaymentActions.QueryListCouponsArgs) =>
    dispatch(PaymentActions.listCoupons(args)),
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => dispatch(AppActions.openSnackBar(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateNodeDialog)),
);

type FormValues = {
  nameOfNode: string;
  instanceType: Gtypes.NodeSizeType;
  numberOfNode: number;
  nodeVersion?: string;
  clefVersion?: string;
  imageVersion?: string;
  gasPrice?: number;
  gcMode?: Gtypes.GcMode;
  allowUnprotectedTxs?: boolean;
  usedExternalSigner?: boolean;
  externalSignerAddress?: string;
  externalServerUsername?: string;
  externalServerHost?: string;
  externalPort?: number;
  sshPrivateKey?: string;
};
