import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  lightSlateGreyColor,
  snowColor,
  pattensBlueColor,
  romanColor,
  dimGrayColor,
  denimColor,
  whiteSmokeColor,
  whiteColor,
} from '~/styles/themes/common-styles/color';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import { INode, ICluster, INetworkProvider } from '~/types/network-types';
import { Account } from '~/types/account-types';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SubmitButton from './submit-button';
import Grid from '@mui/material/Grid';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
// Util
import { renderClusterRegionLabel } from '~/utilities/render-utils';

interface IStateProps {
  providers: INetworkProvider[];
  accountSeleted?: Account;
}

interface IDispProps {
  deleteNode: (
    args: NetworkActions.MutationDeleteNodeArgs,
  ) => Promise<NetworkActions.DELETE_NODE_RESULT_TYPE>;
  checkNode: (
    args: NetworkActions.QueryCheckNodeRemovalStatusArgs,
  ) => Promise<NetworkActions.NODE_REMOVAL_STATUS_RESULT_TYPE>;
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => void;
}

interface IProps
  extends IStateProps,
    IDispProps,
    RouteComponentProps,
    WithStyles<typeof styles>,
    WithTranslation {
  networkUuid: string;
  cluster: ICluster;
  node: INode;
  open: boolean;
  onClose: () => void;
  organization?: string;
}

interface IState {
  isSubmitting: boolean;
}

class DeleteNodeDialog extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
    };
  }

  componentWillUnmount() {
    this.props.onClose();
    this.setState({
      isSubmitting: false,
    });
  }

  public render() {
    const { classes, open, onClose, node, cluster, organization, t, providers } = this.props;

    const { isSubmitting } = this.state;

    return (
      <CustomDialog open={open} onClose={onClose}>
        <CustomDialogTitle>
          <div id="member-node-del-title">{t('delete_node')}</div>
        </CustomDialogTitle>
        <CustomDialogContent>
          <div id="member-node-del-detail" className={classes.content}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <div className={classes.itemLabel}>{t('node_id')}</div>
                <div className={classes.itemValue}>{node.nodeUuid}</div>
              </Grid>
              <Grid item md={6}>
                <div className={classes.itemLabel}>{t('organization')}</div>
                <div className={classes.itemValue}>{organization || 'N/A'}</div>
              </Grid>
              <Grid item md={6}>
                <div className={classes.itemLabel}>{t('cluster_name')}</div>
                <div className={classes.itemValue}>{cluster.clusterName}</div>
              </Grid>
              <Grid item md={12}>
                <div className={classes.itemLabel}>{t('signing_address')}</div>
                <div className={classes.itemValue}>{node.nodeInfo.coinbaseAddress}</div>
              </Grid>
              <Grid item md={12}>
                <div className={classes.itemLabel}>{t('enode')}</div>
                <div className={classes.itemValue}>{node.nodeInfo.enode}</div>
              </Grid>
              <Grid item md={6}>
                <div className={classes.itemLabel}>{t('region')}</div>
                <div className={classes.itemValue}>
                  {renderClusterRegionLabel(providers, cluster)}
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={classes.itemLabel}>{t('instance_type')}</div>
                <div className={classes.itemValue}>{t(node.serverInfo.instanceType || '')}</div>
              </Grid>
            </Grid>
          </div>
          <div id="member-node-del-attention" className={classes.warningText}>
            {t('are_you_sure_you_want_to_delete_this_node')}
          </div>
        </CustomDialogContent>
        <CustomDialogActions>
          <Button
            id="member-node-del-cancel"
            disabled={isSubmitting}
            className={classes.leftBtn}
            variant="contained"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
          <SubmitButton
            id="member-node-del-submit"
            isValid={true}
            isSubmitting={isSubmitting}
            label={t('delete')}
            submittingLabel={t('deleting')}
            onClick={this.onDeleteNode}
          />
        </CustomDialogActions>
      </CustomDialog>
    );
  }

  private onDeleteNode = async () => {
    const {
      networkUuid,
      cluster,
      node,
      deleteNode,
      checkNode,
      openSnackBar,
      onClose,
      t,
      accountSeleted,
    } = this.props;

    if (!accountSeleted) {
      return;
    }

    this.setState({ isSubmitting: true });

    try {
      // check status before start
      const { checkNodeRemovalStatus: status } = await checkNode({
        accountUuid: accountSeleted.accountUuid,
        networkUuid: networkUuid,
        clusterUuid: cluster.clusterUuid,
        nodeUuid: node.nodeUuid,
      });

      if (status.detectBcSigner) {
        openSnackBar({
          type: 'error',
          message: t('you_can_not_delete_a_validator_node'),
        });
        throw new Error('you_can_not_delete_a_validator_node');
      }

      if (!status.syncedBlock || !status.syncedBcSigner) {
        if (!window.confirm(t('warn_maybe_not_synced_network'))) {
          throw new Error('canceled');
        }
      }

      // do action
      await deleteNode({
        input: {
          accountUuid: accountSeleted.accountUuid,
          networkUuid: networkUuid,
          clusterUuid: cluster.clusterUuid,
          nodeUuid: node.nodeUuid,
        },
      });

      onClose();
      this.setState({ isSubmitting: false });

      if (cluster.nodes.length > 1) {
        this.props.history.push(`/network/${networkUuid}/cluster/${cluster.clusterUuid}/overview`);
      } else {
        this.props.history.push(`/network/${networkUuid}/overview`);
      }
    } catch (error) {
      this.setState({ isSubmitting: false });
    }
  };
}

const styles = createStyles({
  root: {},
  content: {
    padding: 30,
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
    wordBreak: 'break-word',
  },
  itemLabel: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  itemValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  warningText: {
    ...defaultFont,
    fontSize: 18,
    color: romanColor,
    textAlign: 'center',
    marginTop: 25,
    marginBottom: 10,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
});

const mapStateToProps = (store: IStore, ownProps): IStateProps => ({
  providers: store.appState.providers || [],
  accountSeleted: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  deleteNode: (args: NetworkActions.MutationDeleteNodeArgs) =>
    dispatch(NetworkActions.deleteNode(args)),
  checkNode: (args: NetworkActions.QueryCheckNodeRemovalStatusArgs) =>
    dispatch(NetworkActions.checkNodeRemovalStatus(args)),
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => dispatch(AppActions.openSnackBar(args)),
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeleteNodeDialog))),
);
