import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  lightSlateGreyColor,
  dimGrayColor,
  whiteSmokeColor,
  denimColor,
} from '~/styles/themes/common-styles/color';

interface IProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  className?: string;
  leftBtnLabel?: string;
  righBtnLabel?: string;
  onClickLeftBtn?: () => void;
  onClickRigtBtn?: () => void;
  hideSubmitBtn?: boolean;
}

const settingDialog: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  className,
  children,
  open,
  onClose,
  title,
  leftBtnLabel,
  righBtnLabel,
  onClickRigtBtn,
  onClickLeftBtn,
  hideSubmitBtn,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      classes={{
        paper: classes.paper,
      }}
    >
      <div className={classes.title} id="member-profile-setting-title">
        <img src="/images/icons/setting_ico.svg" className={classes.settingIcon} />
        {title}
      </div>
      <div className={classes.content}>{children}</div>
      {!hideSubmitBtn && (
        <div className={classes.btnArea}>
          <Button
            id="member-profile-setting-cancel"
            className={classes.leftBtn}
            variant="contained"
            onClick={onClickLeftBtn}
          >
            {leftBtnLabel}
          </Button>
          <Button
            id="member-profile-setting-submit"
            className={classes.rightBtn}
            variant="contained"
            onClick={onClickRigtBtn}
          >
            {righBtnLabel}
          </Button>
        </div>
      )}

      <IconButton className={classes.closeBtn} onClick={onClose} id="member-profile-setting-close">
        <img src="/images/icons/close-ico.svg" className={classes.closeIcon} />
      </IconButton>
    </Dialog>
  );
};

settingDialog.defaultProps = {
  open: false,
  leftBtnLabel: 'Cancel',
  righBtnLabel: 'OK',
};

const styles: StyleRules = {
  root: {},
  paper: {
    width: '90%',
    maxWidth: 700,
    paddingTop: 56,
    paddingRight: 89,
    paddingLeft: 89,
    paddingBottom: 55,
    position: 'relative',
    margin: '48px auto',
    verticalAlign: 'top',
  },
  title: {
    ...defaultFontMedium,
    fontSize: 24,
    color: lightSlateGreyColor,
    display: 'flex',
    lineHeight: 1,
  },
  btnArea: {
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
  content: {},
  settingIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  closeIcon: {
    width: 16,
    height: 16,
  },
  closeBtn: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
};

export default withStyles(styles)(settingDialog);
