import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import Button from '@mui/material/Button';
import * as Yup from 'yup';

import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  lightSlateGreyColor,
  snowColor,
  pattensBlueColor,
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  whiteColor,
  denimColor,
} from '~/styles/themes/common-styles/color';
import * as NetworkActions from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import { INetwork } from '~/types/network-types';
import { Account } from '~/types/account-types';
import SubmitButton from './submit-button';
import CustomInput from './custom-input';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

interface IProps {
  account: Account;
  network: INetwork;
  open: boolean;
  onClose: () => void;
}

interface IDispProps {
  destroyNetwork: (
    args: NetworkActions.MutationDestroyNetworkArgs,
  ) => Promise<NetworkActions.DESTROY_NETWORK_RESULT_TYPE>;
}

interface IState {
  isSubmitting: boolean;
  isProcessing: boolean;
  inputValue: string;
}

class DeleteNetworkDialog extends React.Component<
  IProps & IDispProps & RouteComponentProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  private destroyNetworkValidateSchema = Yup.object().shape({});

  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isProcessing: false,
      inputValue: '',
    };
  }

  componentWillUnmount() {
    this.props.onClose();
    this.setState({
      isSubmitting: false,
      isProcessing: false,
      inputValue: '',
    });
  }

  public render() {
    const { classes, open, onClose, network, account, t } = this.props;
    const { inputValue } = this.state;

    // validate condition of deletion
    const numberOfSigners = network.clusters.reduce(
      (pre, cur) => pre + cur.nodes.filter((node) => node.nodeInfo.signer).length,
      0,
    );
    const mineOfSigners = network.clusters
      .filter((c) => c.accountUuid === account.accountUuid)
      .reduce((pre, cur) => pre + cur.nodes.filter((node) => node.nodeInfo.signer).length, 0);

    let allowDelete = true;

    if (mineOfSigners === 0 && numberOfSigners > 0) {
      allowDelete = false;
    }
    if (numberOfSigners !== mineOfSigners && numberOfSigners > 1) {
      allowDelete = false;
    }

    return (
      <CustomDialog open={open} onClose={this.onCloseDialog} scroll="body">
        <Formik
          initialValues={destroyNetworkInitialValues}
          validationSchema={this.destroyNetworkValidateSchema}
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting }) => {
            // check processing status
            let isRemoving = false;

            if (network.clusters && network.clusters.length > 0) {
              if (network.clusters[0].status === 'removing') {
                isRemoving = true;
              }
            } else {
              isRemoving = isSubmitting ? true : false;
            }
            const processingText = t(
              isRemoving
                ? t('removing_nodes_and_clusters')
                : allowDelete
                ? 'are_you_sure_you_want_to_delete_this_network'
                : 'network_has_more_than_one_signer',
            );

            // set always 'isValid = true' because there is no input and validation rule currently.
            isValid = allowDelete;

            return (
              <Form>
                <CustomDialogTitle>
                  <div id="member-network-del-title">{t('destroy_network')}</div>
                </CustomDialogTitle>
                <CustomDialogContent>
                  <div id="member-network-del-detail" className={classes.contentArea}>
                    <div className={classNames(classes.clusterItemInfo, classes.leftContentItem)}>
                      <div className={classes.subTitle}>{t('network_name')}</div>
                      <div className={classes.detailValue}>{network.networkName}</div>
                    </div>
                    <div className={classes.clusterItemInfo}>
                      <div className={classes.subTitle}>{t('network_id')}</div>
                      <div className={classes.detailValue}>{network.networkUuid}</div>
                    </div>
                    <div className={classNames(classes.clusterItemInfo, classes.rightContentItem)}>
                      <div className={classes.subTitle}>{t('number_of_nodes')}</div>
                      <div className={classes.detailValue}>
                        {network.clusters
                          .map((c) => (c.nodes || []).length)
                          .reduce((pre, cur) => pre + cur, 0)}
                      </div>
                    </div>
                    <div className={classes.clusterItemInfo}>
                      <div className={classes.subTitle}>{t('number_of_cluster')}</div>
                      <div className={classes.detailValue}>{network.clusters.length}</div>
                    </div>
                  </div>
                  <div id="member-network-del-attention" className={classes.warningText}>
                    {!isRemoving ? processingText : t('removing_nodes_and_clusters')}
                  </div>
                  {!isRemoving && (
                    <div className={classes.input}>
                      <div className={classes.inputLabel}>{t('network_name')}</div>
                      <CustomInput
                        value={inputValue}
                        onChange={this.onInputChange}
                        placeholder={t('destroy_network_dialog_input_placeholder')}
                      />
                    </div>
                  )}
                </CustomDialogContent>
                <CustomDialogActions>
                  <Button
                    id="member-network-del-cancel"
                    disabled={isSubmitting}
                    className={classes.leftBtn}
                    variant="contained"
                    onClick={this.onCloseDialog}
                  >
                    {t('cancel')}
                  </Button>
                  <SubmitButton
                    id="member-network-del-submit"
                    isSubmitting={isSubmitting}
                    label={t('delete')}
                    submittingLabel={t('deleting')}
                    isValid={isRemoving ? isValid : inputValue === network.networkName}
                  />
                </CustomDialogActions>
              </Form>
            );
          }}
        />
      </CustomDialog>
    );
  }

  private onInputChange = (e) => {
    this.setState({
      inputValue: e.target.value,
    });
  };

  private onCloseDialog = () => {
    const { onClose } = this.props;
    this.setState({ inputValue: '' });
    onClose();
  };

  private onSubmit = async () => {
    const { destroyNetwork, network, onClose, account } = this.props;
    try {
      this.setState({ isSubmitting: true });
      this.setState({ isProcessing: true });
      this.setState({ inputValue: '' });
      const result = await destroyNetwork({
        input: {
          accountUuid: account.accountUuid,
          networkUuid: network.networkUuid,
          force: false, // this field is currently not used
        },
      });
      if (!result.destroyNetwork) {
        throw new Error();
      }
    } catch (err) {
      onClose();
      this.setState({
        isSubmitting: false,
        isProcessing: false,
        inputValue: '',
      });
    }
  };
}

const styles: StyleRules = {
  root: {},
  nodeTitle: {
    ...defaultFontMedium,
    fontSize: 15,
    color: lightSlateGreyColor,
  },
  subTitle: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  contentArea: {
    padding: 30,
    display: 'grid',
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
    gridRowGap: '15px',
    gridColumnGap: '15px',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
  },
  clusterItemInfo: {},
  leftContentItem: {
    padding: 0,
  },
  rightContentItem: {
    padding: 0,
  },
  detailValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
    wordBreak: 'break-all',
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 25,
    whiteSpace: 'pre-wrap',
  },
  input: {
    marginTop: 20,
  },
  inputLabel: {
    ...defaultFontBold,
    fontSize: 12,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
};

type DestroyNetworkFormValues = {
  networkName: string;
  numberSigners: number;
};

const destroyNetworkInitialValues: DestroyNetworkFormValues = {
  networkName: '',
  numberSigners: 2,
};

const mapStateToProps = (store: IStore, ownProps) => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({
  destroyNetwork: (args: NetworkActions.MutationDestroyNetworkArgs) =>
    dispatch(NetworkActions.destroyNetwork(args)),
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeleteNetworkDialog))),
);
