import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as AppActions from '~/stores/actions/app-action';
// Component
import Button from '@mui/material/Button';
import CustomDialog from '../../../custom-dialog';
import CustomDialogTitle from '../../../custom-dialog-title';
import CustomDialogContent from '../../../custom-dialog-content';
import CustomDialogActions from '../../../custom-dialog-actions';
import CustomSelect from '../../../custom-select';
import CustomInput from '../../../custom-input';
import SubmitButton from '../../../submit-button';
// Form
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import classNames from 'classnames';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import { romanColor, dimGrayColor, whiteSmokeColor } from '~/styles/themes/common-styles/color';
// Type
import { AccountRoleType, Account } from '~/types/account-types';
// React i18next
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

interface IStateProps {
  account?: Account;
  selectedAccountRole?: AccountRoleType;
}

interface IDispProps {
  addMember: (args: AppActions.MutationAddMemberArgs) => Promise<AppActions.ADD_MEMBER_RESULT_TYPE>;
  listMembers: (
    args: AppActions.QueryToListMembersArgs,
  ) => Promise<AppActions.LIST_MEMBERS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  onClose: () => void;
  open: boolean;
}

interface IState {}

class AddUserDialog extends React.Component<IProps, IState> {
  constructor(props: IProps & IDispProps) {
    super(props);
    this.state = {};
  }

  private initialValues: FormValues = {
    email: '',
    accountRole: '',
  };

  private validateSchema = Yup.object().shape<FormValues>({
    email: Yup.string().required(this.props.t('required')).email(this.props.t('invalid_email')),
    accountRole: Yup.string().required(this.props.t('required')),
  });

  public render() {
    const { classes, open, onClose, t } = this.props;

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <Formik
          initialValues={this.initialValues}
          validationSchema={this.validateSchema}
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-account-members-add-title-area">{t('add_member')}</div>
              </CustomDialogTitle>
              {/* Render Form Field */}
              <CustomDialogContent>
                <div title="email-field">
                  <Field name="email" render={this.emailField} />
                </div>
                <div className={classes.formSection} title="account-role-field">
                  <Field name="accountRole" render={this.accountRoleField} />
                </div>
              </CustomDialogContent>
              {/* Render Submit Button */}
              <CustomDialogActions>
                <Button
                  id="member-account-members-add-submit-cancel"
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={onClose}
                >
                  <Trans i18nKey={'cancel'} />
                </Button>
                <SubmitButton
                  id="member-account-members-add-submit-add"
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  label={t('create')}
                  submittingLabel={t('creating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private emailField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>
            <Trans i18nKey={'email'} />
          </div>
          {!!form.errors.email && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.email}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-account-members-add-input-email"
            placeholder={t('input_email_address')}
          />
        </div>
      </>
    );
  };

  private accountRoleField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t, selectedAccountRole } = this.props;
    const options: { value: AccountRoleType; label: string }[] =
      selectedAccountRole === 'owner'
        ? [
            { value: 'owner', label: 'Owner' },
            { value: 'admin', label: 'Admin' },
            { value: 'member', label: 'Member' },
            { value: 'guest', label: 'Guest' },
          ]
        : selectedAccountRole === 'admin'
        ? [
            { value: 'member', label: 'Member' },
            { value: 'guest', label: 'Guest' },
          ]
        : [];

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>
            <Trans i18nKey={'organization_role'} />
          </div>
          {!!form.errors.accountRole && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.accountRole}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-account-members-add-input-role"
            valueSelected={field.value}
            placeholder={t('select_role')}
            items={options}
          />
        </div>
      </>
    );
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { onClose, addMember, account, listMembers } = this.props;

    if (!account) {
      return;
    }

    try {
      await addMember({
        input: {
          accountUuid: account.accountUuid,
          email: values.email,
          accountRole: values.accountRole as AccountRoleType,
        },
      });
      onClose();
      listMembers({ accountUuid: account.accountUuid }).catch(console.log);
    } catch (error) {
      setSubmitting(false);
    }
  };
}

const styles = createStyles({
  root: {},
  dialogPaper: {
    maxWidth: 620,
  },
  dialogTitle: {
    paddingBottom: 25,
  },
  // Form Field
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  // Submit Button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  account: store.appState.accountSeleted,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  addMember: (args: AppActions.MutationAddMemberArgs) => dispatch(AppActions.addMember(args)),
  listMembers: (args: AppActions.QueryToListMembersArgs) => dispatch(AppActions.listMembers(args)),
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddUserDialog)),
);

type FormValues = {
  email: string;
  accountRole: string;
};
