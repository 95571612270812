import React from 'react';
// Redux
import { connect } from 'react-redux';
import * as NetworkActions from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';
// Component
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import Button from '@mui/material/Button';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/submit-button';
import CustomSelect from '~/components/common/custom-select';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  snowColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';
// Type
import { INetwork, IConsortium, IConsortiumRoleType } from '~/types/network-types';
import { Account } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface IStateProps {}

interface IDispProps {
  updateConsortiumRole: (
    args: NetworkActions.MutationUpdateConsortiumRoleArgs,
  ) => Promise<NetworkActions.UPDATE_CONSORTIUM_ROLE_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
  network: INetwork;
  account: Account;
  consortium: IConsortium;
}

interface IState {}

class EditConsortiumRoleDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { classes, t, open, onClose, consortium, network } = this.props;

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <Formik<FormValues>
          initialValues={{ role: consortium.role }}
          enableReinitialize
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form>
              <CustomDialogTitle>{t('edit_role')}</CustomDialogTitle>
              <CustomDialogContent>
                <div className={classes.consortiumInfo}>
                  <div className={classes.consortiumInfoItem}>
                    <div className={classes.consortiumInfoTitle}>{t('network_name')}</div>
                    <div className={classes.consortiumInfoValue}>{network.networkName}</div>
                  </div>
                  <div className={classes.consortiumInfoItem}>
                    <div className={classes.consortiumInfoTitle}>{t('network_id')}</div>
                    <div className={classes.consortiumInfoValue}>{network.networkUuid}</div>
                  </div>
                  <div className={classes.consortiumInfoItem}>
                    <div className={classes.consortiumInfoTitle}>{t('organization_name')}</div>
                    <div className={classes.consortiumInfoValue}>{consortium.accountName}</div>
                  </div>
                  <div className={classes.consortiumInfoItem}>
                    <div className={classes.consortiumInfoTitle}>{t('organization_id')}</div>
                    <div className={classes.consortiumInfoValue}>{consortium.accountUuid}</div>
                  </div>
                </div>
                <div className={classes.formSection} title="name-field">
                  <Field name="role" render={this.roleField} />
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <Button className={classes.leftBtn} variant="contained" onClick={onClose}>
                  {t('cancel')}
                </Button>
                <SubmitButton
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private roleField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('role')}</div>
          {Boolean(form.errors.role) && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.role}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            valueSelected={field.value}
            placeholder={t('select_role')}
            items={getRoleOptions(t)}
          />
        </div>
      </>
    );
  };

  onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { network, account, onClose, consortium, updateConsortiumRole } = this.props;
    const { setSubmitting } = formikActions;
    try {
      if (consortium.accountUuid) {
        await updateConsortiumRole({
          input: {
            networkUuid: network.networkUuid,
            accountUuid: account.accountUuid,
            targetAccountUuid: consortium.accountUuid,
            role: values.role,
          },
        });
      }
      onClose();
    } catch (error) {
      console.log(error.message);
    }
    setSubmitting(false);
  };
}

const styles = createStyles({
  root: {},
  dialogPaper: {},
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  consortiumInfo: {
    padding: '15px 30px',
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
  },
  consortiumInfoItem: {
    display: 'inline-block',
    padding: '15px 0',
    width: '50%',
    maxWidth: '50%',
  },
  consortiumInfoTitle: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  consortiumInfoValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  formSection: {
    marginTop: 15,
    marginBottom: 8,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontBold,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({
  updateConsortiumRole: (args: NetworkActions.MutationUpdateConsortiumRoleArgs) =>
    dispatch(NetworkActions.updateConsortiumRole(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditConsortiumRoleDialog)),
);

interface FormValues {
  role: IConsortiumRoleType;
}

const getRoleOptions = (t: TFunction) => [
  {
    value: 'admin',
    label: t('consortium_admin'),
  },
  {
    value: 'member',
    label: t('consortium_member'),
  },
];
