import React from 'react';
import moment from 'moment-timezone';
import firebase from 'firebase';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as AppActions from '~/stores/actions/app-action';
// Route
import { withRouter, RouteComponentProps } from 'react-router-dom';
// Type
import { Profile } from '~/types/account-types';

import { withStyles, WithStyles } from '@mui/styles';
import { createStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import SubmitButton from '~/components/common/submit-button';
import CustomSelect from '~/components/common/custom-select';
import { defaultFontRegular, defaultFontBold } from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  nightRiderColor,
  aliceBlue3Color,
  whisperColor,
  pattensBlueColor,
  dimGray2Color,
  pelorousColor2,
  denimColor,
} from '~/styles/themes/common-styles/color';
// Component
import Layout from './layout';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
// Contents
import { getTermsOfServiceText } from './contents';
import { isDevMode, getGeolocationFromIpAddress } from '~/utilities/utils';
import { AppRoutePath } from '~/AppRouter';

const TimeZoneList = moment.tz.names();

// types
interface IStateProps {
  profile?: Profile;
}

interface IDispProps {
  acceptTermsOfService: (
    args: AppActions.MutationAcceptTermsOfServiceArgs,
  ) => Promise<AppActions.ACCEPT_TERMS_OF_SERVICE_RESULT_TYPE>;
}

interface Props
  extends IStateProps,
    IDispProps,
    RouteComponentProps,
    WithStyles<typeof styles>,
    WithTranslation {}

interface IState {
  checked: boolean;
  isProceeding: boolean;
  selectedTz: string;
}

class TermsOfService extends React.Component<Props, IState> {
  private mounted = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      checked: false,
      isProceeding: false,
      selectedTz: 'UTC',
    };
  }

  async componentDidMount() {
    this.mounted = true;
    this.setState({ isProceeding: true });

    const geoInfo = await getGeolocationFromIpAddress();

    if (isDevMode()) {
      console.log(geoInfo, this.state.selectedTz);
    }
    if (
      geoInfo.timezone &&
      moment.tz.zone(geoInfo.timezone) &&
      geoInfo.timezone !== this.state.selectedTz
    ) {
      this.setState({ selectedTz: geoInfo.timezone });
    }
    this.setState({ isProceeding: false });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  public render() {
    const { classes, t, i18n } = this.props;
    const { checked, isProceeding, selectedTz } = this.state;

    const zoneSelection = TimeZoneList.filter((z) => z.substr(0, 4) !== 'Etc/').map((z) => ({
      value: z,
      label: z,
    }));

    return (
      <Layout>
        <div className={classes.root}>
          <Paper classes={{ root: classes.paperRoot }}>
            <div className={classes.header}>
              <div className={classes.headerTitle}>{t('terms_of_service_header_title')}</div>
            </div>
            <div className={classes.content}>
              <div className={classes.termOfService}>{getTermsOfServiceText(i18n.language)}</div>
            </div>
            <div className={classes.content}>
              <div className={classes.combineBlockContent}>
                <div className={classes.timezoneLabel}>{t('select_timezone')}</div>
                <CustomSelect
                  id="member-terms-of-service-select-timezone"
                  valueSelected={selectedTz}
                  placeholder={t('select_timezone')}
                  items={zoneSelection}
                  onChange={this.onTimezoneSelectorChange}
                  disabled={isProceeding}
                  classes={{
                    root: classes.bgNone,
                    selectPaper: classes.selectPaper,
                  }}
                />
              </div>
            </div>
            <div className={classes.checkboxWrapper}>
              <div className={classes.checkbox} onClick={this.onToggleCheckBox}>
                {checked ? (
                  <img src="/images/icons/check_on.svg" alt="" />
                ) : (
                  <img src="/images/icons/check_off.svg" alt="" />
                )}
                <span>{t('terms_of_service_checkbox_label')}</span>
              </div>
            </div>
            <div className={classes.action}>
              <Button onClick={this.onDisagreeBtnClick} className={classes.disagreeBtn}>
                {t('terms_of_service_disagree_button_label')}
              </Button>
              <SubmitButton
                isValid={checked}
                isSubmitting={isProceeding}
                label={t('terms_of_service_agree_button_label')}
                onClick={this.onAgreeBtnClick}
                classes={{
                  root: classes.agreeBtn,
                }}
              />
            </div>
          </Paper>
        </div>
      </Layout>
    );
  }

  private onTimezoneSelectorChange = (e) => {
    if (this.mounted) {
      const newTimezone = e.target.value as string;
      this.setState({ selectedTz: newTimezone });
    }
  };

  private onToggleCheckBox = () => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  onDisagreeBtnClick = async () => {
    window.location.href = AppRoutePath.Logout;
  };

  private onAgreeBtnClick = async () => {
    this.setState({
      isProceeding: true,
    });
    try {
      await this.props.acceptTermsOfService({
        input: {
          timezone: this.state.selectedTz,
          language: this.props.i18n.language,
        },
      });
      this.props.history.push(AppRoutePath.Dashboard);
    } catch (error) {
      // Do nothing
    }
    this.setState({
      isProceeding: false,
    });
  };
}

const styles = createStyles({
  root: {
    width: '100%',
    paddingTop: 75,
    paddingBottom: 50,
    display: 'flex',
    backgroundImage: `url('/images/backgrounds/termofservice_bg.svg')`,
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '110%',
    height: '100%',
  },
  paperRoot: {
    borderRadius: 4,
    width: 700,
    textAlign: 'center',
    margin: 'auto',
  },
  header: {
    height: 130,
    backgroundImage: `url('/images/backgrounds/termsofserivce_header_bg.png')`,
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  headerTitle: {
    ...defaultFontRegular,
    fontSize: 24,
    color: whiteColor,
  },
  content: {
    padding: '40px 40px 0 40px',
  },
  termOfService: {
    borderRadius: 4,
    backgroundColor: aliceBlue3Color,
    padding: '20px 30px',
    height: 325,
    overflow: 'auto',
    whiteSpace: 'pre-wrap',
    ...defaultFontRegular,
    fontSize: 12,
    color: nightRiderColor,
    lineHeight: '20px',
    textAlign: 'left',
  },
  combineBlockContent: {
    display: 'flex',
    borderRadius: 4,
    backgroundColor: aliceBlue3Color,
    margin: '15px 0 10px 0',
    padding: '17px 71px',
    height: 66,
  },
  timezoneLabel: {
    ...defaultFontRegular,
    width: '40%',
    margin: 'auto',
    fontSize: 14,
  },
  checkboxWrapper: {
    paddingTop: 30,
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...defaultFontRegular,
    fontSize: 14,
    color: dimGray2Color,
    '& img': {
      marginRight: 10,
    },
    cursor: 'pointer',
  },
  action: {
    padding: '30px 30px 50px',
  },
  disagreeBtn: {
    backgroundImage: `linear-gradient(to bottom, ${whiteColor} , ${whisperColor})`,
    width: 160,
    height: 36,
    border: `1px solid ${pattensBlueColor}`,
    ...defaultFontRegular,
    fontSize: 14,
    color: dimGray2Color,
    marginRight: 20,
    textTransform: 'none',
  },
  agreeBtn: {
    backgroundImage: `linear-gradient(to bottom, ${pelorousColor2} , ${denimColor})`,
    width: 160,
    height: 36,
    border: `1px solid ${pattensBlueColor}`,
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    textTransform: 'none',
  },
  bgNone: {
    background: whiteColor,
  },
  selectMenu: {
    ...defaultFontRegular,
    padding: '6px 0px 15px 9px !important',
    textAlign: 'initial',
    fontSize: 14,
    background: 'url("/images/icons/arrow-down.svg") no-repeat right',
    backgroundPositionX: 300,
    backgroundPositionY: 12,
    '&:focus': {
      paddingBottom: '0px !important',
      backgroundColor: `${whiteColor} !important`,
      background: 'url("/images/icons/arrow-down.svg") no-repeat right',
      backgroundPositionX: 300,
      backgroundPositionY: 12,
    },
  },
  icon: {
    top: '3px !important',
    display: 'none',
  },
  selectPaper: {
    textAlign: 'start',
    height: '32px',
    marginLeft: 23,
    backgroundColor: whiteColor,
  },
});

const mapStateToProps = (store: IStore, ownProps) => ({
  profile: store.appState.profile,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  acceptTermsOfService: (args: AppActions.MutationAcceptTermsOfServiceArgs) =>
    dispatch(AppActions.acceptTermsOfService(args)),
});

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation('member')(TermsOfService)),
  ),
);
