import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
// Component
import Table from '@mui/material/Table';
import LoadingIcon from '~/components/common/loading-icon';
import LGButton from '~/components/common/lg-button';
import ImgIcon from '~/components/common/img-icon';
import EditRulesDialog from '~/components/common/edit-node-rules-dialog';
import TableHeadCustom from '~/components/common/table-head';
import TableBodyCustom from '~/components/common/table-body';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import TableRowHeadCustom from '~/components/common/table-row-head';
import TableRowBodyCustom from '~/components/common/table-row-body';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Type
import {
  INetwork,
  ICluster,
  INode,
  IRestrictionNetwork,
  IRestrictionViewRules,
} from '~/types/network-types';
import { AccountRoleType, NetworkRoleType, Account } from '~/types/account-types';
import { protocolTypeSelection } from '~/types/network-selection';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  whiteSmokeColor,
  denimColor,
  matterhornColor,
  snowColor,
} from '~/styles/themes/common-styles/color';
import { oneLineText } from '~/styles/themes/common-styles/misc';
// Utils
import { displayPortRange } from '~/utilities/render-utils';
// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';

interface IStateProps {
  rules: IRestrictionNetwork[];
  isLoading: boolean;
  isReloading: boolean;
  isCheckingRole: boolean;
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
  accountSelected?: Account;
}

interface IDispProps {
  listNodeRules: (
    args: NetworkActions.QueryListNodeRestrictionsArgs,
  ) => Promise<NetworkActions.LIST_NODE_RESTRICTIONS_RESULT_TYPE>;
  getNetworkRole: (
    args: NetworkActions.QueryGetNetworkRoleArgs,
  ) => Promise<NetworkActions.GET_NETWORK_ROLE_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  network: INetwork;
  cluster: ICluster;
  node: INode;
}

interface IState {
  openEditRulesDialog: boolean;
}

class SecurityTab extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      openEditRulesDialog: false,
    };
  }

  componentDidMount() {
    this.listNodeRules();
  }

  listNodeRules = () => {
    const { network, listNodeRules, cluster, node, accountSelected } = this.props;
    const restrictionList = this.getRestrictionList();
    if (!restrictionList) {
      accountSelected &&
        listNodeRules({
          input: {
            accountUuid: accountSelected.accountUuid,
            networkUuid: network.networkUuid,
            clusterUuid: cluster.clusterUuid,
            nodeUuid: node.nodeUuid,
          },
        }).catch(console.log);
    }
  };

  public render() {
    const {
      t,
      classes,
      network,
      cluster,
      node,
      isLoading,
      isReloading,
      isCheckingRole,
      selectedAccountRole,
      selectedNetworkRole,
    } = this.props;
    const editableRules =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin';
    const { openEditRulesDialog } = this.state;
    const rules = this.getNodeRuleList();

    if (isLoading || isReloading || isCheckingRole) {
      return (
        <div className={classes.root}>
          <div className={classes.loadingArea}>
            <LoadingIcon />
          </div>
        </div>
      );
    }

    document.title = TAB_TITLE_CONCAT + this.props.t('node_security_title');

    return (
      <div className={classes.root}>
        <EditRulesDialog
          network={network}
          cluster={cluster}
          node={node}
          rules={rules}
          open={openEditRulesDialog}
          onClose={this.onCloseEditRules}
        />
        <div className={classes.tableArea}>
          <LGButton
            id="node-security-button-edit"
            classes={{ root: classes.selectListBtn }}
            onClick={this.onOpenEditRules}
            disabled={!editableRules}
          >
            <div>
              <ImgIcon className={classes.listIcon} imgUrl={`/images/icons/edit-ico.png`} />
              <span className={classes.listSelectedName}>{t('edit_rules')}</span>
            </div>
          </LGButton>
          <Table id="node-security-list">
            <TableHeadCustom>
              <TableRowHeadCustom>
                <TableCellHeadCustom>
                  <span>{this.props.t('type')}</span>
                </TableCellHeadCustom>
                <TableCellHeadCustom>
                  <span>{this.props.t('protocol')}</span>
                </TableCellHeadCustom>
                <TableCellHeadCustom>
                  <span>{this.props.t('port_range')}</span>
                </TableCellHeadCustom>
                <TableCellHeadCustom>
                  <span>{this.props.t('source')}</span>
                </TableCellHeadCustom>
                <TableCellHeadCustom>
                  <span>{this.props.t('description')}</span>
                </TableCellHeadCustom>
              </TableRowHeadCustom>
            </TableHeadCustom>
            <TableBodyCustom>
              {rules.map((rule, i) => {
                const { protocol, range } = displayPortRange(rule.protocol);
                const typeInfo = protocolTypeSelection.find((t) => t.value === rule.protocol);
                return (
                  <TableRowBodyCustom key={i}>
                    <TableCellBodyCustom>
                      {typeInfo ? typeInfo.label : rule.protocol}
                    </TableCellBodyCustom>
                    <TableCellBodyCustom>{protocol}</TableCellBodyCustom>
                    <TableCellBodyCustom>{range}</TableCellBodyCustom>
                    <TableCellBodyCustom>{rule.cidr}</TableCellBodyCustom>
                    <TableCellBodyCustom className={classes.tableCellDesc}>
                      {rule.desc}
                    </TableCellBodyCustom>
                  </TableRowBodyCustom>
                );
              })}
            </TableBodyCustom>
          </Table>
        </div>
      </div>
    );
  }

  private getNodeRuleList = (): IRestrictionViewRules[] => {
    const base = this.getRestrictionList() || [];

    return base
      .map((r) =>
        r.inbound.map((ip) => ({
          protocol: r.protocol,
          cidr: ip.cidr,
          desc: ip.desc,
        })),
      )
      .reduce((pre, cur) => {
        pre.push(...cur);
        return pre;
      }, []);
  };

  getRestrictionList = () => {
    const { cluster, network, rules, node } = this.props;
    const restrictionNetwork = rules.find(
      (restrictionNetwork) => restrictionNetwork.networkUuid === network.networkUuid,
    );
    if (restrictionNetwork) {
      const restrictionCluster = restrictionNetwork.clusters.find(
        (restrictionCluster) => restrictionCluster.clusterUuid === cluster.clusterUuid,
      );
      if (restrictionCluster) {
        const restrictionNode = restrictionCluster.nodes.find(
          (restrictionNode) => restrictionNode.nodeUuid === node.nodeUuid,
        );
        if (restrictionNode) {
          return restrictionNode.rules;
        }
      }
    }
    return undefined;
  };

  private onOpenEditRules = () => {
    this.setState({
      openEditRulesDialog: true,
    });
  };

  private onCloseEditRules = () => {
    this.setState({
      openEditRulesDialog: false,
    });
  };
}

const styles = createStyles({
  root: {
    paddingBottom: 50,
  },
  nodeTitle: {
    ...defaultFontMedium,
    fontSize: 16,
    color: lightSlateGreyColor,
    marginBottom: 21,
  },
  nodeOptionPopoverPaper: {},
  clusterTitle: {
    ...defaultFont,
    fontSize: 15,
    color: lightSlateGreyColor,
  },
  titleArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 36,
    backgroundColor: snowColor,
    paddingLeft: 20,
    paddingRight: 10,
  },
  contentArea: {
    paddingTop: 20,
    paddingBottom: 30,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridRowGap: '20px',
  },
  clusterItemInfo: {
    paddingLeft: 20,
  },
  separateBorder: {
    borderRight: `1px solid ${pattensBlueColor}`,
  },
  borderBlock: {
    marginTop: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: pattensBlueColor,
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
    position: 'relative',
  },
  subTitle: {
    ...defaultFontBold,
    fontSize: 12,
  },
  detailValue: {
    ...defaultFont,
    fontSize: 15,
    color: lightSlateGreyColor,
  },
  // Node Type
  nodeType: {
    ...defaultFontMedium,
    display: 'flex',
    alignItems: 'center',
    height: 24,
    borderRadius: 14,
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: 12,
    color: matterhornColor,
  },
  backgroundRomanColor: {
    // backgroundColor: romanColor,
    backgroundColor: 'rgb(227, 90, 90, 0.2)',
  },
  backgroundSummerSkyColor: {
    // backgroundColor: summerSkyColor,
    backgroundColor: 'rgb(64, 194, 230, 0.2)',
  },
  backgroundCreamCanColor: {
    // backgroundColor: creamCanColor,
    backgroundColor: 'rgb(240, 193, 80, 0.2)',
  },
  nodeItemLeft: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    ...defaultFont,
    fontSize: 13,
    color: denimColor,
    '& span': {
      marginLeft: 10,
    },
  },
  nodeItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 40,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: whiteSmokeColor,
    border: `1px solid ${pattensBlueColor}`,
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 10,
  },
  // Create Node Button
  createNodeBtn: {
    width: '100%',
    height: 40,
    // '& span': {
    //   position: 'relative',
    //   justifyContent: 'center',
    // },
  },
  addNodeIcon: {
    position: 'absolute',
    left: 0,
  },
  createNodeBtnContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  recentEvent: {
    marginTop: 30,
    '& th:first-child': {
      paddingLeft: 10,
    },
  },
  recentEventTitle: {
    ...defaultFontBold,
    fontSize: 16,
    color: lightSlateGreyColor,
    marginBottom: 21,
  },
  // Handle Button
  handleBtnArea: {
    display: 'flex',
    alignItems: 'center',
  },
  verticalSeparate: {
    width: 1,
    height: 20,
    backgroundColor: pattensBlueColor,
    marginRight: 5,
    marginLeft: 5,
  },
  // Node option popover
  listOption: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listOptionItem: {
    height: 46,
    width: 210,
    ...defaultFont,
    fontSize: 16,
  },
  deleteIcon: {
    marginRight: 8,
  },
  horizontalSeparate: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
  tableArea: {
    marginTop: 5,
  },
  nodeStatusIcon: {
    marginRight: 10,
    verticalAlign: 'middle',
  },
  tableCellStatusText: {
    verticalAlign: 'middle',
    color: lightSlateGreyColor,
  },
  tableCellDesc: {
    maxWidth: 102,
    wordBreak: 'break-all',
  },
  recentEventDate: {
    ...defaultFont,
    color: lightSlateGreyColor,
    paddingLeft: 10,
  },
  selectListBtn: {
    width: 160,
    height: 34,
    marginTop: 20,
    marginBottom: 18,
  },
  listIcon: {
    verticalAlign: 'middle',
  },
  listSelectedName: {
    ...defaultFontMedium,
    ...oneLineText,
    fontSize: 15,
    marginRight: 10,
    marginLeft: 8,
    verticalAlign: 'middle',
  },
  loadingArea: {
    marginTop: 200,
    textAlign: 'center',
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  rules: store.appState.restrictions,
  isLoading: NetworkActions.listNodeRestrictions.isPending(store),
  isReloading: NetworkActions.setNodeRestriction.isPending(store),
  isCheckingRole: NetworkActions.getNetworkRole.isPending(store),
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
  accountSelected: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  getNetworkRole: (args: NetworkActions.QueryGetNetworkRoleArgs) =>
    dispatch(NetworkActions.getNetworkRole(args)),
  listNodeRules: (args: NetworkActions.QueryListNodeRestrictionsArgs) =>
    dispatch(NetworkActions.listNodeRestrictions(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SecurityTab)),
);
