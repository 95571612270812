import React from 'react';
// Component
import TableRowBodyCustom from '~/components/common/table-row-body';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import ImgIcon from '~/components/common/img-icon';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import {
  lightSlateGreyColor,
  denimColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
import { defaultFontBold, defaultFont } from '~/styles/themes/common-styles/font';
import { oneLineText } from '~/styles/themes/common-styles/misc';
// Translation
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
// Type
import { IAlert, AlertType } from '~/types/alert-types';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  alert: IAlert;
  showInfo: boolean;
  showNotificationInfo: () => void;
}

interface IState {}

class NotificationItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { classes, alert, showInfo, showNotificationInfo, i18n } = this.props;

    return (
      <TableRowBodyCustom classes={{ root: classes.tableRowBodyRoot }}>
        <TableCellBodyCustom className={classNames(classes.typeCell, classes.cellBody)}>
          <div className={classes.typeField}>{this.renderAlertType(alert.type)}</div>
        </TableCellBodyCustom>
        <TableCellBodyCustom className={classNames(classes.contentCell, classes.cellBody)}>
          <div
            onClick={showNotificationInfo}
            className={classNames(classes.alertTitle, { [classes.readedAlert]: alert.read })}
          >
            <span>{alert.title[i18n.language]}</span>
          </div>
          {showInfo && (
            <div>
              {alert.description && (
                <div className={classes.description}>{alert.description[i18n.language]}</div>
              )}
              {alert.linkUrl && (
                <a href={alert.linkUrl}>
                  <div className={classes.link}>{this.props.t('link').toLocaleUpperCase()}</div>
                </a>
              )}
            </div>
          )}
        </TableCellBodyCustom>
        <TableCellBodyCustom className={classNames(classes.dateCell, classes.cellBody)}>
          <span className={classes.dateText}>
            {alert.createAt && (
              <Trans i18nKey="alert_createAt" values={{ date: new Date(alert.createAt) }} />
            )}
          </span>
        </TableCellBodyCustom>
      </TableRowBodyCustom>
    );
  }

  private renderAlertType = (type: AlertType) => {
    switch (type) {
      case 'important':
        return (
          <>
            <ImgIcon imgUrl="/images/icons/alert_important_ico.png" />
            <span>{this.props.t('important')}</span>
          </>
        );
      case 'information':
        return (
          <>
            <ImgIcon imgUrl="/images/icons/alert_info_ico.png" />
            <span>{this.props.t('information')}</span>
          </>
        );
      case 'warning':
        return (
          <>
            <ImgIcon imgUrl="/images/icons/alert_warning_ico.png" />
            <span>{this.props.t('warning')}</span>
          </>
        );
      case 'error':
        return (
          <>
            <ImgIcon imgUrl="/images/icons/alert_error_ico.png" />
            <span>{this.props.t('error')}</span>
          </>
        );
      default:
        return null;
    }
  };
}

const styles: StyleRules = {
  tableRowBodyRoot: {
    verticalAlign: 'top',
  },
  cellBody: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  nodeStatusIcon: {
    marginRight: 10,
    transform: 'translateY(2px)',
  },
  typeField: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: 10,
      ...defaultFont,
      fontSize: 15,
      lineHeight: '18px',
      color: lightSlateGreyColor,
      letterSpacing: 'normal',
    },
  },
  leftGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  currentDisplay: {
    display: 'flex',
    alignItems: 'center',
  },
  currentDisplayText: {
    ...defaultFont,
    fontSize: 13,
    lineHeight: '15px',
    color: lightSlateGreyColor,
  },
  datePicker: {
    marginRight: 15,
    marginLeft: 15,
  },
  toText: {
    ...defaultFont,
    fontSize: 13,
    lineHeight: '15px',
    color: lightSlateGreyColor,
  },
  description: {
    fontSize: 13,
    color: lightSlateGreyColor,
    lineHeight: 1.62,
  },
  link: {
    ...defaultFontBold,
    textAlign: 'center',
    fontSize: 13,
    color: denimColor,
    cursor: 'pointer',
  },
  alertTitle: {
    ...oneLineText,
    cursor: 'pointer',
    '& span': {
      ...defaultFontBold,
      fontSize: 15,
      lineHeight: '18px',
      color: nightRiderColor,
      whiteSpace: 'normal !important',
      letterSpacing: 'normal',
    },
  },
  dateText: {
    letterSpacing: 'normal',
  },
  readedAlert: {
    '& span': {
      ...defaultFont,
    },
  },
};

export default withStyles(styles)(withTranslation()(NotificationItem));
